import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ViewUserInfoService } from "../../services/view-user-info.service";
import { NotificationService } from "@shared/services/notification.service";
import { AuthService } from "../../../auth/auth.service";
import { photoLinks } from "@shared/constants";
import { SettingsApiService } from '../../api-services/settings-api.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  ChangePasswordModalComponent
} from '@shared/components/change-password-modal/change-password-modal.component';

@Component({
  selector: 'app-teacher-settings-modal',
  templateUrl: './teacher-settings-modal.component.html',
  styleUrls: ['./teacher-settings-modal.component.scss']
})
export class TeacherSettingsModalComponent {

  @ViewChild('photo') private uploadPhoto: ElementRef;
  public readonly maxKilobytesPerPhoto = 5000;
  public userPhotoLinks = photoLinks;

  public photoAcceptanceFiles: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      avatarLink?: string;
      avatarLetter: string;
      teacherId: string;
    },
    public translateService: TranslateService,
    private dialog: MatDialog,
    private viewUserInfoService: ViewUserInfoService,
    public readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private settingsService: SettingsApiService,
    private readonly modalService: NzModalService,
  ) {
  }

  public closeDialog(): void {
    this.dialog.getDialogById('teacher-settings-modal')?.close();
  }

  getUserEmail(): string {
    return this.authService.user?.email;
  }

  uploadNewPhoto(): void {
    this.uploadPhoto.nativeElement.click();
  }

  removePhoto(): void {
    this.settingsService.removeFileByUrl$DELETE(this.data.teacherId, this.data.avatarLink).toPromise().then(() => {
      this.updateInfo();
      this.data.avatarLink = undefined;
      this.authService.updateUserAvatar(null);
    });
  }

  private updateInfo(): void {
    this.viewUserInfoService.loadTeacherInfo();
  }

  public handlePhotoInputChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList = element.files as FileList;
    const formData = new FormData();
    const targetFile: File = fileList.item(0) as File;

    if (!this.photoAcceptanceFiles.some(item => item === ('.' + targetFile.name.split('.')[targetFile.name.split('.').length - 1]))) {
      this.notificationService.showErrorMessage(this.translateService.instant('invalid_data_format'));
      return;
    }
    if (!this.calculateSizeOfFile(targetFile, this.maxKilobytesPerPhoto)) {
      this.notificationService.showErrorMessage(this.translateService.instant('file_too_big'));
      return;
    }
    formData.append('file', targetFile);

    this.settingsService.updateTeacherPhoto(this.data.teacherId, formData).subscribe((data: { avatarLink: string }) => {
      this.data.avatarLink = data.avatarLink;
      this.authService.updateUserAvatar(data.avatarLink);
      this.updateInfo();
      this.notificationService.showSuccessMessage(this.translateService.instant('successful_uploading'));
    });
  }

  public onChangePassword(): void {
    this.closeDialog();

    this.modalService.create({
      nzClosable: true,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: true,
      nzBodyStyle: { padding: '0' },
      nzContent: ChangePasswordModalComponent,
      nzData: {
        userId: this.authService.user.id,
      },
    });
  }

  private calculateSizeOfFile(file: File, maxFileSize: number): boolean {
    if (!this.maxKilobytesPerPhoto || maxFileSize === 0) {
      return true;
    }
    const totalKiloBytes = file.size / 1024;
    return totalKiloBytes <= maxFileSize;
  }
}
