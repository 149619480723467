<div class="lesson-details-wrapper" *ngIf="lessonData$ | async as lesson; else loadingOrError">
  <div class="lesson-details-body">
    <div class="lesson-conduction" [class.mobile]="mobileMode">
      <ng-container *ngIf="(authService.isTeacher() || authService.isAdmin()) && lesson.status !== 'reserved'">
        <div *ngIf="authService.isTeacher() && isNotFutureAndNotMoreThanTwoDaysAgo(lesson)">
          <ng-template [ngTemplateOutlet]="lessonConduction"></ng-template>
        </div>
        <div *ngIf="authService.isAdmin()">
          <ng-template [ngTemplateOutlet]="lessonConduction"></ng-template>
        </div>
        <ng-template #lessonConduction>
          <div class="not-conducted-lesson" *ngIf="!lesson.isConducted; else lessonIsConducted">
            <button (click)="onConductLesson.emit(lesson)" nz-button>{{'calendar.lesson-details.conduct_lesson' | translate}}</button>
          </div>
          <ng-template #lessonIsConducted>
            <div class="conducted-lesson" (click)="onCancelConductLesson.emit()">
              <span nz-icon><img src="assets/icons/calendar-done-white.svg" alt="done"></span>
              <span>{{'calendar.lesson-details.lesson_conducted' | translate}}</span>
            </div>
          </ng-template>
        </ng-template>
      </ng-container>
    </div>
    <div
      class="actions"
      *ngIf="lesson.type === 'regular' && isLessonMoveAllowed(lesson)"
    >
      <button
        nz-button
        (click)="onSkipLesson.emit(lesson)"
        [disabled]="lesson.isConducted"
        *ngIf="!lesson.markedForFutureSubscription"
      >
        {{'calendar.lesson-details.skip_lesson' | translate}}
      </button>
      <button nz-button (click)="onMoveLesson.emit()" [disabled]="lesson.isConducted">
        {{'calendar.lesson-details.move_lesson' | translate}}
      </button>
    </div>
    <div class="actions" *ngIf="lesson.type === 'first' && !authService.isTeacherOrClient()">
      <button nz-button (click)="onEditFirstLesson.emit()" [disabled]="lesson.isConducted">{{'edit' | translate}}</button>
      <button nz-button (click)="onCancelLesson.emit()" [disabled]="lesson.isConducted">
        {{'calendar.lesson-details.cancel_lesson' | translate}}
      </button>
    </div>
    <ng-container *ngIf="(lesson.history | filter : filterAllowedHistoryRecords) as history">
      <ng-container *ngIf="history?.length">
        <nz-collapse class="lesson-history">
          <nz-collapse-panel [nzHeader]="'calendar.lesson-details.history.title' | translate">
            <nz-timeline>
              <nz-timeline-item *ngFor="let record of history" [nzDot]="timelineIcon">
                <p class="action-name">
                  {{('calendar.lesson-details.history.' + record.context.action) | translate}}&nbsp;&nbsp;
                  <span>{{formatHistoryDate(record.createdAt)}}</span>
                </p>
                <div class="changes" *ngIf="['move', 'skip'].includes(record.context.action)">
                  <div><p>{{formatDate(record.previousValue.date)}}</p><p>{{timeSlotToTime(record.previousValue.timeSlot)}}</p></div>
                  <span nz-icon nzType="right" nzTheme="outline"></span>
                  <div><p>{{formatDate(record.newValue.date)}}</p><p>{{timeSlotToTime(record.newValue.timeSlot)}}</p></div>
                </div>
                <div class="skip-reason" *ngIf="record.context.action === 'skip' && record.context.reason">
                  <p *ngIf="record.context.reason === 'other'; else skipReason">{{record.context.reasonDescription}}</p>
                  <ng-template #skipReason>
                    <p>{{('calendar.skip-lesson.reason.' + record.context.reason) | translate}}</p>
                  </ng-template>
                </div>
                <div class="rate" *ngIf="record.context.action === 'rate_by_client'">
                  <math-lesson-rate [value]="record.newValue?.clientRate" [disabled]="true"></math-lesson-rate>
                </div>
                <div class="author">
                  <div nz-typography nzCopyable [nzCopyTooltips]="null" [nzCopyText]="record.user.firstName + ' ' + record.user.lastName">
                    <span nz-typography><strong>{{record.user.firstName}} {{record.user.lastName}}</strong></span>
                    <span class="role">{{('main.roles.' + record.user.role.name | lowercase) | translate}}</span>
                  </div>
                  <ng-container
                    *ngIf="(authService.isTeacherOrClient() && record.user.role.name === userRole.CLIENT); else
                    email"></ng-container>
                  <ng-template #email>
                    <div class="ant-typography" *ngIf="record.user.role.name === userRole.TEACHER; else userEmail">
                      <a
                        (click)="$event.stopPropagation();
                          openAccountInfoPage(record.user.teacherId)"
                      >{{record.user.email}}
                      </a>
                      <span
                        nz-typography
                        nzCopyable
                        [nzCopyTooltips]="null"
                        [nzCopyText]="record.user.email"
                      >
                      </span>
                    </div>
                  </ng-template>
                </div>
                <ng-template #timelineIcon>
                  <span *ngIf="record.context.action === 'move'" nz-icon nzType="swap"></span>
                  <span *ngIf="record.context.action === 'skip'" nz-icon nzType="node-index"></span>
                  <span *ngIf="record.context.action === 'conduct'" nz-icon nzType="check-circle" nzTheme="outline" style="color: #1CC28F"></span>
                  <span *ngIf="record.context.action === 'remove_conduction'" nz-icon nzType="undo" nzTheme="outline" style="color: #E53935"></span>
                  <span *ngIf="record.context.action === 'rate_by_client'" nz-icon nzType="star" nzTheme="fill" style="color: #FFC368"></span>
                </ng-template>
                <ng-template #userEmail>
                  <div nz-typography nzCopyable [nzCopyTooltips]="null" [nzContent]="record.user.email"></div>
                </ng-template>
              </nz-timeline-item>
            </nz-timeline>
          </nz-collapse-panel>
        </nz-collapse>
      </ng-container>
    </ng-container>
    <div class="lesson-details">
      <div class="details-block">
        <div class="lesson-detail">
          <div class="label">{{'calendar.lesson-details.event_datetime' | translate}}:</div>
          <div class="value date">{{formatDate(lesson.date)}}<span class="time">{{timeSlotToTime(lesson.timeSlot)}}</span></div>
        </div>
      </div>
      <div class="details-block">
        <div class="lesson-detail" *ngIf="!authService.isTeacherOrClient()">
          <div class="label">{{'calendar.lesson-details.deal_number' | translate}}:</div>
          <div class="value">{{lesson.deal.dealNumber}}</div>
        </div>
        <div class="lesson-detail">
          <div class="label">{{'teacher' | translate}}:</div>
          <div class="value">{{lesson.teacher.firstName}} {{lesson.teacher.lastName}}</div>
        </div>
      </div>
      <div class="details-block white">
        <div class="lesson-detail">
          <div class="label">{{'student' | translate}}:</div>
          <div class="value">{{lesson.student?.firstName}} {{lesson.student?.lastName}}
            <span *ngIf="lesson.deal.classNumber; else classFromStudent" class="sticker grade">{{buildClassNumber(lesson.deal.classNumber)}}</span>
            <ng-template #classFromStudent>
              <span *ngIf="lesson.student.grade" class="sticker grade">{{buildClassNumber(lesson.student.grade)}}</span>
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="!authService.isClient()">
          <div class="lesson-detail" *ngIf="lesson.deal.client">
            <div class="label">{{'contact' | translate}}:</div>
            <div class="value">{{lesson.deal.client?.firstName}} {{lesson.deal.client?.lastName}}</div>
            <div class="value">{{lesson.deal.client?.phone}}</div>
            <div class="value">{{lesson.deal.client?.secondPhone}}</div>
          </div>
        </ng-container>

        <div class="lesson-detail" *ngIf="lesson.status === 'booked'">
          <button class="link-to-journal" (click)="openStudentJournal(lesson.student.id)">
            <span nz-icon nzType="unordered-list"></span>
            {{'calendar.lesson-details.student-journal' | translate}}
          </button>
        </div>
      </div>
      <div class="details-block">
        <div class="lesson-detail">
          <div class="label">{{'lesson_type' | translate}}:</div>
          <div class="value">
            <span [className]="'sticker ' + lesson.type">{{'just_' + lesson.type | translate | titlecase}}</span>
            <span [ngClass]="{
              sticker: true,
              reserved: lesson.status === 'reserved',
              booked: lesson.status === 'booked',
              'future-subscription': lesson.markedForFutureSubscription && !authService.isTeacher()
            }">{{lesson.status | translate}}</span>
          </div>
        </div>
        <div class="lesson-detail" *ngIf="lesson.deal?.internalType && lesson.type !== 'first'">
          <div class="label">{{'calendar.lesson-details.abonement_type' | translate}}:</div>
          <div class="value"><span class="sticker subscription">{{'calendar.lesson-details.' + (lesson.deal?.internalType === 'report' ? 'report' : 'report&check') | translate}}</span></div>
        </div>
      </div>
      <ng-container *ngIf="!lesson.markedForFutureSubscription">
        <div class="details-block" *ngIf="lesson.deal.lang">
          <div class="lesson-detail">
            <div class="label">{{'study_language' | translate}}:</div>
            <div class="value">{{lesson.deal.lang.slice(0, 2) | lowercase | translate}}</div>
          </div>
        </div>
        <div class="details-block" *ngIf="!authService.isClient() && getPaidLessonsFromInvoices(lesson.deal.invoices) as paidLessons">
          <div class="lesson-detail">
            <div class="label">{{'calendar.lesson-details.paid-lessons' | translate}}:</div>
            <div class="value">{{paidLessons}}</div>
          </div>
        </div>
        <div class="details-block" *ngIf="!authService.isClient() && lesson.type === 'regular'">
          <div class="lesson-detail">
            <div class="label">{{'calendar.lesson-details.paid_lessons_remainder' | translate}}:</div>
            <div class="value">{{getPaidNotConductedLessonsFromDeal(lesson.deal)}} {{'from' | translate}} {{getAllPaidLessonsFromDeal(lesson.deal)}}</div>
          </div>
          <div class="lesson-detail">
            <div class="label">{{'calendar.lesson-details.lessons_reserved' | translate}}:</div>
            <div class="value">{{getAllReservedLessonsFromDeal(lesson.deal)}}</div>
          </div>
        </div>
        <div class="details-block" *ngIf="!authService.isClient() && lesson.deal.parentComment">
          <div class="lesson-detail">
            <div class="label">{{'calendar.lesson-details.additionally_about_study' | translate}}:</div>
            <div class="value parent-comment" [innerHTML]="formatParentComment(lesson.deal.parentComment)"></div>
          </div>
        </div>
        <ng-container *ngIf="lesson.type === 'regular'">
          <ng-container *ngIf="lesson.deal.typeId === dealType.STUDY_PROGRESS; else notStudyProgressDeal">
            <ng-container *ngIf="lesson.deal?.parentDeal as deal">
              <ng-template *ngTemplateOutlet="isPartialPaymentDeal; context: { typeId: deal.typeId, stageId: deal.stageId }"></ng-template>
            </ng-container>
          </ng-container>
          <ng-template #notStudyProgressDeal>
            <ng-container *ngIf="lesson.deal as deal">
              <ng-template *ngTemplateOutlet="isPartialPaymentDeal; context: { typeId: deal.typeId, stageId: deal.stageId }"></ng-template>
            </ng-container>
          </ng-template>
          <ng-template #isPartialPaymentDeal let-typeId="typeId" let-stageId="stageId">
            <ng-container
              *ngIf="typeId === dealType.STUDY_REQUEST && stageId === studyRequestStages.SUBSCRIPTION_PARTIALLY_PAID">
              <ng-template [ngTemplateOutlet]="partialPaymentInfo"></ng-template>
            </ng-container>
            <ng-container
              *ngIf="typeId === dealType.SELL_REPEAT && stageId === sellRepeatStages.NEW_SUBSCRIPTION_PARTIALLY_PAID">
              <ng-template [ngTemplateOutlet]="partialPaymentInfo"></ng-template>
            </ng-container>
          </ng-template>
          <ng-template #partialPaymentInfo>
            <div class="details-block">
              <mathema-info-block _type="info" [text]="'calendar.lesson-details.partial-payment-tip' | translate"></mathema-info-block>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </div>
    <div class="subscription-edit">
      <button
        *ngIf="lesson.type === 'regular' && !authService.isTeacherOrClient() && !lesson.markedForFutureSubscription"
        nz-button
        (click)="onEditDealClick(lesson.deal.id)"
      >{{'calendar.lesson-details.edit-subscription' | translate}}</button>
    </div>
    <div class="future-subscription-lesson-wrap" [class.mobile]="mobileMode" *ngIf="lesson.markedForFutureSubscription && !authService.isTeacherOrClient()">
      <div class="future-subscription-lesson">
        <span>{{'calendar.lesson-details.next_sub_reservations' | translate}}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple class="calendar-lesson-details-loader"></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
