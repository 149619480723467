import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ViewUserInfoService} from "@modules/components/view-user-info/services/view-user-info.service";
import { HttpClient, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {AuthService} from "@modules/components/auth/auth.service";
import {photoLinks} from "../../constants";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-photo-modal',
  templateUrl: './change-photo-modal.component.html',
  styleUrls: ['./change-photo-modal.component.scss']
})
export class ChangePhotoModalComponent {
  @ViewChild('photo') private uploadPhoto: ElementRef;
  public readonly maxKilobytesPerPhoto = 5000;
  public userPhotoLinks = photoLinks;

  public photoAcceptanceFiles: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      avatarLink?: string;
      avatarLetter: string;
      teacherId: string;
    },
    public translateService: TranslateService,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private viewUserInfoService: ViewUserInfoService,
    public readonly notificationService: NotificationService,
    private readonly authService: AuthService,
  ) {}

  public closeDialog(): void {
    this.dialog.getDialogById('change-photo-modal')?.close();
  }

  uploadNewPhoto(): void {
    this.uploadPhoto.nativeElement.click();
  }

  removePhoto(): void {
    this.removeFileByUrl$DELETE(this.data.teacherId).toPromise().then(()=>{
      this.updateInfo();
      this.data.avatarLink = undefined;
      this.authService.updateUserAvatar(null);
    })

  }


  private updateInfo(): void {
    this.viewUserInfoService.loadTeacherInfo();
  }

  public handlePhotoInputChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList = element.files as FileList;
    const formData = new FormData();
    const targetFile: File = fileList.item(0) as File;

    if(!this.photoAcceptanceFiles.some(item=>item === ('.' + targetFile.name.split('.')[targetFile.name.split('.').length - 1]))){
      this.notificationService.showErrorMessage(this.translateService.instant('invalid_data_format'));
      return;
    }
    if(!this.calculateSizeOfFile(targetFile, this.maxKilobytesPerPhoto)){
      this.notificationService.showErrorMessage(this.translateService.instant('file_too_big'));
      return;
    }
    formData.append('file', targetFile);


    const request = new HttpRequest('POST', `/teachers/${this.data.teacherId}/updatePhoto`, formData, {
      reportProgress: true,
      responseType: 'json'
    });


    this.httpClient.request(request).toPromise().then((res )=>{
      let data: {body: {
          avatarLink: string
        }} = res as {body: {
          avatarLink: string
        }}
      this.data.avatarLink = data.body.avatarLink;
      this.authService.updateUserAvatar(data.body.avatarLink);
      this.updateInfo();
      this.notificationService.showSuccessMessage(this.translateService.instant('successful_uploading'));
    });
  }

  private calculateSizeOfFile(file: File, maxFileSize: number): boolean {
    if (!this.maxKilobytesPerPhoto || maxFileSize === 0) {
      return true;
    }
    const totalKiloBytes = file.size / 1024;
    return totalKiloBytes <= maxFileSize;
  }

  public removeFileByUrl$DELETE(teacherId: string): Observable<void> {
    return this.httpClient.delete<void>(`/teachers/${teacherId}/avatar`, {body: {url: this.data.avatarLink}});
  }
}
