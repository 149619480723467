<input style="display: none" type="file" (change)="handlePhotoInputChange($event)" [accept]="photoAcceptanceFiles" [multiple]="false" #photo>
<input style="display: none" type="file" (change)="handleFileInputChange($event)" [accept]="fileAcceptanceFiles" [multiple]="false" #file>

<ng-template #pageTitle>
  <div class="titles">
    <div class="nav-button" (click)="handleBackClick()">
      <mat-icon>keyboard_backspace</mat-icon>
      {{'teacher_cabinet' | translate}}
    </div>
    <div class="header">
      {{'profile_changing' | translate}}
    </div>
  </div>
</ng-template>

<math-base-wrapper [pageTitle]="pageTitle">
  <div *ngIf="isLoading else content" class="loading">
    <mat-spinner></mat-spinner>
  </div>

  <ng-template #content>
    <div class="content-wrapper">
      <div class="content">
        <div class="menu">
          <app-button
            *ngFor="let page of pagesInfo"
            [btnId]="page.label"
            (btnClick)="handleMenuClick($event)"
            [icon]="page.icon"
            [underlineAnimation]="true"
            [text]="page.label | translate"
            [selected]="selectedPage === page.label"
            [buttonType]="'menuButton'">
          </app-button>
          <!-- <div *ngIf="authService.isTeacher()" class="teacher-alert-info">
            <mat-icon>info</mat-icon>
            <span class="text">{{'curators_to_change_label' | translate}}</span>
          </div> -->
        </div>
        <div class="pages">
          <div class="header">{{selectedPage | translate}}</div>

          <div class="main-content" [ngSwitch]="selectedPage">

            <div class="personal-info" *ngSwitchCase="pagesNames.PERSONAL_INFO">
              <div class="row">
                <div class="photo-loading-container">
                  <div class="avatar">
                    <img [src]="getUserAvatarLink()" alt="user avatar" *ngIf="getUserAvatarLink() else noAvatar">
                    <ng-template #noAvatar>
                      {{getUserAvatarLetter()}}
                    </ng-template>
                  </div>
                  <div class="buttons-container">
                    <app-button (btnClick)="loadPhoto()" [buttonType]="'loading-file-btn'" [text]="'add_photo' | translate"></app-button>
                    <app-button [disabled]="!userInfo.avatarLink" (btnClick)="removePhoto()" [buttonType]="'remove-file-btn'" [text]="'remove_photo' | translate"></app-button>
                  </div>
                  <div class="additional-text">
                    <img src="../../../../../assets/add-photo-tooltip.png" alt="adding">
                    <span>{{'image_alert' | translate}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <app-input
                  inputType="text"
                  [label]="'name' | translate"
                  [formControl]="firstName"
                  [error]="firstName.invalid && firstName.touched"
                  [errorText]="getErrorText('firstName')"
                ></app-input>
                <app-input
                  inputType="text"
                  [label]="'surname' | translate"
                  [formControl]="lastName"
                  [error]="lastName.invalid && lastName.touched"
                  [errorText]="getErrorText('lastName')"
                ></app-input>
                <app-input
                  inputType="dropdown"
                  [label]="'gender' | translate"
                  [dropdownParams]="genderArr"
                  [error]="!gender && tappedFields.includes('gender')"
                  (click)="onFieldsFocus('gender')"
                  [value]="gender"
                  [errorText]="getErrorText('gender')"
                  (dropdownSelectValue)="handleGenderSelect($event)"
                ></app-input>
              </div>
              <div class="row">
                <app-input
                  inputType="countries-dropdown"
                  [label]="'country' | translate"
                  [dropdownParams]="countryArr"
                  [placeholder]="('country-search' | translate) + '...'"
                  [error]="!country && tappedFields.includes('country')"
                  (click)="onFieldsFocus('country')"
                  [value]="country"
                  [errorText]="getErrorText('country')"
                  (dropdownSelectValue)="handleCountrySelect($event)"
                ></app-input>
                <app-input
                  inputType="text"
                  [formControl]="birthDate"
                  additionalParams="date"
                  placeholder="MM.DD.YYYY"
                  [error]="birthDate.invalid && birthDate.touched"
                  [errorText]="getErrorText('dateOfBirth')"
                  [label]="'birth_date' | translate"
                ></app-input>
                <app-input
                  *ngIf="!authService.isTeacher()"
                  inputType="dropdown"
                  [error]="!status && tappedFields.includes('status')"
                  (click)="onFieldsFocus('status')"
                  [dropdownParams]="statuses"
                  [value]="status"
                  [errorText]="getErrorText('status')"
                  (dropdownSelectValue)="handleStatusSelect($event)"
                  [label]="'status' | translate"
                ></app-input>
              </div>
              <div class="row">
                <app-input
                  style="margin-right: 81px"
                  inputType="text"
                  [label]="'email' | translate"
                  [customInputParams]="{customWidth: '360px'}"
                  [formControl]="email"
                  [error]="email.invalid && email.touched"
                  [errorText]="getErrorText('email')"
                  *ngIf="!authService.isTeacher()"
                ></app-input>
                <app-input
                  inputType="text"
                  [label]="'mobile_number' | translate"
                  additionalParams="phone-number"
                  placeholder="+(XXX) XXX XXX XXX"
                  [formControl]="phoneNumber"
                  [error]="phoneNumber.invalid && phoneNumber.touched"
                  [errorText]="getErrorText('phoneNumber')"
                ></app-input>
              </div>
            </div>

            <div *ngSwitchCase="pagesNames.WORK_SPECIFICATION">
              <div class="row" style="margin-top: 8px">
                <app-input
                  inputType="dropdown"
                  [error]="!tutorExperience && tappedFields.includes('tutorExperience')"
                  (click)="onFieldsFocus('tutorExperience')"
                  [dropdownParams]="experienceArr"
                  [value]="tutorExperience"
                  (dropdownSelectValue)="handleTutorExperienceSelect($event)"
                  [errorText]="getErrorText('tutorExperience')"
                  [label]="'tutor_experience' | translate"
                ></app-input>

                <app-input
                  inputType="dropdown"
                  [error]="!experience && tappedFields.includes('experience')"
                  (click)="onFieldsFocus('experience')"
                  [dropdownParams]="experienceStudyArr"
                  [value]="experience"
                  (dropdownSelectValue)="handleStudyExperienceSelect($event)"
                  [errorText]="getErrorText('experience')"
                  [label]="'study_experience' | translate"
                ></app-input>

                <app-input
                  style="margin-left: 40px"
                  inputType="multiselect-square"
                  [error]="handleMultiselectError('workingGradesArr').errorState && tappedFields.includes('working_grades')"
                  (click)="onFieldsFocus('working_grades')"
                  [errorText]="handleMultiselectError('workingGradesArr').errorText"
                  [label]="'working_grades' | translate"
                  [multiselectData]="workingGradesArr"
                  [language]="getUserLangByRegion(userInfo.marketRegion)"
                  (multiselectDataOutput)="multiselectValueChange($event, 'grades')"
                ></app-input>
              </div>
              <div class="row">
                <div class="container-with-label">
                  <label>{{'preparing_to_exams' | translate}}:</label>
                  <div class="content exams">
                    <div class="input-combo">
                      <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox" [(ngModel)]="zno" [disabled]="!isExamPrepAvailable()"></div>
                      <div class="input-title">{{'zno' | translate}}</div>
                      <app-input
                        *ngIf="zno"
                        [hideLabel]="true"
                        [isDisabled]="!zno"
                        [formControl]="znoValue"
                        [error]="znoValue.invalid && znoValue.touched"
                        [errorText]="getErrorText('znoValue')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="dpa"><div class="border" (click)="dpa = !dpa"></div></div>
                      <div class="input-title">{{'dpa' | translate}}</div>
                      <app-input
                        *ngIf="dpa"
                        [hideLabel]="true"
                        [isDisabled]="!dpa"
                        [formControl]="dpaValue"
                        [error]="dpaValue.invalid && dpaValue.touched"
                        [errorText]="getErrorText('dpaValue')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.UKRAINE && (authService.isManager() || authService.isAdmin())">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="nmt"><div class="border" (click)="nmt = !nmt"></div></div>
                      <div class="input-title">{{'nmt' | translate}}</div>
                      <app-input
                        *ngIf="nmt"
                        [hideLabel]="true"
                        [isDisabled]="!nmt"
                        [formControl]="nmtValue"
                        [error]="nmtValue.invalid && nmtValue.touched"
                        [errorText]="getErrorText('nmtValue')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.POLAND">
                      <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox" [(ngModel)]="polExam" [disabled]="!isExamPrepAvailable()"></div>
                      <div class="input-title">{{'polExam' | translate}}</div>
                      <app-input
                        *ngIf="polExam"
                        [hideLabel]="true"
                        [isDisabled]="!polExam"
                        [formControl]="polExamValue"
                        [error]="polExamValue.invalid && polExamValue.touched"
                        [errorText]="getErrorText('polExamValue')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.POLAND">
                      <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox" [(ngModel)]="polInternational" [disabled]="!isExamPrepAvailable()"></div>
                      <div class="input-title">{{'polInternational' | translate}}</div>
                      <app-input
                        *ngIf="polInternational"
                        [hideLabel]="true"
                        [isDisabled]="!polInternational"
                        [formControl]="polInternationalValue"
                        [error]="polInternationalValue.invalid && polInternationalValue.touched"
                        [errorText]="getErrorText('polInternationalValue')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.UNITED_KINGDOM">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="ks2"><div class="border" (click)="ks2 = !ks2"></div></div>
                      <div class="input-title">{{'ks2' | translate}}</div>
                      <app-input
                        *ngIf="ks2"
                        [hideLabel]="true"
                        [isDisabled]="!ks2"
                        [formControl]="ks2Value"
                        [error]="ks2Value.invalid && ks2Value.touched"
                        [errorText]="getErrorText('ks2')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.UNITED_KINGDOM">
                      <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox" [(ngModel)]="gcse_foundation" [disabled]="!isExamPrepAvailable()"></div>
                      <div class="input-title">{{'gcse_foundation' | translate}}</div>
                      <app-input
                        *ngIf="gcse_foundation"
                        [hideLabel]="true"
                        [isDisabled]="!gcse_foundation"
                        [formControl]="gcse_foundationValue"
                        [error]="gcse_foundationValue.invalid && gcse_foundationValue.touched"
                        [errorText]="getErrorText('gcse_foundation')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo" *ngIf="userInfo.marketRegion === MarketRegion.UNITED_KINGDOM">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="ib_math"><div class="border" (click)="ib_math = !ib_math"></div></div>
                      <div class="input-title">{{'ib_math' | translate}}</div>
                      <app-input
                        *ngIf="ib_math"
                        [hideLabel]="true"
                        [isDisabled]="!ib_math"
                        [formControl]="ib_mathValue"
                        [error]="ib_mathValue.invalid && ib_mathValue.touched"
                        [errorText]="getErrorText('ib_math')"
                        [inputType]="'number'"
                        [min]="1"
                        [title]="'years_of_experience' | translate">
                      </app-input>
                    </div>
                    <div class="input-combo">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="examsOther"><div class="border" (click)="examsOther = !examsOther"></div></div>
                      <div class="input-title">{{'other' | translate}}</div>
                      <app-input
                        [hideLabel]="true"
                        *ngIf="examsOther"
                        [inputType]="'text'"
                        [error]="examsOtherValue.invalid && examsOtherValue.touched"
                        [errorText]="getErrorText('examsOtherValue')"
                        [formControl]="examsOtherValue"
                        [placeholder]="'explanation_to_other' | translate">
                      </app-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <app-input
                  inputType="multiselect-auto-size"
                  [label]="'study_programs' | translate"
                  [multiselectData]="studyProgramsArr"
                  [language]="getUserLangByRegion(userInfo.marketRegion)"
                  (multiselectDataOutput)="multiselectValueChange($event, 'studyPrograms')"
                ></app-input>
                <app-input
                  style="margin-left: -40px"
                  inputType="multiselect-auto-size"
                  [multiselectData]="studyProgramsOther"
                  (multiselectDataOutput)="multiselectValueChange($event, 'studyProgramsOther')"
                ></app-input>
                <app-input
                  inputType="text"
                  style="margin-left: -40px"
                  *ngIf="studyProgramsOther[0].selected"
                  [inputType]="'text'"
                  [error]="studyProgramsOtherValue.invalid && studyProgramsOtherValue.touched"
                  [formControl]="studyProgramsOtherValue"
                  [errorText]="getErrorText('studyProgramsOtherValue')"
                  [placeholder]="'explanation_to_other' | translate"
                ></app-input>
              </div>
              <div class="row">
                <div class="container-with-label">
                  <label>{{'study_language' | translate}}</label>
                  <div class="content checkboxes">
                    <div class="input-combo language-input" *ngFor="let item of languageArr">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="item.selected"> </div>
                      <span class="fi fi-{{item.iconUrl}}" *ngIf="item.iconUrl"></span>
                      <div class="language-label">{{item.label | translate}}</div>
                    </div>
                    <app-input
                      [hideLabel]="true"
                      style="margin-top: 0"
                      *ngIf="isOtherStudyLanguageSelected()"
                      inputType="text"
                      [error]="langOther.invalid && langOther.touched"
                      [formControl]="langOther"
                      [errorText]="getErrorText('langOther')">
                    </app-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <app-input
                  inputType="dropdown"
                  [error]="!studyStyle && tappedFields.includes('studyStyle')"
                  (click)="onFieldsFocus('studyStyle')"
                  [value]="studyStyle"
                  [dropdownParams]="studyStyleArr"
                  (dropdownSelectValue)="handleStudyStyleSelect($event)"
                  [errorText]="getErrorText('studyStyle')"
                  [label]="'study_style' | translate"
                ></app-input>
                <div class="container-with-label">
                  <label>{{'additional_jubjects' | translate}}:</label>
                  <div class="content checkboxes">
                    <div class="input-combo">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="physics"><div class="border" (click)="physics = !physics"></div></div>
                      <div class="input-title">{{'phisics' | translate}}</div>
                    </div>
                    <div class="input-combo">
                      <div class="checkbox"><input type="checkbox" [(ngModel)]="informatics"><div class="border" (click)="informatics = !informatics"></div></div>
                      <div class="input-title">{{'informatics' | translate}}</div>
                    </div>
                  </div>
                </div>
                <div class="container-with-label">
                  <label>{{'depth_study' | translate}}:</label>
                  <div class="content checkboxes">
                    <div class="input-combo">
                      <div class="radio" nz-tooltip [nzTooltipTitle]="'deep_study_restriction' | translate"><input type="radio" name="depth_study" [value]="true" [(ngModel)]="depthStudy" [disabled]="!isDeepStudyAvailable()">
                        <!-- <div class="border" (click)="depthStudy = true"></div> -->
                      </div>
                      <div class="input-title">{{'yes' | translate}}</div>
                    </div>
                    <div class="input-combo">
                      <div class="radio"><input type="radio" name="depth_study" [value]="false" [(ngModel)]="depthStudy" [disabled]="!isDeepStudyAvailable()">
                        <!-- <div class="border" (click)="depthStudy = false"></div> -->
                      </div>
                      <div class="input-title">{{'no' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="container-with-label">
                  <label>{{'special_needs' | translate}}:</label>
                  <div class="content checkboxes">
                    <div class="content checkboxes" nz-tooltip
                         [nzTooltipTitle]="'curators_to_change' | translate"
                         [nzTooltipTrigger]="authService.isTeacher() ? 'hover' : null">
                      <div class="input-combo" *ngFor="let item of specialNeeds">
                        <div class="checkbox"><input type="checkbox" [(ngModel)]="item.state" [disabled]="authService.isTeacher()"></div>
                        <div class="input-title">{{item.label | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: -21px">
                <app-input
                  inputType="multiselect-auto-size"
                  [error]="handleMultiselectError('toolsForStuding').errorState && tappedFields.includes('tools_for_studing')"
                  (click)="onFieldsFocus('tools_for_studing')"
                  [errorText]="handleMultiselectError('toolsForStuding').errorText"
                  [multiselectData]="toolsForStuding"
                  [label]="'tools_for_studing' | translate"
                  (multiselectDataOutput)="multiselectValueChange($event, 'toolsForStuding')"
                ></app-input>
                <app-input
                  style="margin-left: -40px"
                  inputType="multiselect-auto-size"
                  [error]="handleMultiselectError('toolsForStuding').errorState && tappedFields.includes('tools_for_studing')"
                  (click)="onFieldsFocus('tools_for_studing')"
                  [hideLabel]="true"
                  [multiselectData]="toolsForStudingOther"
                  (multiselectDataOutput)="multiselectValueChange($event, 'toolsForStudingOther')"
                ></app-input>
                <app-input
                  inputType="text"
                  style="margin-left: -40px"
                  *ngIf="toolsForStudingOther[0].selected"
                  [inputType]="'text'"
                  [error]="toolsForStudingOtherValue.invalid && toolsForStudingOtherValue.touched"
                  [formControl]="toolsForStudingOtherValue"
                  [errorText]="getErrorText('toolsForStudingOtherValue')"
                  [placeholder]="'tape_recorder' | translate"
                ></app-input>
              </div>
            </div>

            <div *ngSwitchCase="pagesNames.EDUCATION">
              <div class="row" style="margin-top: 8px">
                <app-input
                  inputType="text"
                  [formControl]="education"
                  [error]="education.invalid && education.touched"
                  [errorText]="getErrorText('education')"
                  [label]="'education' | translate"
                  [customInputParams]="{customWidth: '360px'}"
                ></app-input>
                <app-input
                  inputType="text"
                  [formControl]="secondEducation"
                  [error]="secondEducation.invalid && secondEducation.touched"
                  [errorText]="getErrorText('secondEducation')"
                  [label]="'second_education' | translate"
                  [customInputParams]="{customWidth: '360px'}"
                ></app-input>
              </div>
              <div class="row" style="margin-bottom: 20px">
                <div class="container-with-label" style="width: 157px">
                  <label>{{'teacher_dyploma' | translate}}:</label>
                  <div class="content checkboxes">
                    <div class="input-combo" style="margin-right: 16px">
                      <div class="radio">
                        <input
                          type="radio"
                          name="teacher_dyploma"
                          [value]="true"
                          [(ngModel)]="teachersDiploma"
                          [disabled]="authService.isTeacher()"
                        >
                        <div class="border" (click)="onDiplomaChanges(true)"></div>
                      </div>
                      <div class="input-title">{{'yes' | translate}}</div>
                    </div>
                    <div class="input-combo">
                      <div class="radio">
                        <input
                          type="radio"
                          name="teacher_dyploma"
                          [value]="false"
                          [(ngModel)]="teachersDiploma"
                          [disabled]="authService.isTeacher()"
                        >
                        <div class="border" (click)="onDiplomaChanges(false)"></div>
                      </div>
                      <div class="input-title">{{'no' | translate}}</div>
                    </div>
                  </div>
                </div>
                <div class="container-with-label diploma-file">
                  <label>{{'load_dyploma' | translate}}:</label>
                  <div class="content checkboxes">
                    <div class="input-combo fileName" *ngIf="userInfo.diplomaFileName">
                      <mat-icon>attach_file</mat-icon> <span><a [href]="userInfo.diplomaLink" target="_blank">{{userInfo.diplomaFileName}}</a></span> <mat-icon (click)="removeDiplomaFile()">delete_forever</mat-icon>
                    </div>
                  </div>
                </div>
                <div class="button-container">
                  <app-button (btnClick)="loadFile()" [buttonType]="'loading-file-btn'" [text]="'load_file' | translate"></app-button>
                </div>
              </div>
              <div class="row">
                <app-input
                  inputType="dropdown"
                  [error]="!teacherQualification && tappedFields.includes('qualification')"
                  (click)="onFieldsFocus('qualification')"
                  [value]="teacherQualification"
                  [language]="getUserLangByRegion(userInfo.marketRegion)"
                  [dropdownParams]="teacherQualificationArr"
                  (dropdownSelectValue)="handleQualificationSelect($event)"
                  [errorText]="getErrorText('qualification')"
                  [label]="'teacher_qualification' | translate"
                ></app-input>
                <app-input
                  *ngIf="teacherQualification === 'other'"
                  inputType="text"
                  [formControl]="qualificationOther"
                  [value]="qualificationOther.value"
                  [error]="qualificationOther.invalid && qualificationOther.touched"
                  [errorText]="getErrorText('qualificationOther')"
                  [label]="'other' | translate"
                ></app-input>
              </div>
            </div>
            <div *ngSwitchCase="pagesNames.EXPERIENCE">
              <div class="row" style="margin-top: 8px">
                <app-input
                  inputType="text"
                  [formControl]="workPlace"
                  [value]="workPlace.value"
                  [error]="workPlace.invalid && workPlace.touched"
                  [errorText]="getErrorText('workplace')"
                  [label]="'current_work_place' | translate"
                  [customInputParams]="{customWidth: '360px'}"
                ></app-input>
              </div>

              <div class="row" style="align-items: center;" *ngFor="let item of previousWorkplacesArr; index as i">
                <app-input
                  [error]="previousWorkplacesControl[i].invalid && previousWorkplacesControl[i].touched"
                  [formControl]="previousWorkplacesControl[i]"
                  [errorText]="getErrorText('additionalWorkPlaces', i)"
                  [value]="previousWorkplacesControl[i].value"
                  style="margin-right: 20px"
                  inputType="text"
                  [label]="'previous_work_place' | translate"
                  [customInputParams]="{customWidth: '360px'}"
                ></app-input>
                <app-button (btnClick)="removeWorkPlace(i)" style="margin-top: 18px" [icon]="'delete_forever'" [buttonType]="'invincibleButton'"></app-button>
              </div>
              <app-button (btnClick)="addWorkPlace()"  [icon]="'add'" [customParams]="{customWidth: '360px'}" [buttonType]="'commonWhiteButton'" [text]="'add_previous_position' | translate"></app-button>

            </div>

            <div *ngSwitchCase="pagesNames.ZOOM">
              <div class="row" *ngIf="userInfo.marketRegion !== MarketRegion.UKRAINE">
                <div class="container-with-label">
                  <label>{{'select-lesson-platform' | translate}}:</label>
                  <div class="content checkboxes" style="padding-top: 10px;">
                    <div class="input-combo" style="margin-right: 16px">
                      <div class="radio"><input type="radio" name="lessonPlatformZoom" value="zoom" [(ngModel)]="lessonPlatform"></div>
                      <div class="input-title">Zoom</div>
                    </div>
                    <div class="input-combo">
                      <div class="radio"><input type="radio" name="lessonPlatformGoogle" value="google" [(ngModel)]="lessonPlatform"></div>
                      <div class="input-title">Google Meet</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 8px" *ngIf="lessonPlatform == 'google'">
                <app-input
                  inputType="text"
                  [error]="googleMeetLink.invalid && googleMeetLink.touched"
                  [formControl]="googleMeetLink"
                  [errorText]="getErrorText('googleMeetLink')"
                  [value]="googleMeetLink.value"
                  [label]="'link' | translate"
                  [customInputParams]="{customWidth: '738px'}"
                ></app-input>
              </div>
              <div class="row" style="margin-top: 8px" *ngIf="lessonPlatform == 'zoom'">
                <app-input
                  inputType="text"
                  [error]="zoomLink.invalid && zoomLink.touched"
                  [formControl]="zoomLink"
                  [errorText]="getErrorText('zoomLink')"
                  [value]="zoomLink.value"
                  [label]="'link' | translate"
                  [customInputParams]="{customWidth: '738px'}"
                ></app-input>
              </div>
              <div class="row" *ngIf="lessonPlatform == 'zoom'">
                <app-input
                  inputType="text"
                  [error]="zoomAccessCode.invalid && zoomAccessCode.touched"
                  [formControl]="zoomAccessCode"
                  [errorText]="getErrorText('zoomAccessCode')"
                  [value]="zoomAccessCode.value"
                  [label]="'access_code' | translate"
                ></app-input>
                <app-input
                  inputType="text"
                  [error]="zoomId.invalid && zoomId.touched"
                  [formControl]="zoomId"
                  [errorText]="getErrorText('zoomId')"
                  [label]="'zoom_id' | translate"
                ></app-input>
              </div>
            </div>

            <div *ngSwitchCase="pagesNames.ADDITIONAL" style="margin-top: 8px">
              <app-input
                inputType="textArea"
                [error]="additional.invalid && additional.touched"
                [formControl]="additional"
                [errorText]="getErrorText('additional')"
                [label]="'digital_resources' | translate"
                [customInputParams]="{customWidth: '758px', customHeight: '109px'}"
              ></app-input>
            </div>

          </div>
          <div class="footer">
            <div class="btn-container">
              <app-button
                *ngIf="selectedPage!== 'additional' && isNewUserState"
                [buttonType]="'commonColourfulButton'"
                [text]="'next' | translate"
                (btnClick)="handleNextButtonClick()"
              ></app-button>
              <app-button
                *ngIf="!isNewUserState"
                [buttonType]="'commonColourfulButton'"
                [text]="'save' | translate"
                (btnClick)="handleSaveButtonClick()"
                [disabled]="isSaving"
              ></app-button>
              <app-button
                [buttonType]="'commonWhiteButton'"
                [text]="'cancel' | translate"
                (btnClick)="handleBackClick()"
              ></app-button>
            </div>
            <div class="btn-container">
              <app-button
                *ngIf="selectedPage!== 'personal_info' && isNewUserState"
                [buttonType]="'commonWhiteButton'"
                [text]="'back' | translate"
                (btnClick)="handlePreviousButtonClick()"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</math-base-wrapper>



