<div class="free-hours-filters">

  <!-- Date between -->
  <nz-divider></nz-divider>
  <div class="study-date-range">
    <nz-collapse nzGhost nzExpandIconPosition="right" >
      <nz-collapse-panel
        nzActive
        #studyDateRangeControl
        [nzExtra]="studyDateRangeRefresh"
        [nzHeader]="'fh-study-date-range' | translate"
        [nzExpandedIcon]="studyDateRangeArrow">
        <nz-range-picker nzInline [(ngModel)]="studyDateRange" (ngModelChange)="changeStudyDateRange($event)"></nz-range-picker>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyDateRangeRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.STUDY_DATE_RANGE)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #studyDateRangeArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="studyDateRangeControl.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Study days -->
  <nz-divider></nz-divider>
  <div class="study-days">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #studyDaysControl
        [nzExtra]="studyDaysRefresh"
        [nzHeader]="'fh-study-days' | translate"
        nzActive
        [nzExpandedIcon]="studyDaysArrow">
        <ng-container *ngFor="let day of getValuesByKey(filtersKeys.STUDY_DAYS)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.STUDY_DAYS, day.value, day.label)" [nzChecked]="day.isChecked">
            {{day.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyDaysRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.STUDY_DAYS)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #studyDaysArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="studyDaysControl.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Study time -->
  <nz-divider></nz-divider>
  <div class="study-time">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #studyTime
        [nzExtra]="studyTimeRefresh"
        [nzHeader]="'fh-study-time' | translate"
        nzActive
        [nzExpandedIcon]="studyTimeArrow">
        <nz-slider
          nzRange
          [(ngModel)]="studyTimeRange"
          [nzTipFormatter]="formatStudyTime"
          (ngModelChange)="onStudyTimeRangeChange($event)"
          nzMax="48"
          nzMin="0"></nz-slider>
        <nz-input-number-group nzCompact>
          <span>{{'from' | translate}}</span>
          <nz-input-number
            style="width: 30%"
            [nzFormatter]="formatStudyTime"
            [(ngModel)]="studyTimeMin"
            (ngModelChange)="onStudyTimeMinChange($event)"
            [nzMin]="0"
            [nzMax]="studyTimeMax"
            [nzStep]="1"></nz-input-number>
          <span>{{'to' | translate}}</span>
          <nz-input-number
            style="width: 30%"
            [nzFormatter]="formatStudyTime"
            [(ngModel)]="studyTimeMax"
            (ngModelChange)="onStudyTimeMaxChange($event)"
            [nzMin]="studyTimeMin"
            [nzMax]="48"
            [nzStep]="1"></nz-input-number>
          <button
            nz-button
            nzType="default"
            nzSize="default"
            class="apply-filter-btn"
            (click)="onConfirmStudyTime()"
          >{{'ok' | translate}}</button>
        </nz-input-number-group>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyTimeRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetStudyTime()">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #studyTimeArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="studyTime.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Lesson duration -->
  <nz-divider></nz-divider>
  <div class="study-days">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #lessonDurationControl
        nzActive
        [nzExtra]="lessonDurationRefresh"
        [nzHeader]="'fh-lesson-duration' | translate"
        [nzExpandedIcon]="lessonDurationArrow">
        <ng-container *ngFor="let duration of getValuesByKey(filtersKeys.LESSON_DURATION)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.LESSON_DURATION, duration.value, duration.label)" [nzChecked]="duration.isChecked">
            {{duration.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #lessonDurationRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.LESSON_DURATION)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #lessonDurationArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="lessonDurationControl.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Grade -->
  <nz-divider></nz-divider>
  <div class="grade">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #grade
        nzActive
        [nzExtra]="gradeRefresh"
        [nzHeader]="'grade' | translate"
        [nzExpandedIcon]="gradeArrow">
        <ng-container *ngFor="let grade of getValuesByKey(filtersKeys.STUDENT_GRADE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.STUDENT_GRADE, grade.value, grade.label)" [nzChecked]="grade.isChecked">
            {{grade.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #gradeRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.STUDENT_GRADE)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #gradeArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="grade.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <ng-container *ngIf="authService.isUserUkrainian() || authService.isUserPoland()">
    <!-- Verification -->
    <nz-divider></nz-divider>
    <div class="verification">
      <nz-collapse nzGhost nzExpandIconPosition="right">
        <nz-collapse-panel
          #verification
          nzActive
          [nzExtra]="verificationRefresh"
          [nzHeader]="'verified_teachers' | translate"
          [nzExpandedIcon]="verificationArrow">
          <ng-container *ngFor="let verify of getValuesByKey(filtersKeys.VERIFICATION)">
            <label
              nz-checkbox
              [nzChecked]="verify.isChecked"
              (nzCheckedChange)="onCheckFilter($event, filtersKeys.VERIFICATION, verify.value, verify.label)">
              {{verify.label | translate}}
            </label><br>
          </ng-container>
        </nz-collapse-panel>
      </nz-collapse>
      <ng-template #verificationRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.VERIFICATION)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
      </ng-template>
      <ng-template #verificationArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="verification.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
      </ng-template>
    </div>
  </ng-container>

  <!-- Teacher age -->
  <nz-divider></nz-divider>
  <div class="teacher-age">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #teacherAge
        nzActive
        [nzExtra]="teacherAgeRefresh"
        [nzHeader]="'fh-teacher-age' | translate"
        [nzExpandedIcon]="teacherAgeArrow">
        <nz-slider
          nzRange
          [(ngModel)]="teacherAgeRange"
          (ngModelChange)="onTeacherAgeRangeChange($event)"
          nzMax="75"
          nzMin="16"></nz-slider>
        <nz-input-number-group nzCompact>
          <span>{{'from' | translate}}</span>
          <nz-input-number
            style="width: 25%"
            [(ngModel)]="teacherMinAge"
            (ngModelChange)="onTeacherMinAgeChange($event)"
            [nzMin]="16"
            [nzMax]="teacherMaxAge"
            [nzStep]="1"></nz-input-number>
          <span>{{'to' | translate}}</span>
          <nz-input-number
            style="width: 25%"
            [(ngModel)]="teacherMaxAge"
            (ngModelChange)="onTeacherMaxAgeChange($event)"
            [nzMin]="teacherMinAge"
            [nzMax]="75"
            [nzStep]="1"></nz-input-number>
          <button
            nz-button
            nzType="default"
            nzSize="default"
            class="apply-filter-btn"
            (click)="onConfirmTeacherAge()"
          >{{'ok' | translate}}</button>
        </nz-input-number-group>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teacherAgeRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetTeacherAge()">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #teacherAgeArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="teacherAge.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Study program -->
  <nz-divider></nz-divider>
  <div class="study-program">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #studyProgram
        nzActive
        [nzExtra]="studyProgramRefresh"
        [nzHeader]="'study_programs' | translate"
        [nzExpandedIcon]="studyProgramArrow">
        <ng-container *ngFor="let program of getValuesByKey(filtersKeys.STUDY_PROGRAM)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.STUDY_PROGRAM, program.value, program.label)" [nzChecked]="program.isChecked">
            {{program.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyProgramRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.STUDY_PROGRAM)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #studyProgramArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="studyProgram.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Additional subject -->
  <nz-divider></nz-divider>
  <div class="add-subject">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #addSubject
        nzActive
        [nzExtra]="addSubjectRefresh"
        [nzHeader]="'additional_jubjects' | translate"
        [nzExpandedIcon]="addSubjectArrow">
        <ng-container *ngFor="let addSubject of getValuesByKey(filtersKeys.ADDITIONAl_SUBJECT)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.ADDITIONAl_SUBJECT, addSubject.value, addSubject.label)" [nzChecked]="addSubject.isChecked">
            {{addSubject.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #addSubjectRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.ADDITIONAl_SUBJECT)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #addSubjectArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="addSubject.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Study level -->
  <nz-divider></nz-divider>
  <div class="study-level">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #studyLevel
        nzActive
        [nzExtra]="studyLevelRefresh"
        [nzHeader]="'study-level' | translate"
        [nzExpandedIcon]="studyLevelArrow">
        <div *ngFor="let level of getValuesByKey(filtersKeys.STUDYING_LEVELS)" class="toggle-wrap">
          <span>{{level.label | translate}}</span>
          <nz-switch [ngModel]="level.isChecked" (ngModelChange)="onCheckFilter($event, filtersKeys.STUDYING_LEVELS, level.value, level.label)"></nz-switch>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyLevelRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.STUDYING_LEVELS)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #studyLevelArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="studyLevel.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Gender -->
  <nz-divider></nz-divider>
  <div class="gender">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #genderControl
        [nzExtra]="genderRefresh"
        [nzHeader]="'gender' | translate"
        [nzExpandedIcon]="genderArrow">
        <ng-container *ngFor="let gender of getValuesByKey(filtersKeys.GENDER)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.GENDER, gender.value, gender.label)" [nzChecked]="gender.isChecked">
            {{gender.label | translate | titlecase}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #genderRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.GENDER)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #genderArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="genderControl.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Country -->
  <nz-divider></nz-divider>
  <div class="country">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #country
        [nzExtra]="countryRefresh"
        [nzHeader]="'country' | translate"
        [nzExpandedIcon]="countryArrow">
        <nz-select
          style="width: 100%"
          [nzCustomTemplate]="selectedCountry"
          nzAllowClear
          nzShowSearch
          nzSize="large"
          [nzShowArrow]="true"
          (ngModelChange)="onSetCountries($event)"
          nzPlaceHolder="Оберіть країну перебування"
          nzMode="multiple"
          [nzMaxTagCount]="3"
          [(ngModel)]="selectedCountries">
          <nz-option *ngFor="let country of countries" nzCustomContent [nzLabel]="country.label" [nzValue]="country.value">
            <span class="fi fi-{{country.id}}"></span>
            <span class="country-label">{{country.label}}</span>
          </nz-option>
        </nz-select>
        <ng-template #selectedCountry let-selected>
          <span class="fi fi-{{selected.nzValue}}"></span>
          <span class="country-label">{{selected.nzLabel}}</span>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #countryRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.COUNTRY)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #countryArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="country.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Status -->
  <nz-divider></nz-divider>
  <div class="status">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #status
        [nzExtra]="statusRefresh"
        [nzHeader]="'teacher_type' | translate"
        [nzExpandedIcon]="statusArrow">
        <ng-container *ngFor="let status of getValuesByKey(filtersKeys.TEACHER_STATUS)">
          <label
            nz-checkbox
            [nzChecked]="status.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHER_STATUS, status.value, status.label)">
            {{status.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #statusRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.TEACHER_STATUS)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #statusArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="status.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Payment level -->
  <nz-divider></nz-divider>
  <div class="payment-level">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #paymentLevel
        [nzExtra]="paymentLevelRefresh"
        [nzHeader]="'payment_level' | translate"
        [nzExpandedIcon]="paymentLevelArrow">
        <ng-container *ngFor="let level of getValuesByKey(filtersKeys.PAYMENT_LEVEL)">
          <nz-tag
            nz-tooltip
            [nzTooltipTitle]="getPaymentLevelTip(level.label)"
            nzTooltipPlacement="topLeft"
            nzMode="checkable"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.PAYMENT_LEVEL, level.value, level.label)" [nzChecked]="level.isChecked"
          >
            {{level.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #paymentLevelRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.PAYMENT_LEVEL)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #paymentLevelArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="paymentLevel.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Qualification -->
  <nz-divider></nz-divider>
  <div class="qualification">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #qualification
        [nzExtra]="qualificationRefresh"
        [nzHeader]="'teacher_qualification' | translate"
        [nzExpandedIcon]="qualificationArrow">
        <ng-container *ngFor="let qualification of getValuesByKey(filtersKeys.QUALIFICATION)">
          <nz-tag
            nzMode="checkable"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.QUALIFICATION, qualification.value, qualification.label)" [nzChecked]="qualification.isChecked"
          >
            {{qualification.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #qualificationRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.QUALIFICATION)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #qualificationArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="qualification.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Experience -->
  <nz-divider></nz-divider>
  <div class="experience">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #experience
        [nzExtra]="experienceRefresh"
        [nzHeader]="'study_experience' | translate"
        [nzExpandedIcon]="experienceArrow">
        <ng-container *ngFor="let exp of getValuesByKey(filtersKeys.TEACHING_EXPERIENCE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_EXPERIENCE, exp.value, exp.label)" [nzChecked]="exp.isChecked">
            {{exp.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #experienceRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.TEACHING_EXPERIENCE)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #experienceArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="experience.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Tutor experience -->
  <nz-divider></nz-divider>
  <div class="tutor-experience">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #tutorExperience
        [nzExtra]="tutorExperienceRefresh"
        [nzHeader]="'tutor_experience' | translate"
        [nzExpandedIcon]="tutorExperienceArrow">
        <ng-container *ngFor="let exp of getValuesByKey(filtersKeys.TUTORING_EXPERIENCE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.TUTORING_EXPERIENCE, exp.value, exp.label)" [nzChecked]="exp.isChecked">
            {{exp.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #tutorExperienceRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.TUTORING_EXPERIENCE)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #tutorExperienceArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="tutorExperience.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Exams preparation -->
  <nz-divider></nz-divider>
  <div class="exams-preparation">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #examsPreparation
        [nzExtra]="examsPreparationRefresh"
        [nzHeader]="'preparing_to_exams' | translate"
        [nzExpandedIcon]="examsPreparationArrow">
        <ng-container *ngFor="let exam of getValuesByKey(filtersKeys.EXAMS)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.EXAMS, exam.value, exam.label)" [nzChecked]="exam.isChecked">
            {{exam.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #examsPreparationRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.EXAMS)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #examsPreparationArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="examsPreparation.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Special needs -->
  <nz-divider></nz-divider>
  <div class="special-needs">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #specialNeeds
        [nzExtra]="specialNeedsRefresh"
        [nzHeader]="'special-needs' | translate"
        [nzExpandedIcon]="specialNeedsArrow">
        <ng-container *ngFor="let need of getValuesByKey(filtersKeys.SPECIAL_NEEDS)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.SPECIAL_NEEDS, need.value, need.label)" [nzChecked]="need.isChecked">
            {{need.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #specialNeedsRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.SPECIAL_NEEDS)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #specialNeedsArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="specialNeeds.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Teaching style -->
  <nz-divider></nz-divider>
  <div class="teaching-style">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #teachingStyle
        [nzExtra]="teachingStyleRefresh"
        [nzHeader]="'study_style' | translate"
        [nzExpandedIcon]="teachingStyleArrow">
        <ng-container *ngFor="let style of getValuesByKey(filtersKeys.TEACHING_STYLE)">
          <label
            nz-checkbox
            [nzChecked]="style.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_STYLE, style.value, style.label)">
            {{style.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teachingStyleRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.TEACHING_STYLE)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #teachingStyleArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="teachingStyle.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>

  <!-- Teaching lang -->
  <nz-divider></nz-divider>
  <div class="teaching-lang">
    <nz-collapse nzGhost nzExpandIconPosition="right">
      <nz-collapse-panel
        #teachingLang
        [nzExtra]="teachingLangRefresh"
        [nzHeader]="'study_language' | translate"
        [nzExpandedIcon]="teachingLangArrow"
        (nzActiveChange)="scrollDown($event)">
        <ng-container *ngFor="let lang of getValuesByKey(filtersKeys.TEACHING_LANG)">
          <label
            nz-checkbox
            [nzChecked]="lang.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_LANG, lang.value, lang.label)">
            <img class="country" *ngIf="lang.img" [src]="lang.img" [alt]="lang.value">
            {{lang.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teachingLangRefresh>
      <span nz-icon (click)="$event.stopPropagation(); resetFilterByKey(filtersKeys.TEACHING_LANG)">
        <img src="./assets/icons/reload.svg" alt="reload" class="reload">
      </span>
    </ng-template>
    <ng-template #teachingLangArrow>
      <span nz-icon class="ant-collapse-arrow" [nzRotate]="teachingLang.nzActive ? -0 : 180">
        <img src="./assets/icons/chevrone.svg" alt="open-close">
      </span>
    </ng-template>
  </div>
</div>
