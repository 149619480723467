import {
  AfterViewInit,
  Component,
  Inject, OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ViewUserInfoService } from "../services/view-user-info.service";
import { ViewUserInfoApiService } from "../api-services/view-user-info-api.service";
import { take, takeUntil } from "rxjs/operators";
import {
  EAdditionalSubject,
  ETeacherInstrument,
  Gender,
  IGetTeacherSchema,
  PageActions,
  PagesNames,
} from "../../change-user-info/models/change-user-info";
import { ActivatedRoute, Router } from "@angular/router";
import { AppRoutesDefinitions } from "@app/app.routes";
import { AuthService } from "../../auth/auth.service";
import { translatedCountriesList } from "../../change-user-info/models/countries";
import { ToolsForStuding } from "../../change-user-info/models/variables";
import { MultiselectData } from "@shared/components/app-input/app-input.component";
import { LanguageEnum, phoneNumbersFormats, photoLinks } from "@shared/constants";
import { MatDialog } from "@angular/material/dialog";
import { NotificationService } from "@shared/services/notification.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { IndicatorsApiService } from "../api-services/indicators-api.service";
import {
  AddCustomRewardModel,
  CustomRewardModel,
  GetRewardsModel,
  IndicatorsInitialSettings,
  LevelModel
} from "../models/indicators";
import { IndicatorsService } from "../services/indicators.service";
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { TeacherSettingsModalComponent } from './teacher-settings-modal/teacher-settings-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import LanguageService from '@shared/language/language.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-view-user-info',
  templateUrl: './view-user-info.component.html',
  styleUrls: ['./view-user-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewUserInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('tabs') tabsGroups: QueryList<MatTabGroup>;
  private tabs = ['profile', 'indicators', 'library'];
  private destroyed = new Subject<void>()

  public readonly initialSettings = IndicatorsInitialSettings

  private initialStart: boolean;
  public zoomLink: string;
  public pageActions: typeof PageActions= PageActions
  public obtData?: GetRewardsModel;
  public userLevels: LevelModel[] = [];
  public positiveFeedback: UntypedFormControl = new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(IndicatorsInitialSettings.positiveResponsesMaxValue)]);
  public negativeFeedback: UntypedFormControl = new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(IndicatorsInitialSettings.negativeResponsesMaxValue)]);
  public regulationViolation: UntypedFormControl = new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(IndicatorsInitialSettings.regulationViolationMaxValue)]);
  public isVerified: UntypedFormControl = new UntypedFormControl(null);
  public customRewardsList: CustomRewardModel[];
  public customRewardsTouchedList: CustomRewardModel[] = [];
  public customRewardsNonTouchedList: CustomRewardModel[] = [];

  public userInfo: IGetTeacherSchema;
  public isLoading: boolean;
  public pagesNames: typeof PagesNames = PagesNames;
  private teacherId: string;

  public showLoadMaterialsButton = true;

  constructor(
    private readonly viewUserInfoService: ViewUserInfoService,
    private readonly viewUserInfoApiService: ViewUserInfoApiService,
    public translateService: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    public readonly authService: AuthService,
    private readonly languageService: LanguageService,
    private readonly dialog: MatDialog,
    private readonly indicatorsApiService: IndicatorsApiService,
    private readonly indicatorsService: IndicatorsService,
    public readonly notificationService: NotificationService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnDestroy() {
    this.destroyed.next();
  }

  public convertPhoneNumberToValidView(phoneNumber: string): string {
    if(!phoneNumber){
      return '';
    }
    if(phoneNumbersFormats.threeDigitsFormat.includes(phoneNumber[0]+phoneNumber[1]+phoneNumber[2])){
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]+phoneNumber[1]+phoneNumber[2]);
    } else if(phoneNumbersFormats.twoDigitsFormats.includes(phoneNumber[0]+phoneNumber[1])) {
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]+phoneNumber[1]);
    } else if(phoneNumbersFormats.oneDigitsFormat.includes(phoneNumber[0])) {
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]);
    } else {
      return phoneNumber;
    }
  }

  public formatPhoneNumber(phoneNumber: string, countryCode: string = '1'): string {
    let cleaned = phoneNumber.replace(/\D/g, '');
    let tmp: string = '';
    cleaned = cleaned.substr(countryCode.length, cleaned.length-1);
    let groups = cleaned.match(/(\d{1,3})/g);
    if(groups[groups.length-1].length === 1){
      tmp = groups[groups.length-1];
      groups.pop();
    }
    return `+(${countryCode}) ${groups.join(' ')}` + tmp;
  }

  ngOnInit(): void {
    if(!this.authService.isTeacher()){
      this.indicatorsApiService.getRewards$GET().subscribe(data=>{
        this.customRewardsList = data;
        this.loadTeacherCustomRewards();
      })
    }
    this.viewUserInfoService.updateTeacherInfo$.pipe(takeUntil(this.destroyed)).subscribe(()=>{
      this.loadTeacherData(this.activatedRoute.snapshot.queryParams['teacherId'] || this.authService.user.teacherId)
    })
    this.initialStart = true;
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed))
      .subscribe((params)=>{
        if(!this.authService.isTeacher()){
          if(params['teacherId'] && this.initialStart){
            this.loadTeacherData(params['teacherId']);
          } else if (this.initialStart) {
            this.noDataAboutTeacher()
          }
        } else if (this.authService.user.teacherId && this.initialStart) {
          this.teacherId = this.authService.user.teacherId;
          this.loadTeacherData(this.teacherId);
        } else if(this.initialStart) {
          this.noDataAboutTeacher()
        }
      });
    this.loadIndicatorsData();
    this.indicatorsService.updateIndicatorsData$.pipe(takeUntil(this.destroyed)).subscribe(()=>{
      this.loadIndicatorsData();
    })

    this.addHelpCrunchWidgetHandlers();
  }

  private loadTeacherCustomRewards(): void {
    this.indicatorsApiService.getTeacherCustomRewards$GET(this.activatedRoute.snapshot.queryParams['teacherId'])
      .subscribe(res=>{
        this.customRewardsList.forEach((item, index)=>{
          if (res.data.some(elem=>elem.reward.id === item.id) && res.data.find(elem=>elem.reward.id === item.id).id){
            this.customRewardsList[index] = {...this.customRewardsList[index], state: true, connectionId: res.data.find(elem=>elem.reward.id === item.id).id}
          } else if (res.data.some(elem=>elem.reward.id === item.id)) {
            this.customRewardsList[index] = {...this.customRewardsList[index], state: false}
          }
        })
        this.customRewardsNonTouchedList = JSON.parse(JSON.stringify(this.customRewardsList));
      })
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed))
      .subscribe((params)=> {
        this.tabsGroups.changes.subscribe(tabGroups => {
          tabGroups.first.selectedIndex = this.tabs.findIndex(tab => tab === params['page']);
        });
      });
  }

  public loadIndicatorsData(): void {
    this.indicatorsApiService.obtainLevelsData$GET().pipe(take(1)).subscribe(data=>{
      this.userLevels = data;
    })
    if (!!(this.authService.user.teacherId)){
      this.indicatorsApiService.obtainRewards$GET().pipe(take(1)).subscribe(data=>{
        this.obtData = data;
        this.negativeFeedback.setValue(data.feedbacks.negative);
        this.positiveFeedback.setValue(data.feedbacks.positive);
        this.regulationViolation.setValue(data.regulationViolations);
      })
    } else {
      this.indicatorsApiService.obtainTeacherRewards$GET(this.activatedRoute.snapshot.queryParams['teacherId']).pipe(take(1)).subscribe(data=>{
        this.obtData = data;
        this.negativeFeedback.setValue(data.feedbacks.negative);
        this.positiveFeedback.setValue(data.feedbacks.positive);
        this.regulationViolation.setValue(data.regulationViolations);
      })
    }
  }

  private noDataAboutTeacher(): void {
    this.isLoading = false;
  }

  private loadTeacherData(teacherId: string): void{
    this.isLoading = true;
   this.viewUserInfoApiService.getTeacherDataById$GET(teacherId).toPromise().catch(()=>{
      this.noDataAboutTeacher();
      return undefined;
    }).then(res=>{
      this.initialStart = false;
      if(res){
        this.isLoading = false;
        this.userInfo = res.data;
        this.zoomLink = res.data.linkToZoom;
        this.isVerified.setValue(res.data.verifiedAt);
      } else {
        this.noDataAboutTeacher()
      }
    })
  }

  public handleBackClick(): void {
    this.router.navigate([AppRoutesDefinitions.FREE_HOURS]);
  }

  public handleChangeBtnClick(query?: PagesNames): void {
    if(!this.authService.isTeacher())
      this.router.navigate([AppRoutesDefinitions.PROFILE_CREATION], {queryParams: {page: query, teacherId: this.userInfo.id}})
    else
      this.router.navigate([AppRoutesDefinitions.PROFILE_CREATION], {queryParams: {page: query}})
  }

  public changeMenuPage(event: MatTabChangeEvent): void {
    this.router.navigate([], {queryParams: {page: this.tabs[event.index]}, queryParamsHandling: 'merge'})
  }

  public getField(data: string | number | null): string | number | null {
    if (data==='null'){
      return null;
    } else if (data===0) {
      return '0'
    } else if (data === 'regular'){
      return this.translateService.instant('regular_teacher');
    }
    return data;
  }

  public getDateWithAge(dateOfBirth: string): { date: string; age: string } | null {
    let date = DateTime.fromFormat(dateOfBirth, 'dd.MM.yyyy');
    if (!date.isValid) date = DateTime.fromFormat(dateOfBirth, 'yyyy-MM-dd');

    if (!date.isValid) {
      return null;
    }

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return { date: formatter.format(date.toJSDate()), age: DateTime.now().diff(date, 'years').toFormat('yy') };
  }

  public getCountryName(): {name: string; id: string }{
    if (!this.userInfo?.country){
      return {name: '', id: ''}
    }

    if((translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).find(item=> item.value===this.userInfo.country.toUpperCase()) as { text: string; value: string }).text){
      return {
        name: (translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).find(item=> item.value===this.userInfo.country.toUpperCase()) as { text: string; value: string }).text,
          id: this.userInfo?.country.toLowerCase()
      };
    }
    return {
      name: this.userInfo?.country,
      id: this.userInfo?.country?.toLowerCase()
    };
  }

  public getInstrumentData(id: ETeacherInstrument | string): MultiselectData{
    return ToolsForStuding.find(item => item.id === id) as MultiselectData;
  }

  public handleCopyBtnClick(): void {
    this.notificationService.showSuccessMessage(this.translateService.instant('copied'))
  }

  public getUserAvatarLetter(): string {
    if(this.userInfo?.firstName){
      return this.userInfo?.firstName.trim()[0];
    } else if(this.userInfo?.secondName) {
      return this.userInfo?.secondName.trim()[0];
    } else {
      return this.userInfo?.lastName.trim()[0];
    }
  }

  public getUserAvatarLink(): string | undefined {
    if (this.userInfo?.gender && !this.userInfo?.avatarLink) {
      return this.userInfo.gender === Gender.MALE ? photoLinks.noPhotoBoyLink : photoLinks.noPhotoGirlLink;
    } else if (this.userInfo?.gender && this.userInfo?.avatarLink) {
      return this.userInfo.avatarLink;
    } else {
      return undefined;
    }
  }

  public getLangName(lang: string): {name: string; flagId: string; countryName?: string} {
    const currentLang = this.translateService.currentLang.toUpperCase() as LanguageEnum

    if(lang.includes('Укр')){
      return {name: 'uk', flagId: 'ua', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='UA') as {text: string; value: string}).text}
    }
    switch (lang){
      case LanguageEnum.ukrainian: {
        return {name: 'uk', flagId: 'ua', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='UA') as {text: string; value: string}).text}
      }
      case LanguageEnum.polish: {
        return {name: 'pl', flagId: 'pl', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='PL') as {text: string; value: string}).text}
      }
      case LanguageEnum.english: {
        return {name: 'en', flagId: 'gb', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='GB') as {text: string; value: string}).text}
      }
      case LanguageEnum.german: {
        return {name: 'de', flagId: 'de', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='DE') as {text: string; value: string}).text}
      }
      case LanguageEnum.french: {
        return {name: 'gf', flagId: 'gf', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='GF') as {text: string; value: string}).text}
      }
      case LanguageEnum.czechia: {
        return {name: 'cz', flagId: 'cz', countryName: (translatedCountriesList(currentLang).find(item=>item.value==='CZ') as {text: string; value: string}).text}
      }
      default: {
        return {name: 'other', flagId: ''}
      }
    }
  }

  public generateLanguages(lang: string): Array<string> {
    return lang.split(',').filter(item => (item != 'other' && item.length > 0));
  }

  public getAdditionalSubjectsKeys(key: string): string {
    switch (key) {
      case EAdditionalSubject.PHISICS: {
        return 'phisics';
      }
      case EAdditionalSubject.COMPUTES_SCIENCE: {
        return 'informatics';
      }
      default: {
        return '';
      }
    }
  }

  public openSettingsModal(): void {
    this.dialog.open(TeacherSettingsModalComponent, {id: 'teacher-settings-modal', data: {avatarLink: this.getUserAvatarLink(), avatarLetter: this.getUserAvatarLetter(), teacherId: this.teacherId}})
  }

  public sortGrades(data: string[]): string[] {
    let tmp = JSON.parse(JSON.stringify(data));
    return tmp.sort((a: string, b: string)=>{
      if(parseInt(a)>parseInt(b)){
        return 1;
      }
      if(parseInt(a)<parseInt(b)){
        return -1;
      }
      return 0;
    })
  }

  public clickIndicatorsChangeBtn(): void{
    if(!this.authService.isTeacher()){
      this.router.navigate([], {queryParams: {action: PageActions.EDIT_INDICATORS}, queryParamsHandling: "merge"})
    }
  }

  public getEditIndicatorsState(): boolean {
    return this.activatedRoute.snapshot.queryParams['action']===this.pageActions.EDIT_INDICATORS && !this.authService.isTeacher()
  };

  public indicatorsSaveBtnClick(): void {
    let promises: Promise<any>[] = [];
    if(this.positiveFeedback.valid
      && this.positiveFeedback.valid) {
      promises.push(
        this.indicatorsApiService.updateTeacherFeedbacks({teacherId: this.activatedRoute.snapshot.queryParams['teacherId'], negative: this.negativeFeedback.value, positive: this.positiveFeedback.value})
          .toPromise()
      )
    }
    if(this.regulationViolation.valid){
      promises.push(
        this.indicatorsApiService.updateTeacherRegulationViolation({teacherId: this.activatedRoute.snapshot.queryParams['teacherId'], violations: this.regulationViolation.value})
          .toPromise()
      )
    }
    if (this.isVerified.dirty) {
      promises.push(
        this.indicatorsApiService.updateTeacherVerification({ teacherId: this.activatedRoute.snapshot.queryParams['teacherId'], isVerified: !!this.isVerified.value }).toPromise()
      );
    }

    this.compareRewardsState().then(()=>{
      Promise.all(promises).then(_=>{
        this.obtData = undefined
        this.notificationService.showSuccessMessage(this.translateService.instant('saved'))
        this.loadIndicatorsData();
        this.router.navigate([], {queryParamsHandling: "merge", queryParams: {action: undefined}})
      })
    });
  }
  public indicatorsCancelBtnClick(): void {
    this.router.navigate([], {queryParamsHandling: "merge", queryParams: {action: undefined}})
  }
  public generateValidImgUrl(url: string): string {
    const langRegex = /\$\{lang\}/g;
    switch (this.translateService.currentLang.toUpperCase()){
      case LanguageEnum.ukrainian:
        return url.replace(langRegex, 'ua')
      case LanguageEnum.polish:
        return url.replace(langRegex, 'pl')
      case LanguageEnum.czechia:
      case LanguageEnum.french:
      case LanguageEnum.english:
      case LanguageEnum.german:
      case LanguageEnum.other:
      default:
        return url.replace(langRegex, 'en')
    }
  }

  private async compareRewardsState(): Promise<void> {
    const resultList: CustomRewardModel[] = [];
    const promises: Promise<AddCustomRewardModel | unknown>[] = [];


    this.customRewardsTouchedList.forEach(elem=>{
      if (elem.state !== this.customRewardsNonTouchedList.find(item => item.id === elem.id).state) {
        resultList.push(elem);
      }
    })

    resultList.forEach(item=>{
      if (!item.state && item.connectionId) {
        promises.push(
          this.indicatorsApiService.removeTeacherCustomReward$DELETE(item.connectionId).toPromise().then(()=>{
            this.customRewardsNonTouchedList = [];
            this.customRewardsTouchedList = [];
          })
        )
      } else if (item.state) {
        promises.push(
          this.indicatorsApiService.addTeacherCustomReward$POST(this.activatedRoute.snapshot.queryParams['teacherId'], item.id).toPromise().then((res)=>{
              this.customRewardsList.forEach((item, index)=>{
                if (res.data.reward.id === item.id) {
                  this.customRewardsList[index].state = true;
                  this.customRewardsList[index].connectionId = res.data.id;
                }
              })
            this.customRewardsNonTouchedList = [];
            this.customRewardsTouchedList = [];
          })
        )
      }
    })

    await Promise.all(promises).then(()=>{
      this.loadTeacherCustomRewards();
    })
  }


  public toggleTeacherReward(rewardId: number/*, rewardTeacherConnectionId?: number*/): void {
    if(!this.customRewardsTouchedList.some(item=>item.id === rewardId)){
      this.customRewardsTouchedList.push(this.customRewardsList.find(item=>item.id === rewardId));
    } else {
      this.customRewardsTouchedList[this.customRewardsTouchedList.findIndex(item=>item.id === rewardId)] = this.customRewardsList.find(item=>item.id === rewardId);
    }
  }

  private addHelpCrunchWidgetHandlers(): void {
    const uiLang = this.translateService.currentLang;
    const event = new CustomEvent('InitHelpCrunchWidget', { detail: { locale: uiLang } });
    this.document.addEventListener('HelpCrunchWidgetReady', this.tryToHideHelpCrunchWidget.bind(this));
    this.router.events.subscribe(this.tryToHideHelpCrunchWidget.bind(this));
    this.document.dispatchEvent(event);
  }

  private tryToHideHelpCrunchWidget(): void {
    if (this.authService.isTeacher()) {
      return;
    }

    // @ts-ignore
    window.HelpCrunch('hideChatWidget'); // todo: think to extend window by custom service
  }

  public isZoomLink(link?: string): boolean {
    if(!link) return true;
    const zoomRegex = /^\s*https?:\/\/(?:[\w-]+\.)?zoom\.us\/(?:j\/\d{8,}(?:\?\S*)?|my\/[^\/\?\s]+\/\w{10,})(?:\?\S*)?$/;
    const match = link.match(zoomRegex);
    return (match && match.length > 0);
  }

  public getPrecisionedNumber(value: number): string {
    if (value) {
      if (value < 10) return value.toPrecision(3);
      return value.toPrecision(4);
    }
    return '0';
  }
}
