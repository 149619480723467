import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import AuthApiService from '@app/shared/services/auth/auth-api.service';
import MathAsyncValidators from '@app/shared/validators/async-validators';
import MathValidators from '@app/shared/validators/sync-validators';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { ExperienceEnum, TERMS_AND_CONDITIONS_LINK_PL } from '@app/shared/constants';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { RouterModule } from '@angular/router';
import { AppRoutesDefinitions } from '@app/app.routes';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { ContactMarketRegion } from '@app/shared/services/dto/pifagor-api.dto';
import { AutoUnsubscribeComponent } from '@app/shared/components/auto-unsubscribe/auto-unsubscribe.component';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth.service';
import CreateTeacherDto from '@app/shared/services/users/dto/create-teacher.dto';

@Component({
  selector: 'math-teacher-signup-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzSelectModule,
    NzCheckboxModule,
    NzSpaceModule,
    RouterModule,
    NzFlexModule,
  ],
  templateUrl: './teacher-signup-form.component.html',
  styleUrl: './teacher-signup-form.component.scss'
})
export class TeacherSignupFormComponent extends AutoUnsubscribeComponent implements OnInit {
  public readonly termsAndConditionsLink = TERMS_AND_CONDITIONS_LINK_PL;
  public readonly loginLink = AppRoutesDefinitions.LOGIN;
  public readonly marketRegion = ContactMarketRegion;
  public form: FormGroup;
  public passwordVisible = signal(false);
  public passwordConfirmVisible = signal(false);
  public isSubmitLoading = signal(false);

  constructor(
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authApiService: AuthApiService,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.form.patchValue({
          marketRegion: this.mapMarketRegionToIdEnum(params.market_region),
          language: params.market_region?.toUpperCase(),
        });
      });
  }

  public getTutoringExperienceOptions(): { label: string, value: string }[] {
    const enumValues = Object.values(ExperienceEnum);
    return enumValues.map(value => ({
      label: value,
      value: value,
    }));
  }

  public async submit(): Promise<void> {
    const dto: CreateTeacherDto = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      email: this.form.value.email,
      phone: this.form.value.phone,
      tutoringExperience: this.form.value.tutoringExperience,
      password: this.form.value.password,
      language: this.form.value.language,
      marketRegion: this.form.value.marketRegion,
    }

    this.isSubmitLoading.set(true);
    await this.authApiService
      .registerTeacher(dto)
      .toPromise()
      .then(() => {
        this.authService.login(dto.email, dto.password);
      })
      .finally(() => {
        this.isSubmitLoading.set(false);
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      marketRegion: [null, Validators.required],
      language: [null, Validators.required],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [
        null,
        [Validators.required, MathValidators.email()],
        MathAsyncValidators.isEmailTaken(this.authApiService)
      ],
      phone: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      tutoringExperience: [null, Validators.required],
      canTeachMathematics: [false, Validators.required],
      password: [null, [Validators.required, Validators.minLength(8)]],
      passwordConfirm: [null, [Validators.required]],
      termsAndConditions: [false, Validators.required],
      signUpToNewsletter: [false, Validators.required],
    }, { validators: MathValidators.passwordsMatchValidator('password', 'passwordConfirm') });
  }

  private mapMarketRegionToIdEnum(marketRegion: string): string {
    switch (marketRegion) {
      case 'pl':
        return ContactMarketRegion.POLAND;
      case 'ua':
        return ContactMarketRegion.UKRAINE;
      case 'tr':
        return ContactMarketRegion.TURKEY;
      case 'gb':
        return ContactMarketRegion.UNITED_KINGDOM;
      default:
        return ContactMarketRegion.UKRAINE;
    }
  }
}
