import {
  EEducationProgram, EEducationStyle,
  ETeacherInstrument,
  ETeacherStatus,
  Gender,
  PagesInterface,
  PagesNames
} from "./change-user-info";
import {CheckboxData, DropdownData, MultiselectData} from "@shared/components/app-input/app-input.component";
import {LanguageEnum} from "@shared/constants";

export const PagesInfo: PagesInterface[] = [
  {
    label: PagesNames.PERSONAL_INFO,
    icon: 'user'
  },
  {
    label: PagesNames.WORK_SPECIFICATION,
    icon: 'file-text'
  },
  {
    label: PagesNames.EDUCATION,
    icon: 'safety-certificate'
  },
  {
    label: PagesNames.EXPERIENCE,
    icon: 'bank'
  },
  {
    label: PagesNames.ZOOM,
    icon: 'video-camera'
  },
  {
    label: PagesNames.ADDITIONAL,
    icon: 'link'
  },
]

export const LanguageArr: CheckboxData[] = [
  {label: 'uk', id: LanguageEnum.ukrainian, iconUrl:'ua', selected: false},
  {label: 'en', id: LanguageEnum.english, iconUrl:'gb', selected: false},
  {label: 'de', id: LanguageEnum.german, iconUrl:'de', selected: false},
  {label: 'pl', id: LanguageEnum.polish, iconUrl:'pl', selected: false},
  {label: 'gf', id: LanguageEnum.french, iconUrl:'gf', selected: false},
  {label: 'cz', id: LanguageEnum.czechia, iconUrl:'cz', selected: false},
  {label: 'other', id: LanguageEnum.other, selected: false},
]
export const GenderArr: DropdownData[] = [
  {label: 'male', id: Gender.MALE},
  {label: 'female', id: Gender.FEMALE},
]
export const studyPrograms: MultiselectData[] = [
  {
    label: EEducationProgram.INTELIGENCE_OF_UKRAINE,
    selected: false,
    id: EEducationProgram.INTELIGENCE_OF_UKRAINE
  },
  {
    label: EEducationProgram.WINGS_OF_SUCCESS,
    selected: false,
    id: EEducationProgram.WINGS_OF_SUCCESS
  },
  {
    label: EEducationProgram.ROSTOK,
    selected: false,
    id: EEducationProgram.ROSTOK
  },
  {
    label: EEducationProgram.NUSH,
    selected: false,
    id: EEducationProgram.NUSH
  },
]

export const ToolsForStuding: MultiselectData[] = [
  {
    label: 'headset',
    id: ETeacherInstrument.HEADSET,
    selected: false,
    icon: 'customer-service',
  },
  {
    label: 'graphics_tablet',
    id: ETeacherInstrument.GRAPHIC_TABLE,
    selected: false,
    icon: 'signature',
  },
  {
    label: 'cell',
    id: ETeacherInstrument.CAMERA,
    selected: false,
    icon: 'video-camera',
  },
  {
    label: 'interactive_whiteboard',
    id: ETeacherInstrument.MOUSE_AND_INTERACTIVE_BOARD,
    selected: false,
    icon: 'fund-projection-screen',
  },
]

export const StatusArr: DropdownData[] = [
  {
    label: 'regular',
    id: ETeacherStatus.regular,
  },
  {
    label: 'trainee',
    id: ETeacherStatus.trainee,
  },
  {
    label: 'resigns',
    id: ETeacherStatus.resigns,
  },
  {
    label: 'resigned',
    id: ETeacherStatus.resigned,
  },
]

export const ExperienceArr: DropdownData[] = [
  {
    label: 'less_then_year',
    id: 'less_then_year',
  },
  {
    label: 'to_three_years',
    id: 'to_three_years',
  },
  {
    label: 'from_three_to_five',
    id: 'from_three_to_five',
  },
  {
    label: 'from_five_to_ten',
    id: 'from_five_to_ten',
  },
  {
    label: 'from_ten_to_fifteen',
    id: 'from_ten_to_fifteen',
  },
  {
    label: 'above_fifteen',
    id: 'above_fifteen',
  },
]

export const StudyStyleArr: DropdownData[] = [
  {
    label: 'no_info',
    id: null,
  },
  {
    label: EEducationStyle.AUTHORITARIAN,
    id: EEducationStyle.AUTHORITARIAN,
  },
  {
    label: EEducationStyle.DEMOCRATIC,
    id: EEducationStyle.DEMOCRATIC,
  },
  {
    label: EEducationStyle.LIBERAL,
    id: EEducationStyle.LIBERAL,
  },
]

export const TeacherQualificationArr: DropdownData[] = [
  {
    label: 'chartered_math_teacher',
    id: 'chartered_math_teacher',
  },
  {
    label: 'further_math_teacher',
    id: 'further_math_teacher',
  },
  {
    label: 'npqml',
    id: 'npqml',
  },
  {
    label: 'npqsl',
    id: 'npqsl',
  },
  {
    label: 'first_category',
    id: 'first_category',
  },
  {
    label: 'second_category',
    id: 'second_category',
  },
  {
    label: 'higher_category',
    id: 'higher_category',
  },
  {
    label: 'no_category',
    id: 'no_category',
  },
  {
    label: 'other',
    id: 'other',
  },
]

export const OtherArr: MultiselectData[] = [
  {
    label: 'other',
    id: 'other',
    selected: false,
  }
]
export const OtherArrWithIcon: MultiselectData[] = [
  {
    label: 'other',
    id: 'other',
    selected: false,
    icon: 'square_foot'
  }
]
