<nz-layout class="calendar-page">
  <!--  PAGE TITLE-->
  @if (authService.isTeacher()) {
    @if (scheduleCalendarService.activeTeacher.value; as teacher) {
      <ng-template mathHeaderTitle>
        {{'calendar.timetable' | translate}}
        @if (teacher.blockedAvailability) {
          <nz-tag 
            nzColor="red" 
            nzBordered 
            nz-tooltip 
            [nzTooltipTrigger]="'hover'"
            [nzTooltipTitle]="getTeacherBlockedTooltipForTeacher(teacher.blockedAt)"
          >
            {{'calendar.blocked' | translate}}
          </nz-tag>
        }
      </ng-template>
    }
  } @else {
    <ng-template mathHeaderTitle>
      {{ 'calendar.timetable' | translate }}
    </ng-template>

    @let teacher = scheduleCalendarService.activeTeacher.value;
    <ng-template mathHeaderSubtitle>
      {{teacher?.lastName}} {{teacher?.firstName}}
    </ng-template>

    @let allowedRoles = authService.isAdmin() || authService.isManager();
    @if (allowedRoles && teacher) {
    <ng-template mathHeaderTitleAction>
      <button 
        nz-button 
        nzSize="small"
        (click)="openTeacherProfile(teacher?.id)"
        nz-tooltip
        [nzTooltipTitle]="'calendar.to-teacher-profile' | translate"
      >
        <span nz-icon nzType="user"></span>
      </button>
    </ng-template>
    }
  }
  <!--  END PAGE TITLE-->

  <!--  ACTION BUTTONS-->
  <ng-template mathHeaderExtra>
    @if (authService.isTeacher()) {
      <div class="free-slots-btn">
        <button nz-button nzType="primary" (click)="onFreeSlotsClick()">
          <span nz-icon nzType="edit"></span>
          @if (!mobileMode) {
            <span >{{'calendar.filters.slots' | translate}}</span>
          }
        </button>
      </div>
    }

    @if (authService.isManager() || authService.isAdmin()) {
      <button 
        nz-button 
        nzType="primary" 
        nz-dropdown 
        [nzDropdownMenu]="addMenu"
        [nzOverlayClassName]="'add-menu-overlay'" 
        nzTrigger="click"
        (nzVisibleChange)="isAddMenuOpened = !isAddMenuOpened"
      >
        <span nz-icon nzType="plus"></span>
        <span >{{'main.btn.add' | translate}}</span>
        <span nz-icon nzType="caret-down"></span>
      </button>
      <nz-dropdown-menu #addMenu="nzDropdownMenu">
        <ul nz-menu>
          <ng-container *ngIf="scheduleCalendarService.activeTeacher.value as teacher; else addMenuWithoutTeacher">
            @let isDisabled = !!teacher.notTakeNewStudentsAt || teacher.blockedAvailability || isTeacherResigned();
            <li
              nz-menu-item
              (click)="onOpenFirstLessonForm()"
              [nzDisabled]="isDisabled"
              nz-tooltip
              [nzTooltipTrigger]="isDisabled ? 'hover' : null"
              [nzTooltipTitle]="getTeacherBlockedTooltipForStuff(teacher.notTakeNewStudentsAt, teacher.blockedAvailability, teacher.blockedAt)"
            >
              <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
            </li>
            <li nz-menu-item (click)="onFreeSlotsClick()"
              [nzDisabled]="isTeacherResigned()"
              nz-tooltip
              [nzTooltipTrigger]="isTeacherResigned() ? 'hover' : null"
              [nzTooltipTitle]="'calendar.slot.tooltips.teacher_resigned' | translate"
            >
              <span>{{'calendar.filters.slots' | translate}}</span>
            </li>
          </ng-container>
          <ng-template #addMenuWithoutTeacher>
            <li nz-menu-item (click)="onOpenFirstLessonForm()">
              <span>{{'calendar.first-lesson.first_lesson' | translate}}</span>
            </li>
          </ng-template>
          <li nz-menu-item (click)="onOpenVacations()"
            [nzDisabled]="isTeacherResigned()"
            nz-tooltip
            [nzTooltipTrigger]="isTeacherResigned() ? 'hover' : null"
            [nzTooltipTitle]="'calendar.slot.tooltips.teacher_resigned' | translate"
          >
            <span>{{'calendar.vacations.open-btn' | translate}}</span>
          </li>
          <li nz-menu-item (click)="onNewDealClick()">
            <span>{{'lessons_package' | translate}}</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    }
  </ng-template>
  <!-- END ACTION BUTTONS-->

  <nz-layout class="layout-wrapper">
    <!-- Filters -->
    <nz-drawer
      *ngIf="tabletMode"
      [nzClosable]="false"
      (nzOnClose)="toggleFiltersDrawer(true)"
      [nzVisible]="authService.isClientOrStudent() ? !isClientFiltersDrawerClosed : !isFiltersDrawerClosed"
      [nzContent]="authService.isClientOrStudent() ? clientsFiltersComponent : filtersComponent"
      nzPlacement="left"
      [nzWidth]="280"
      [nzTitle]="filtersDrawerTitle"
      nzWrapClassName="schedule-filters-drawer"
    ></nz-drawer>
    <ng-template mathLeftSider>
      <nz-sider
        mathContentSiderStyle
        *ngIf="!tabletMode"
        nzCollapsible
        nzBreakpoint="lg"
        [nzTrigger]="null"
        [nzCollapsedWidth]="0"
        (nzCollapsedChange)="toggleFiltersSidebar($event)"
        [nzCollapsed]="authService.isClientOrStudent() ? isClientFiltersSidebarCollapsed : isFiltersSidebarCollapsed"
        [nzWidth]="280"
        class="sidebar"
      >
        <ng-template [ngTemplateOutlet]="filtersSidebarTitle"></ng-template>
        <ng-template [ngTemplateOutlet]="authService.isClientOrStudent() ? clientsFiltersComponent : filtersComponent"></ng-template>
      </nz-sider>
    </ng-template>
    <!-- END Filters -->

    <!-- Lesson details -->
    @if(!isLessonDetailsDrawerClosed) {
      <nz-drawer
        [nzClosable]="false"
        (nzOnClose)="onCloseLessonDetails()"
        [nzVisible]="!isLessonDetailsDrawerClosed"
        [nzContent]="lessonDetailsComponent"
        nzPlacement="right"
        [nzWidth]="370"
        [nzTitle]="lessonDetailsDrawerTitle"
        nzWrapClassName="lesson-details-drawer"
      ></nz-drawer>
    }

    <!-- Content -->
    <nz-content class="content calendar-content">

      <nz-row [nzGutter]="15">
      <!--        FILTERS BTN-->
        @let showFiltersBtn = isFiltersSidebarCollapsed || isClientFiltersSidebarCollapsed || tabletMode;
        @if (showFiltersBtn) {
          <nz-col [nzSm]="4"
                  [nzMd]="3"
                  [nzXl]="2"
                  [nzXs]="0">
            <button
              (click)="tabletMode ? toggleFiltersDrawer(false) : toggleFiltersSidebar(false)"
              class="action-btn filters">
              {{'filters' | translate}}
            </button>
          </nz-col>
        }
        <!--        END FILTERS BTN-->

        <!--        CALENDAR ALERTS-->
        @if (authService.isTeacher() && authService.isUserUkrainian()) {
          <nz-col [nzSm]="showFiltersBtn ? 20 : 24"
                  [nzMd]="showFiltersBtn ? 21 : 24"
                  [nzXl]="showFiltersBtn ? 22 : 24"
                  [nzXs]="24">
            <div class="conduction-warning">
              {{'calendar.free-students-warning' | translate}}
            </div>
          </nz-col>
        }

        @if (authService.isTeacher() && !authService.isUserUkrainian() && isUncoductedLessonsPresent) {
          <nz-col [nzSm]="showFiltersBtn ? 20 : 24"
                  [nzMd]="showFiltersBtn ? 21 : 24"
                  [nzXl]="showFiltersBtn ? 22 : 24"
                  [nzXs]="24">
            <div class="conduction-warning">
              {{'calendar.conduction-warning' | translate}}
            </div>
          </nz-col>
        }
        <!--        END CALENDAR ALERTS-->
      </nz-row>

<!--        CALENDAR NAVIGATION-->
      <nz-row class="view-switchers">
        <nz-col>
          <mathema-schedule-calendar-type-switch [desktopMode]="true"></mathema-schedule-calendar-type-switch>
        </nz-col>
        <nz-col
          class="diapason-switch">
          <mathema-schedule-calendar-diapason-switch [desktopMode]="true"></mathema-schedule-calendar-diapason-switch>
        </nz-col>
        <nz-col
          class="today-btn">
          <button class="action-btn bordered" (click)="onTodayClick()">
            {{'today' | translate}}
          </button>
        </nz-col>
      </nz-row>
      <nz-row class="header-mobile">
        <div class="actions-block">
          <button
            class="action-btn bordered filters"
            (click)="toggleFiltersModal(false)"
          >
            {{'filters' | translate}}
          </button>

          <div class="main-action-buttons">
            <nz-col class="today-btn">
              <button class="action-btn bordered" (click)="onTodayClick()">
                {{'today' | translate}}
              </button>
            </nz-col>
          </div>
        </div>
      </nz-row>
      <!--      END CALENDAR NAVIGATION-->

      <nz-row class="calendar">
        <section class="host"><ng-template scheduleCalendarHost></ng-template></section>
      </nz-row>

      <nz-row class="footer-mobile">
        <nz-col nzFlex="1">
          <mathema-schedule-calendar-type-switch>
          </mathema-schedule-calendar-type-switch>
        </nz-col>
        <nz-col nzFlex="3">
          <mathema-schedule-calendar-diapason-switch>
          </mathema-schedule-calendar-diapason-switch>
        </nz-col>
      </nz-row>
    </nz-content>

    <nz-drawer
      *ngIf="!mobileMode && !isFirstLessonDrawerCollapsed"
      [nzClosable]="false"
      (nzOnClose)="onCloseFirstLessonForm()"
      [nzVisible]="!isFirstLessonDrawerCollapsed"
      [nzContent]="firstLessonComponent"
      nzPlacement="right"
      [nzWidth]="365"
      [nzTitle]="null"
      nzWrapClassName="create-first-lesson-drawer"
    ></nz-drawer>

    <!-- Move lesson -->
    <ng-template mathRightSider>
      <nz-sider
        mathContentSiderStyle
        *ngIf="!tabletMode && !isMoveLessonSidebarCollapsed"
        nzBreakpoint="lg"
        [nzWidth]="365"
        (clickOutside)="onCloseMoveLesson()"
        class="move-lesson-sider sidebar right-sidebar"
      >
        <ng-template [ngTemplateOutlet]="moveLessonComponent"></ng-template>
      </nz-sider>
    </ng-template>

    <nz-drawer
      *ngIf="tabletMode"
      [nzClosable]="false"
      (nzOnClose)="onCloseMoveLesson()"
      [nzVisible]="!isMoveLessonDrawerCollapsed"
      [nzContent]="moveLessonComponent"
      nzPlacement="right"
      [nzWidth]="365"
      [nzTitle]="null"
      nzWrapClassName="move-lesson-drawer"
    ></nz-drawer>
  </nz-layout>

  <!-- Filters templates -->
  <ng-template #filtersComponent>
    <mathema-schedule-calendar-filters></mathema-schedule-calendar-filters>
  </ng-template>

  <ng-template #clientsFiltersComponent>
    <math-schedule-calendar-client-filters></math-schedule-calendar-client-filters>
  </ng-template>

  <ng-template #filtersDrawerTitle>
    <nz-row
      class="schedule-filters-header"
      nzJustify="space-between">
      <nz-col nzSpan="8"
              nzOffset="1">
        <h2>{{'filters' | translate}}</h2>
      </nz-col>
      <nz-col nzSpan="2"
              nzPull="1">
        <button (click)="toggleFiltersDrawer(true)"
                class="close-schedule-filters-btn">
        </button>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #filtersSidebarTitle>
    <nz-row
      class="schedule-filters-header"
      nzJustify="space-between">
      <nz-col nzSpan="8"
              nzOffset="1">
        <h2>{{'filters' | translate}}</h2>
      </nz-col>

      <nz-col
        nzSpan="2"
        nzPull="1">
        <button (click)="toggleFiltersSidebar(true)"
                class="close-schedule-filters-btn">
        </button>
      </nz-col>
    </nz-row>
  </ng-template>

  <!-- Lesson details templates -->
  <ng-template #lessonDetailsDrawerTitle>
    <nz-row class="lesson-details-title" nzJustify="center" nzAlign="middle">
      <nz-col nzSpan="2">
        <span nz-icon (click)="onCloseLessonDetails()"><img src="assets/icons/cross.svg" alt="close"></span>
      </nz-col>
      <nz-col nzOffset="2" nzSpan="20">
        <h2>{{'calendar.lesson-details.lesson_details' | translate}}</h2>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template #lessonDetailsComponent>
    <mathema-schedule-calendar-lesson-details
      (onEditFirstLesson)="onCloseLessonDetails(true); onOpenFirstLessonForm()"
      (onMoveLesson)="onCloseLessonDetails(true); onOpenMoveLesson()"
      (onSkipLesson)="onOpenSkipLesson($event)"
      (onCancelConductLesson)="onOpenCancelConductLesson()"
      (onConductLesson)="openConductLesson($event)"
      (onCancelLesson)="onOpenCancelLesson()"
      [mobileMode]="mobileMode"
    ></mathema-schedule-calendar-lesson-details>
  </ng-template>

  <ng-template #conductLessonModalComponent>
    <math-conduct-lesson-modal
      [lessonId]="activeLessonToConduct?.id"
      [isConductInsteadSkip]="isConductInsteadSkip"
      (onSuccess)="onLessonConducted()"
      (onCancel)="onCloseConductLesson()"
    ></math-conduct-lesson-modal>
  </ng-template>

  <!-- Create first lesson templates -->
  <ng-template #firstLessonComponent>
    <mathema-schedule-calendar-first-lesson (onCancel)="onCloseFirstLessonForm()"></mathema-schedule-calendar-first-lesson>
  </ng-template>

  <!-- Move lesson component templates -->
  <ng-template #moveLessonComponent>
    <mathema-schedule-calendar-move-lesson
      (onClose)="onCloseMoveLesson()"
      [showNotification]="isSendNotificationNeeded()">
    </mathema-schedule-calendar-move-lesson>
  </ng-template>
</nz-layout>

<!-- Filters modal -->
<nz-modal
  [nzContent]="authService.isClientOrStudent() ? clientsFiltersComponent : filtersComponent"
  [nzVisible]="authService.isClientOrStudent() ? !isClientFiltersModalClosed : !isFiltersModalClosed"
  [nzTitle]="'filters' | translate"
  nzClassName="filters-mobile"
  (nzOnCancel)="toggleFiltersModal(true)"
  (nzOnOk)="toggleFiltersModal(true)"
  [nzFooter]="null"
></nz-modal>

<!-- Conduct lesson modal -->
<nz-modal
  [nzContent]="conductLessonModalComponent"
  [nzVisible]="!isConductLessonModalClosed" (nzVisibleChange)="isConductLessonModalClosed = $event"
  nzTitle="{{'calendar.conduct-lesson.title' | translate}}"
  [nzClassName]="mobileMode ? 'calendar-modal-mobile' : 'calendar-modal'"
  (nzOnCancel)="onCloseConductLesson()"
  (nzOnOk)="onCloseConductLesson()"
  [nzFooter]="null"
  nzCentered
  nzWidth="500px"
></nz-modal>

<!-- Lesson details modal -->
<nz-modal
  [nzContent]="lessonDetailsComponent"
  [nzVisible]="!isLessonDetailsModalClosed" (nzVisibleChange)="isLessonDetailsModalClosed = $event"
  nzTitle="{{'calendar.lesson-details.lesson_details' | translate}}"
  nzClassName="lesson-details-mobile"
  (nzOnCancel)="onCloseLessonDetails()"
  (nzOnOk)="onCloseLessonDetails()"
  [nzFooter]="null"
></nz-modal>

<!-- Create first lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="firstLessonComponent"
  [nzVisible]="!isFirstLessonModalClosed"
  (nzVisibleChange)="isFirstLessonModalClosed = $event"
  [nzTitle]="null"
  nzClassName="create-first-lesson-mobile"
  (nzOnCancel)="onCloseFirstLessonForm()"
  (nzOnOk)="onCloseFirstLessonForm()"
  [nzFooter]="null"
></nz-modal>

<!-- Move lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="moveLessonComponent"
  [nzVisible]="!isMoveLessonModalClosed" (nzVisibleChange)="isMoveLessonModalClosed = $event"
  [nzTitle]="null"
  nzClassName="move-lesson-mobile"
  (nzOnCancel)="onCloseMoveLesson()"
  (nzOnOk)="onCloseMoveLesson()"
  [nzFooter]="null"
></nz-modal>

<!-- Vacations modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="vacationsComponent"
  [nzVisible]="!isVacationsModalClosed" (nzVisibleChange)="isVacationsModalClosed = $event"
  [nzTitle]="vacationsModalTitle"
  [nzMaskClosable]="false"
  [nzClassName]="mobileMode ? 'vacations-mobile' : 'vacations-modal'"
  (nzOnCancel)="onCloseVacations()"
  (nzOnOk)="onCloseVacations()"
  [nzFooter]="null"
  nzWidth="400"
  nzCentered
></nz-modal>


<!-- Vacations component templates -->
<ng-template #vacationsComponent>
  <mathema-schedule-calendar-vacations (onSuccess)="onCloseVacations(); scheduleCalendarService.refreshCalendarDate()"></mathema-schedule-calendar-vacations>
</ng-template>

<ng-template #vacationsModalTitle>
  <nz-row class="vacations-title" nzJustify="center" nzAlign="middle">
    <nz-col nzSpan="2">
      <span nz-icon (click)="onCloseVacations()"><img src="assets/icons/cross.svg" alt="close"></span>
    </nz-col>
    <nz-col nzSpan="22">
      <h2>{{'calendar.vacations.header' | translate}}</h2>
    </nz-col>
  </nz-row>
</ng-template>
