import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { combineLatest, Subscription } from 'rxjs';
import { delay, finalize, throttleTime } from 'rxjs/operators';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../../auth/auth.service';
import ScheduleCalendarService, { ScheduleCalendarType } from './schedule-calendar.service';
import { ScheduleYearCalendarComponent } from './schedule-year-calendar/schedule-year-calendar.component';
import { ScheduleMonthCalendarComponent } from './schedule-month-calendar/schedule-month-calendar.component';
import { ScheduleWeekCalendarComponent } from './schedule-week-calendar/schedule-week-calendar.component';
import { ScheduleCalendarHostDirective } from './schedule-calendar-host.directive';
import { ScheduleDayCalendarComponent } from './schedule-day-calendar/schedule-day-calendar.component';
import {
  MathemaModalConfirmComponent,
  MathemaModalConfirmParams
} from '@shared/components/modal-confirm/modal-confirm.component';
import ScheduleApiService from '../schedule-api.service';
import { Router } from '@angular/router';
import { AppRoutesDefinitions } from '@app/app.routes';
import LanguageService from '@shared/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import LessonDto, { LessonType } from '../dto/lesson.dto';
import { SkipLessonModalComponent } from './modals/skip-lesson-modal/skip-lesson-modal.component';

@Component({
  selector: 'mathema-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarComponent implements OnInit, OnDestroy {

  public mobileMode: boolean;
  public tabletMode: boolean;

  public isFiltersSidebarCollapsed: boolean = false;
  public isFiltersDrawerClosed: boolean = true;
  public isFiltersModalClosed: boolean = true;

  public isClientFiltersSidebarCollapsed: boolean = false;
  public isClientFiltersDrawerClosed: boolean = true;
  public isClientFiltersModalClosed: boolean = true;

  public isLessonDetailsDrawerClosed: boolean = true;
  public isLessonDetailsModalClosed: boolean = true;

  public activeLessonToConduct: LessonDto | null = null;
  public isConductInsteadSkip: boolean = false;
  public isConductLessonModalClosed: boolean = true;

  public isReportModalClosed: boolean = true;

  public isAddMenuOpened: boolean = false;

  public isFirstLessonDrawerCollapsed: boolean = true;
  public isFirstLessonModalClosed: boolean = true;

  public isVacationsModalClosed: boolean = true;

  public isMoveLessonSidebarCollapsed: boolean = true;
  public isMoveLessonDrawerCollapsed: boolean = true;
  public isMoveLessonModalClosed: boolean = true;

  public isUncoductedLessonsPresent: boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild(ScheduleCalendarHostDirective, { static: true }) calendarHost: ScheduleCalendarHostDirective;

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly modalService: NzModalService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
  ) {
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 991px)']).subscribe(result => {
      this.tabletMode = result.matches;
    }));
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 575px)']).subscribe(result => {
      this.mobileMode = result.matches;
    }));
  }

  public ngOnInit(): void {
    if (this.authService.isTeacher()) {

      this.subscriptions.push(combineLatest([
        this.scheduleCalendarService.calendarDate,
        this.scheduleCalendarService.calendarFilters,
      ]).pipe(throttleTime(100)).subscribe(() => {
        this.scheduleApiService.checkNotConductedLessons().subscribe((value) => {
          this.isUncoductedLessonsPresent = value;
        })
      }));

      const teacherId = this.authService.user.teacherId;

      const filters = this.scheduleCalendarService.calendarFilters.value;
      this.scheduleCalendarService.calendarFilters.next({
        ...filters,
        teacherId,
      });

      const subscription = this.scheduleApiService.getTeacherById(teacherId).subscribe((teacher) => {
        this.scheduleCalendarService.activeTeacher.next(teacher);
        this.subscribeToCalendarType();
      });

      this.subscriptions.push(subscription);
    } else if (!this.authService.isTeacher()) {
      this.subscribeToCalendarType();
    }

    this.subscribeToActiveSlot();
  }

  public openAiHelper(): void {
    let aiHelperLink: string;

    if (this.authService.isUserPoland()) {
      aiHelperLink = 'https://bit.ly/3zK6AFy';
    } else if (this.authService.isUserUkrainian()) {
      aiHelperLink = 'https://bit.ly/3S9OiDT';
    }

    if (!aiHelperLink) {
      return;
    }

    window.open(aiHelperLink, '_blank');
  }

  private subscribeToCalendarType(): void {
    this.subscriptions.push(
      this.scheduleCalendarService.calendarType.subscribe(calendarType => this.renderCalendar(calendarType)),
    );
  }

  public onCloseLessonDetails(keepActiveSlot: boolean = false): void {
    if (!this.mobileMode) {
      this.isLessonDetailsDrawerClosed = true;
    } else {
      this.isLessonDetailsModalClosed = true;
    }

    if (keepActiveSlot) {
      return;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenFirstLessonForm(): void {
    this.onCloseMoveLesson(true);

    if (this.mobileMode) {
      this.isFirstLessonModalClosed = false;
    } else {
      this.isFirstLessonDrawerCollapsed = false;
    }
  }

  public onOpenVacations(): void {
    this.onCloseMoveLesson(true);
    this.isVacationsModalClosed = false;
  }

  public onCloseFirstLessonForm(): void {
    if (this.mobileMode) {
      this.isFirstLessonModalClosed = true;
    } else {
      this.isFirstLessonDrawerCollapsed = true;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onCloseVacations(): void {
    this.isVacationsModalClosed = true;
    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenMoveLesson(): void {
    if (this.mobileMode) {
      this.isMoveLessonModalClosed = false;
    } else if (this.tabletMode) {
      this.isMoveLessonDrawerCollapsed = false;
    } else {
      this.isMoveLessonSidebarCollapsed = false;
    }
  }

  public onCloseMoveLesson(keepActiveSlot: boolean = false): void {
    if (this.mobileMode) {
      this.isMoveLessonModalClosed = true;
    } else if (this.tabletMode) {
      this.isMoveLessonDrawerCollapsed = true;
    } else {
      this.isMoveLessonSidebarCollapsed = true;
    }

    if (keepActiveSlot) {
      return;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenSkipLesson(lesson: LessonDto): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzTitle: null,
      nzFooter: null,
      nzCentered: true,
      nzWrapClassName: 'skip-lesson-modal',
      nzContent: SkipLessonModalComponent,
      nzData: {
        showNotification: this.isSendNotificationNeeded(),
      },
    });

    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.onCloseLessonDetails();
      this.scheduleCalendarService.refreshCalendarDate();
    });

    modalRef.componentInstance.onOpenConduct.subscribe(() => {
      this.openConductLesson(lesson, true);
    });
  }

  public openConductLesson(lesson: LessonDto, isConductInsteadSkip?: boolean): void {
    this.activeLessonToConduct = lesson;
    this.isConductInsteadSkip = isConductInsteadSkip;

    if (lesson.type === LessonType.REGULAR) {
      this.isConductLessonModalClosed = false;
    } else {
      this.isReportModalClosed = false;
    }
  }

  public onCloseConductLesson(): void {
    this.activeLessonToConduct = null;
    this.isConductInsteadSkip = false;
    this.isConductLessonModalClosed = true;
    this.isReportModalClosed = true;
  }

  public onLessonConducted(): void {
    this.activeLessonToConduct = null;
    this.isConductInsteadSkip = false;
    this.isConductLessonModalClosed = true;
    this.isReportModalClosed = true;
    this.onCloseLessonDetails();
    this.scheduleCalendarService.refreshCalendarDate();
  }

  public toggleFiltersDrawer(value: boolean): void {
    if (this.authService.isClient()) {
      this.isClientFiltersDrawerClosed = value;
    } else {
      this.isFiltersDrawerClosed = value;
    }
  }

  public toggleFiltersSidebar(value: boolean): void {
    if (this.authService.isClient()) {
      this.isClientFiltersSidebarCollapsed = value;
    } else {
      this.isFiltersSidebarCollapsed = value;
    }
  }

  public toggleFiltersModal(value: boolean): void {
    if (this.authService.isClient()) {
      this.isClientFiltersModalClosed = value;
    } else {
      this.isFiltersModalClosed = value;
    }
  }

  public onOpenCancelConductLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.conduct-lesson.header_unconduction'),
      message: this.translateService.instant('calendar.conduct-lesson.message_unconduction'),
      acceptText: this.translateService.instant('main.btn.yes'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

      modalRef.componentInstance.isLoading = true;

      this.subscriptions.push(
        this.scheduleApiService.removeConductionFromLesson(lessonId).pipe(
          finalize(() => {
            modalRef.close();
            this.onCloseLessonDetails();
          })
        ).subscribe(() => {
          this.scheduleCalendarService.refreshCalendarDate();
        }),
      );
    });
  }

  public onOpenCancelLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.cancel-lesson.header'),
      message: this.translateService.instant('calendar.cancel-lesson.message'),
      acceptText: this.translateService.instant('calendar.lesson-details.cancel_lesson'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

      modalRef.componentInstance.isLoading = true;
      this.subscriptions.push(
        this.scheduleApiService.cancelLesson(lessonId).pipe(
          delay(2000),
          finalize(() => {
            modalRef.close();
            this.onCloseLessonDetails();
          })
        ).subscribe(() => {
          this.scheduleCalendarService.refreshCalendarDate();
        }),
      );
    });
  }

  private openModalConfirm(params: MathemaModalConfirmParams, acceptCallback: (modalRef: NzModalRef) => void): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzData: { params },
    });

    this.subscriptions.push(
      modalRef.componentInstance.onDecline.subscribe(() => {
        modalRef.close();
      }),
    );
    this.subscriptions.push(
      modalRef.componentInstance.onAccept.subscribe(acceptCallback.bind(this, modalRef)),
    );
  }

  public isSendNotificationNeeded(): boolean {
    return this.authService.isAdmin() || this.authService.isManager();
  }

  public getTeacherBlockedTooltipForStuff(notTakeNewStudentsAt: Date, isTeacherBlocked: boolean, blockedAt: Date): string {
    if (notTakeNewStudentsAt && !isTeacherBlocked) {
      return this.translateService.instant('calendar.slot.tooltips.teacher_not_take_students');
    }

    const unblockFrom = new Date(blockedAt);
    unblockFrom.setDate(unblockFrom.getDate() + 14);

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return this.translateService.instant('calendar.slot.tooltips.teacher_blocked_until', { unblockDate: formatter.format(unblockFrom) });
  }

  public getTeacherBlockedTooltipForTeacher(blockedAt: Date): string {
    const unblockFrom = new Date(blockedAt);
    unblockFrom.setDate(unblockFrom.getDate() + 14);

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return this.translateService.instant('calendar.slot.tooltips.teacher_blocked_self', { unblockDate: formatter.format(unblockFrom) });
  }

  private renderCalendar(calendarType: ScheduleCalendarType): void {
    const viewContainerRef = this.calendarHost.viewContainerRef;
    viewContainerRef.clear();

    let component;

    if (calendarType === ScheduleCalendarType.YEAR) {
      component = ScheduleYearCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.MONTH) {
      component = ScheduleMonthCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.WEEK) {
      component = ScheduleWeekCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.DAY) {
      component = ScheduleDayCalendarComponent;
    }

    localStorage.setItem('activeCalendarType', calendarType as unknown as string);

    // pass data into component ref if need
    const componentRef = viewContainerRef.createComponent(component);
  }

  private get firstLessonActive(): boolean {
    return !this.isFirstLessonDrawerCollapsed || !this.isFirstLessonModalClosed;
  }

  private get moveLessonActive(): boolean {
    return !this.isMoveLessonSidebarCollapsed || !this.isMoveLessonDrawerCollapsed || !this.isMoveLessonModalClosed;
  }

  private subscribeToActiveSlot(): void {
    this.subscriptions.push(
      this.scheduleCalendarService.activeSlot.subscribe(activeSlot => {
        if (this.firstLessonActive) {
          this.onCloseFirstLessonForm();
        }

        if (this.moveLessonActive) {
          this.onCloseMoveLesson();
        }

        if (!activeSlot) {
          return;
        }

        const { type, freeSlotAction } = activeSlot;

        if (type === 'lesson') {
          this.mobileMode
            ? this.isLessonDetailsModalClosed = false
            : this.isLessonDetailsDrawerClosed = false;
        }

        if (type === 'freeSlot') {
          if (freeSlotAction === 'firstLesson') {
            this.onOpenFirstLessonForm();
          }
        }
      }),
    );
  }

  public onTodayClick(): void {
    const value = this.scheduleCalendarService.calendarType.value;
    this.scheduleCalendarService.calendarType.next(value);
    this.scheduleCalendarService.calendarDate.next(new Date());
  }

  public onNewDealClick(): void {
    this.router.navigate([AppRoutesDefinitions.DEAL]);
  }

  public onFreeSlotsClick(): void {
    this.router.navigate([AppRoutesDefinitions.FREE_SLOTS]);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
