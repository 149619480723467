import {LanguageEnum} from "@shared/constants";

export const countries: {text: string; value: string}[] = [
  { text: "Ukraine", value: "UA" },
  { text: "Poland", value: "PL" },
  { text: "Afghanistan", value: "AF" },
  { text: "Aland Islands", value: "AX" },
  { text: "Albania", value: "AL" },
  { text: "Algeria", value: "DZ" },
  { text: "American Samoa", value: "AS" },
  { text: "Andorra", value: "AD" },
  { text: "Angola", value: "AO" },
  { text: "Anguilla", value: "AI" },
  { text: "Antarctica", value: "AQ" },
  { text: "Antigua and Barbuda", value: "AG" },
  { text: "Argentina", value: "AR" },
  { text: "Armenia", value: "AM" },
  { text: "Aruba", value: "AW" },
  { text: "Australia", value: "AU" },
  { text: "Austria", value: "AT" },
  { text: "Azerbaijan", value: "AZ" },
  { text: "Bahamas", value: "BS" },
  { text: "Bahrain", value: "BH" },
  { text: "Bangladesh", value: "BD" },
  { text: "Barbados", value: "BB" },
  { text: "Belarus", value: "BY" },
  { text: "Belgium", value: "BE" },
  { text: "Belize", value: "BZ" },
  { text: "Benin", value: "BJ" },
  { text: "Bermuda", value: "BM" },
  { text: "Bhutan", value: "BT" },
  { text: "Bolivia", value: "BO" },
  { text: "Bosnia and Herzegovina", value: "BA" },
  { text: "Botswana", value: "BW" },
  { text: "Bouvet Island", value: "BV" },
  { text: "Brazil", value: "BR" },
  { text: "British Indian Ocean Territory (Chagos Archipelago)", value: "IO" },
  { text: "Brunei Darussalam", value: "BN" },
  { text: "Bulgaria", value: "BG" },
  { text: "Burkina Faso", value: "BF" },
  { text: "Burundi", value: "BI" },
  { text: "Cambodia", value: "KH" },
  { text: "Cameroon", value: "CM" },
  { text: "Canada", value: "CA" },
  { text: "Cape Verde", value: "CV" },
  { text: "Cayman Islands", value: "KY" },
  { text: "Central African Republic", value: "CF" },
  { text: "Chad", value: "TD" },
  { text: "Chile", value: "CL" },
  { text: "China", value: "CN" },
  { text: "Christmas Island", value: "CX" },
  { text: "Cocos (Keeling) Islands", value: "CC" },
  { text: "Colombia", value: "CO" },
  { text: "Comoros", value: "KM" },
  { text: "Congo", value: "CG" },
  { text: "Democratic Republic of the Congo", value: "CD" },
  { text: "Cook Islands", value: "CK" },
  { text: "Costa Rica", value: "CR" },
  { text: "Cote D'Ivoire", value: "CI" },
  { text: "Croatia", value: "HR" },
  { text: "Cuba", value: "CU" },
  { text: "Cyprus", value: "CY" },
  { text: "Czechia", value: "CZ" },
  { text: "Denmark", value: "DK" },
  { text: "Djibouti", value: "DJ" },
  { text: "Dominica", value: "DM" },
  { text: "Dominican Republic", value: "DO" },
  { text: "Ecuador", value: "EC" },
  { text: "Egypt", value: "EG" },
  { text: "El Salvador", value: "SV" },
  { text: "England", value: "GB" },
  { text: "Equatorial Guinea", value: "GQ" },
  { text: "Eritrea", value: "ER" },
  { text: "Estonia", value: "EE" },
  { text: "Ethiopia", value: "ET" },
  { text: "Falkland Islands (Malvinas)", value: "FK" },
  { text: "Faroe Islands", value: "FO" },
  { text: "Fiji", value: "FJ" },
  { text: "Finland", value: "FI" },
  { text: "France", value: "FR" },
  { text: "French Guiana", value: "GF" },
  { text: "French Polynesia", value: "PF" },
  { text: "French Southern Territories", value: "TF" },
  { text: "Gabon", value: "GA" },
  { text: "Gambia", value: "GM" },
  { text: "Georgia", value: "GE" },
  { text: "Germany", value: "DE" },
  { text: "Ghana", value: "GH" },
  { text: "Gibraltar", value: "GI" },
  { text: "Greece", value: "GR" },
  { text: "Greenland", value: "GL" },
  { text: "Grenada", value: "GD" },
  { text: "Guadeloupe", value: "GP" },
  { text: "Guam", value: "GU" },
  { text: "Guatemala", value: "GT" },
  { text: "Guernsey", value: "GG" },
  { text: "Guinea", value: "GN" },
  { text: "Guinea-Bissau", value: "GW" },
  { text: "Guyana", value: "GY" },
  { text: "Haiti", value: "HT" },
  { text: "Heard Island and Mcdonald Islands", value: "HM" },
  { text: "Holy See (Vatican City State)", value: "VA" },
  { text: "Honduras", value: "HN" },
  { text: "Hong Kong", value: "HK" },
  { text: "Hungary", value: "HU" },
  { text: "Iceland", value: "IS" },
  { text: "India", value: "IN" },
  { text: "Indonesia", value: "ID" },
  { text: "Iran, Islamic Republic Of", value: "IR" },
  { text: "Iraq", value: "IQ" },
  { text: "Ireland", value: "IE" },
  { text: "Isle of Man", value: "IM" },
  { text: "Israel", value: "IL" },
  { text: "Italy", value: "IT" },
  { text: "Jamaica", value: "JM" },
  { text: "Japan", value: "JP" },
  { text: "Jersey", value: "JE" },
  { text: "Jordan", value: "JO" },
  { text: "Kazakhstan", value: "KZ" },
  { text: "Kenya", value: "KE" },
  { text: "Kiribati", value: "KI" },
  { text: "Korea, Democratic People'S Republic of", value: "KP" },
  { text: "Korea, Republic of", value: "KR" },
  { text: "Kuwait", value: "KW" },
  { text: "Kyrgyz Republic", value: "KG" },
  { text: "Lao People'S Democratic Republic", value: "LA" },
  { text: "Latvia", value: "LV" },
  { text: "Lebanon", value: "LB" },
  { text: "Lesotho", value: "LS" },
  { text: "Liberia", value: "LR" },
  { text: "Libyan Arab Jamahiriya", value: "LY" },
  { text: "Liechtenstein", value: "LI" },
  { text: "Lithuania", value: "LT" },
  { text: "Luxembourg", value: "LU" },
  { text: "Macao", value: "MO" },
  { text: "North Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { text: "Madagascar", value: "MG" },
  { text: "Malawi", value: "MW" },
  { text: "Malaysia", value: "MY" },
  { text: "Maldives", value: "MV" },
  { text: "Mali", value: "ML" },
  { text: "Malta", value: "MT" },
  { text: "Marshall Islands", value: "MH" },
  { text: "Martinique", value: "MQ" },
  { text: "Mauritania", value: "MR" },
  { text: "Mauritius", value: "MU" },
  { text: "Mayotte", value: "YT" },
  { text: "Mexico", value: "MX" },
  { text: "Micronesia, Federated States of", value: "FM" },
  { text: "Moldova, Republic of", value: "MD" },
  { text: "Monaco", value: "MC" },
  { text: "Mongolia", value: "MN" },
  { text: "Montserrat", value: "MS" },
  { text: "Morocco", value: "MA" },
  { text: "Mozambique", value: "MZ" },
  { text: "Myanmar", value: "MM" },
  { text: "Namibia", value: "NA" },
  { text: "Nauru", value: "NR" },
  { text: "Nepal", value: "NP" },
  { text: "Netherlands", value: "NL" },
  { text: "Netherlands Antilles", value: "AN" },
  { text: "New Caledonia", value: "NC" },
  { text: "New Zealand", value: "NZ" },
  { text: "Nicaragua", value: "NI" },
  { text: "Niger", value: "NE" },
  { text: "Nigeria", value: "NG" },
  { text: "Niue", value: "NU" },
  { text: "Norfolk Island", value: "NF" },
  { text: "Northern Mariana Islands", value: "MP" },
  { text: "Norway", value: "NO" },
  { text: "Oman", value: "OM" },
  { text: "Pakistan", value: "PK" },
  { text: "Palau", value: "PW" },
  { text: "Palestinian Territory, Occupied", value: "PS" },
  { text: "Panama", value: "PA" },
  { text: "Papua New Guinea", value: "PG" },
  { text: "Paraguay", value: "PY" },
  { text: "Peru", value: "PE" },
  { text: "Philippines", value: "PH" },
  { text: "Pitcairn Islands", value: "PN" },
  { text: "Portugal", value: "PT" },
  { text: "Puerto Rico", value: "PR" },
  { text: "Qatar", value: "QA" },
  { text: "Reunion", value: "RE" },
  { text: "Romania", value: "RO" },
  { text: "Russian Federation", value: "RU" },
  { text: "RWANDA", value: "RW" },
  { text: "Saint Helena", value: "SH" },
  { text: "Saint Kitts and Nevis", value: "KN" },
  { text: "Saint Lucia", value: "LC" },
  { text: "Saint Pierre and Miquelon", value: "PM" },
  { text: "Saint Vincent and the Grenadines", value: "VC" },
  { text: "Samoa", value: "WS" },
  { text: "San Marino", value: "SM" },
  { text: "Sao Tome and Principe", value: "ST" },
  { text: "Saudi Arabia", value: "SA" },
  { text: "Senegal", value: "SN" },
  { text: "Serbia and Montenegro", value: "CS" },
  { text: "Seychelles", value: "SC" },
  { text: "Sierra Leone", value: "SL" },
  { text: "Singapore", value: "SG" },
  { text: "Slovakia", value: "SK" },
  { text: "Slovenia", value: "SI" },
  { text: "Solomon Islands", value: "SB" },
  { text: "Somalia", value: "SO" },
  { text: "South Africa", value: "ZA" },
  { text: "South Georgia and the South Sandwich Islands", value: "GS" },
  { text: "Spain", value: "ES" },
  { text: "Sri Lanka", value: "LK" },
  { text: "Sudan", value: "SD" },
  { text: "Suriname", value: "SR" },
  { text: "Svalbard and Jan Mayen", value: "SJ" },
  { text: "Swaziland", value: "SZ" },
  { text: "Sweden", value: "SE" },
  { text: "Switzerland", value: "CH" },
  { text: "Syrian Arab Republic", value: "SY" },
  { text: "Taiwan, Province of China", value: "TW" },
  { text: "Tajikistan", value: "TJ" },
  { text: "Tanzania, United Republic of", value: "TZ" },
  { text: "Thailand", value: "TH" },
  { text: "Timor-Leste", value: "TL" },
  { text: "Togo", value: "TG" },
  { text: "Tokelau", value: "TK" },
  { text: "Tonga", value: "TO" },
  { text: "Trinidad and Tobago", value: "TT" },
  { text: "Tunisia", value: "TN" },
  { text: "Turkey", value: "TR" },
  { text: "Turkmenistan", value: "TM" },
  { text: "Turks and Caicos Islands", value: "TC" },
  { text: "Tuvalu", value: "TV" },
  { text: "Uganda", value: "UG" },
  { text: "United Arab Emirates", value: "AE" },
  { text: "United Kingdom", value: "GB" },
  { text: "United States of America", value: "US" },
  { text: "United States Minor Outlying Islands", value: "UM" },
  { text: "Uruguay", value: "UY" },
  { text: "Uzbekistan", value: "UZ" },
  { text: "Vanuatu", value: "VU" },
  { text: "Venezuela", value: "VE" },
  { text: "Viet Nam", value: "VN" },
  { text: "Virgin Islands, British", value: "VG" },
  { text: "Virgin Islands, U.S.", value: "VI" },
  { text: "Wallis and Futuna", value: "WF" },
  { text: "Western Sahara", value: "EH" },
  { text: "Yemen", value: "YE" },
  { text: "Zambia", value: "ZM" },
  { text: "Zimbabwe", value: "ZW" },
]


const engTranslations: string[] = [
  'Afghanistan',
  'Aland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (Chagos Archipelago)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czechia',
  'Democratic Republic of the Congo',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Faroe Islands',
  'Falkland Islands (Malvinas)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (Vatican City State)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Democratic People's Republic of Korea",
  'Republic of Korea',
  'Kuwait',
  'Kyrgyz Republic',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Macedonia',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Virgin Islands, British',
  'Virgin Islands, U.S.',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]

const ukrTranslations: string[] = [
  "Афганістан",
    'Аландські острови',
"Албанія",
  "Алжир",
    'Американське Самоа',
"Андорра",
  "Ангола",
  "Ангілья",
  "Антарктида",
    'Антигуа і Барбуда',
"Аргентина",
  "Вірменія",
  "Аруба",
  "Австралія",
  "Австрія",
    'Азербайджан',
'Багамські острови',
"Бахрейн",
  "Бангладеш",
  "Барбадос",
  "Білорусь",
  "Бельгія",
    'Беліз',
"Бенін",
  "Бермуди",
  "Бутан",
  "Болівія",
  'Боснія і Герцеговина',
  "Ботсвана",
    'Острів Буве',
"Бразилія",
    'Британська територія в Індійському океані (архіпелаг Чагос)',
"Бруней Даруссалам",
  "Болгарія",
    'Буркіна-Фасо',
"Бурунді",
  "Камбоджа",
  "Камерун",
  "Канада",
'Кабо-Верде',
  "Кайманові острови",
    'Центральноафриканська Республіка',
"Чад",
  "Чилі",
  "Китай",
    'Острів Різдва',
'Кокосові (Кілінгові) острови',
"Колумбія",
    'Коморські острови',
"Конго",
  "Острови Кука",
  'Коста-Ріка',
  "Кот д'Івуар",
  "Хорватія",
  "Куба",
  "Кюрасао",
  "Кіпр",
  "Чехія",
    'Демократична Республіка Конго',
"Данія",
  "Джібуті",
  "Домініка",
  'Домініканська республіка',
  "Еквадор",
  "Єгипет",
  'Сальвадор',
  'Екваторіальна Гвінея',
  "Еритрея",
  "Естонія",
  "Ефіопія",
    'Фарерські острови',
'Фолклендські (Мальвінські) острови',
"Фіджі",
  "Фінляндія",
  "Франція",
    'Французька Гвіана',
'Французька Полінезія',
    'Французькі південні території',
"Габон",
  "Гамбія",
  "Грузія",
  'Німеччина',
  "Гана",
    'Гібралтар',
"Греція",
  "Гренландія",
  "Гренада",
  "Гваделупа",
  "Гуам",
  "Гватемала",
  "Гернсі",
  "Гвінея",
    'Гвінея-Бісау',
"Гаяна",
  "Гаїті",
    'Острови Херд і Макдональд',
'Святий Престол (місто-держава Ватикан)',
"Гондурас",
  'Гонконг',
  "Угорщина",
  "Ісландія",
  "Індія",
  "Індонезія",
    'Іран',
"Ірак",
  "Ірландія",
  'Острів Мен',
  "Ізраїль",
  "Італія",
  "Ямайка",
  "Японія",
    'Джерсі',
"Йорданія",
  "Казахстан",
  "Кенія",
  "Кірібаті",
    'Корейська Народно-Демократична Республіка',
'Республіка Корея',
  "Кувейт",
  "Киргизька Республіка",
    'Лаоська Народно-Демократична Республіка',
"Латвія",
  "Ліван",
  "Лесото",
  "Ліберія",
    'Лівійська Арабська Джамахірія',
'Ліхтенштейн',
"Литва",
  "Люксембург",
  "Макао",
  "Мадагаскар",
  "Малаві",
  "Малайзія",
    'Мальдіви',
"Малі",
  "Мальта",
    'Маршаллові острови',
"Мартиніка",
  "Мавританія",
  "Маврикій",
    'Майотта',
"Мексика",
    'Мікронезія',
"Молдова",
  "Монако",
  "Монголія",
  "Чорногорія",
  "Монсеррат",
  "Марокко",
  "Мозамбік",
  "М'янма",
  "Намібія",
  "Науру",
  "Непал",
  "Нідерланди",
    'Нова Каледонія',
'Нова Зеландія',
  "Нікарагуа",
  "Нігер",
  "Нігерія",
  "Ніуе",
    'Острів Норфолк',
'Північна Македонія',
'Північні Маріанські острови',
"Норвегія",
  "Оман",
  "Пакистан",
  "Палау",
  "Панама",
  'Папуа-Нова Гвінея',
  "Парагвай",
  "Перу",
    'Філіппіни',
'Острови Піткерн',
"Польща",
  "Португалія",
  'Пуерто Ріко',
  "Катар",
  "Возз'єднання",
  "Румунія",
  'Російська Федерація',
  "Руанда",
  "Свята Єлена",
    'Сент-Кітс і Невіс',
"Сент-Люсія",
    'Сен-П`єр і Мікелон',
    'Сент-Вінсент і Гренадини',
"Самоа",
  "Сан-Марино",
  "Сан-Томе і Принсіпі",
  'Саудівська Аравія',
  "Сенегал",
  "Сербія",
    'Сейшельські острови',
'Сьєрра-Леоне',
  "Сінгапур",
  "Словаччина",
  "Словенія",
  "Соломонові острови",
  "Сомалі",
  'Південна Африка',
    'Південна Джорджія та Південні Сандвічеві острови',
'Іспанія',
'Шрі Ланка',
  "Судан",
  "Суринам",
  "Швеція",
  "Швейцарія",
    'Сирійська Арабська Республіка',
"Тайвань",
    'Таджикистан',
"Танзанія",
  "Таїланд",
    'Тимор-Лешті',
'Йти',
  "Токелау",
  "Тонга",
    'Тринідад і Тобаго',
"Туніс",
  "Туреччина",
    'Туркменістан',
'Острови Теркс і Кайкос',
"Тувалу",
  "Уганда",
  "Україна",
  'Об`єднані Арабські Емірати',
'Об`єднане Королівство',
'Сполучені Штати Америки',
    'Віддалені малі острови Сполучених Штатів',
"Уругвай",
  "Узбекистан",
  "Вануату",
  "Венесуела",
  "В'єтнам",
    'Віргінські острови, Британські',
'Віргінські острови, США',
'Уолліс і Футуна',
'Західна Сахара',
"Ємен",
  "Замбія",
  "Зімбабве",
]
const plTranslations: string[] = [
  'Afganistan',
  'Wyspy Alandzkie',
  'Albania',
  'Algieria',
  'Samoa Amerykańskie',
    'Andora',
'Angola',
    'Anguilla',
'Antarktyda',
  'Antigua i Barbuda',
  'Argentyna',
  'Armenia',
    'Aruba',
'Australia',
  'Austria',
  'Azerbejdżan',
    'Bahamy',
'Bahrajn',
  'Bangladesz',
    'Barbados',
'Białoruś',
  'Belgia',
  'Belize',
  'Benin',
    'Bermudy',
'Bhutan',
'Boliwia',
  'Bośnia i Hercegowina',
    'Botswany',
'Wyspa Bouveta',
'Brazylia',
    'Brytyjskie Terytorium Oceanu Indyjskiego (Archipelag Chagos)',
'Brunei Darussalam',
'Bułgaria',
  'Burkina Faso',
    'Burundi',
'Kambodża',
  'Kamerun',
  'Kanada',
  'Wyspy Zielonego Przylądka',
  'Kajmany',
  'Republika Środkowoafrykańska',
    'Czad',
'Chile',
  'Chiny',
  'Wyspa Bożego Narodzenia',
    'Wyspy Kokosowe (Keelinga)',
'Kolumbia',
    'Komory',
'Kongo',
'Wyspy Cooka',
  'Kostaryka',
    'Wybrzeże Kości Słoniowej',
'Chorwacja',
  'Kuba',
  'Curaçao',
  'Cypr',
    'Czechy',
'Demokratyczna Republika Konga',
  'Dania',
  'Dżibuti',
  'Dominika',
  'Republika Dominikany',
  'Ekwador',
  'Egipt',
  'Salwador',
  'Gwinea Równikowa',
    'Erytrea',
'Estonia',
  'Etiopia',
  'Wyspy Owcze',
    'Falklandy (Malwiny)',
'Fidżi',
  'Finlandia',
  'Francja',
  'Gujana Francuska',
  'Polinezja Francuska',
  'Francuskie Terytoria Południowe',
  'Gabon',
    'Gambia',
'Gruzja',
  'Niemcy',
    'Ghana',
'Gibraltar',
'Grecja',
  'Grenlandia',
  'Grenada',
    'Gwadelupa',
'Guam',
  'Gwatemala',
  'Guernsey',
  'Gwinea',
    'Gwinea Bissau',
'Gujana',
  'Haiti',
    'Wyspy Heard i McDonalda',
'Stolica Apostolska (Państwo Watykańskie)',
'Honduras',
'Hongkong',
  'Węgry',
  'Islandia',
  'Indie',
  'Indonezja',
    'Iran',
'Irak',
  'Irlandia',
    'Wyspa Man',
'Izrael',
  'Włochy',
    'Jamajka',
'Japonia',
  'Golf',
  'Jordania',
    'Kazachstan',
'Kenia',
    'Kiribati',
"Koreańska Republika Ludowo-Demokratyczna",
  'Republika Korei',
  'Kuwejt',
    'Republika Kirgiska',
"Laotańska Republika Ludowo-Demokratyczna",
  'Łotwa',
  'Liban',
    'Lesoto',
'Liberia',
'Libijska Arabska Dżamahirija',
'Liechtenstein',
'Litwa',
  'Luksemburg',
  'Makao',
  'Madagaskar',
    'Malawi',
'Malezja',
  'Malediwy',
    'Mali',
'Malta',
'Wyspy Marshalla',
    'Martynika',
'Mauretania',
'Mauritius',
    'Majotta',
'Meksyk',
    'Mikronezja',
"Moldova",
    'Monako',
'Mongolia',
  'Czarnogóra',
    'Montserrat',
'Maroko',
    'Mozambik',
'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Holandia',
  'Nowa Kaledonia',
  'Nowa Zelandia',
    'Nikaragua',
'Niger',
'Nigeria',
  'Niue',
  'Wyspa Norfolk',
    'Macedonia Północna',
'Mariany Północne',
'Norwegia',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua Nowa Gwinea',
  'Paragwaj',
  'Peru',
  'Filipiny',
    'Wyspy Pitcairn',
'Polska',
  'Portugalia',
  'Portoryko',
    'Katar',
'Zjazd',
  'Rumunia',
  'Federacja Rosyjska',
    'Rwanda',
'Święta Helena',
'Saint Kitts i Nevis',
'Święta Lucia',
    'Saint-Pierre i Miquelon',
'Saint Vincent i Grenadyny',
'Samoa',
  'San Marino',
    'Wyspy Świętego Tomasza i Książęca',
'Arabia Saudyjska',
    'Senegal',
'Serbia',
  'Seszele',
    'Sierra Leone',
'Singapur',
  'Słowacja',
  'Słowenia',
  'Wyspy Salomona',
  'Somali',
  'Afryka Południowa',
    'Georgia Południowa i Sandwich Południowy',
'Hiszpania',
  'Sri Lanka',
  'Sudan',
    'Surinam',
'Szwecja',
  'Szwajcaria',
  'Republika Syryjsko-Arabska',
  'Tajwan',
  'Tadżykistan',
  'Tanzania',
  'Tajlandia',
    'Timor Wschodni',
'Iść',
    'Tokelau',
'Tonga',
'Trynidad i Tobago',
  'Tunezja',
  'Indyk',
  'Turkmenia',
  'Wyspy Turks i Caicos',
    'Tuvalu',
'Uganda',
"Ukraina",
  'Zjednoczone Emiraty Arabskie',
  'Zjednoczone Królestwo',
  'Stany Zjednoczone Ameryki',
  'Stany Zjednoczone Dalekie Wyspy Mniejsze',
  'Urugwaj',
  'Uzbekistan',
    'Vanuatu',
'Wenezuela',
  'Wietnam',
    'Wyspy Dziewicze, Brytyjskie',
'Wyspy Dziewicze, Stany Zjednoczone',
'Wallis i Futuna',
  'Sahara Zachodnia',
  'Jemen',
  'Zambia',
  'Zimbabwe',
]

export function translatedCountriesList(lang: LanguageEnum): {text: string; value: string}[] {
  const resultArr: {text: string; value: string}[] = [];
  switch (lang) {
    case LanguageEnum.ukrainian: {
      engTranslations.forEach((item)=>{
        if(countries.some(elem=> elem.text === item)) {
          resultArr.push({
            text: ukrTranslations[findLanguageIndex(item)],
            value: (countries.find(elem => elem.text === item) as { text: string; value: string }).value
          })
        }
      });
      return resultArr
    }
    case LanguageEnum.polish: {
      engTranslations.forEach((item)=>{
        if(countries.some(elem=> elem.text === item)) {
          resultArr.push({
            text: plTranslations[findLanguageIndex(item)],
            value: (countries.find(elem => elem.text === item) as { text: string; value: string }).value
          })
        }
      });
      return resultArr
    }
    case LanguageEnum.czechia:
    case LanguageEnum.french:
    case LanguageEnum.german:
    case LanguageEnum.english:
    default: {
      engTranslations.forEach((item)=>{
        if(countries.some(elem=> elem.text === item)) {
          resultArr.push({
            text: (countries.find(elem => elem.text === item) as { text: string; value: string }).text,
            value: (countries.find(elem => elem.text === item) as { text: string; value: string }).value
          })
        }
      });
      return resultArr
    }
  }
}

function findLanguageIndex(langName: string): number {
  return engTranslations.indexOf(langName);
}

export function getLanguageByIndex(langIndex: string = 'UA'): number {
  if(countries.find(item=> item.value===langIndex)){
    return findLanguageIndex((countries.find(item=> item.value===langIndex) as { text: string; value: string }).text)
  }
  return -1;
}
