import { Component, OnInit, signal } from '@angular/core';
import {
  AbstractControl, FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppRoutesDefinitions } from '@app/app.routes';
import { NotificationService } from '@shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '@shared/constants';
import { UserRole } from '@shared/models/role';
import AuthApiService from '@shared/services/auth/auth-api.service';
import SignupInfoDto from '@shared/services/auth/dto/sighup-info.dto';
import MathAsyncValidators from '@shared/validators/async-validators';
import MathValidators from '@shared/validators/sync-validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  teacherId: string;
  clientId: string;
  language: LanguageEnum;
  role: UserRole;
  getDataSub: Subscription;
  public form: FormGroup;

  public isSubmitted = false;
  public passwordView = false;
  public isTeacherSignupView = signal(false);

  constructor(
    public fb: UntypedFormBuilder,
    public route: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly authApiService: AuthApiService,
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        [Validators.required, MathValidators.email()],
        MathAsyncValidators.isEmailTaken(authApiService)
      ],
      username: [
        null,
        [Validators.maxLength(128), Validators.minLength(6), Validators.pattern(/^[a-zA-Z0-9]*$/)],
        MathAsyncValidators.isUsernameTaken(authApiService),
      ],
      password: ['',[Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required]],
    }, { validators: MathValidators.passwordsMatchValidator('password', 'passwordConfirm') });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params?.role?.toUpperCase() === UserRole.TEACHER.toUpperCase()) {
        this.isTeacherSignupView.set(true);
      }

      if (params.teacherId) {
        this.role = UserRole.TEACHER;
        this.teacherId = params.teacherId;
        this.getTeacherSignupInfo(this.teacherId);
      }
      if (params.clientId) {
        this.role = UserRole.CLIENT;
        this.clientId = params.clientId;
        this.getClientSignupInfo(this.clientId);
      }
    });
  }

  getTeacherSignupInfo(id: string): void {
    this.getDataSub = this.authApiService
      .getTeacherSignupInfo(id)
      .subscribe(
        (data) => {
          this.updateSignupInfo(data);
        },
        (err) => console.log(err)
      );
  }

  getClientSignupInfo(id: string): void {
    this.getDataSub = this.authApiService
      .getClientSignupInfo(id)
      .subscribe(
        (data) => {
          this.updateSignupInfo(data);
        },
        (err) => console.log(err)
      );
  }

  private updateSignupInfo(data: SignupInfoDto): void {
    if (data) {
      this.language = data.language;
      this.switchLanguage(this.language);
      this.form.patchValue({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      });
      this.form.updateValueAndValidity();
      this.form.controls['email'].disable();
    }
  }

  private switchLanguage(lang: LanguageEnum): void {
    this.translateService.use(String(lang).toLowerCase());
  }

  register(): void {
    this.isSubmitted = true;
    if (!this.teacherId && !this.clientId) {
      this.notificationService.showErrorMessage(
        this.translateService.instant('registration_rejected_unverified')
      );
      return;
    }
    if (this.form.valid) {
      const userModel = {
        firstName: this.form.controls['firstName'].value,
        lastName: this.form.controls['lastName'].value,
        email: this.form.controls['email'].value,
        password: this.form.controls['password'].value,
        username: this.form.controls['username'].value,
        teacherId: this.teacherId,
        clientId: this.clientId,
        role: this.role,
        language: this.language,
      };
      this.authApiService.register(userModel).subscribe((_) => {
        this.router.navigate([AppRoutesDefinitions.SIGNUP_SUCCESS]);
      });
    }
  }

  ngOnDestroy() {
    if (this.getDataSub) this.getDataSub.unsubscribe();
  }
}
