import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';
import { AppWindow } from '@shared/types';
import { AuthService } from '@modules/components/auth/auth.service';
import { I18nLanguage } from '@shared/language/language.service';
import { Router } from '@angular/router';
import LocalStorageService, { LocalStorageKey } from '@shared/services/local-storage.service';
import UsersApiService from '../services/users/users-api.service';
import { take } from 'rxjs/operators';

declare const window: AppWindow;

export interface HelpCrunchWidgetSettings {
  organization: string;
  appId: string;
}

export type HelpCrunchWidget = (eventOrCommand: string, dataOrCallback?: string | (() => void)) => void;

export interface HelpCrunchContact {
  fullName: string;
  email: string;
  phone: string;
  role: string;
  signupDate: string;
  market: string;
}

@Injectable({
  providedIn: 'root',
})
export default class HelpCrunchWidgetService {
  private readonly document = inject(DOCUMENT);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly authService = inject(AuthService);
  private readonly router: Router = inject(Router);
  private readonly usersApiService = inject(UsersApiService);

  public init(): void {
    const { production: isProduction, helpCrunchWidgetSettings } = environment;

    if (!isProduction || !helpCrunchWidgetSettings) {
      return;
    }

    if (this.authService.isLoggedIn() && !this.authService.isAdminOrManager()) {
      this.usersApiService.getContactInfoForHelpCrunchWidget(this.authService.user.id)
        .pipe(take(1))
        .subscribe((contact: HelpCrunchContact) => {
          const settings = {
            ...helpCrunchWidgetSettings,
            user: {
              user_id: this.authService.user.id,
              name: contact.fullName,
              email: contact.email,
              phone: contact.phone,
              customData: {
                role: contact.role,
                registrationDate: contact.signupDate,
                market: contact.market,
              },
            }
          };
          window.helpcrunchSettings = settings;
          this.helpCrunchOriginalInitScript(window, this.document);
          window.HelpCrunch('onReady', () => {
            this.router.events.subscribe(this.tryToHideHelpCrunchWidget.bind(this));
            this.setLanguage(this.localStorageService.getItem(LocalStorageKey.USER_LANGUAGE) || I18nLanguage.EN);
            this.tryToHideHelpCrunchWidget();
          });
        });
    }     
  }

  public setLanguage(language: string): void {
    window.HelpCrunch('setPhraseList', language);
  }

  private tryToHideHelpCrunchWidget(): void {
    if (this.authService.isLoggedIn() && (this.authService.isTeacher() || this.authService.isClient())) {
      return;
    }

    window.HelpCrunch('hideChatWidget');
  }

  private helpCrunchOriginalInitScript(w, d): void { var hS = w.helpcrunchSettings; if (!hS || !hS.organization) { return; } var widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/'; w.HelpCrunch = function () { w.HelpCrunch.q.push(arguments) }; w.HelpCrunch.q = []; function r() { if (d.querySelector('script[src="' + widgetSrc + '"')) { return; } var s = d.createElement('script'); s.async = 1; s.type = 'text/javascript'; s.src = widgetSrc; (d.body || d.head).appendChild(s); } if (d.readyState === 'complete' || hS.loadImmediately) { r(); } else if (w.attachEvent) { w.attachEvent('onload', r) } else { w.addEventListener('load', r, false) } }
}
