import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import ScheduleApiService, { MonthlyCalendarDay, MonthlyCalendarItem } from '../../../schedule-api.service';
import LanguageService from '../../../../../../shared/language/language.service';
import { halfHours } from '../../../../../../shared/constants';
import {
  dateToYyyyMmDd,
  timeSlotDiapasonToTimeString,
  timeStringToTimeSlotDiapason
} from '../../../../../../shared/utils';
import FreeSlotsService from '../../../../free-slots/free-slots.service';
import { NzMarks } from 'ng-zorro-antd/slider';
import { finalize } from 'rxjs/operators';
import ScheduleCalendarService from '../../schedule-calendar.service';
import {
  MathemaModalConfirmComponent
} from '../../../../../../shared/components/modal-confirm/modal-confirm.component';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'mathema-remove-free-slot-modal',
  templateUrl: './remove-free-slot-modal.component.html',
  styleUrls: ['./remove-free-slot-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RemoveFreeSlotModalComponent implements OnInit, OnDestroy {

  @Input() freeSlotItem: MonthlyCalendarItem;

  public isLoading: boolean;

  public minSlotTime: number = 0;
  public maxSlotTime: number = 48;
  public timeMin: number = 0;
  public timeMax: number = 48;
  public timeRange: number[] = [this.timeMin, this.timeMax];
  public timeMarks: NzMarks = {};

  public repeatCount: number;
  public maxRepeatCount: number;
  public lastRepeatDate: string;

  public isRemoveDisabled: boolean;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly modalRef: NzModalRef,
    private readonly modalService: NzModalService,
    private readonly languageService: LanguageService,
    private readonly freeSlotsService: FreeSlotsService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly authService: AuthService,
  ) { }

  public ngOnInit(): void {
    const [startTime, endTime] = this.freeSlotItem.time.split(' - ');
    this.resetTime(startTime, endTime);

    this.uploadSlotsIfNeedAndInitRepeats();
  }

  private uploadSlotsIfNeedAndInitRepeats(): void {
    const affectedDates = [this.freeSlotItem.date];

    for (let i = 1; i <= 52; i++) {
      const startDate = new Date(this.freeSlotItem.date);
      startDate.setDate(startDate.getDate() + (7 * i));
      affectedDates.push(dateToYyyyMmDd(startDate));
    }

    const days = this.freeSlotsService.freeSlotsCalendarDays.value;
    const datesNotPresentedInCalendar = affectedDates.filter((date) => !Object.keys(days).includes(date));

    if (datesNotPresentedInCalendar.length) {
      this.isLoading = true;

      const query = this.scheduleCalendarService.calendarFilters.value;
      const subscription = this.scheduleApiService.getSetOfDates({
        ...query,
        dates: datesNotPresentedInCalendar,
      }).pipe(
        finalize(() => this.isLoading = false)
      )
        .subscribe(result => {
          for (const day of result) {
            days[day.date] = day;
          }
          this.freeSlotsService.freeSlotsCalendarDays.next(days);
          this.initRepeats();
        });

      this.subscriptions.push(subscription);
    } else {
      this.initRepeats();
    }
  }

  private setLastRepeatDate(repeats: number): void {
    const startDate = new Date(this.freeSlotItem.date);
    const repeatDate = new Date(startDate);

    for (let i = 1; i <= repeats; i++) {
      repeatDate.setDate(repeatDate.getDate() + 7);
    }

    this.lastRepeatDate = dateToYyyyMmDd(repeatDate);
  }

  private initRepeats(): void {
    const days = this.freeSlotsService.freeSlotsCalendarDays.value;
    const startDate = new Date(this.freeSlotItem.date);
    startDate.setDate(startDate.getDate() + 7);

    let repeats = 0;
    let lastRepeatDate = this.freeSlotItem.date;

    for (let i = 0; true; i++) {
      const repeatDate = new Date(startDate);
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day = days[dateToYyyyMmDd(repeatDate)];
      const isAnyFreeSlotInDay = day?.items?.some((item) => !item.isLesson);

      if (isAnyFreeSlotInDay) {
        repeats += 1;
        lastRepeatDate = dateToYyyyMmDd(repeatDate);
      } else {
        break;
      }
    }

    this.repeatCount = repeats;
    this.maxRepeatCount = repeats;
    this.lastRepeatDate = lastRepeatDate;
  }

  public onRemoveWithoutRepeats(): void {
    const days = JSON.parse(JSON.stringify(this.freeSlotsService.freeSlotsCalendarDays.value));
    const day: MonthlyCalendarDay = days[this.freeSlotItem.date];

    const diapasonToRemove = timeStringToTimeSlotDiapason(`${halfHours[this.timeMin]} - ${halfHours[this.timeMax]}`);
    const allDaySlotsDiapason: number[] = day.items
      .filter((item) => !item.isLesson)
      .reduce((acc, item) => acc.concat(timeStringToTimeSlotDiapason(item.time)), []);
    const diapasonToSave = allDaySlotsDiapason.filter((slot) => !diapasonToRemove.includes(slot));

    const removedSlotsIds = this.replaceDaySlotsByNewSlotsFromDiapason(day, diapasonToSave);

    const freeSlotsIdsToRemove = this.freeSlotsService.freeSlotsIdsWithTimesToRemove.value;
    this.freeSlotsService.freeSlotsIdsWithTimesToRemove.next([...freeSlotsIdsToRemove, ...removedSlotsIds]);

    this.validateFutureSlots(days, () => {
      this.freeSlotsService.freeSlotsCalendarDays.next(days);
      this.freeSlotsService.freeSlotsFormValueChanged.next(true);

      this.modalRef.close();
    });
  }

  public onRemoveWithRepeats(): void {
    const days = JSON.parse(JSON.stringify(this.freeSlotsService.freeSlotsCalendarDays.value));
    const slotsToRemove = [];

    for (let i = 0; i <= this.repeatCount; i++) {
      const repeatDate = new Date(this.freeSlotItem.date)
      repeatDate.setDate(repeatDate.getDate() + (7 * i));

      const day: MonthlyCalendarDay = days[dateToYyyyMmDd(repeatDate)];
      const diapasonToRemove = timeStringToTimeSlotDiapason(`${halfHours[this.timeMin]} - ${halfHours[this.timeMax]}`);

      if (!diapasonToRemove.length) {
        continue;
      }

      const allDaySlotsDiapason: number[] = day.items
        .filter((item) => !item.isLesson)
        .reduce((acc, item) => acc.concat(timeStringToTimeSlotDiapason(item.time)), []);
      const diapasonToSave = allDaySlotsDiapason.filter((slot) => !diapasonToRemove.includes(slot));

      const removedSlotsIds = this.replaceDaySlotsByNewSlotsFromDiapason(day, diapasonToSave);
      slotsToRemove.push(...removedSlotsIds);
    }

    const removedSlots = this.freeSlotsService.freeSlotsIdsWithTimesToRemove.value;
    this.freeSlotsService.freeSlotsIdsWithTimesToRemove.next([...removedSlots, ...slotsToRemove]);

    this.validateFutureSlots(days, () => {
      this.freeSlotsService.freeSlotsCalendarDays.next(days);
      this.freeSlotsService.freeSlotsFormValueChanged.next(true);

      this.modalRef.close();
    });
  }

  private validateFutureSlots(days: MonthlyCalendarDay[], onSuccess: () => void): void {
    let teacherId;
    if (this.authService.isTeacher()) {
      teacherId = this.authService.user.teacherId;
    } else {
      teacherId = this.scheduleCalendarService.activeTeacher.value?.id;
    }

    const validationValues = Object.values(days)
      .filter((day: MonthlyCalendarDay) => day.isAffectedByFreeSlotsEditor)
      .map((day: MonthlyCalendarDay) => {
        const dayFreeSlots = day.items.filter((item) => !item.isLesson);
        const timeSlots = dayFreeSlots.reduce((acc, freeSlot) => acc.concat(
          timeStringToTimeSlotDiapason(freeSlot.time),
        ), []);

        return {
          id: day.scheduleRecordId,
          teacherId,
          date: day.date,
          timeSlots,
        };
      });

    this.isLoading = true;
    const subscription = this.scheduleApiService.validateSchedules(validationValues, teacherId)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(result => {
        if (result.isValid) {
          onSuccess();
        } else if (!result.isValid && result.studentsWithLessonsPastTwoMonths) {
          this.openConfirmRemoveWithActiveStudents(result.studentsWithLessonsPastTwoMonths, onSuccess);
        }
      });

    this.subscriptions.push(subscription);
  }

  private openConfirmRemoveWithActiveStudents(studentNames: string[], onConfirm: () => void): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzComponentParams: {
        params: {
          header: 'Підтвердження видалення',
          message: `Ви впевнені, що хочете видалити цей час? За цим розкладом ви займаєтесь з наступними учнями: ${studentNames.join(', ')}`,
          declineText: 'Ні',
          acceptText: 'Так'
        }
      },
    });

    this.subscriptions.push(
      modalRef.componentInstance.onDecline.subscribe(() => {
        modalRef.close();
      }),
    );
    this.subscriptions.push(
      modalRef.componentInstance.onAccept.subscribe(() => {
        onConfirm();
        modalRef.close();
      }),
    );
  }

  private replaceDaySlotsByNewSlotsFromDiapason(day: MonthlyCalendarDay, diapasonToSave: number[]): string[] {
    const newItems = [];
    let diapasonForNewSlot = [];

    for (let i = 0; i < diapasonToSave.length; i++) {
      if (diapasonToSave[i] + 1 === diapasonToSave[i + 1]) {
        diapasonForNewSlot.push(diapasonToSave[i]);
      } else {
        diapasonForNewSlot.push(diapasonToSave[i]);
        const newSlot = {
          id: crypto.randomUUID(),
          date: day.date,
          time: timeSlotDiapasonToTimeString(diapasonForNewSlot),
          isUIGeneratedSlot: true,
        };
        newItems.push(newSlot);
        diapasonForNewSlot = [];
      }
    }

    const idsToRemove = day.items
      .filter((item) => !item.isLesson)
      .map((item) => `${item.id}|${item.time}`);

    day.items = day.items.filter((item) => item.isLesson);
    day.items.push(...newItems);
    day.items.sort((a, b) => a.time.localeCompare(b.time));
    day.isAffectedByFreeSlotsEditor = true;

    return idsToRemove;
  }

  private resetTime(startTime: string, endTime: string): void {
    this.timeMin = halfHours.findIndex(el => el === startTime);
    this.timeMax = halfHours.findIndex(el => el === endTime);
    this.timeRange = [this.timeMin, this.timeMax];
    this.minSlotTime = 0;
    this.maxSlotTime = 48;
    this.timeMarks = {
      [this.timeMin]: {
        style: { left: '3%', top: '2px' },
        label: halfHours[this.timeMin],
      },
      [this.timeMax]: {
        style: { left: '97%', top: '2px' },
        label: halfHours[this.timeMax],
      },
    }
  }

  public onTimeMinChange(value: number): void {
    if (this.isRemoveDisabled) {
      this.isRemoveDisabled = false;
    }

    this.timeRange = [value, this.timeRange[1]];
  }

  public onTimeMaxChange(value: number): void {
    if (this.isRemoveDisabled) {
      this.isRemoveDisabled = false;
    }

    this.timeRange = [this.timeRange[0], value];
  }

  public onTimeRangeChange(value: number[] | number): void {
    if (this.isRemoveDisabled) {
      this.isRemoveDisabled = false;
    }

    if (Array.isArray(value)) {
      const [min, max] = value;
      this.timeMin = min;
      this.timeMax = max;
    }
  }

  public onChangeRepeatCount(value: number): void {
    if (this.isRemoveDisabled) {
      this.isRemoveDisabled = false;
    }

    this.repeatCount = value;
    this.setLastRepeatDate(this.repeatCount);
  }

  public formatTime(value: number): string {
    return halfHours[value];
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
