import { Component } from '@angular/core';
import { AppRoutesDefinitions } from '../../../../app.routes';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MathemaModalConfirmComponent } from '../../../../shared/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'mathema-main-menu-nav',
  templateUrl: './main-menu-nav.component.html',
  styleUrls: ['./main-menu-nav.component.scss']
})
export class MainMenuNavComponent {

  public readonly routes = AppRoutesDefinitions;
  public currentUrl: string;

  constructor(
    private router: Router,
    public authService: AuthService,
    public translateService: TranslateService,
    private readonly modalService: NzModalService,
  ) {
  }

  onLogoutClick() {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzComponentParams: {
        params: {
          acceptText: this.translateService.instant('main.btn.yes'),
          declineText: this.translateService.instant('main.btn.decline'),
          header: this.translateService.instant('log-out-from-account'),
          message: this.translateService.instant('log-out-confirm'),
        }
      },
    });

    modalRef.componentInstance.onAccept.subscribe(() => {
      modalRef.close();
      this.authService.removeToken();
      this.router.navigate(['/login']);
    })

    modalRef.componentInstance.onDecline.subscribe(() => {
      modalRef.close();
    });
  }

  public redirectToMyAccount(): void {
    this.router.navigate([AppRoutesDefinitions.VIEW_USER_INFO]);
  }

}
