import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { NzModule } from '@app/modules/nz-module';

@Component({
  selector: 'math-collapse-extra-buttons',
  standalone: true,
  imports: [NzModule],
  templateUrl: './collapse-extra-buttons.component.html',
  styleUrl: './collapse-extra-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseExtraButtonsComponent {
  public isActive = input<boolean>();
  public onReset = output<void>();

  public resetFilter($event: Event): void {
    $event.stopPropagation(); 
    this.onReset.emit();
  }
}
