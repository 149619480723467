import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {
  EAdditionalSubject,
  EEducationProgram,
  EEducationStyle,
  EPeopleSpecialNeed,
  ETeacherInstrument,
  ETeacherStatus,
  Gender,
  ICreateExamSchema,
  ICreateTeacherSchema,
  IGetTeacherSchema,
  PageActions,
  PagesInterface,
  PagesNames
} from "../models/change-user-info";
import {AppRoutesDefinitions} from "@app/app.routes";
import {AuthService} from "../../auth/auth.service";
import {CheckboxData, DropdownData, MultiselectData} from "@shared/components/app-input/app-input.component";
import {translatedCountriesList} from "../models/countries";
import {
  ExperienceArr,
  GenderArr,
  LanguageArr,
  OtherArr,
  OtherArrWithIcon,
  PagesInfo,
  StatusArr,
  studyPrograms,
  StudyStyleArr,
  TeacherQualificationArr,
  ToolsForStuding,
  WorkingGradesArr
} from "../models/variables";
import {ChangeUserInfoApiService} from "../api-services/change-user-info-api.service";
import { LanguageEnum, phoneNumbersFormats, photoLinks, Regions } from "@shared/constants";
import {ChangeUserInfoService} from "../services/change-user-info.service";
import { HttpClient, HttpRequest } from "@angular/common/http";
import moment from "moment/moment";
import { TranslateService } from '@ngx-translate/core';
import {NotificationService} from "@shared/services/notification.service";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-user-info',
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.scss']
})
export class ChangeUserInfoComponent implements OnInit, OnDestroy{
  @ViewChild('photo') private uploadPhoto: ElementRef;
  public readonly maxKilobytesPerPhoto = 5000;

  @ViewChild('file') private uploadFile: ElementRef;
  public readonly maxKilobytesPerFile = 10000;


  public isLoading: boolean;
  public isSaving: boolean;
  private initialStart: boolean;
  public userInfo: IGetTeacherSchema;
  public regions: typeof Regions = Regions;
  public tappedFields: string[] = [];

  public photoAcceptanceFiles: string[] = [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
  ]
  public fileAcceptanceFiles: string[] = [
    '.jpg',
    '.jpeg',
    '.pdf',
  ]


  public phoneNumber: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\+ ?\(\d{1,3}\)\s\d{3}\s\d{3}\s\d{3,4}$/), Validators.maxLength(19), Validators.minLength(14)]);
  public birthDate: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]);
  public firstName: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(255), Validators.minLength(2), this.noWhitespaceValidator]);
  public lastName: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(255), Validators.minLength(2), this.noWhitespaceValidator]);
  public email: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\s*[\w#$%&‘*+—\/=?^_`{|}()[\]\~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\s*$/), Validators.maxLength(255), Validators.minLength(6), this.noWhitespaceValidator]);
  public education: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public secondEducation: UntypedFormControl = new UntypedFormControl('', [Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public workPlace: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public qualificationOther: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public studyProgramsOtherValue: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public toolsForStudingOtherValue: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public examsOtherValue: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]);
  public previousWorkplacesControl: UntypedFormControl[] = [];
  public googleMeetLink: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\s*(https:\/\/)?meet\.google\.com\/[a-z]{3}\-[a-z]{4}\-[a-z]{3}$/), Validators.minLength(25), Validators.maxLength(50)]);
  public zoomLink: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\s*https?:\/\/(?:[\w-]+\.)?zoom\.us\/(?:j\/\d{8,}(?:\?\S*)?|my\/[^\/\?\s]+\/\w{10,})(?:\?\S*)?$/), Validators.maxLength(2048), Validators.minLength(2)]);
  public zoomAccessCode = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\s*[a-zA-Z0-9]+(\s*[a-zA-Z0-9]+)*\s*$/), Validators.maxLength(30), Validators.minLength(4)]);
  public zoomId = new UntypedFormControl('', [Validators.required, Validators.pattern(/^\s*\d+( \d+)*\s*$/), this.countMaxLengthWithoutSpaces11, this.countMinLengthWithoutSpaces10]);
  public additional = new UntypedFormControl('', [Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), this.noWhitespaceValidator]);
  public langOther = new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.minLength(2), Validators.maxLength(100), this.noWhitespaceValidator]);
  public dpaValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public znoValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public polExamValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public polInternationalValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public ks2Value = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public gcse_foundationValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);
  public ib_mathValue = new UntypedFormControl(1, [Validators.required, Validators.min(1)]);

  public depthStudy: boolean = false;
  public teachersDiploma: boolean = false;
  public lessonPlatform: string = 'zoom';

  public zno: boolean;
  public dpa: boolean;
  public polExam: boolean;
  public polInternational: boolean;
  public ks2: boolean;
  public gcse_foundation: boolean;
  public ib_math: boolean;
  public examsOther: boolean;
  // public dpaValue: number = 1;
  // public znoValue: number = 1;

  public specialNeeds: {id: EPeopleSpecialNeed; state: boolean; label: string}[] = [
    {
      id: EPeopleSpecialNeed.AUTISM,
      state: false,
      label: EPeopleSpecialNeed.AUTISM
    },
    {
      id: EPeopleSpecialNeed.MISCALCULATION,
      state: false,
      label: EPeopleSpecialNeed.MISCALCULATION
    },
    {
      id: EPeopleSpecialNeed.CEREBRAL_PALSY,
      state: false,
      label: EPeopleSpecialNeed.CEREBRAL_PALSY
    },
    {
      id: EPeopleSpecialNeed.MENTAL_STRESS,
      state: false,
      label: EPeopleSpecialNeed.MENTAL_STRESS
    },
    {
      id: EPeopleSpecialNeed.MENTAL_DISABILITIES,
      state: false,
      label: EPeopleSpecialNeed.MENTAL_DISABILITIES
    },
  ]
  public autism: boolean;
  public discalculation: boolean;
  public cerebral_palsy: boolean;
  public mental_stress: boolean;
  public mental_disabilities: boolean;
  public physics: boolean;
  public informatics: boolean;


  public gender: string;
  public country: string;
  // public birthDate: string;
  public status: ETeacherStatus;
  public experience: string;
  public tutorExperience: string;
  public studyLanguage: string;
  public studyStyle: string;
  public teacherQualification:string;
  public previousWorkplacesArr: {index: number; value?: string}[] = []


  public pagesInfo: PagesInterface[] = JSON.parse(JSON.stringify(PagesInfo)) as PagesInterface[];
  public workingGradesArr: MultiselectData[];
  public languageArr: CheckboxData[] = JSON.parse(JSON.stringify(LanguageArr)) as CheckboxData[];
  public genderArr: DropdownData[] = JSON.parse(JSON.stringify(GenderArr)) as DropdownData[];
  public studyProgramsArr: MultiselectData[] = JSON.parse(JSON.stringify(studyPrograms)) as MultiselectData[];
  public toolsForStuding: MultiselectData[] = JSON.parse(JSON.stringify(ToolsForStuding)) as MultiselectData[];
  public statuses: DropdownData[] = JSON.parse(JSON.stringify(StatusArr)) as DropdownData[];
  public experienceArr: DropdownData[] = JSON.parse(JSON.stringify(ExperienceArr)) as DropdownData[];
  public experienceStudyArr: DropdownData[] = JSON.parse(JSON.stringify(ExperienceArr)) as DropdownData[];
  public studyStyleArr: DropdownData[] = JSON.parse(JSON.stringify(StudyStyleArr)) as DropdownData[];
  public teacherQualificationArr: DropdownData[] = JSON.parse(JSON.stringify(TeacherQualificationArr)) as DropdownData[];
  public studyProgramsOther: MultiselectData[] = JSON.parse(JSON.stringify(OtherArr)) as MultiselectData[];
  public toolsForStudingOther: MultiselectData[] = JSON.parse(JSON.stringify(OtherArrWithIcon)) as MultiselectData[];

  public countryArr: DropdownData[] = []

  public selectedPage: PagesNames;
  public pagesNames = PagesNames;
  public currentAction: PageActions;
  public isNewUserState: boolean = false;

  private destroyed: Subject<void> = new Subject<void>();


  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    public readonly translateService: TranslateService,
    public readonly authService: AuthService,
    private readonly changeUserInfoApiService: ChangeUserInfoApiService,
    private readonly changeUserInfoService: ChangeUserInfoService,
    private readonly httpClient: HttpClient,
    public readonly notificationService: NotificationService
  ) {}

  private noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0 && (control.value || '').length > 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  private noBegWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = control.value[0]===' ';
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  private noEndWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = control.value[control.value.length-1]===' ';
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  private countMaxLengthWithoutSpaces11(control) {
    if(control.value){
      const inputValue = control.value;
      const isValid = inputValue.replace(/ /g, '').length<=11;
      return isValid ? null : {'longerThen11': true}
    } else {
      return null
    }
  }
  private countMinLengthWithoutSpaces10(control) {
    if(control.value){
      const inputValue = control.value;
      const isValid = inputValue.replace(/ /g, '').length>=10;
      return isValid ? null : {'shorterThen10': true}
    } else {
      return null
    }
  }

  public ngOnInit(): void {
    this.initialStart = true;

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroyed))
      .subscribe((params)=>{
        if(params['page']){
          this.selectedPage = params['page'] as PagesNames;
        } else {
          this.selectedPage = this.pagesInfo[0].label;
        }

        if (params['action']){
          this.currentAction = params['action'];
        } else {
          this.currentAction = PageActions.CREATE_NEW;
        }

        if(this.initialStart) {
          if(params['teacherId'])
            this.loadTeacherData(params['teacherId'])
          else
            this.loadTeacherData(this.authService.user.teacherId);
        }


      })
    translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).forEach((country)=>{
      this.countryArr.push({id: country.value.toLowerCase(), label: country.text})
    })
  }

  public handleMenuClick(btnId: string): void {
    this.router.navigate([], {queryParamsHandling: 'merge', queryParams: {page: btnId}})
  }

  public handleBackClick(): void {
    if(!this.authService.isTeacher())
      this.router.navigate([AppRoutesDefinitions.VIEW_USER_INFO], {queryParams: {teacherId: this.userInfo.id}});
    else
      this.router.navigate([AppRoutesDefinitions.VIEW_USER_INFO]);
  }

  public handleNextButtonClick(): void {
    for (let i = 0; i < this.pagesInfo.length; ++i) {
      if (this.pagesInfo[i].label === this.selectedPage) {
        this.handleMenuClick(this.pagesInfo[i+1].label);
        break;
      }
    }
  }
  public handlePreviousButtonClick(): void {
    for (let i = 0; i < this.pagesInfo.length; ++i) {
      if (this.pagesInfo[i].label === this.selectedPage) {
        this.handleMenuClick(this.pagesInfo[i-1].label);
        break;
      }
    }
  }
  public handleSaveButtonClick(): void {
    if(!this.blockSaveButton()){
      this.isSaving = true;
      this.changeUserInfoApiService.updateTeacherInfo$PATCH(this.generateUpdatedTeacherData(), this.userInfo.id).toPromise().then(()=>{
        this.notificationService.showSuccessMessage(this.translateService.instant('saved'))
        this.isSaving = false;
      }).catch((_)=>{this.isSaving = false})
    } else {
      this.markPageFieldsAsTouched();
    }
  }

  public multiselectValueChange(data: MultiselectData[], multiselectCase: 'grades' | 'studyPrograms' | 'toolsForStuding' | 'toolsForStudingOther' | 'studyProgramsOther' | 'other') {
    switch (multiselectCase) {
      case 'grades': {
        this.workingGradesArr = data;
        this.checkDeepStudyCondition();
        this.checkExamCondition();
        break;
      }
      case 'studyPrograms': {
        this.studyProgramsArr = data;
        break;
      }
      case 'toolsForStuding': {
        this.toolsForStuding = data;
        break;
      }
      case 'studyProgramsOther': {
        this.studyProgramsOther = data;
        break;
      }
      case 'toolsForStudingOther': {
        this.toolsForStudingOther = data;
        break;
      }
      default: return;
    }
  }

  public handleGenderSelect(gender: string): void {
    this.gender = gender;
  }
  public handleCountrySelect(country: string): void {
    this.country = country;
  }
  // public handleBirthDateSelect(date: string): void {
    // this.birthDate = date;
  // }
  public handleStatusSelect(status: string): void {
    this.status = status as ETeacherStatus;
  }
  public handleTutorExperienceSelect(tutorExperience: string): void {
    this.tutorExperience = tutorExperience;
  }
  public handleStudyExperienceSelect(experience: string): void {
    this.experience = experience;
  }
  public handleStudyStyleSelect(style: string): void {
    this.studyStyle = style;
  }
  public handleQualificationSelect(qualification: string): void {
    this.teacherQualification = qualification;
  }

  public getErrorText(
    controlName:
      'phoneNumber' |
      'firstName' |
      'secondName' |
      'lastName' |
      'email' |
      'dateOfBirth' |
      'country' |
      'gender' |
      'status' |
      'experience' |
      'tutorExperience' |
      'language' |
      'studyStyle' |
      'qualification' |
      'workplace' |
      'googleMeetLink' |
      'zoomLink' |
      'zoomAccessCode' |
      'zoomId' |
      'additional' |
      'accessCode' |
      'additionalWorkPlaces' |
      'qualificationOther' |
      'secondEducation' |
      'examsOtherValue' |
      'studyProgramsOtherValue' |
      'toolsForStudingOtherValue' |
      'langOther' |
      'znoValue' |
      'dpaValue' |
      'ks2' |
      'gcse_foundation' |
      'ib_math' |
      'polExamValue' |
      'polInternationalValue' |
      'education',
    index?: number
      ): string {
    switch (controlName) {
      case "phoneNumber": {
        if (this.phoneNumber.invalid && this.phoneNumber.touched){
          if (this.phoneNumber.errors?.required) {
            return this.translateService.instant('required_field')
          } else if(this.phoneNumber.errors?.pattern){
            return this.translateService.instant('bad_phone_template')
          }
        }
        return ''
      }
      case "firstName": {
        if (this.firstName.invalid && this.firstName.touched){
          if (this.firstName.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.firstName.value.length < 2) {
            return this.translateService.instant('must_be_longer', {length: 2});
          } else if (this.firstName.value.length > 255) {
            return this.translateService.instant('must_be_shorter', {length: 255});
          } else if(this.firstName.errors?.pattern || this.firstName.errors?.whitespace){
            return this.translateService.instant('invalid_symbols');
          }

        }
        return ''
      }
      case "lastName": {
        if (this.lastName.invalid && this.lastName.touched){
          if (this.lastName.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.lastName.value.length < 2) {
            return this.translateService.instant('must_be_longer', {length: 2});
          } else if (this.lastName.value.length > 255) {
            return this.translateService.instant('must_be_shorter', {length: 255})
          } else if(this.lastName.errors?.pattern || this.lastName.errors?.whitespace){
            return this.translateService.instant('invalid_symbols');
          }

        }
        return ''
      }
      case "email": {
        if (this.email.invalid && this.email.touched){
          if (this.email.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.email.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.email.value.length > 255) {
            return this.translateService.instant('must_be_shorter', {length: 255})
          } else if(this.email.errors?.pattern || this.email.errors?.email){
            return this.translateService.instant('bad_email_template')
          }

        }
        return ''
      }
      case "education": {
        if (this.education.invalid && this.education.touched){
          if (this.education.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.education.errors?.pattern || this.education.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.education.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.education.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "langOther": {
        if (this.langOther.invalid && this.langOther.touched){
          if (this.langOther.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.langOther.errors?.pattern || this.langOther.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.langOther.value.length < 2) {
            return this.translateService.instant('must_be_longer', {length: 2});
          } else if (this.langOther.value.length > 100) {
            return this.translateService.instant('must_be_shorter', {length: 100});
          }

        }
        return ''
      }
      case "studyProgramsOtherValue": {
        if (this.studyProgramsOtherValue.invalid && this.studyProgramsOtherValue.touched){
          if (this.studyProgramsOtherValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.studyProgramsOtherValue.errors?.pattern || this.studyProgramsOtherValue.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.studyProgramsOtherValue.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.studyProgramsOtherValue.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "znoValue": {
        if (this.znoValue.invalid && this.znoValue.touched){
          if (this.znoValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.znoValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "ks2": {
        if (this.ks2Value.invalid && this.ks2Value.touched){
          if (this.ks2Value.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.ks2Value.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "gcse_foundation": {
        if (this.gcse_foundationValue.invalid && this.gcse_foundationValue.touched){
          if (this.gcse_foundationValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.gcse_foundationValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "ib_math": {
        if (this.ib_mathValue.invalid && this.ib_mathValue.touched){
          if (this.ib_mathValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.ib_mathValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "dpaValue": {
        if (this.dpaValue.invalid && this.dpaValue.touched){
          if (this.dpaValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.dpaValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "polExamValue": {
        if (this.polExamValue.invalid && this.polExamValue.touched){
          if (this.polExamValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.polExamValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "polInternationalValue": {
        if (this.polInternationalValue.invalid && this.polInternationalValue.touched){
          if (this.polInternationalValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.polInternationalValue.errors?.min) {
            return this.translateService.instant('must_be_more_then') + ' 1'
          }
        }
        return ''
      }
      case "toolsForStudingOtherValue": {
        if (this.toolsForStudingOtherValue.invalid && this.toolsForStudingOtherValue.touched){
          if (this.toolsForStudingOtherValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.toolsForStudingOtherValue.errors?.pattern || this.toolsForStudingOtherValue.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.toolsForStudingOtherValue.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.toolsForStudingOtherValue.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "qualificationOther": {
        if (this.qualificationOther.invalid && this.qualificationOther.touched){
          if (this.qualificationOther.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.qualificationOther.errors?.pattern || this.qualificationOther.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.qualificationOther.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.qualificationOther.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "examsOtherValue": {
        if (this.examsOtherValue.invalid && this.examsOtherValue.touched){
          if (this.examsOtherValue.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.examsOtherValue.errors?.pattern || this.examsOtherValue.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.examsOtherValue.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.examsOtherValue.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "workplace": {
        if (this.workPlace.invalid && this.workPlace.touched){
          if (this.workPlace.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.workPlace.errors?.pattern || this.workPlace.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.workPlace.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.workPlace.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "secondEducation": {
        if (this.secondEducation.invalid && this.secondEducation.touched){
          if (this.secondEducation.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.secondEducation.errors?.pattern || this.secondEducation.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.secondEducation.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.secondEducation.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      }
      case "additionalWorkPlaces":
        if (this.previousWorkplacesControl[index as number].invalid && this.previousWorkplacesControl[index as number].touched){
          if (this.previousWorkplacesControl[index as number].errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.previousWorkplacesControl[index as number].errors?.pattern || this.previousWorkplacesControl[index as number].errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.previousWorkplacesControl[index as number].value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.previousWorkplacesControl[index as number].value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      case "googleMeetLink":
        if (this.googleMeetLink.invalid && this.googleMeetLink.touched){
          if (this.googleMeetLink.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.googleMeetLink.errors?.pattern || this.googleMeetLink.errors?.whitespace) {
            return this.translateService.instant('invalid_data_format')
          }  else if (this.googleMeetLink.value.length < 25) {
            return this.translateService.instant('must_be_longer', {length: 25});
          } else if (this.googleMeetLink.value.length > 50) {
            return this.translateService.instant('must_be_shorter', {length: 50});
          }
        }
        return ''
      case "zoomLink":
        if (this.zoomLink.invalid && this.zoomLink.touched){
          if (this.zoomLink.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.zoomLink.errors?.pattern || this.zoomLink.errors?.whitespace) {
            return this.translateService.instant('invalid_data_format')
          }  else if (this.zoomLink.value.length < 6) {
            return this.translateService.instant('must_be_longer', {length: 6});
          } else if (this.zoomLink.value.length > 2048) {
            return this.translateService.instant('must_be_shorter', {length: 2048});
          }

        }
        return ''
      case "zoomAccessCode":
        if (this.zoomAccessCode.invalid && this.zoomAccessCode.touched){
          if (this.zoomAccessCode.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.zoomAccessCode.errors?.pattern || this.zoomAccessCode.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.zoomAccessCode.value.length < 4) {
            return this.translateService.instant('must_be_longer', {length: 4});
          } else if (this.zoomAccessCode.value.length > 30) {
            return this.translateService.instant('must_be_shorter', {length: 30});
          }

        }
        return ''
      case "zoomId":
        if (this.zoomId.invalid && this.zoomId.touched){
          if (this.zoomId.errors?.required) {
            return this.translateService.instant('required_field')
          } else if (this.zoomId.errors?.pattern || this.zoomId.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          }  else if (this.zoomId.errors?.shorterThen10) {
            return this.translateService.instant('must_be_longer', {length: 10});
          } else if (this.zoomId.errors?.longerThen11) {
            return this.translateService.instant('must_be_shorter', {length: 11});
          }

        }
        return ''
      case "additional":
        if (this.additional.invalid && this.additional.touched){
          if (this.additional.errors?.pattern || this.additional.errors?.whitespace) {
            return this.translateService.instant('invalid_symbols')
          } else if (this.additional.value.length > 1000) {
            return this.translateService.instant('must_be_shorter', {length: 1000});
          }

        }
        return ''
      case "dateOfBirth":
        if (this.birthDate.invalid && this.birthDate.touched){
          if (this.birthDate.errors?.pattern || this.birthDate.errors?.whitespace) {
            return this.translateService.instant('invalid_data_format')
          } else if (this.birthDate.errors?.required) {
            return this.translateService.instant('required_field')
          }
        }
        return ''
      case "qualification":
        if (!this.teacherQualification && this.tappedFields.includes('qualification')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "status":
        if (!this.status && this.tappedFields.includes('status')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "gender":
        if (!this.gender && this.tappedFields.includes('gender')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "country":
        if (!this.country && this.tappedFields.includes('country')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "experience":
        if (!this.experience && this.tappedFields.includes('experience')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "studyStyle":
        if (!this.studyStyle && this.tappedFields.includes('studyStyle')) {
          return this.translateService.instant('required_field')
        }
        return ''
      case "language":
        if (!this.studyLanguage && this.tappedFields.includes('language')) {
          return this.translateService.instant('required_field')
        }
        return ''

      case "tutorExperience":
        if (!this.tutorExperience && this.tappedFields.includes('tutorExperience')) {
          return this.translateService.instant('required_field')
        }
        return ''
      default: {
        return ''
      }
    }
  }


  private setDataForCustomTeacher(teacherReg: Regions): void {
    const polAddSchools: MultiselectData[] = [
      {
        label: 'high_school',
        selected: false,
        id: 'high_school'
      },
      {
        label: 'technical_school',
        selected: false,
        id: 'technical_school'
      },
      {
        label: 'professional_school',
        selected: false,
        id: 'professional_school'
      },
      {
        label: '0_grade',
        selected: false,
        id: '0'
      }
    ]
    const enAddStudyPrograms: MultiselectData[] = [
      {
        label: 'a_level_further',
        selected: false,
        id: 'a_level_further'
      },
      {
        label: 'adv_math',
        selected: false,
        id: 'adv_math'
      }
    ]
    switch (teacherReg) {
      case Regions.PL:{
        this.workingGradesArr = JSON.parse(JSON.stringify(WorkingGradesArr))
          .splice(0, WorkingGradesArr.length-3)
          .concat(polAddSchools)
          .map(el => ({ ...el, label: `grade_level.${el.label}`}));
        this.workingGradesArr.sort((a, b) => {
          if (a.id < b.id) return -1
          else return 1;
        });
        this.teacherQualificationArr = JSON.parse(JSON.stringify(TeacherQualificationArr)).splice(0, 4);
        this.teacherQualificationArr.push(TeacherQualificationArr[TeacherQualificationArr.length-1]);
        this.studyProgramsArr.splice(this.studyProgramsArr.length-1, this.studyProgramsArr.length-2);
        break;
      }
      case Regions.EN:{
        this.workingGradesArr = JSON.parse(JSON.stringify(WorkingGradesArr))
          .map(el => ({ ...el, label: `grade_level.${el.label}`}));
        this.teacherQualificationArr = JSON.parse(JSON.stringify(TeacherQualificationArr)).splice(0, 4);
        this.teacherQualificationArr.push(TeacherQualificationArr[TeacherQualificationArr.length-1]);
        this.studyProgramsArr = this.studyProgramsArr.concat(enAddStudyPrograms);
        break;
      }
      case Regions.UA:
      default: {
        this.workingGradesArr = JSON.parse(JSON.stringify(WorkingGradesArr))
          .map(el => ({ ...el, label: `grade_level.${el.label}`}));
        this.teacherQualificationArr = TeacherQualificationArr;
        break;
      }
    }

    console.log('this.workingGradesArr', teacherReg, this.workingGradesArr);
  }

  public formatPhoneNumber(phoneNumber: string, countryCode: string = '1'): string {
    let cleaned = phoneNumber.replace(/\D/g, '');
    let tmp: string = '';
    cleaned = cleaned.substr(countryCode.length, cleaned.length-1);
    let groups = cleaned.match(/(\d{1,3})/g);
    if(groups[groups.length-1].length === 1){
      tmp = groups[groups.length-1];
      groups.pop();
    }
    return `+(${countryCode}) ${groups.join(' ')}` + tmp;
  }

  public convertPhoneNumberToValidView(phoneNumber: string): string {
    if(!phoneNumber){
      return '';
    }
    if(phoneNumbersFormats.threeDigitsFormat.includes(phoneNumber[0]+phoneNumber[1]+phoneNumber[2])){
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]+phoneNumber[1]+phoneNumber[2]);
    } else if(phoneNumbersFormats.twoDigitsFormats.includes(phoneNumber[0]+phoneNumber[1])) {
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]+phoneNumber[1]);
    } else if(phoneNumbersFormats.oneDigitsFormat.includes(phoneNumber[0])) {
      return this.formatPhoneNumber(phoneNumber, phoneNumber[0]);
    } else {
      return phoneNumber;
    }
  }


  private setUserInfo(teacherData: IGetTeacherSchema): void {
    this.firstName.setValue(teacherData.firstName);
    this.lastName.setValue(teacherData.lastName);
    this.gender = teacherData.gender;
    this.country = this.getCountryName().id;
    this.birthDate.setValue(teacherData.dateOfBirth);
    this.status = teacherData.status;
    this.email.setValue(teacherData.email);
    this.phoneNumber.setValue(this.convertPhoneNumberToValidView(teacherData.phone));
    this.teacherQualification = teacherData.qualification;
    this.tutorExperience = teacherData.tutoringExperience;
    this.education.setValue(teacherData.education);
    this.secondEducation.setValue(teacherData.additionalEducation);
    this.workPlace.setValue(teacherData.currentWorkPlace);
    this.langOther.setValue(teacherData.langOther);
    this.teachersDiploma = !!teacherData.diploma;

    if(teacherData.qualification === 'other' && teacherData.qualificationOther){
      this.qualificationOther.setValue(teacherData.qualificationOther);
    }

    if(teacherData.instrumentsOther && teacherData.instrumentsOther.length > 0){
      this.toolsForStudingOther[0].selected = true;
      this.toolsForStudingOtherValue.setValue(teacherData.instrumentsOther)
    }

    if(teacherData.educationProgramOther && teacherData.educationProgramOther.length > 0){
      this.studyProgramsOther[0].selected = true;
      this.studyProgramsOtherValue.setValue(teacherData.educationProgramOther)
    }

    this.experience = teacherData.experience;
    this.studyLanguage = teacherData.lang;
    this.buildLanguageArr();
    teacherData.workingClass?.forEach(item=>{
      if (this.workingGradesArr.some(elem=> elem.id===item)){
        (this.workingGradesArr.find(elem=> elem.id === item) as MultiselectData).selected = true;
      }
    })
    teacherData.teacherInstruments?.forEach(item=>{
      if (this.toolsForStuding.some(elem=> elem.id===item)){
        (this.toolsForStuding.find(elem=> elem.id === item) as MultiselectData).selected = true;
      }
    })
    teacherData.educationProgram?.forEach(item=>{
      if (this.studyProgramsArr.some(elem=> elem.id===item)){
        (this.studyProgramsArr.find(elem=> elem.id === item) as MultiselectData).selected = true;
      }
    })
    teacherData.teacherInstruments?.forEach(item=>{
      if (this.toolsForStuding.some(elem=> elem.id===item)){
        (this.toolsForStuding.find(elem=> elem.id === item) as MultiselectData).selected = true;
      }
    })
    teacherData.examPreparation.forEach(item=>{
      if (item.name == 'zno') {
        this.zno = true;
        this.znoValue.setValue(item.expirience);
      } else if (item.name == 'dpa') {
        this.dpa = true;
        this.dpaValue.setValue(item.expirience);
      } else if (item.name == 'polExam') {
        this.polExam = true;
        this.polExamValue.setValue(item.expirience);
      } else if (item.name == 'polInternational') {
        this.polInternational = true;
        this.polInternationalValue.setValue(item.expirience);
      } else if (item.name == 'ks2') {
        this.ks2 = true;
        this.ks2Value.setValue(item.expirience);
      }  else if (item.name == 'gcse_foundation') {
        this.gcse_foundation = true;
        this.gcse_foundationValue.setValue(item.expirience);
      }  else if (item.name == 'ib_math') {
        this.ib_math = true;
        this.ib_mathValue.setValue(item.expirience);
      } else if (item.name == 'other') {
        this.examsOther = true;
        this.examsOtherValue.setValue(item.expirience);
      }
    })
    teacherData.additionalSubject?.forEach(item=>{
      if(item === EAdditionalSubject.PHISICS){
        this.physics = true;
      } else if(item === EAdditionalSubject.COMPUTES_SCIENCE){
        this.informatics = true;
      }
    })
    if(teacherData?.lang){
      if(teacherData?.lang.includes('Укр')){
        this.studyLanguage = 'UA';
      } else if (teacherData.lang.includes('Pol')){
        this.studyLanguage = 'PL';
      } else if (teacherData.lang.includes('Eng')){
        this.studyLanguage = 'EN';
      }
    } else {
      switch(teacherData.workingRegion) {
        case Regions.UA: this.studyLanguage = 'UA'; break;
        case Regions.PL: this.studyLanguage = 'PL'; break;
        case Regions.EN: this.studyLanguage = 'EN'; break;
        default: this.studyLanguage = 'UA'; break;
      }
    }
    this.studyStyle = teacherData.educationStyle;
    this.depthStudy = !teacherData.isDepthStudy ? false : teacherData.isDepthStudy;
    teacherData.childSpecialNeeds?.forEach(item=>{
      (this.specialNeeds.find(elem=>elem.id === item) as {id: EPeopleSpecialNeed; state: boolean; label: string}).state = true;
    })

    if(this.isZoomLink(teacherData.linkToZoom)) {
      this.lessonPlatform = 'zoom';
      this.zoomLink.setValue(teacherData.linkToZoom);
      this.zoomId.setValue(teacherData.zoomIdentificator);
      this.zoomAccessCode.setValue(teacherData.zoomCode);
    } else {
      this.lessonPlatform = 'google';
      this.googleMeetLink.setValue(teacherData.linkToZoom);
    }

    this.additional.setValue(teacherData.description)

    if(this.userInfo.previousWorkPlace){
      this.userInfo.previousWorkPlace.forEach((item, index)=>{
        this.previousWorkplacesArr.push({index});
        this.previousWorkplacesControl.push(new UntypedFormControl('', [Validators.required, Validators.pattern(/^[a-zA-Zа-яА-Я'`":;№/&іІїЇєЄґҐąćęłńóśźż.,«»\s\w#$%&‘*+—\/=?^_`{|}()[\]\~.\d-_]/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator]));
        this.previousWorkplacesControl[index].setValue(item);
      })
    }

    this.birthDate.removeValidators([Validators.pattern(this.getUserDateFormat(teacherData.workingRegion) === 'MM.DD.YYYY' ? /^(0?[1-9]|1[0-2])\.(0?[1-9]|[12]\d|3[01])\.\d{4}$/ : /^(0?[1-9]|[12]\d|3[01])\.(0?[1-9]|1[0-2])\.\d{4}$/)]);
    this.birthDate.addValidators([Validators.pattern(this.getUserDateFormat(teacherData.workingRegion) === 'MM.DD.YYYY' ? /^(0?[1-9]|1[0-2])\.(0?[1-9]|[12]\d|3[01])\.\d{4}$/ : /^(0?[1-9]|[12]\d|3[01])\.(0?[1-9]|1[0-2])\.\d{4}$/)]);

  }

  public getUserDateFormat(region: Regions): 'DD.MM.YYYY' | 'MM.DD.YYYY' {
    switch (region) {
      case Regions.EN: {
        return 'MM.DD.YYYY'
      }
      case Regions.UA:
      case Regions.PL:
      default: {
        return 'DD.MM.YYYY'
      }
    }
  }



  private loadTeacherData(teacherId: string): void{
    this.isLoading = true;
    this.changeUserInfoApiService.getTeacherDataById$GET(teacherId).toPromise().catch(()=>{
      this.noDataAboutTeacher();
      return undefined;
    }).then(res=>{
      this.initialStart = false;
      if(res){
        this.isLoading = false;
        this.userInfo = res.data;
        for (let teacherResponseKey in this.userInfo) {
          // @ts-ignore
          this.userInfo[teacherResponseKey] = this.changeUserInfoService.validateField(this.userInfo[teacherResponseKey]);
        }

        this.setDataForCustomTeacher(res.data.workingRegion as Regions)
        this.setUserInfo(res.data)
      } else {
        this.noDataAboutTeacher()
      }
    })
  }


  public addWorkPlace(): void {
    this.previousWorkplacesArr.push({index: this.previousWorkplacesArr.length})
    this.previousWorkplacesControl.push(
      new UntypedFormControl('', [Validators.required, Validators.pattern(/^[а-яА-Яa-zA-Z0-9_’\-() ~'`,!@#$%^&*+?:"{}[\];.ąćęłńóśźżĄĆĘŁŃÓŚŹŻіІїЇєЄґҐ«»№&\/\s\n]*$/), Validators.maxLength(1000), Validators.minLength(2), this.noWhitespaceValidator])
    );
  }
  public removeWorkPlace(index: number): void {
    this.previousWorkplacesArr.splice(index, 1);
    this.previousWorkplacesControl.splice(index, 1);
  }

  private noDataAboutTeacher(): void {
    this.isLoading = false;
    this.router.navigate([AppRoutesDefinitions.USERS])
  }

  public loadPhoto(): void {
    this.uploadPhoto.nativeElement.click();
  }
  public removePhoto(): void {
    this.changeUserInfoApiService.removePhotoByUrl$DELETE(this.userInfo.avatarLink as string, this.userInfo.id).toPromise().then(()=>{
      this.userInfo.avatarLink = null;
    })
  }
  public handlePhotoInputChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList = element.files as FileList;
    const formData = new FormData();
    const targetFile: File = fileList.item(0) as File;

    if(!this.photoAcceptanceFiles.some(item=>item === ('.' + targetFile.name.split('.')[targetFile.name.split('.').length - 1]))){
      this.notificationService.showErrorMessage(this.translateService.instant('invalid_data_format'));
      return;
    }
    if(!this.calculateSizeOfFile(targetFile, this.maxKilobytesPerPhoto)){
      this.notificationService.showErrorMessage(this.translateService.instant('file_too_big'));
      return;
    }
    formData.append('file', targetFile);


    const request = new HttpRequest('POST', `/file/photo`, formData, {
      reportProgress: true,
      responseType: 'json'
    });


    this.httpClient.request(request).toPromise().then((res )=>{
      let data: {body: {
          fileName: string;
          publicUrl: string;
          s3FileName: string;
          size: number;
        }} = res as {body: {
          fileName: string;
          publicUrl: string;
          s3FileName: string;
          size: number;
        }}
      this.userInfo.avatarLink = data.body.publicUrl;
      this.notificationService.showSuccessMessage(this.translateService.instant('successful_uploading'));
      this.updateAvatarLink(data.body.publicUrl);
    });
  }


  public loadFile(): void {
    this.uploadFile.nativeElement.click();
  }
  public removeDiplomaFile(): void {
    this.changeUserInfoApiService.removeFileByUrl$DELETE(this.userInfo.diplomaLink as string).toPromise().then(()=>{
      this.userInfo.diplomaLink = null;
      this.userInfo.diplomaFileName = null;
    })
  }
  public handleFileInputChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList = element.files as FileList;
    const formData = new FormData();
    const targetFile: File = fileList.item(0) as File;

    if(!this.fileAcceptanceFiles.some(item=>item === ('.' + targetFile.name.split('.')[targetFile.name.split('.').length - 1]).toLowerCase())){
      this.notificationService.showErrorMessage(this.translateService.instant('invalid_data_format'));
      return;
    }
    if(!this.calculateSizeOfFile(targetFile, this.maxKilobytesPerFile)){
      this.notificationService.showErrorMessage(this.translateService.instant('file_too_big'));
      return;
    }
    formData.append('file', targetFile);


    const request = new HttpRequest('POST', `/file/document`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    this.httpClient.request(request).toPromise().then((res )=>{
      let data: {body: {
          fileName: string;
          publicUrl: string;
          s3FileName: string;
          size: number;
        }} = res as {body: {
          fileName: string;
          publicUrl: string;
          s3FileName: string;
          size: number;
        }}
      this.userInfo.diplomaFileName = decodeURIComponent(data.body.fileName);
      this.userInfo.diplomaLink = data.body.publicUrl;
      this.notificationService.showSuccessMessage(this.translateService.instant('successful_uploading'));
    });
  }

  public getUserAvatarLink(): string | undefined {
    if(!this.userInfo.avatarLink) {
      if(this.userInfo.gender && !this.userInfo.avatarLink) {
        return this.userInfo.gender === Gender.MALE ? photoLinks.noPhotoBoyLink : photoLinks.noPhotoGirlLink;
      } else{
        return undefined;
      }
    } else {
      return this.userInfo.avatarLink;
    }
  }

  public getUserAvatarLetter(): string {
    if(this.userInfo.firstName){
      return this.userInfo?.firstName.trim()[0];
    } else if(this.userInfo.secondName) {
      return this.userInfo?.secondName.trim()[0];
    } else {
      return this.userInfo?.lastName.trim()[0];
    }
  }

  private getCountryName(): {name: string; id: string }{
    if (!this.userInfo.country){
      return {name: '', id: ''}
    }
    if(this.userInfo.country.toLowerCase() === 'україна'){
      return {
        name:(translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).find(item => item.value === 'UA') as { text: string; value: string }).text,
        id: 'ua'
      };
    } else if(this.userInfo.country.toLowerCase() === 'poland') {
      return {
        name:(translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).find(item => item.value === 'EN') as { text: string; value: string }).text,
        id: 'en'
      };
    } else if(this.userInfo.country.toLowerCase() === 'english') {
      return {
        name:(translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum).find(item => item.value === 'PL') as { text: string; value: string }).text,
        id: 'pl'
      };
    }

    // if((translatedCountriesList(this.selectedLanguage).find(item=> item.value===this.userInfo.country) as { text: string; value: string }).text){
    //   return {
    //     name: (translatedCountriesList(this.selectedLanguage).find(item=> item.value===this.userInfo.country) as { text: string; value: string }).text,
    //     id: this.userInfo.country
    //   };
    // }
    return {
      name: this.userInfo.country,
      id: this.userInfo.country
    };
  }

  public handleMultiselectError(type: 'workingGradesArr' | 'toolsForStuding'): {errorState?: boolean; errorText?: string} {
    switch (type){
      case 'workingGradesArr': {
        if(!this.workingGradesArr.some(item=>item.selected) && this.tappedFields.includes('working_grades')){
          return {errorState: true, errorText: this.translateService.instant('required_field')}
        } else {
          return {errorState: false}
        }
      }
      case 'toolsForStuding': {
        if(!this.toolsForStuding.some(item=>item.selected) && !this.toolsForStudingOther[0].selected && this.tappedFields.includes('tools_for_studing')){
          return {errorState: true, errorText: this.translateService.instant('required_field')}
        }
        return {errorState: false}
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
  }



  private calculateSizeOfFile(file: File, maxFileSize: number): boolean {
    if (!this.maxKilobytesPerFile || maxFileSize === 0) {
      return true;
    }
    const totalKiloBytes = file.size / 1024;
    return totalKiloBytes <= maxFileSize;
  }

  private updateAvatarLink(avatarLink: string): void {
    this.changeUserInfoApiService.updateTeacherInfo$PATCH({avatarLink} as ICreateTeacherSchema, this.userInfo.id).toPromise();
  }

  public convertToValidFDateFormat(date: string, region: Regions): string | null {
    if (this.getUserDateFormat(region) === 'DD.MM.YYYY'){
      const tmp = JSON.parse(JSON.stringify(date));
      if(tmp){
        const resArr = tmp.split('.');
        return resArr[1] + '.'
          + resArr[0] + '.'
          + resArr[2];
      }
      else return null;
    } else {
      return date;
    }
  }

  public getDateFormatPlaceHolder(region: Regions): string {
    switch (region){
      case Regions.EN: {
        switch (this.translateService.currentLang.toUpperCase()){
          case LanguageEnum.polish: {
            return 'MM.DD.RRRR'
          }
          case LanguageEnum.czechia:
          case LanguageEnum.other:
          case LanguageEnum.german:
          case LanguageEnum.french:
          case LanguageEnum.english: {
            return 'MM.DD.YYYY';
          }
          case LanguageEnum.ukrainian:{
            return 'ММ.ДД.РРРР';
          }
        }
        return 'MM.DD.YYYY'
      }
      case Regions.UA:
      case Regions.PL:
      default: {
        switch (this.translateService.currentLang){
          case LanguageEnum.polish:{
            return 'DD.MM.RRRR'
          }
          case LanguageEnum.czechia:
          case LanguageEnum.other:
          case LanguageEnum.german:
          case LanguageEnum.french:
          case LanguageEnum.english: {
            return 'DD.MM.YYYY';
          }
          case LanguageEnum.ukrainian:{
            return 'ДД.ММ.РРРР';
          }
        }
        return 'DD.MM.YYYY'
      }
    }
  }

  public getUserLangByRegion(region: Regions): LanguageEnum {
    switch (region){
      case Regions.UA: {
        return LanguageEnum.ukrainian;
      }
      case Regions.EN: {
        return LanguageEnum.english;
      }
      case Regions.PL: {
        return LanguageEnum.polish;
      }
      default: return this.translateService.currentLang.toUpperCase() as LanguageEnum;
    }
  }

  private generateUpdatedTeacherData(): ICreateTeacherSchema {
    if (this.selectedPage === PagesNames.PERSONAL_INFO) {
      return {
        avatarLink: this.userInfo.avatarLink ? this.userInfo.avatarLink : undefined,
        firstName: this.firstName.value ? this.firstName.value.trim():null,
        lastName: this.lastName.value ? this.lastName.value.trim():null,
        gender: this.gender,
        country: this.country,
        dateOfBirth: this.birthDate.value ? this.birthDate.value.trim():null,
        status: this.status,
        email: this.email.value ? this.email.value.trim():null,
        phone: this.phoneNumber.value ? this.phoneNumber.value.trim().replace(/ /g, '').replace('+', '').replace('(', '').replace(')', '') : null,
        age: moment().diff(this.convertToValidFDateFormat(this.birthDate.value, this.userInfo.workingRegion), 'year'),
        type: 'teachers',
      }
    } else if (this.selectedPage === PagesNames.WORK_SPECIFICATION){
      return {
        educationProgram: this.getStringArrays(this.studyProgramsArr) as EEducationProgram[],
        educationProgramOther: this.studyProgramsOther[0].selected ? this.studyProgramsOtherValue.value ? this.studyProgramsOtherValue.value.trim():null : null,
        additionalSubject: this.generateAdditionalSubjectsArr(),
        examPreparation: this.generateExamPreparationArr(),
        lang: this.generateStudyLangValue(),
        workingClass: this.getStringArrays(this.workingGradesArr),
        tutoringExperience: this.tutorExperience,
        experience: this.experience,
        educationStyle: this.studyStyle as EEducationStyle,
        langOther: this.isOtherStudyLanguageSelected() ? this.langOther.value.trim() : null,
        isDepthStudy: this.depthStudy,
        childSpecialNeeds: this.getSpecialNeedsArr(this.specialNeeds),
        teacherInstruments: this.getStringArrays(this.toolsForStuding) as ETeacherInstrument[],
        instrumentsOther: this.toolsForStudingOther[0].selected ? this.toolsForStudingOtherValue.value ? this.toolsForStudingOtherValue.value.trim():null : null,
        type: 'teachers',
      }
    } else if (this.selectedPage === PagesNames.EDUCATION){
      return {
        education: this.education.value ? this.education.value.trim():null,
        additionalEducation: this.secondEducation.value ? this.secondEducation.value.trim():null,
        diplomaLink: this.userInfo.diplomaLink,
        diplomaFileName: this.userInfo.diplomaFileName,
        diploma: this.teachersDiploma,
        qualification: this.teacherQualification,
        qualificationOther: this.teacherQualification==='other' && this.qualificationOther.valid && this.qualificationOther.value.length > 0 ? this.qualificationOther.value : null,
        type: 'teachers',
      }
    } else if (this.selectedPage === PagesNames.EXPERIENCE){
      return {
        currentWorkPlace: this.workPlace.value ? this.workPlace.value.trim():null,
        previousWorkPlace: this.previousWorkplacesControl.map(item=>item.value),
        type: 'teachers',
      }
    } else if (this.selectedPage === PagesNames.ZOOM){
      if(this.isZoomSelected()) {
        return {
          zoomCode: this.zoomAccessCode.value ? this.zoomAccessCode.value.trim():null,
          zoomIdentificator: this.zoomId.value ? this.zoomId.value.trim():null,
          linkToZoom: this.zoomLink.value ? this.zoomLink.value.trim():null,
          type: 'teachers',
        }
      } else {
        return {
          linkToZoom: this.googleMeetLink.value ? this.googleMeetLink.value.trim():null,
          type: 'teachers',
        }
      }
    } else if (this.selectedPage === PagesNames.ADDITIONAL){
      return {
        description: this.additional.value ? this.additional.value.trim():null,
        type: 'teachers',
      }
    } else {
      return {
        avatarLink: this.userInfo.avatarLink ? this.userInfo.avatarLink : undefined,
        firstName: this.firstName.value ? this.firstName.value.trim():null,
        lastName: this.lastName.value ? this.lastName.value.trim():null,
        gender: this.gender,
        country: this.country,
        dateOfBirth: this.birthDate.value ? this.birthDate.value.trim():null,
        status: this.status,
        email: this.email.value ? this.email.value.trim():null,
        phone: this.phoneNumber.value ? this.phoneNumber.value.trim().replace(/ /g, '').replace('+', '').replace('(', '').replace(')', '') : null,
        age: moment().diff(this.convertToValidFDateFormat(this.birthDate.value, this.userInfo.workingRegion), 'year'),
        educationProgram: this.getStringArrays(this.studyProgramsArr) as EEducationProgram[],
        educationProgramOther: this.studyProgramsOther[0].selected ? this.studyProgramsOtherValue.value ? this.studyProgramsOtherValue.value.trim():null : null,
        additionalSubject: this.generateAdditionalSubjectsArr(),
        examPreparation: this.generateExamPreparationArr(),
        lang: this.generateStudyLangValue(),
        workingClass: this.getStringArrays(this.workingGradesArr),
        tutoringExperience: this.tutorExperience,
        experience: this.experience,
        educationStyle: this.studyStyle as EEducationStyle,
        langOther: this.isOtherStudyLanguageSelected() ? this.langOther.value.trim() : null,
        isDepthStudy: this.depthStudy,
        childSpecialNeeds: this.getSpecialNeedsArr(this.specialNeeds),
        teacherInstruments: this.getStringArrays(this.toolsForStuding) as ETeacherInstrument[],
        instrumentsOther: this.toolsForStudingOther[0].selected ? this.toolsForStudingOtherValue.value ? this.toolsForStudingOtherValue.value.trim():null : null,
        education: this.education.value ? this.education.value.trim():null,
        additionalEducation: this.secondEducation.value ? this.secondEducation.value.trim():null,
        diplomaLink: this.userInfo.diplomaLink,
        diplomaFileName: this.userInfo.diplomaFileName,
        diploma: this.teachersDiploma,
        qualification: this.teacherQualification,
        qualificationOther: this.teacherQualification==='other' && this.qualificationOther.valid && this.qualificationOther.value.length > 0 ? this.qualificationOther.value : null,
        currentWorkPlace: this.workPlace.value ? this.workPlace.value.trim():null,
        previousWorkPlace: this.previousWorkplacesControl.map(item=>item.value),
        zoomCode: this.zoomAccessCode.value ? this.zoomAccessCode.value.trim():null,
        zoomIdentificator: this.zoomId.value ? this.zoomId.value.trim():null,
        linkToZoom: (this.isZoomSelected()) ? this.zoomLink.value.trim() : this.googleMeetLink.value.trim(),
        description: this.additional.value ? this.additional.value.trim():null,
        type: 'teachers',
      }
    }
  }

  public onDiplomaChanges(value: boolean): void {
    if (this.authService.isTeacher()) {
      return;
    }

    this.teachersDiploma = value;
  }

  private getStringArrays(data: MultiselectData[]): string[] {
    const resData: string[] = [];
    data.forEach(item=>{
      if (item.selected) {
        resData.push(item.id);
      }
    })
    return resData;
  }
  private generateAdditionalSubjectsArr(): string[] {
    const resData: string[] = [];
    if(this.physics){
      resData.push(EAdditionalSubject.PHISICS)
    }
    if(this.informatics){
      resData.push(EAdditionalSubject.COMPUTES_SCIENCE)
    }
    return resData;
  }
  private generateExamPreparationArr(): ICreateExamSchema[] {
    const resData: ICreateExamSchema[] = [];
    if(this.zno){
      resData.push({
        expirience: this.znoValue.value.toString(),
        name: 'zno'
      })
    }
    if(this.dpa){
      resData.push({
        expirience: this.dpaValue.value.toString(),
        name: 'dpa'
      })
    }
    if(this.polExam){
      resData.push({
        expirience: this.polExamValue.value.toString(),
        name: 'polExam'
      })
    }
    if(this.polInternational){
      resData.push({
        expirience: this.polInternationalValue.value.toString(),
        name: 'polInternational'
      })
    }
    if(this.ks2){
      resData.push({
        expirience: this.ks2Value.value.toString(),
        name: 'ks2'
      })
    }
    if(this.gcse_foundation){
      resData.push({
        expirience: this.gcse_foundationValue.value.toString(),
        name: 'gcse_foundation'
      })
    }
    if(this.ib_math){
      resData.push({
        expirience: this.ib_mathValue.value.toString(),
        name: 'ib_math'
      })
    }
    if(this.examsOther){
      resData.push({
        expirience: this.examsOtherValue.value,
        name: 'other'
      })
    }
    return resData;
  }

  private getSpecialNeedsArr(data: {id: EPeopleSpecialNeed; state: boolean; label: string}[]): EPeopleSpecialNeed[] {
    const resData: EPeopleSpecialNeed[] = []

    data.forEach(item=>{
      if (item.state){
        resData.push(item.id);
      }
    })

    return resData
  }

  public blockSaveButton(): boolean{
    if (this.selectedPage === PagesNames.PERSONAL_INFO) {
      return this.firstName.invalid ||
          this.lastName.invalid ||
          !this.gender ||
          !this.country ||
          this.birthDate.invalid ||
          !this.status ||
          this.email.invalid ||
          this.phoneNumber.invalid;
    } else if (this.selectedPage === PagesNames.WORK_SPECIFICATION){
      return !this.experience ||
        !this.tutorExperience ||
        this.handleMultiselectError('workingGradesArr').errorState ||
        this.handleMultiselectError('toolsForStuding').errorState ||
        (this.toolsForStudingOther[0].selected && this.toolsForStudingOtherValue.invalid) ||
        (this.zno && this.znoValue.invalid) ||
        (this.dpa && this.dpaValue.invalid) ||
        (this.polExam && this.polExamValue.invalid) ||
        (this.polInternational && this.polInternationalValue.invalid) ||
        (this.ks2 && this.ks2Value.invalid) ||
        (this.gcse_foundation && this.gcse_foundationValue.invalid) ||
        (this.ib_math && this.ib_mathValue.invalid) ||
        (this.examsOther && this.examsOtherValue.invalid) ||
        (this.studyProgramsOther[0].selected && this.studyProgramsOtherValue.invalid) ||
        !this.studyLanguage ||
        (this.isOtherStudyLanguageSelected() && this.langOther.invalid) ||
        !this.studyStyle;
    } else if (this.selectedPage === PagesNames.EDUCATION){
      return this.education.invalid ||
        this.secondEducation.invalid ||
        !this.teacherQualification ||
        (this.teacherQualification === 'other' && this.qualificationOther.invalid);
    } else if (this.selectedPage === PagesNames.EXPERIENCE){
      return this.workPlace.invalid ||
        this.previousWorkplacesControl.some(item=>item.invalid);
    } else if (this.selectedPage === PagesNames.ZOOM){
      if(this.isZoomSelected()){
        return this.zoomLink.invalid ||
        this.zoomAccessCode.invalid ||
        this.zoomId.invalid;
      } else return this.googleMeetLink.invalid;
    } else if (this.selectedPage === PagesNames.ADDITIONAL){
      return this.additional.invalid;
    } else {
      return this.firstName.invalid ||
        this.lastName.invalid ||
        !this.gender ||
        !this.country ||
        this.birthDate.invalid ||
        !this.status ||
        this.email.invalid ||
        this.phoneNumber.invalid ||
        !this.experience ||
        !this.tutorExperience ||
        this.handleMultiselectError('workingGradesArr').errorState ||
        this.handleMultiselectError('toolsForStuding').errorState ||
        (this.toolsForStudingOther[0].selected && this.toolsForStudingOtherValue.invalid) ||
        (this.zno && this.znoValue.invalid) ||
        (this.dpa && this.dpaValue.invalid) ||
        (this.polExam && this.polExamValue.invalid) ||
        (this.ks2 && this.ks2Value.invalid) ||
        (this.gcse_foundation && this.gcse_foundationValue.invalid) ||
        (this.ib_math && this.ib_mathValue.invalid) ||
        (this.examsOther && this.examsOtherValue.invalid) ||
        (this.studyProgramsOther[0].selected && this.studyProgramsOtherValue.invalid) ||
        !this.studyLanguage ||
        (this.isOtherStudyLanguageSelected() && this.langOther.invalid) ||
        !this.studyStyle ||
        this.education.invalid ||
        this.secondEducation.invalid ||
        !this.teacherQualification ||
        (this.teacherQualification === 'other' && this.qualificationOther.invalid) ||
        this.workPlace.invalid ||
        this.previousWorkplacesControl.some(item=>item.invalid) ||
        (this.isZoomSelected() ? (this.zoomLink.invalid || this.zoomAccessCode.invalid || this.zoomId.invalid) : this.googleMeetLink.invalid ) ||
        this.additional.invalid
    }
  }

  public onFieldsFocus(fieldName: string): void {
    if(!this.tappedFields.includes(fieldName)){
      this.tappedFields.push(fieldName)
    } else return
  }

  private markPageFieldsAsTouched(): void {
    if (this.selectedPage === PagesNames.PERSONAL_INFO) {
      this.firstName.markAllAsTouched();
      this.lastName.markAllAsTouched();
      this.onFieldsFocus('gender');
      this.onFieldsFocus('country');
      this.birthDate.markAllAsTouched();
      this.onFieldsFocus('status');
      this.email.markAllAsTouched();
      this.phoneNumber.markAllAsTouched();
    } else if (this.selectedPage === PagesNames.WORK_SPECIFICATION){
      this.onFieldsFocus('experience');
      this.onFieldsFocus('tutorExperience');
      this.onFieldsFocus('working_grades');
      this.znoValue.markAllAsTouched();
      this.dpaValue.markAllAsTouched();
      this.polExamValue.markAllAsTouched();
      this.ks2Value.markAllAsTouched();
      this.gcse_foundationValue.markAllAsTouched();
      this.ib_mathValue.markAllAsTouched();
      if(this.examsOther) this.examsOtherValue.markAllAsTouched();
      if(this.studyProgramsOther[0].selected) this.studyProgramsOtherValue.markAllAsTouched();
      if(this.isOtherStudyLanguageSelected()) this.langOther.markAllAsTouched();
      this.onFieldsFocus('studyStyle');
      this.onFieldsFocus('tools_for_studing');
      if(this.toolsForStudingOther[0].selected) this.toolsForStudingOtherValue.markAllAsTouched();
    } else if (this.selectedPage === PagesNames.EDUCATION){
      this.education.markAllAsTouched();
      this.secondEducation.markAllAsTouched();
      if(this.teacherQualification === 'other') this.qualificationOther.markAllAsTouched();
    } else if (this.selectedPage === PagesNames.EXPERIENCE){
      this.workPlace.markAllAsTouched();
      this.previousWorkplacesControl.forEach(item=>{item.markAllAsTouched()})
    } else if (this.selectedPage === PagesNames.ZOOM){
      if(this.isZoomSelected()) {
        this.zoomLink.markAllAsTouched();
        this.zoomAccessCode.markAllAsTouched();
        this.zoomId.markAllAsTouched();
      } else this.googleMeetLink.markAllAsTouched();
    } else if (this.selectedPage === PagesNames.ADDITIONAL){
      this.additional.markAllAsTouched();
    }
  }

  private markAllFields(): void {
    const dropdownField: string[] = [
      'qualification',
      'status',
      'gender',
      'country',
      'experience',
      'studyStyle',
      'language',
      'tutorExperience',
    ]

    this.firstName.markAllAsTouched();
    this.lastName.markAllAsTouched();
    this.birthDate.markAllAsTouched();
    this.phoneNumber.markAllAsTouched();
    this.znoValue.markAllAsTouched();
    this.dpaValue.markAllAsTouched();
    this.polExamValue.markAllAsTouched();
    this.polInternationalValue.markAllAsTouched();
    this.email.markAllAsTouched();
    this.ks2Value.markAllAsTouched();
    this.gcse_foundationValue.markAllAsTouched();
    this.ib_mathValue.markAllAsTouched();
    if(this.examsOther) this.examsOtherValue.markAllAsTouched();
    if(this.studyProgramsOther[0].selected) this.studyProgramsOtherValue.markAllAsTouched();
    if(this.isOtherStudyLanguageSelected()) this.langOther.markAllAsTouched();
    if(this.toolsForStudingOther[0].selected) this.toolsForStudingOtherValue.markAllAsTouched();
    this.education.markAllAsTouched();
    this.secondEducation.markAllAsTouched();
    if(this.teacherQualification === 'other') this.qualificationOther.markAllAsTouched();
    this.workPlace.markAllAsTouched();
    if(this.isZoomSelected()){
      this.zoomLink.markAllAsTouched();
      this.zoomAccessCode.markAllAsTouched();
      this.zoomId.markAllAsTouched();
    } else this.googleMeetLink.markAllAsTouched();
    this.additional.markAllAsTouched();
    this.previousWorkplacesControl.forEach(item=>{item.markAllAsTouched()})
    dropdownField.forEach(item=>{this.onFieldsFocus(item)})
    this.onFieldsFocus('working_grades');
    this.onFieldsFocus('tools_for_studing');
  }

  public isOtherStudyLanguageSelected(): boolean {
     return this.languageArr.find(lang => lang.id === LanguageEnum.other).selected;
  }

  public generateStudyLangValue(): string {
    return this.languageArr.reduce((str, item) => str + (item.selected ? item.id + ',' : ''), '');
  }

  public buildLanguageArr(): void {
    this.languageArr.forEach(l => {
      if (this.studyLanguage && this.studyLanguage.split(',').includes(l.id))
        l.selected = true
      })
  }

  public isDeepStudyAvailable(): boolean {
    switch(this.userInfo.workingRegion) {
      case Regions.UA: return this.workingGradesArr.some(grade => grade.selected && +grade.id >= 8);
      case Regions.PL: return this.isPolHighSchool();
      default: return true;
    }
  }

  public isExamPrepAvailable(): boolean {
    switch(this.userInfo.workingRegion) {
      case Regions.UA: return this.workingGradesArr.some(grade => grade.selected && +grade.id >= 9);
      case Regions.PL: return this.isPolHighSchool();
      case Regions.EN: return this.workingGradesArr.some(grade => grade.selected && +grade.id >= 9);
      default: return true;
    }
  }

  public isPolHighSchool(): boolean {
    return this.workingGradesArr.some(grade => grade.selected &&
      (grade.id === 'high_school' || grade.id === 'technical_school' || grade.id === 'professional_school'));
  }

  public checkDeepStudyCondition(): void {
    if(!this.isDeepStudyAvailable()) this.depthStudy = false;
  }

  public checkExamCondition(): void {
    if(!this.isExamPrepAvailable()) {
      this.zno = false;
      this.polExam = false;
      this.polInternational = false;
      this.gcse_foundation = false;
    }
  }

  public isZoomLink(link?: string): boolean {
    if(!link) return true;
    const zoomRegex = /^\s*https?:\/\/(?:[\w-]+\.)?zoom\.us\/(?:j\/\d{8,}(?:\?\S*)?|my\/[^\/\?\s]+\/\w{10,})(?:\?\S*)?$/;
    const match = link.match(zoomRegex);
    return (match && match.length > 0);
  }

  private isZoomSelected(): boolean {
    return this.lessonPlatform === 'zoom';
  }
}

