import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageEnum, PaymentLevels } from '../../../shared/constants';
import { AuthService } from '../auth/auth.service';
import { TeacherStatus } from '../schedule/dto/teacher.dto';
import BasePagedQuery from '@app/shared/models/base-paged-query';
import { FiltersKeys } from './free-hours-filters/free-hours-filters.component';
import BasePagedModel from '@app/shared/models/base-paged-model';
import { environment } from '@env/environment';
export interface TeacherSelectionPagedQueryDto extends BasePagedQuery {
  filters?: {
    [key: string]: string | string[] | number[] | number | StudyDayFilterDto[];
  };
  sorting?: TeacherSelectionSortingDto;
}

export interface TeacherSelectionSortingDto {
  [FiltersKeys.WORKLOAD]?: SortingItem;
  [FiltersKeys.CONVERSION]?: SortingItem;
}

export interface SortingItem {
  order: 'ASC' | 'DESC';
  priority?: number;
}

export interface StudyDayFilterDto {
  weekDays: number[];
  timeStart: string;
  timeEnd: string;
}

export interface TeacherSelectionPagedResponse extends BasePagedModel<TeacherSelectionDto> {
}

export interface TeacherSelectionDto {
  id: string;
  firstName: string;
  lastName: string;
  age: string | null;
  tutoringExperience: string | null;
  qualification: string | null;
  teacherWorkLoad: number;
  conversion: number | null;
  status: TeacherStatus;
  level: string | null;
  examPreparation: string | null;
  isVerified: Date | null;
  notTakeNewStudentsAt: Date | null;
}

@Injectable({
  providedIn: 'root',
})
export default class FreeHoursService {

  public $expandedTeacherWeekStart = new BehaviorSubject<Date>(null);
  private url = `${environment.PifagorApiAddress}/teachers`;

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
  ) {
  }

  public getTeachersList(body: TeacherSelectionPagedQueryDto): Observable<TeacherSelectionPagedResponse> {
    return this.http.post<TeacherSelectionPagedResponse>(`${this.url}/selection`, body);
  }

  public getTeachersUsedCountries(): Observable<string[]> {
    return this.http.get<string[]>(`${this.url}/residence`);
  }

  public makePaymentLevelTip(paymentLevel: string, authService: AuthService): string {
    if (!paymentLevel) {
      return '';
    }

    if (authService.user.language === LanguageEnum.english) {
      return '';
    }

    let rate;
    switch (paymentLevel) {
      case PaymentLevels.PREMIUM:
        rate = 250;
        break;
      case PaymentLevels.GOLD:
        rate = 200;
        break;
      case PaymentLevels.SILVER:
        rate = 175;
        break;
      case PaymentLevels.BASIC:
        rate = 150;
    }

    if (authService.user.language === LanguageEnum.polish) {
      rate = 50;
    }

    const teacher = this.translateService.instant('free_hours.teacher_with_level');
    const receives = this.translateService.instant('free_hours.receives');
    const currency = this.translateService.instant('free_hours.currancy');
    const perLesson = this.translateService.instant('free_hours.per_lesson');

    return `${teacher} ${paymentLevel} ${receives} ${rate} ${currency} ${perLesson}`;
  }
}
