<div class="week-calendar" #weekCalendar id="weekCalendar">
  <ng-container *ngIf="calendarView$">
    <ng-container *ngIf="calendarView$ | async as days; else loadingOrError">
      <ng-container *ngIf="currentTime$ | async as time">
        <div class="current-time" [style.top]="getTopOffsetForCurrentTime(time) + 'px'">
          <div class="value">{{time}}</div>
        </div>
      </ng-container>
      <div class="week-days">
        <div class="week-day ruler"></div>
        <div
          class="week-day"
          *ngFor="let day of days | keyvalue:sortObjectNumericKeys"
          [class.is-today]="day.value.isToday"
          [class.vacation]="day.value.isVacation"
          nz-tooltip
          [nzTooltipTitle]="'calendar.vacations.vacation-day' | translate"
          [nzTooltipTrigger]="day.value.isVacation ? 'hover' : null"
        >
          {{formatWeekDay(day.value.date) | titlecase}}
        </div>
      </div>
      <div class="days">
        <div class="day ruler">
          <div *ngFor="let hour of RULER_HOURS" class="time"><span class="value">{{hour}}</span></div>
        </div>

        <div
          class="day"
          *ngFor="let day of days | keyvalue:sortObjectNumericKeys"
          [class.hidden]="day.key === 'offset'"
          [class.empty]="!day.value['items']?.length"
        >
          <div
            *ngFor="let hour of TIME_HOURS"
            class="hour-line"
            [style.top]="getTopOffsetFromTime(hour) + 'px'"
          ></div>

          <div class="items">
            <div
              class="dndPlaceholder"
              dndPlaceholderRef
              *ngFor="let item of day.value['items']"
              [style.top]="getTopOffsetForSlot(item.time) + 1 + 'px'"
              [style.height]="getHeightFromTime(item.time) - 1 + 'px'"
            ></div>
            <div
              [dndDraggable]="item"
              [dndDisableDragIf]="!item.isLesson || (authService.isTeacher() && isMoveDisabledForTeacher(item)) || (authService.isClient() && isMoveDisabledForClient(item))"
              dndEffectAllowed="move"
              class="item-wrapper"
              [class.lesson]="item.isLesson"
              *ngFor="let item of day.value['items']"
              [style.top]="getTopOffsetForSlot(item.time) + 'px'"
              [style.height]="getHeightFromTime(item.time) + 'px'"
            >
              <mathema-schedule-calendar-slot [item]="item" [viewType]="'week'" [isVacation]="day.value.isVacation"></mathema-schedule-calendar-slot>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingOrError>
  <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  <ng-template #loading>
    <nz-spin class="week-schedule-spin" [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
