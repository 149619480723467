import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, combineLatest, EMPTY, interval, Observable, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { isPastTime } from '@shared/utils';
import LanguageService from '@shared/language/language.service';
import { TIME_SLOTS_INDICES, TIME_HOURS } from '@shared/constants';
import ScheduleCalendarService, { ScheduleCalendarType } from '../schedule-calendar.service';
import ScheduleApiService, { MonthlyCalendarItem, MonthlyCalendarResponse } from '../../schedule-api.service';
import { AuthService } from '../../../auth/auth.service';
import { LessonType } from '@shared/services/lessons/dto/lesson.dto';
import { MainLayoutService } from '@app/layouts/main';

const slotHeight = 60;

@Component({
  selector: 'mathema-schedule-week-calendar',
  templateUrl: './schedule-week-calendar.component.html',
  styleUrls: ['./schedule-week-calendar.component.scss']
})
export class ScheduleWeekCalendarComponent implements OnInit {

  public TIME_HOURS: string[] = TIME_HOURS;
  public RULER_HOURS: string[] = TIME_HOURS;
  public calendarView$: Observable<MonthlyCalendarResponse>;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);
  public currentTime$: Observable<string> = interval(1000).pipe(
    map((_) => {
      const now = new Date();
      const [hour, minute] = now.toTimeString().split(':');

      return `${hour}:${minute}`;
    }),
  );

  private readonly subscriptions: Subscription[] = [];

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly languageService: LanguageService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly mainLayoutService: MainLayoutService,
  ) {
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 575px)']).subscribe(result => {
      if (result.matches) {
        this.scheduleCalendarService.calendarType.next(ScheduleCalendarType.DAY);
      }
    }));
  }

  ngOnInit(): void {
    const state = combineLatest([
      this.scheduleCalendarService.calendarDate,
      this.scheduleCalendarService.calendarFilters,
    ]);

    const calendarViewSub = state.subscribe(([calendarDate, calendarFilters]) => {
      if (calendarDate.getDay() !== 1) {
        const currentDay = calendarDate.getDay();
        calendarDate.setDate(calendarDate.getDate() - (currentDay - 1));
      }

      this.loadingError$.next(false);

      const year = calendarDate.getFullYear();
      const month = calendarDate.getMonth();
      const day = calendarDate.getDate();
      this.calendarView$ = this.scheduleApiService.getWeeklyCalendar({
        year,
        month,
        day,
        ...calendarFilters,
      }).pipe(
        finalize(() => {
          requestAnimationFrame(() => {
            document.getElementById('weekCalendar').scrollTop = 960; // 08:00 by default
          })
          this.mainLayoutService.setLoading(false);
        }),
        catchError(() => {
          this.loadingError$.next(true);
          return EMPTY;
        }),
      );
    });

    this.subscriptions.push(calendarViewSub);
  }

  public getTopOffsetFromTime(time: string): number {
    const hour = Number(time.split(':')[0]);
    return hour * slotHeight;
  }

  public getTopOffsetForSlot(time: string): number {
    const [hour, minute] = time.split(' - ')[0].split(':').map(Number);
    return (hour * slotHeight) + (minute);
  }

  public getTopOffsetForCurrentTime(time: string): number {
    const [hour, minute] = time.split(':').map(Number);
    return (hour * slotHeight) + (minute) + 36;
  }

  public getHeightFromTime(time: string): number {
    const [startTime, endTime] = time.split(' - ');
    return (TIME_SLOTS_INDICES[endTime] - TIME_SLOTS_INDICES[startTime]) * 5 - 1;
  }

  public formatWeekDay(date: string): string {
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      weekday: 'long',
      day: 'numeric',
    });

    const monthDay = new Date(date);

    return formatter.format(monthDay);
  }

  public sortObjectNumericKeys(a, b): number {
    return a.key - b.key;
  }

  public isMoveDisabledForTeacher(item: MonthlyCalendarItem): boolean {
    if (item.isLesson && item.type === LessonType.FIRST) {
      return true;
    }

    const date = DateTime.fromFormat(item.date, 'yyyy-MM-dd').plus({ days: 2 }).toSQLDate();
    return isPastTime(date, item.time);
  }

  public isMoveDisabledForClient(item: MonthlyCalendarItem): boolean {
    if (item.isLesson && item.type === LessonType.FIRST) {
      return true;
    }

    const [lessonStart] = item.time.split(' - ');
    const lessonTime = DateTime.fromSQL(`${item.date} ${lessonStart}`);
    const now = DateTime.now();

    return now.plus({ hour: 3 }) >= lessonTime;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
