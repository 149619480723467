export interface ChangePasswordDto {
  oldPassword: string;
  newPassword: string;
}

export enum ContactMarketRegion {
  EMPTY = '',
  ALL = 'ee8bf257-4d93-46e2-ac47-1f3eda524894',
  UNITED_KINGDOM = '422582aa-8f98-4ea2-a9fe-6c1f7b6805ed',
  UKRAINE = 'f5e793ca-f76b-4248-88bf-52b314ef7b2c',
  POLAND = 'b9c17e6a-58ba-48ba-aec2-7fd836751921',
  TURKEY = '29369aba-8ace-42c5-8993-73a607a16de1',
}
