@let columnHorizontalGutter = 32;
@let columnVerticalGutter = 12;

<ng-template mathHeaderTitle>
  @if (authService.isTeacher()) {
  {{'my_cabinet' | translate}}
  } @else {
  {{'teacher_cabinet' | translate}}
  }
</ng-template>

<ng-template mathHeaderSubtitle>
  {{userInfo?.firstName}} {{userInfo?.lastName}}
</ng-template>

@if (authService.isAdmin() || authService.isManager()) {
<ng-template mathHeaderBackButton>
  <button (click)="handleBackClick()"></button>
</ng-template>
}

@if (authService.isAdmin() || authService.isManager() || authService.isTeacher()) {
<ng-template mathHeaderTitleAction>
  <button nz-button nzSize="small" (click)="openTeacherCalendar()" nz-tooltip
    [nzTooltipTitle]="'to_teacher_calendar' | translate">
    <span nz-icon nzType="calendar"></span>
  </button>
</ng-template>
}

<ng-container>
    <div nz-row [nzGutter]="[12, 12]">
      <div nz-col [nzXs]="24" [nzMd]="6">
        <ng-container *ngTemplateOutlet="teacherMainInfo" />
      </div>
      <div nz-col [nzXs]="24" [nzMd]="18">
        <ng-container *ngIf="!getEditIndicatorsState() else changeIndicatorsPage">
          <div nz-row [nzGutter]="[12, 12]">
            <div nz-col nzSpan="24">
              @let profile = authService.isTeacher()? ('info_about_myself' | translate) : ('profile' | translate);
              @let indicators = authService.isTeacher()? ('my_indicators' | translate) : ('indicators' | translate);
              @let tabs = [
              { label: profile, value: 0 },
              { label: indicators, value: 1 },
              ];
              
              <nz-segmented 
                class="non-selectable" 
                [ngModel]="currentTabIndex()" 
                nzBlock 
                [nzOptions]="tabs"
                (nzValueChange)="changeTabIndex($event)"
              />
            </div>
      
            <div nz-col nzSpan="24">
              <div nz-flex>
                @switch (currentTabIndex()) {
                @case (0) {
                <ng-container *ngTemplateOutlet="profileTab"></ng-container>
                }
                @case (1) {
                <ng-container *ngTemplateOutlet="indicatorsTab"></ng-container>
                }
                }
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  <ng-template #changeIndicatorsPage>
    <div class="card">
      <!-- Indicators -->
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <div nz-col [nzSpan]="24">
            <div class="section-title">{{'indicators' | translate}}</div>
          </div>

          <div nz-col [nzSpan]="24">
            <div class="label">{{'statistics' | translate}}</div>
          </div>

          <div nz-col>
            <div nz-flex nzVertical [nzGap]="8">
              <div class="label">{{'regulation_violations' | translate}} ({{'amount' | translate}}):</div>
              <nz-input-number
                [formControl]="regulationViolation"
                [nzMin]="0" 
                [nzMax]="initialSettings.regulationViolationMaxValue" 
                [nzStep]="1" 
              />
            </div>
          </div>

          <div nz-col>
            <div nz-flex nzVertical [nzGap]="8">
              <div class="label">{{'positive_feedbacks' | translate}} ({{'amount' | translate}}):</div>
              <nz-input-number
                [formControl]="positiveFeedback"
                [nzMin]="0" 
                [nzMax]="initialSettings.positiveResponsesMaxValue" 
                [nzStep]="1" 
              />
            </div>
          </div>

          <div nz-col>
            <div nz-flex nzVertical [nzGap]="8">
              <div class="label">{{'negative_feedbacks' | translate}} ({{'amount' | translate}}):</div>
              <nz-input-number
                [formControl]="negativeFeedback"
                [nzMin]="0" 
                [nzMax]="initialSettings.negativeResponsesMaxValue" 
                [nzStep]="1" 
              />
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <!-- Achievements -->
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <div nz-col [nzSpan]="24">
            <div class="label">{{'achievements' | translate}}</div>
          </div>

          <div *ngFor="let achievement of customRewardsList">
            <div nz-col>
              <div nz-row [nzGutter]="[12, 12]">
                <div nz-col>
                  <div>
                    <img
                      class="achievement-image"
                      [src]="generateValidImgUrl(achievement.image) + (achievement.state ? '' : '-grey') + '.svg'" 
                      [alt]="achievement.name"
                      nz-tooltip [nzTooltipTitle]="achievementTooltip"
                    />
                  </div>
                  <ng-template #achievementTooltip>
                    <span *ngIf="achievement.name" class="math-tooltip-header">
                      {{achievement.name | translate}}
                    </span><br>
                    <span *ngIf="achievement.header">{{achievement.header | translate}}</span>
                  </ng-template>
                </div>

                <!--Achievement Name-->
                <div nz-col>
                  <div class="label">{{achievement.name | translate}}</div>
                  <label 
                    nz-checkbox 
                    [ngModel]="achievement.state"
                    (change)="toggleTeacherReward(achievement.id); achievement.state = !achievement.state"
                  >
                    {{achievement.header | translate}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <!-- Verification -->
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <div nz-col [nzSpan]="24">
            <div class="label">{{'verification' | translate}}</div>
          </div>

          <div nz-col>
            <div nz-flex nzVertical [nzGap]="8" *ngIf="authService.isUserUkrainian() || authService.isUserPoland()">
              <div nz-flex [nzGap]="8">
                <label nz-checkbox [formControl]="isVerified">
                  @if (isVerified.value) {
                    {{'verified' | translate}}
                  } @else {
                    {{'not_verified' | translate}}
                  }
                </label>
              </div>
              <div class="label">
                {{'verification_description_long' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <!-- Footer -->
      <div class="card-block">
        <div nz-row nzJustify="end">
          <div nz-col>
            <div nz-flex [nzGap]="8">
              <button nz-button nzType="default" (click)="indicatorsCancelBtnClick()">
                {{ 'cancel' | translate }}
              </button>
              <button nz-button nzType="primary"
                [disabled]="regulationViolation.invalid || positiveFeedback.invalid || negativeFeedback.invalid"
                (click)="indicatorsSaveBtnClick()">
                {{ 'save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #teacherMainInfo>
    <div class="card">
      <div class="card-block" nz-flex nzVertical [nzGap]="8">
        <div nz-flex nzAlign="end" nzJustify="end">
          <button nz-button nzType="text" *ngIf="authService.isTeacher()" (click)="openSettingsModal()">
            <span nz-icon nzType="setting" nzTheme="fill" color="primary"></span>
          </button>
        </div>

        <div nz-flex nzAlign="center" nzJustify="center">
          @let isVerifiedBadge = (authService.isUserUkrainian() || authService.isUserPoland()) && isVerified.value;
          <nz-badge 
            [nzCount]="isVerifiedBadge ? isVerifiedIcon : null"
          >
            <nz-avatar [nzSrc]="this.userInfo?.avatarLink" nzIcon="user" [nzSize]="80" nzAlt="avatar" class="avatar"/>
          </nz-badge>

          <ng-template #isVerifiedIcon>
            <nz-avatar 
              [nzSrc]="'assets/icons/verified.svg'" 
              [nzSize]="20" 
              class="verified-icon"
              alt="verified" 
              [nz-tooltip]="'verification_description' | translate"
            />
          </ng-template>
        </div>
        <div class="teacher-name" nz-flex nzAlign="center" nzJustify="center" nzVertical [nzGap]="4">
          <span [class.no-data-text]="!getField(userInfo?.firstName)">
            {{getField(userInfo?.firstName) ?
            getField(userInfo?.firstName) : ('no_data_n' | translate) + '!'}}</span>
          <span [class.no-data-text]="!getField(userInfo?.lastName)">{{getField(userInfo?.lastName) ?
            getField(userInfo?.lastName) : 'no_data_n' | translate}}</span>
        </div>

        <div nz-flex nzAlign="center" nzJustify="center">
          <div 
            class="teacher-status" 
            [class.no-data-text]="!getField(userInfo?.status)" 
            [ngClass]="{
              trainee: userInfo?.status === 'trainee',
              regular: userInfo?.status === 'regular',
              resigns: userInfo?.status === 'resigns',
              resigned: userInfo?.status === 'resigned'
            }"
          >
            {{userInfo?.status | translate}}
          </div>
        </div>
      </div>
      <nz-divider />

      <div class="card-block" nz-flex nzAlign="center" nzJustify="center" nzVertical [nzGap]="8">
        <div *ngIf="getCountryName() as country">
          <span>{{country.name}}</span>
        </div>
        <div nz-flex nzAlign="center" nzJustify="center" [nzGap]="4">
          <ng-container *ngIf="userInfo?.dateOfBirth; else noDateOfBirth">
            <ng-container *ngIf="getDateWithAge(userInfo?.dateOfBirth) as dateAndAge">
              <span nz-icon nzType="calendar" nzTheme="twotone"></span>
              <span>{{dateAndAge.date}}</span>
              <span class="birthday-date-age">({{dateAndAge.age}} {{'years_short' | translate}})</span>
            </ng-container>
          </ng-container>
          <ng-template #noDateOfBirth>
            <span class="no-data-text">{{'no_info' | translate}}</span>
          </ng-template>
        </div>
      </div>
      <nz-divider />
      
      <div class="card-block" nz-flex nzVertical [nzGap]="8">
        <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" nzJustify="space-between">
          <div nz-col>
              <div nz-flex [nzGap]="8">
                <span nz-icon nzType="fund" nzTheme="twotone"></span>
                <div class="label">{{'conversion_value' | translate}}</div>
              </div>
          </div>
          <div nz-col>
            <div class="parameter-value" *ngIf="userInfo?.conversionValue !== -1"
              [ngClass]="{red: userInfo?.conversionValue < 30, yellow: userInfo?.conversionValue >=30 && userInfo?.conversionValue <=40, green: userInfo?.conversionValue > 40}">
              {{getPrecisionedNumber(userInfo?.conversionValue)}}%
            </div>
            
            <div class="parameter-value" *ngIf="userInfo?.conversionValue === -1">
              -
            </div>
          </div>
        </div>

        <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" nzJustify="space-between">
          <div nz-col>
            <div nz-flex [nzGap]="8">
              <span nz-icon nzType="project" nzTheme="twotone" nzRotate="180"></span>
              <div class="label">{{'load' | translate}}</div>
            </div>
          </div>
          <div nz-col>
            <div class="parameter-value" [class.no-data-text]="!getField(userInfo?.teacherWorkLoad)">
              {{ getField(userInfo?.teacherWorkLoad) ? (getField(userInfo?.teacherWorkLoad) + '%') : 'no_data_f' | translate }}
            </div>
          </div>
        </div>

        <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" nzJustify="space-between">
          <div nz-col>
            <div nz-flex [nzGap]="8">
              <span nz-icon nzType="team" nzTheme="outline" class="parameter-icon"></span>
              <div class="label">{{'number_of_active_students' | translate}}</div>
            </div>
          </div>
          <div nz-col>
            <div class="parameter-value" [class.no-data-text]="!getField(userInfo?.activeStudents)">
              {{getField(userInfo?.activeStudents) ? getField(userInfo?.activeStudents) : 'no_data_f' | translate}}
            </div>
          </div>
        </div>

        <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" nzJustify="space-between">
          <div nz-col>
            <div nz-flex [nzGap]="8">
              <span nz-icon nzType="carry-out" nzTheme="twotone"></span>
              <div class="label">{{'completed_lessons' | translate}}</div>
            </div>
          </div>
          <div nz-col>
            <div class="parameter-value" [class.no-data-text]="!getField(userInfo?.lessons)">
              {{ getField(userInfo?.lessons) ? getField(userInfo?.lessons) : 'no_data_n' | translate }}
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <div class="card-block" nz-flex nzVertical [nzGap]="8">
        <div class="contact-container">
          <div class="label">{{'e_mail' | translate}}:</div>
          <a class="email" [class.no-email]="!getField(userInfo?.email)" [href]="'mailto:'+ getField(userInfo?.email)"
            target="_blank">
            <span [class.no-data-text]="!getField(userInfo?.email)">{{getField(userInfo?.email) ?
              getField(userInfo?.email) : 'no_data_m' | translate}}</span>
          </a>
        </div>
        <div class="contact-container">
          <div class="label">{{'mobile_number' | translate}}:</div>
          <div [class.no-data-text]="!getField(userInfo?.phone)">{{getField(userInfo?.phone) ?
            convertPhoneNumberToValidView(userInfo?.phone) : 'no_data_m' | translate}}</div>
        </div>
      </div>
      
      <div class="card-block">
        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="handleChangeBtnClick()"
        >
          <span nz-icon nzType="edit" nzTheme="outline"></span>
          {{'edit' | translate}}
        </button>
      </div>
    </div>
</ng-template>

<ng-template #profileTab>
  <div nz-flex nzVertical [nzGap]="16" style="width: 100%;">
    <div class="card">
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <div nz-col [nzSpan]="24">
            <div nz-flex nzAlign="center" nzJustify="space-between">
              <div nz-flex nzAlign="center" nzJustify="center" [nzGap]="8">
                <span nz-icon nzType="file-text" nzTheme="twotone"></span>
                <span class="section-title">{{'work_specification' | translate}}</span>
              </div>
              <button nz-button nzType="text" (click)="handleChangeBtnClick(pagesNames.WORK_SPECIFICATION)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <!-- Tutor experience -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'tutor_experience' | translate}}:</div>
              <div class="info-content" [class.no-data-text]="!getField(userInfo?.tutoringExperience)">
                {{getField(userInfo?.tutoringExperience) ? (userInfo?.tutoringExperience | translate) : ('no_data_m' | translate)}}
              </div>
            </div>
          </div>

          <!-- Study experience -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'study_experience' | translate}}:</div>
              <div class="info-content" [class.no-data-text]="!getField(userInfo?.experience)">
                {{getField(userInfo?.experience) ? (userInfo?.experience | translate) : ('no_data_m' | translate)}}</div>
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Working grades -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'working_grades' | translate}}:</div>
              <div class="grades-container" *ngIf="userInfo?.workingClasses?.length; else noData">
                <div class="grades" *ngFor="let studentClass of userInfo.workingClasses">
                  {{ ('main.entity.student.class.' + studentClass) | translate}}
                </div>
              </div>
            </div>
          </div>

          <!-- Preparing to exams -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'preparing_to_exams' | translate}}:</div>
              <div *ngIf="userInfo?.examPreparation && userInfo?.examPreparation.length>0 else noData"
                style="display: flex; flex-wrap: wrap;">
                <div class="exams" *ngFor="let exam of userInfo?.examPreparation; index as i">
                  <div class="name color-{{i+1}}" [class.color-other]="exam.name === 'other'">{{exam.name | translate}}
                  </div>
                  <div class="exp color-{{i+1}}" *ngIf="exam.name !== 'other' else examPrepOther">{{exam.expirience}}
                    {{'years' | translate}}</div>
                  <ng-template #examPrepOther>
                    <div class="exp color-other">{{exam.expirience}}</div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <!-- Study programs -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'study_programs' | translate}}:</div>
              <div class="study-programs-container"
                *ngIf="(userInfo?.educationProgram && userInfo?.educationProgram.length>0) || (userInfo?.educationProgramOther && userInfo?.educationProgramOther.length>0) else noData">
                <div class="study-programs" *ngFor="let program of userInfo?.educationProgram">
                  {{program | translate}}
                </div>
                <div class="study-programs" *ngIf="userInfo?.educationProgramOther">
                  {{'other' | translate}} ({{userInfo?.educationProgramOther}})
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Study language -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'study_language' | translate}}:</div>
              <div *ngIf="userInfo?.lang else noData">
                <div *ngFor="let item of generateLanguages(userInfo?.lang)">
                  {{getLangName(item).name | translate}}
                </div>
                <span *ngIf="userInfo?.lang.includes('other')">{{'other' | translate}}({{userInfo?.langOther}})</span>
              </div>
            </div>
          </div>

          <!-- Additional subjects -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'additional_jubjects' | translate}}:</div>
              <div class="content-with-coma-container">
                <div *ngIf="userInfo?.additionalSubject && userInfo?.additionalSubject.length > 0 else noData">
                  <div class="content-with-coma" *ngFor="let subj of userInfo?.additionalSubject">
                    {{getAdditionalSubjectsKeys(subj) | translate}}
                    <div class="coma">,</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Study style -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'study_style' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.educationStyle">{{userInfo?.educationStyle ?
                (userInfo?.educationStyle | translate) : 'no_data_m' | translate}}
              </div>
            </div>
          </div>

          <!-- Depth study -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'depth_study' | translate}}:</div>
              <div nz-flex nzAlign="center" nzJustify="start" [nzGap]="4">
                @if (userInfo?.isDepthStudy) {
                  <span nz-icon nzType="check" nzTheme="outline" class="success-icon"></span>
                } @else {
                  <span nz-icon nzType="close" nzTheme="outline" class="error-icon"></span>
                }
                <span>
                  {{userInfo?.isDepthStudy ? ('yes' | translate) : ('no' | translate)}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nz-divider />

      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Special needs -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'special_needs' | translate}}:</div>
              <div class="content-with-coma-container">
                <div *ngIf="(userInfo?.childSpecialNeeds && userInfo?.childSpecialNeeds.length>0) else noData">
                  <div class="content-with-coma" *ngFor="let spec of userInfo?.childSpecialNeeds">
                    {{spec | translate}}
                    <div class="coma">,</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Tools for studing -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'tools_for_studing' | translate}}:</div>
              <div class="info-content">
                <div class="data-with-icons-container tools-for-studing"
                  *ngIf="(userInfo?.teacherInstruments && userInfo?.teacherInstruments.length) || (userInfo?.instrumentsOther && userInfo?.instrumentsOther.length > 0) else noData">
                  <div class="item" *ngFor="let item of userInfo?.teacherInstruments">
                    <span 
                      *ngIf="getInstrumentData(item).icon" 
                      nz-icon 
                      [nzType]="getInstrumentData(item).icon" 
                      nzTheme="outline" 
                      class="success-icon">
                    </span>
                    <span class="name">{{getInstrumentData(item).label | translate}}</span>
                  </div>
                  <div class="item" *ngIf="userInfo?.instrumentsOther">
                    <span nz-icon nzType="appstore" nzTheme="outline" class="success-icon"></span>
                    <span class="name">{{'other' | translate}} ({{userInfo?.instrumentsOther}})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Education -->
    <div class="card">
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Title -->
          <div nz-col [nzSpan]="24">
            <div nz-flex nzAlign="center" nzJustify="space-between">
              <div nz-flex [nzGap]="8">
                <span nz-icon nzType="safety-certificate" nzTheme="twotone"></span>
                <span class="section-title">{{'education' | translate}}</span>
              </div>
              <button nz-button nzType="text" (click)="handleChangeBtnClick(pagesNames.EDUCATION)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <!-- First education -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'education' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.education">
                {{userInfo?.education ? userInfo?.education : 'no_data_f' | translate}}
              </div>
            </div>
          </div>
          
          <!-- Second education -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'second_education' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.additionalEducation">
                {{userInfo?.additionalEducation ? userInfo?.additionalEducation : 'no_data_f' | translate}}
              </div>
            </div>
          </div>
          
          <!-- Diploma -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'teacher_dyploma' | translate}}:</div>
              <div class="boolean-container">
                <div nz-flex nzAlign="center" nzJustify="start" [nzGap]="4">
                  @if (userInfo?.diploma) {
                  <span nz-icon nzType="check" nzTheme="outline" class="success-icon"></span>
                  } @else {
                  <span nz-icon nzType="close" nzTheme="outline" class="error-icon"></span>
                  }
                  <span>
                    {{userInfo?.diploma ? ('yes' | translate) : ('no' | translate)}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Diploma link -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'load_dyploma' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.diplomaFileName" nz-flex [nzGap]="4">
                <span nz-icon nzType="paper-clip" nzTheme="outline" class="parameter-icon"></span>
                <a class="link" [href]="userInfo?.diplomaLink" *ngIf="userInfo?.diplomaLink else noData">
                  <span>{{userInfo?.diplomaFileName}}</span>
                </a>
              </div>
            </div>
          </div>
          
          <!-- Teacher qualification -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'teacher_qualification' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.qualification">{{userInfo?.qualification ?
                ((userInfo?.qualification | translate) + (userInfo?.qualificationOther ? ' (' +
                userInfo?.qualificationOther + ')' : '')) : ('no_data_f' | translate)}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Experience -->
    <div class="card">
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Title -->
          <div nz-col [nzSpan]="24">
            <div nz-flex nzAlign="center" nzJustify="space-between">
              <div nz-flex [nzGap]="8">
                <span nz-icon nzType="bank" nzTheme="twotone"></span>
                <span class="section-title">{{'experience' | translate}}</span>
              </div>
              <button nz-button nzType="text" (click)="handleChangeBtnClick(pagesNames.EXPERIENCE)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <!-- Current work place -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'current_work_place' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.currentWorkPlace">
                {{userInfo?.currentWorkPlace ? userInfo?.currentWorkPlace : 'no_data_n' | translate}}
              </div>
            </div>
          </div>

          <!-- Previous work places -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'previous_work_place' | translate}}:</div>
              <div class="content-with-coma-container">
                <div *ngIf="(userInfo?.previousWorkPlace && userInfo?.previousWorkPlace.length) else noData">
                  <div class="content-with-coma" *ngFor="let subj of userInfo?.previousWorkPlace">
                    {{subj | translate}}
                    <div class="coma">,</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="card">
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Title -->
          <div nz-col [nzSpan]="24">
            <div nz-flex nzAlign="center" nzJustify="space-between">
              <div nz-flex nzAlign="center" nzJustify="center" [nzGap]="8">
                <span nz-icon nzType="video-camera" nzTheme="twotone"></span>
                <span class="section-title">
                  {{'lesson_link' | translate}}
                </span>
              </div>
              <button nz-button nzType="text" (click)="handleChangeBtnClick(pagesNames.ZOOM)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <!-- Zoom link -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'link' | translate}}:</div>
              <ng-container *ngIf="userInfo?.linkToZoom else noData">
                <div nz-flex [nzGap]="4">
                  <span nz-typography nzCopyable [nzContent]="userInfo?.linkToZoom"></span>
                </div>
              </ng-container>
            </div>
          </div>

          <div nz-col *ngIf="isZoomLink(userInfo?.linkToZoom)">
            <div nz-flex nzVertical>
              <div class="label">{{'access_code' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.zoomCode">
                {{userInfo?.zoomCode ? userInfo?.zoomCode : 'no_data_m' | translate}}
              </div>
            </div>
          </div>

          <div nz-col *ngIf="isZoomLink(userInfo?.linkToZoom)">
            <div nz-flex nzVertical>
              <div class="label">{{'zoom_id' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.zoomIdentificator">
                {{userInfo?.zoomIdentificator ? userInfo?.zoomIdentificator : 'no_data_m' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Additional -->
    <div class="card">
      <div class="card-block">
        <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
          <!-- Title -->
          <div nz-col [nzSpan]="24">
            <div nz-flex nzAlign="center" nzJustify="space-between">
              <div nz-flex nzAlign="center" nzJustify="center" [nzGap]="8">
                <span nz-icon nzType="api" nzTheme="twotone"></span>
                <span class="section-title">
                  {{'additional' | translate}}
                </span>
              </div>
              <button nz-button nzType="text" (click)="handleChangeBtnClick(pagesNames.ADDITIONAL)">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <!-- Digital resources -->
          <div nz-col>
            <div nz-flex nzVertical>
              <div class="label">{{'digital_resources' | translate}}:</div>
              <div [class.no-data-text]="!userInfo?.description">
                {{userInfo?.description ? userInfo?.description : 'no_data_n' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noData>
  <span class="no-data-text">{{'no_data_n' | translate}}</span>
</ng-template>

<ng-template #indicatorsTab>
  <app-indicators 
    [obtData]="obtData" 
    [userLevels]="userLevels"
    [lessonsPassed]="userInfo?.lessons || 0" 
    [isTeacher]="!!(authService.user.teacherId)"
    (changeButtonClick)="clickIndicatorsChangeBtn()">
  </app-indicators>
</ng-template>