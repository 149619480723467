<form nz-form [formGroup]="form" nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzRequired nzFor="firstName">
      {{ 'auth.sign-up.first-name' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
      <input nz-input type="text" formControlName="firstName" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="lastName">
      {{ 'auth.sign-up.last-name' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
      <input nz-input type="text" formControlName="lastName">
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="email">
      {{ 'auth.sign-up.email' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="emailErrorTpl" [nzValidatingTip]="'main.form.validation.pending' | translate">
      <nz-input-group nzPrefixIcon="mail">
        <input nz-input type="email" formControlName="email">
      </nz-input-group>
      <ng-template #emailErrorTpl let-control>
        @if (control?.errors?.['required']) {
        {{'main.form.errors.required-tip' | translate}}<br />
        }
        @if (control?.errors?.['emailTaken']) {
        {{'main.form.errors.email-taken' | translate}}<br />
        }
        @if (control?.errors?.['email']) {
        {{'main.form.errors.email-not-valid' | translate}}<br />
        }
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="phone">
      {{ 'auth.sign-up.phone' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="phoneErrorTpl">
      <nz-input-group nzPrefixIcon="phone">
        <input nz-input type="number" formControlName="phone">
      </nz-input-group>
    </nz-form-control>

    <ng-template #phoneErrorTpl let-control>
      @if (control?.errors?.['required']) {
      {{'main.form.errors.required-tip' | translate}}<br />
      }
      @if (control?.errors?.['pattern']) {
      {{'main.form.errors.phone-not-valid' | translate}}<br />
      }
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="tutoringExperience">
      {{ 'auth.sign-up.tutoring-experience' | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-select formControlName="tutoringExperience">
        @for (option of getTutoringExperienceOptions(); track option.value) {
        <nz-option [nzValue]="option.value" [nzLabel]="option.label | translate"></nz-option>
        }
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item class="padding-top">
    <label nz-checkbox formControlName="canTeachMathematics">
      <span>{{ 'auth.sign-up.can-teach-mathematics' | translate }}</span>
    </label>
  </nz-form-item>

  <nz-form-item class="padding-top">
    <nz-form-label nzRequired nzFor="password">
      {{ 'auth.sign-up.password' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="passwordErrorTpl">
      <nz-input-group nzPrefixIcon="lock" [nzSuffix]="passwordSuffix">
        <input nz-input [type]="passwordVisible() ? 'text' : 'password'" formControlName="password">
      </nz-input-group>
      <ng-template #passwordErrorTpl let-control>
        @if (control?.errors?.['required']) {
        {{'main.form.errors.required-tip' | translate}}<br />
        }
        @if (control?.errors?.['minlength']) {
        {{'main.form.errors.min-length' | translate: { value: 8 } }}<br />
        }
      </ng-template>
    </nz-form-control>

    <ng-template #passwordSuffix>
      <span nz-icon class="ant-input-password-icon" [nzType]="passwordVisible() ? 'eye-invisible' : 'eye'"
        (click)="passwordVisible.set(!passwordVisible())"></span>
    </ng-template>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired nzFor="passwordConfirm">
      {{ 'auth.sign-up.password-confirm' | translate }}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="passwordConfirmErrorTpl">
      <nz-input-group nzPrefixIcon="lock" [nzSuffix]="passwordConfirmSuffix">
        <input nz-input [type]="passwordConfirmVisible() ? 'text' : 'password'" formControlName="passwordConfirm">
      </nz-input-group>
      <ng-template #passwordConfirmErrorTpl let-control>
        @if (control?.errors?.['required']) {
        {{'main.form.errors.required-tip' | translate}}<br />
        }
        @if (control?.errors?.['minlength']) {
        {{'main.form.errors.min-length' | translate: { value: 8 } }}<br />
        }
        @if (control?.errors?.['passwordsMismatch']) {
        {{'main.form.errors.password-mismatch' | translate: { value: 8 } }}<br />
        }
      </ng-template>
    </nz-form-control>

    <ng-template #passwordConfirmSuffix>
      <span nz-icon class="ant-input-password-icon" [nzType]="passwordConfirmVisible() ? 'eye-invisible' : 'eye'"
        (click)="passwordConfirmVisible.set(!passwordConfirmVisible())"></span>
    </ng-template>
  </nz-form-item>

  <div class="padding-top">
    @let isPoland = form.get('marketRegion')?.value === marketRegion.POLAND;
    @if (isPoland) {
    <nz-form-item>
      <label nz-checkbox formControlName="termsAndConditions">
        <span>
          {{ 'auth.sign-up.terms-and-conditions' | translate }}
        </span>
      </label>
      <a [href]="termsAndConditionsLink" target="_blank">
        {{ 'auth.sign-up.terms-and-conditions-link' | translate }}
      </a>
    </nz-form-item>
    }

    <nz-form-item>
      <label nz-checkbox formControlName="signUpToNewsletter">
        <span>{{ 'auth.sign-up.sign-up-to-newsletter' | translate }}</span>
      </label>
    </nz-form-item>
  </div>

  <div nz-flex [nzGap]="12" nzVertical class="padding-top">
    @let isFormValid = form.valid && form.dirty;
    @let isNewsletterChecked = form.get('signUpToNewsletter')?.value;
    @let isTermsAndConditionsChecked = isPoland ? form.get('termsAndConditions')?.value : true;
    @let canTeachMathematics = form.get('canTeachMathematics')?.value;
    @let isDisabled = !isFormValid || !isNewsletterChecked || !isTermsAndConditionsChecked || !canTeachMathematics;
    <button nz-button nzBlock nzType="primary" (click)="submit()" [disabled]="isDisabled" [nzLoading]="isSubmitLoading()">
      {{ 'auth.sign-up.confirm' | translate }}
    </button>
  
    <div nz-flex nzJustify="center" nzAlign="center" [nzGap]="4">
      <span>{{ 'auth.sign-up.already-signed-up' | translate }}</span>
      <a [routerLink]="['/', loginLink]">
        {{ 'auth.sign-up.to-sign-in' | translate }}
      </a>
    </div>
  </div>
</form>


