<div [ngSwitch]="inputType">
  <label
    class="name"
    [class.no-wrap]="noWrapOpt"
    *ngIf="!hideLabel"
    [style.width]="customInputParams?.customWidth ? customInputParams.customWidth : ''"
  >
    {{label ? label + ':' : ''}}
  </label>

  <input
    [ngStyle]="{'margin-top': hideLabel ? '-3px' : ''}"
    [style.width]="customInputParams?.customWidth ? customInputParams.customWidth : ''"
    [style.height]="customInputParams?.customHeight ? customInputParams.customHeight : ''"
    [class.error]="!!error"
    class="textInput"
    *ngSwitchCase="'text'"
    type="text"
    [placeholder]="placeholder ? placeholder : ''"
    [(ngModel)]="inputValue"
    (ngModelChange)="handleInputChange()"
    (focus)="handleInputFocus(); onFocus()"
    [disabled]="isDisabled"
  >
  <input
    [ngStyle]="{
      'pointer-events': 'none',
       'margin-top': hideLabel ? '-3px' : ''
     }"
    [style.width]="customInputParams?.customWidth ? customInputParams.customWidth : ''"
    [style.height]="customInputParams?.customHeight ? customInputParams.customHeight : ''"
    [class.error]="!!error"
    class="textInput"
    *ngSwitchCase="'display-field'"
    type="text"
    [placeholder]="placeholder ? placeholder : ''"
    [(ngModel)]="inputValue"
    (ngModelChange)="handleInputChange()"
    (focus)="handleInputFocus(); onFocus()"
    [disabled]="isDisabled"
  >

  <div *ngSwitchCase="'number'"
       class="number"
       [ngStyle]="{'margin-top': hideLabel ? '-3px' : ''}">
    <input type="number"
           [style.width]="customInputParams?.customWidth ? customInputParams.customWidth : ''"
           [style.height]="customInputParams?.customHeight ? customInputParams.customHeight : ''"
           [class.error]="!!error"
           [class.hide-arrows]="!!hideArrows"
           class="textInput"
           [placeholder]="placeholder ? placeholder : ''"
           [(ngModel)]="inputValue"
           (ngModelChange)="handleInputChange()"
           (focus)="handleInputFocus(); onFocus()"
           [min]="min"
           [max]="max"
           [step]="step"
           [disabled]="isDisabled">
    <div class="title" *ngIf="title">{{title}}</div>
  </div>

  <textarea
    *ngSwitchCase="'textArea'"
    [style.width]="customInputParams?.customWidth ? customInputParams.customWidth : ''"
    [style.height]="customInputParams?.customHeight ? customInputParams.customHeight : ''"
    [style.resize]="'none'"
    [class.error]="error"
    rows="6"
    class="textInput"
    [placeholder]="placeholder ? placeholder : ''"
    [(ngModel)]="inputValue"
    (ngModelChange)="handleInputChange()"
    (focus)="onFocus()"
    [disabled]="disabled"></textarea>

  <div class="dropdown"
       [ngStyle]="{ width: customInputParams?.customWidth }"
       [class.error]="!!error"
       [class.focused]="dropdownState"
       [class.disabled]="isDisabled"
       *ngSwitchCase="'dropdown'"
       (outsideClick)="toggleDropdownState(false)">
    <div class="clickable"
         (click)="toggleDropdownState(!dropdownState)">
      <div class="icon"
           *ngIf="dropdownSelectedValue.iconUrl">
        <span class="fi fi-{{dropdownSelectedValue.iconUrl}}"></span>
      </div>

      <span class="placeholder">
        {{dropdownSelectedValue.label | translate}}
      </span>

      <div class="open-indicator"
           [class.expanded]="dropdownState">
        <mat-icon>expand_more</mat-icon>
      </div>
    </div>

    <div class="dropdown-list"
         [style.max-height]="dropdownState ? (((dropdownParams?.length ? dropdownParams.length : 0) * 38)+'px') : '0'">
      <div class="dropdown-list-item"
           *ngFor="let param of dropdownParams"
           [class.selected]="param.id === dropdownSelectedValue.id"
           (click)="handleDropdownSelectValue(param)">
        <div class="name-container">
          <div class="icon"
               *ngIf="param.iconUrl">
            <span class="fi fi-{{param.iconUrl}}"></span>
          </div>

          <span class="label">
            {{param.label | translate}}
          </span>
        </div>

        <mat-icon *ngIf="param.id === dropdownSelectedValue.id">
          done
        </mat-icon>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'countries-dropdown'"
       class="dropdown countries"
       [class.error]="!!error"
       [class.focused]="dropdownState"
       [class.disabled]="isDisabled"
       (outsideClick)="toggleDropdownState(false)">
    <div class="clickable"
         (click)="toggleDropdownState(!dropdownState)">
      <div class="icon"
           *ngIf="dropdownSelectedValue.id">
        <span class="fi fi-{{dropdownSelectedValue.id}}"></span>
      </div>

      <span class="placeholder">
        {{dropdownSelectedValue.label | translate}}
      </span>

      <div class="open-indicator"
           [class.expanded]="dropdownState">
        <mat-icon>expand_more</mat-icon>
      </div>
    </div>

    <div class="dropdown-list"
         [style.max-height]="dropdownState ? expandDropdown ? '516px' : '254px' : '0px'">
      <div class="dropdown-list-item search-container">
        <input class="search"
               type="text"
               [(ngModel)]="searchText"
               (ngModelChange)="filterData()"
               [placeholder]="placeholder ? placeholder : ''">

        <mat-icon>search</mat-icon>
      </div>

      <div class="dropdown-list-item"
           *ngFor="let param of filteredDropdownData"
           [class.selected]="param.id === dropdownSelectedValue.id"
           (click)="handleDropdownSelectValue(param)">
        <div class="name-container">
          <span class="fi fi-{{param.id}}"></span>

          <span class="label">
            {{param.label | translate}}
          </span>
        </div>

        <mat-icon *ngIf="param.id === dropdownSelectedValue.id">
          done
        </mat-icon>
      </div>

      <div class="dropdown-list-item expand-more"
           *ngIf="filteredDropdownData.length > 5"
           (click)="expandDropdown = !expandDropdown">
        {{expandDropdown ? ('show_less' | translate) : ('show_more' | translate)}}
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'multiselect-square'"
       [class.error]="error"
       class="square-buttons-container">
    <button *ngFor="let item of multiselectData"
            [class.selected]="item.selected"
            class="multiselect-item"
            (click)="multiselectItemSelect(item.id)">
      {{item.label | translate}}
    </button>
  </div>

  <div *ngSwitchCase="'multiselect-auto-size'"
       [class.error]="error"
       [class.no-left-border]="hideLabel"
       class="auto-size-buttons-container">

    <button *ngFor="let item of multiselectData"
            [class.selected]="item.selected"
            class="multiselect-item"
            (click)="multiselectItemSelect(item.id)">
      <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon>
        {{item.label | translate}}
    </button>
  </div>

  <span class="error" [ngStyle]="{'max-width': customInputParams?.customWidth}">
    {{errorText ? errorText : ''}}
  </span>
</div>
