<input style="display: none" type="file" (change)="handlePhotoInputChange($event)" [accept]="photoAcceptanceFiles"
  [multiple]="false" #photo>

<nz-row [nzGutter]="[16, 16]">
  <nz-col [nzSpan]="24">
    <div nz-flex [nzGap]="12">
      <nz-avatar [nzSrc]="data.avatarLink" nzIcon="user" [nzSize]="80" nzAlt="avatar" class="avatar" />
      <div nz-flex [nzGap]="12" nzVertical nzJustify="center">
        <button nz-button nzType="default" nzSize="small" (click)="uploadNewPhoto()">
          <span nz-icon nzType="upload" nzTheme="outline"></span>
          {{ 'add_photo' | translate }}
        </button>
        <button nz-button nzDanger nzType="default" nzSize="small" (click)="removePhoto()" [disabled]="!data.avatarLink">
          <span nz-icon nzType="delete" nzTheme="outline"></span>
          {{ 'remove_photo' | translate }}
        </button>
      </div>
    </div>
  </nz-col>

  <nz-col [nzSpan]="24">
    <div class="label">{{'email' | translate}}</div>
    <div>{{getUserEmail()}}</div>
  </nz-col>

  <nz-col [nzSpan]="24">
    <button nz-button nzType="default" (click)="onChangePassword()">
      {{ 'changePassword' | translate }}
    </button>
  </nz-col>
</nz-row>