<input style="display: none" type="file" (change)="handlePhotoInputChange($event)" [accept]="photoAcceptanceFiles" [multiple]="false" #photo>
<input style="display: none" type="file" (change)="handleFileInputChange($event)" [accept]="fileAcceptanceFiles" [multiple]="false" #file>

<ng-template mathHeaderTitle>
  {{'profile_changing' | translate}}
</ng-template>

<ng-template mathHeaderBackButton>
  <button (click)="handleBackClick()"></button>
</ng-template>

@let columnHorizontalGutter = 32;
@let columnVerticalGutter = 12;

<ng-container>
  <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]">
    <!-- Menu -->
    <div nz-col [nzXs]="24" [nzMd]="6">
      <div class="card">
        <div class="card-block">
          <ul nz-menu nzMode="vertical" [nzTheme]="'light'" [nzSelectable]="false" class="menu-list">
            @for (page of pagesInfo; track page.label) {
              <li nz-menu-item nzMatchRouter>
                @let teacherQueryParams = {page: page.label};
                @let queryParams = {teacherId: userInfo?.id, page: page.label};

                <a 
                  [routerLink]="['/', routes.PROFILE_CREATION]" 
                  [queryParams]="authService.isTeacher() ? teacherQueryParams : queryParams"
                >
                  <span nz-icon [nzType]="page.icon"></span>
                  <span>{{page.label | translate}}</span>
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>

    <!-- Pages -->
    <div nz-col [nzXs]="24" [nzMd]="18">
      <div class="card">
        <div class="card-block">
          <div nz-flex nzVertical [nzGap]="columnVerticalGutter">
            <div class="header">{{selectedPage | translate}}</div>

            <!-- Page -->
            @switch (selectedPage) {
              @case (pagesNames.PERSONAL_INFO) {
                <ng-container *ngTemplateOutlet="personalInfoPage"></ng-container>
              }
              @case (pagesNames.WORK_SPECIFICATION) {
                <ng-container *ngTemplateOutlet="workSpecificationPage"></ng-container>
              }
              @case (pagesNames.EDUCATION) {
                <ng-container *ngTemplateOutlet="educationPage"></ng-container>
              }
              @case (pagesNames.EXPERIENCE) {
                <ng-container *ngTemplateOutlet="experiencePage"></ng-container>
              }
              @case (pagesNames.ZOOM) {
                <ng-container *ngTemplateOutlet="zoomPage"></ng-container>
              }
              @case (pagesNames.ADDITIONAL) {
                <ng-container *ngTemplateOutlet="additionalPage"></ng-container>
              }
            }
          </div>
        </div>
        <nz-divider />

        <!-- Footer -->
        <div class="card-block">
          <div nz-flex nzJustify="end" [nzGap]="columnVerticalGutter">
              <button nz-button nzType="default" (click)="handleBackClick()">
                {{'cancel' | translate}}
              </button>

              @if (selectedPage!== 'personal_info' && isNewUserState) {
                <button nz-button nzType="default" (click)="handlePreviousButtonClick()">
                  {{'back' | translate}}
                </button>
              }
          
              @if (selectedPage!== 'additional' && isNewUserState) {
                <button nz-button nzType="primary" (click)="handleNextButtonClick()">
                  {{'next' | translate}}
                </button>
              }
          
              @if (!isNewUserState) {
                <button nz-button nzType="primary" (click)="handleSaveButtonClick()" [disabled]="isSaving">
                  {{'save' | translate}}
                </button>
              }

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #personalInfoPage>
  <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" [nzAlign]="'middle'">
    <div nz-col>
      <nz-avatar [nzSrc]="this.userInfo?.avatarLink" nzIcon="user" [nzSize]="80" nzAlt="avatar" class="avatar" />
    </div>
    <div nz-col>
      <div nz-flex nzVertical [nzGap]="columnVerticalGutter">
        <button (click)="loadPhoto()" nz-button nzType="default" nzSize="small">
          {{'add_photo' | translate}}
        </button>
        <button (click)="removePhoto()" nz-button nzType="default" nzDanger nzSize="small">
          {{'remove_photo' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
    <div nz-col>
      <app-input inputType="text" [label]="'name' | translate" [formControl]="firstName"
        [error]="firstName.invalid && firstName.touched" [errorText]="getErrorText('firstName')"></app-input>
    </div>
    <div nz-col>
    <app-input inputType="text" [label]="'surname' | translate" [formControl]="lastName"
      [error]="lastName.invalid && lastName.touched" [errorText]="getErrorText('lastName')"></app-input>
    </div>
    <div nz-col>
      <app-input inputType="dropdown" [label]="'gender' | translate" [dropdownParams]="genderArr"
        [error]="!gender && tappedFields.includes('gender')" (click)="onFieldsFocus('gender')" [value]="gender"
        [errorText]="getErrorText('gender')" (dropdownSelectValue)="handleGenderSelect($event)"></app-input>
    </div>
    <div nz-col>
      <app-input inputType="countries-dropdown" [label]="'country' | translate" [dropdownParams]="countryArr"
        [placeholder]="('country-search' | translate) + '...'" [error]="!country && tappedFields.includes('country')"
        (click)="onFieldsFocus('country')" [value]="country" [errorText]="getErrorText('country')"
        (dropdownSelectValue)="handleCountrySelect($event)"></app-input>
    </div>
    <div nz-col>
      <app-input inputType="text" [formControl]="birthDate" additionalParams="date" placeholder="MM.DD.YYYY"
        [error]="birthDate.invalid && birthDate.touched" [errorText]="getErrorText('dateOfBirth')"
        [label]="'birth_date' | translate"></app-input>
    </div>
    <div nz-col *ngIf="!authService.isTeacher()">
      <app-input  inputType="dropdown" [error]="!status && tappedFields.includes('status')"
        (click)="onFieldsFocus('status')" [dropdownParams]="statuses" [value]="status" [errorText]="getErrorText('status')"
        (dropdownSelectValue)="handleStatusSelect($event)" [label]="'status' | translate"></app-input>
    </div>
    <div nz-col *ngIf="!authService.isTeacher()">
      <app-input inputType="text" [label]="'email' | translate"
        [formControl]="email" [error]="email.invalid && email.touched"
        [errorText]="getErrorText('email')" ></app-input>
    </div>
    <div nz-col>
      <app-input inputType="text" [label]="'mobile_number' | translate" additionalParams="phone-number"
        placeholder="+(XXX) XXX XXX XXX" [formControl]="phoneNumber" [error]="phoneNumber.invalid && phoneNumber.touched"
        [errorText]="getErrorText('phoneNumber')"></app-input>
    </div>
  </div>
</ng-template>

<ng-template #workSpecificationPage>
  <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
    <div nz-col>
      <app-input inputType="dropdown" [error]="!tutorExperience && tappedFields.includes('tutorExperience')"
        (click)="onFieldsFocus('tutorExperience')" [dropdownParams]="experienceArr" [value]="tutorExperience"
        (dropdownSelectValue)="handleTutorExperienceSelect($event)" [errorText]="getErrorText('tutorExperience')"
        [label]="'tutor_experience' | translate"></app-input>
    </div>

    <div nz-col>
      <app-input inputType="dropdown" [error]="!experience && tappedFields.includes('experience')"
        (click)="onFieldsFocus('experience')" [dropdownParams]="experienceStudyArr" [value]="experience"
        (dropdownSelectValue)="handleStudyExperienceSelect($event)" [errorText]="getErrorText('experience')"
        [label]="'study_experience' | translate"></app-input>
    </div>

    <div nz-col>
      <app-input inputType="multiselect-square"
        [error]="handleMultiselectError('workingGradesArr').errorState && tappedFields.includes('working_grades')"
        (click)="onFieldsFocus('working_grades')" [errorText]="handleMultiselectError('workingGradesArr').errorText"
        [label]="'working_grades' | translate" [multiselectData]="workingGradesArr"
        [language]="getUserLangByRegion(userInfo?.marketRegion)"
        (multiselectDataOutput)="multiselectValueChange($event, 'grades')"></app-input>
    </div>

    <div nz-col>
      <div class="container-with-label">
        <label>{{'preparing_to_exams' | translate}}:</label>
        <div class="content exams">
          <div class="input-combo">
            <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox"
                [(ngModel)]="zno" [disabled]="!isExamPrepAvailable()"></div>
            <div class="input-title">{{'zno' | translate}}</div>
            <app-input *ngIf="zno" [hideLabel]="true" [isDisabled]="!zno" [formControl]="znoValue"
              [error]="znoValue.invalid && znoValue.touched" [errorText]="getErrorText('znoValue')" [inputType]="'number'"
              [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="dpa">
              <div class="border" (click)="dpa = !dpa"></div>
            </div>
            <div class="input-title">{{'dpa' | translate}}</div>
            <app-input *ngIf="dpa" [hideLabel]="true" [isDisabled]="!dpa" [formControl]="dpaValue"
              [error]="dpaValue.invalid && dpaValue.touched" [errorText]="getErrorText('dpaValue')" [inputType]="'number'"
              [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo"
            *ngIf="userInfo?.marketRegion === MarketRegion.UKRAINE && (authService.isManager() || authService.isAdmin())">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="nmt">
              <div class="border" (click)="nmt = !nmt"></div>
            </div>
            <div class="input-title">{{'nmt' | translate}}</div>
            <app-input *ngIf="nmt" [hideLabel]="true" [isDisabled]="!nmt" [formControl]="nmtValue"
              [error]="nmtValue.invalid && nmtValue.touched" [errorText]="getErrorText('nmtValue')" [inputType]="'number'"
              [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo" *ngIf="userInfo?.marketRegion === MarketRegion.POLAND">
            <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox"
                [(ngModel)]="polExam" [disabled]="!isExamPrepAvailable()"></div>
            <div class="input-title">{{'polExam' | translate}}</div>
            <app-input *ngIf="polExam" [hideLabel]="true" [isDisabled]="!polExam" [formControl]="polExamValue"
              [error]="polExamValue.invalid && polExamValue.touched" [errorText]="getErrorText('polExamValue')"
              [inputType]="'number'" [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo" *ngIf="userInfo?.marketRegion === MarketRegion.POLAND">
            <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox"
                [(ngModel)]="polInternational" [disabled]="!isExamPrepAvailable()"></div>
            <div class="input-title">{{'polInternational' | translate}}</div>
            <app-input *ngIf="polInternational" [hideLabel]="true" [isDisabled]="!polInternational"
              [formControl]="polInternationalValue" [error]="polInternationalValue.invalid && polInternationalValue.touched"
              [errorText]="getErrorText('polInternationalValue')" [inputType]="'number'" [min]="1"
              [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo" *ngIf="userInfo?.marketRegion === MarketRegion.UNITED_KINGDOM">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="ks2">
              <div class="border" (click)="ks2 = !ks2"></div>
            </div>
            <div class="input-title">{{'ks2' | translate}}</div>
            <app-input *ngIf="ks2" [hideLabel]="true" [isDisabled]="!ks2" [formControl]="ks2Value"
              [error]="ks2Value.invalid && ks2Value.touched" [errorText]="getErrorText('ks2')" [inputType]="'number'"
              [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo" *ngIf="userInfo?.marketRegion === MarketRegion.UNITED_KINGDOM">
            <div class="checkbox" nz-tooltip [nzTooltipTitle]="'exam_prep_restriction' | translate"><input type="checkbox"
                [(ngModel)]="gcse_foundation" [disabled]="!isExamPrepAvailable()"></div>
            <div class="input-title">{{'gcse_foundation' | translate}}</div>
            <app-input *ngIf="gcse_foundation" [hideLabel]="true" [isDisabled]="!gcse_foundation"
              [formControl]="gcse_foundationValue" [error]="gcse_foundationValue.invalid && gcse_foundationValue.touched"
              [errorText]="getErrorText('gcse_foundation')" [inputType]="'number'" [min]="1"
              [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo" *ngIf="userInfo?.marketRegion === MarketRegion.UNITED_KINGDOM">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="ib_math">
              <div class="border" (click)="ib_math = !ib_math"></div>
            </div>
            <div class="input-title">{{'ib_math' | translate}}</div>
            <app-input *ngIf="ib_math" [hideLabel]="true" [isDisabled]="!ib_math" [formControl]="ib_mathValue"
              [error]="ib_mathValue.invalid && ib_mathValue.touched" [errorText]="getErrorText('ib_math')"
              [inputType]="'number'" [min]="1" [title]="'years_of_experience' | translate">
            </app-input>
          </div>
          <div class="input-combo">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="examsOther">
              <div class="border" (click)="examsOther = !examsOther"></div>
            </div>
            <div class="input-title">{{'other' | translate}}</div>
            <app-input [hideLabel]="true" *ngIf="examsOther" [inputType]="'text'"
              [error]="examsOtherValue.invalid && examsOtherValue.touched" [errorText]="getErrorText('examsOtherValue')"
              [formControl]="examsOtherValue" [placeholder]="'explanation_to_other' | translate">
            </app-input>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <div nz-flex [nzGap]="4" nzWrap="wrap">
        <app-input inputType="multiselect-auto-size" [label]="'study_programs' | translate" [multiselectData]="studyProgramsArr"
          [language]="getUserLangByRegion(userInfo?.marketRegion)"
          (multiselectDataOutput)="multiselectValueChange($event, 'studyPrograms')"></app-input>
        <app-input inputType="multiselect-auto-size" [multiselectData]="studyProgramsOther"
          (multiselectDataOutput)="multiselectValueChange($event, 'studyProgramsOther')"></app-input>
        <app-input inputType="text" *ngIf="studyProgramsOther[0].selected" [inputType]="'text'"
          [error]="studyProgramsOtherValue.invalid && studyProgramsOtherValue.touched" [formControl]="studyProgramsOtherValue"
          [errorText]="getErrorText('studyProgramsOtherValue')" [placeholder]="'explanation_to_other' | translate"></app-input>
      </div>
    </div>

    <div nz-col>
      <div class="container-with-label">
        <label>{{'study_language' | translate}}</label>
        <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]">
          <div nz-col *ngFor="let item of languageArr">
            <div nz-flex [nzGap]="4" nzAlign="center">
              <div class="checkbox"><input type="checkbox" [(ngModel)]="item.selected"> </div>
              <div class="language-label">{{item.label | translate}}</div>
            </div>
          </div>
          <div nz-col>
            <app-input [hideLabel]="true" style="margin-top: 0" *ngIf="isOtherStudyLanguageSelected()" inputType="text"
              [error]="langOther.invalid && langOther.touched" [formControl]="langOther" [errorText]="getErrorText('langOther')">
            </app-input>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <app-input inputType="dropdown" (click)="onFieldsFocus('studyStyle')" [value]="studyStyle"
        [dropdownParams]="studyStyleArr" (dropdownSelectValue)="handleStudyStyleSelect($event)"
        [label]="'study_style' | translate"></app-input>
    </div>

    <div nz-col>
      <div class="container-with-label">
        <label>{{'additional_jubjects' | translate}}:</label>
        <div class="content checkboxes">
          <div class="input-combo">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="physics">
              <div class="border" (click)="physics = !physics"></div>
            </div>
            <div class="input-title">{{'phisics' | translate}}</div>
          </div>
          <div class="input-combo">
            <div class="checkbox"><input type="checkbox" [(ngModel)]="informatics">
              <div class="border" (click)="informatics = !informatics"></div>
            </div>
            <div class="input-title">{{'informatics' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    
    <div nz-col>
      <div class="container-with-label">
        <label>{{'depth_study' | translate}}:</label>
        <div class="content checkboxes">
          <div class="input-combo">
            <div class="radio" nz-tooltip [nzTooltipTitle]="'deep_study_restriction' | translate"><input type="radio"
                name="depth_study" [value]="true" [(ngModel)]="depthStudy" [disabled]="!isDeepStudyAvailable()">
            </div>
            <div class="input-title">{{'yes' | translate}}</div>
          </div>
          <div class="input-combo">
            <div class="radio"><input type="radio" name="depth_study" [value]="false" [(ngModel)]="depthStudy"
                [disabled]="!isDeepStudyAvailable()">
            </div>
            <div class="input-title">{{'no' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <div class="container-with-label">
        <label>{{'special_needs' | translate}}:</label>
        <div class="content checkboxes">
          <div nz-row [nzGutter]="[columnVerticalGutter, columnVerticalGutter]" nz-tooltip [nzTooltipTitle]="'curators_to_change' | translate"
            [nzTooltipTrigger]="authService.isTeacher() ? 'hover' : null">
            <div nz-col *ngFor="let item of specialNeeds">
              <div nz-flex nzWrap="wrap" [nzGap]="4">
                <div class="checkbox">
                  <input type="checkbox" [(ngModel)]="item.state" [disabled]="authService.isTeacher()">
                </div>
                <div class="input-title">{{item.label | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <div class="label">{{'tools_for_studing' | translate}}:</div>
      <div class="row">
        <app-input 
          inputType="multiselect-square"
          [error]="handleMultiselectError('toolsForStuding').errorState && tappedFields.includes('tools_for_studing')"
          (click)="onFieldsFocus('tools_for_studing')" 
          [errorText]="handleMultiselectError('toolsForStuding').errorText"
          [multiselectData]="toolsForStuding"
          [hideLabel]="true"
          (multiselectDataOutput)="multiselectValueChange($event, 'toolsForStuding')">
        </app-input>
        <app-input inputType="multiselect-square"
          [hideLabel]="true"
          [error]="handleMultiselectError('toolsForStuding').errorState && tappedFields.includes('tools_for_studing')"
          (click)="onFieldsFocus('tools_for_studing')" [multiselectData]="toolsForStudingOther"
          (multiselectDataOutput)="multiselectValueChange($event, 'toolsForStudingOther')"></app-input>
        <app-input 
          [hideLabel]="true"
          inputType="text" *ngIf="toolsForStudingOther[0].selected" 
          [inputType]="'text'"
          [error]="toolsForStudingOtherValue.invalid && toolsForStudingOtherValue.touched"
          [formControl]="toolsForStudingOtherValue" [errorText]="getErrorText('toolsForStudingOtherValue')"
          [placeholder]="'tape_recorder' | translate"></app-input>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #educationPage>
  <div nz-row [nzGutter]="[columnHorizontalGutter, 20]">
    <div nz-col [nzSpan]="24">
      <app-input inputType="text" [formControl]="education" [error]="education.invalid && education.touched"
        [errorText]="getErrorText('education')" [label]="'education' | translate"
        [customInputParams]="{customWidth: '100%'}"></app-input>
    </div>

    <div nz-col [nzSpan]="24">
      <app-input inputType="text" [formControl]="secondEducation" [error]="secondEducation.invalid && secondEducation.touched"
        [errorText]="getErrorText('secondEducation')" [label]="'second_education' | translate"
        [customInputParams]="{customWidth: '100%'}"></app-input>
    </div>

    <div nz-col>
      <div class="container-with-label" style="width: 157px">
        <label>{{'teacher_dyploma' | translate}}:</label>
        <div class="content checkboxes">
          <div class="input-combo" style="margin-right: 16px">
            <div class="radio">
              <input type="radio" name="teacher_dyploma" [value]="true" [(ngModel)]="teachersDiploma"
                [disabled]="authService.isTeacher()">
              <div class="border" (click)="onDiplomaChanges(true)"></div>
            </div>
            <div class="input-title">{{'yes' | translate}}</div>
          </div>
          <div class="input-combo">
            <div class="radio">
              <input type="radio" name="teacher_dyploma" [value]="false" [(ngModel)]="teachersDiploma"
                [disabled]="authService.isTeacher()">
              <div class="border" (click)="onDiplomaChanges(false)"></div>
            </div>
            <div class="input-title">{{'no' | translate}}</div>
          </div>
        </div>
      </div>
    </div>

    <div nz-col>
      <div class="container-with-label">
        <label>{{'load_dyploma' | translate}}:</label>
        <!-- <div class="content checkboxes"> -->
          <div nz-flex nzAlign="center" [nzGap]="4" *ngIf="userInfo?.diplomaFileName">
            <span nz-icon nzType="paper-clip" nzTheme="outline"></span>
            <span class="file-name">
              <a 
                [href]="userInfo?.diplomaLink"
                target="_blank"
              >
                {{userInfo?.diplomaFileName}}
              </a>
            </span> 
            <button nz-button nzType="link" nzSize="small" nzDanger (click)="removeDiplomaFile()">
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </div>
        <!-- </div> -->
      </div>
      <div class="button-container">
        <button nz-button nzType="default" nzSize="small" (click)="loadFile()">
          <span nz-icon nzType="upload" nzTheme="outline"></span>
          {{'load_file' | translate}}
        </button>
      </div>
    </div>

    <div nz-col>
      <app-input inputType="dropdown" [error]="!teacherQualification && tappedFields.includes('qualification')"
        (click)="onFieldsFocus('qualification')" [value]="teacherQualification"
        [language]="getUserLangByRegion(userInfo?.marketRegion)" [dropdownParams]="teacherQualificationArr"
        (dropdownSelectValue)="handleQualificationSelect($event)" [errorText]="getErrorText('qualification')"
        [label]="'teacher_qualification' | translate"></app-input>

    </div>

    <div nz-col>
      <app-input *ngIf="teacherQualification === 'other'" inputType="text" [formControl]="qualificationOther"
        [value]="qualificationOther.value" [error]="qualificationOther.invalid && qualificationOther.touched"
        [errorText]="getErrorText('qualificationOther')" [label]="'other' | translate"></app-input>
    </div>
  </div>
</ng-template>

<ng-template #experiencePage>
  <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
    <div nz-col [nzSpan]="24">
      <app-input inputType="text" [formControl]="workPlace" [value]="workPlace.value"
        [error]="workPlace.invalid && workPlace.touched" [errorText]="getErrorText('workplace')"
        [label]="'current_work_place' | translate" [customInputParams]="{customWidth: '100%'}"></app-input>
    </div>

    @for (item of previousWorkplacesArr; track item.index; let i = $index) {
    <div nz-col [nzSpan]="24">
      <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
        <div nz-col [nzSpan]="19">
          <app-input [error]="previousWorkplacesControl[i].invalid && previousWorkplacesControl[i].touched"
            [formControl]="previousWorkplacesControl[i]" [errorText]="getErrorText('additionalWorkPlaces', i)"
            [value]="previousWorkplacesControl[i].value" inputType="text" [label]="'previous_work_place' | translate"
            [customInputParams]="{customWidth: '100%'}"></app-input>
        </div>
        <div nz-col [nzSpan]="5">
          <button nz-button nzType="default" nzDanger (click)="removeWorkPlace(i)" style="margin-top: 22px">
            <span nz-icon nzType="delete" nzTheme="outline"></span>
          </button>
        </div>
      </div>
    </div>
    }

    <div nz-col nzSpan="24">
      <button nz-button nzType="default" (click)="addWorkPlace()">
        <span nz-icon nzType="plus" nzTheme="outline"></span>
        {{'add_previous_position' | translate}}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #zoomPage>
  <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
    <div nz-col *ngIf="userInfo?.marketRegion !== MarketRegion.UKRAINE">
      <div class="container-with-label">
        <label>{{'select_lesson_platform' | translate}}</label>
        <div class="content checkboxes" style="padding-top: 10px;">
          <div class="input-combo" style="margin-right: 16px">
            <div class="radio"><input type="radio" name="lessonPlatformZoom" value="zoom" [(ngModel)]="lessonPlatform">
            </div>
            <div class="input-title">Zoom</div>
          </div>
          <div class="input-combo">
            <div class="radio"><input type="radio" name="lessonPlatformGoogle" value="google" [(ngModel)]="lessonPlatform">
            </div>
            <div class="input-title">Google Meet</div>
          </div>
        </div>
      </div>
    </div>

    <div nz-col [nzSpan]="24" *ngIf="lessonPlatform == 'google'">
        <app-input inputType="text" [error]="googleMeetLink.invalid && googleMeetLink.touched" [formControl]="googleMeetLink"
          [errorText]="getErrorText('googleMeetLink')" [value]="googleMeetLink.value" [label]="'link' | translate"
          [customInputParams]="{customWidth: '100%'}"></app-input>
    </div>

    <div nz-col [nzSpan]="24" *ngIf="lessonPlatform == 'zoom'">
        <app-input inputType="text" [error]="zoomLink.invalid && zoomLink.touched" [formControl]="zoomLink"
          [errorText]="getErrorText('zoomLink')" [value]="zoomLink.value" [label]="'link' | translate"
          [customInputParams]="{customWidth: '100%'}"></app-input>
    </div>

    <div nz-col *ngIf="lessonPlatform == 'zoom'">
      <app-input inputType="text" [error]="zoomAccessCode.invalid && zoomAccessCode.touched" [formControl]="zoomAccessCode"
        [errorText]="getErrorText('zoomAccessCode')" [value]="zoomAccessCode.value"
        [label]="'access_code' | translate"></app-input>
    </div>

    <div nz-col *ngIf="lessonPlatform == 'zoom'">
      <app-input inputType="text" [error]="zoomId.invalid && zoomId.touched" [formControl]="zoomId"
        [errorText]="getErrorText('zoomId')" [label]="'zoom_id' | translate"></app-input>
    </div>
  </div>
</ng-template>

<ng-template #additionalPage>
  <div nz-row [nzGutter]="[columnHorizontalGutter, columnHorizontalGutter]">
    <div nz-col [nzSpan]="24">
      <app-input inputType="textArea" [error]="additional.invalid && additional.touched" [formControl]="additional"
        [errorText]="getErrorText('additional')" [label]="'digital_resources' | translate"
        [customInputParams]="{customWidth: '100%', customHeight: '100px'}"></app-input>
    </div>
  </div>
</ng-template>