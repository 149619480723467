import { Component } from '@angular/core';
import { NotificationService } from '@shared/services/notification.service';
import PifagorApiService from '@shared/services/pifagor-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.scss']
})
export class PasswordRestoreComponent {

  email: string;

  constructor(private pifagorApiService: PifagorApiService, private notifications: NotificationService,
    private translateService: TranslateService
  ) { }

  restore(): void {
    if (this.email)
      this.pifagorApiService.restorePassword(this.email).subscribe(() => {
        this.notifications.showSuccessMessage(this.translateService.instant('check_email'));
      });
  }
}
