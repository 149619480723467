@let columnHorizontalGutter = 32;
@let columnVerticalGutter = 12;

<div nz-flex nzVertical [nzGap]="16" style="width: 100%;">
  <!-- Points -->
  <div class="card">
    <div class="card-block">
      <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
        <div nz-col [nzSpan]="24">
          <div nz-flex nzJustify="space-between">
            <div nz-flex nzAlign="center" nzJustify="center" [nzGap]="8">
              <span nz-icon nzType="star" nzTheme="twotone"></span>
              <span class="section-title">{{'my_points' | translate}}</span>
            </div>
            @if (authService.isManager() || authService.isAdmin()) {
              <button nz-button nzType="text" (click)="clickChangeInfoBtn()">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            }
          </div>
        </div>

        <!-- Status icon -->
        <div nz-col>
          <img *ngIf="_obtData?.level?.image" [src]="_obtData?.level?.image" alt="status-icon">
        </div>

        <!-- Points -->
        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'my_points' | translate}}</div>
            <div class="current-points-value" [countUp]="_obtData?.points || 0"></div>
          </div>
        </div>

        <!-- Level -->
        <div nz-col>
          <div nz-flex nzVertical>
            <div class="progress-bar-block" *ngIf="findMaxLevelValue() > (_obtData?.points || 0) else maxLevelContainer">
              <ng-container [ngSwitch]="translateService.currentLang">
                <div class="to-next-level-value" *ngSwitchCase="i18nLanguage.UA">
                  <span class="common-text">{{'to_level' | translate}} </span>
                  <span class="golden-text">{{getNextLevelData()?.name}} </span>
                  <span class="common-text">{{'points_left' | translate}} </span>
                  <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
                  <span class="common-text"> {{ 'points' | translate}}</span>
                </div>
                <div class="to-next-level-value" *ngSwitchCase="i18nLanguage.PL">
                  <span class="common-text">{{'do_poziomu' | translate}} </span>
                  <span class="golden-text">{{getNextLevelData()?.name}} </span>
                  <span class="common-text">{{'points_left3' | translate}} </span>
                  <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
                  <span class="common-text"> {{ 'points' | translate}}</span>
                </div>
                <div class="to-next-level-value" *ngSwitchDefault>
                  <span class="common-text">{{'you_have' | translate}} </span>
                  <span class="to-next-level-value" [countUp]="getNextLevelData()?.pointsFrom - _obtData?.points"></span>
                  <span class="common-text"> {{'points_left2' | translate}} </span>
                  <span class="golden-text">{{getNextLevelData()?.name}} </span>
                  <span class="common-text"> {{ 'level' | translate}}</span>
                </div>
              </ng-container>
            
              <div class="progress-bar">
                <div class="current-status">{{_obtData?.level.name}}</div>
                <div class="bar">
                  <div class="progress"
                    [ngStyle]="{width: getPercentageToNextLevel(_obtData?.level.pointsFrom, _obtData?.points, getNextLevelData()?.pointsFrom) || 0 + '%'}">
                  </div>
                </div>
                <div class="next-status">{{getNextLevelData()?.name}}</div>
              </div>
              <div class="prediction">
                <span class="common-text">{{'Level' | translate}} </span>
                <span class="golden-text">{{getNextLevelData()?.name}} </span>
                <span class="common-text">{{'new_income_level' | translate}} </span>
                <span class="golden-text" [countUp]="_obtData?.profit"></span>
                <span class="common-text"> {{'income_per_month' | translate}}</span>
              </div>
            </div>
            <ng-template #maxLevelContainer>
              <div class="max-level-container">
                <div class="alert-block">
                  <div class="text common">{{'not_stop_on_achieved' | translate}}</div>
                  <div class="text orange">{{'new_levels_will_come' | translate}}</div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <!-- Achievements -->
        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'my_achievements' | translate}}</div>
            <div class="achievements-items-block" nz-flex [nzGap]="4">
              <button nz-button nzType="text" nzSize="small" [disabled]="page === 0"
              (click)="previousBtnClick()">
                <span nz-icon nzType="left" nzTheme="outline"></span>
              </button>

              <div class="achievements-items">
                <div class="wrapper">
                  <div class="pages" [ngStyle]="{'margin-left': currentPosition + 'px'}">
                    <div class="achievement-item" *ngFor="let item of achievementsArr, index as i" #achievement>
                      <img *ngIf="(!item.isVisibleWhenInactive && item.state) || item.isVisibleWhenInactive"
                        [src]="item.photoUrl + (!item.state ? '-grey.svg' : '.svg')" [alt]="item.name" nz-tooltip
                        [nzTooltipTitle]="achievementTooltip">
                      {{getAchievementsWidth(achievement, i)}}
                      <ng-template #achievementTooltip>
                        <span class="math-tooltip-header">{{item.name | translate}}</span><br>
                        <span>{{item.name + '_description' | translate}}</span>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

              <button nz-button nzType="text" nzSize="small" [disabled]="page === getNumberOfPages() - 5"
              (click)="nextBtnClick()">
                <span nz-icon nzType="right" nzTheme="outline"></span>
              </button>
            </div>
          </div>
        </div>

        <!-- Statement -->
        <div nz-col nzSpan="24">
          <button nz-button nzType="default" (click)="openStatementWindow()">
            <span nz-icon nzType="file-text" nzTheme="outline"></span>
            <span >{{'statement_title' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Points calculator -->
  <div class="card">
    <div class="card-block">
      <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
        <div nz-col [nzSpan]="24">
          <div nz-flex [nzGap]="8">
            <span nz-icon nzType="calculator" nzTheme="twotone"></span>
            <span class="section-title">{{'points_calculator' | translate}}</span>
          </div>
        </div>

        <app-indicators-calc 
          [userRewards]="_obtData" 
          [userLevels]="userLevels"
          [lessonsPassedInp]="lessonsPassed" 
        />
      </div>
    </div>
  </div>

  <!-- Statistics -->
  <div class="card">
    <div class="card-block">
      <div nz-row [nzGutter]="[columnHorizontalGutter, columnVerticalGutter]">
        <div nz-col [nzSpan]="24">
          <div nz-flex nzJustify="space-between">
            <div nz-flex nzAlign="center" [nzGap]="8">
              <span nz-icon nzType="pie-chart" nzTheme="twotone"></span>
              <span class="section-title">{{'statistics' | translate}}</span>
            </div>

            @if (authService.isManager() || authService.isAdmin()) {
              <button nz-button nzType="text" (click)="clickChangeInfoBtn()">
                <span nz-icon nzType="edit" nzTheme="outline"></span>
              </button>
            }
          </div>
        </div>

        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'available_hours_per_week' | translate}}:</div>
            <div>{{_obtData?.availableHours || 0}} {{'hours' | translate}}</div>
          </div>
        </div>

        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'parents_responses' | translate}}:</div>
            <div class="value-green">{{_obtData?.feedbacks?.positive || 0}} {{_obtData?.feedbacks?.positive > 0 ?
              ('positive_plural' | translate) : ('positive' | translate)}}</div>
          </div>
        </div>

        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'parents_responses' | translate}}:</div>
            <div class="value-red">{{_obtData?.feedbacks?.negative || 0}} {{_obtData?.feedbacks?.negative > 0 ?
              ('negative_plural' | translate) : ('negative' | translate)}}</div>
          </div>
        </div>

        <div nz-col>
          <div nz-flex nzVertical>
            <div class="label">{{'regulation_violations' | translate}}:</div>
            <div>{{_obtData?.regulationViolations || 0}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>