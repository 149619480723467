<div class="free-hours-filters">

  <!-- Date between -->
  <nz-divider></nz-divider>
  <div class="study-date-range">
    <nz-collapse nzGhost nzExpandIconPosition="end" >
      <nz-collapse-panel
        nzActive
        #studyDateRangeControl
        [nzExtra]="studyDateRangeExtra"
        [nzHeader]="'free_hours.study_date_range' | translate"
        [nzShowArrow]="false"
      >
        <nz-range-picker nzInline [(ngModel)]="studyDateRange" (ngModelChange)="changeStudyDateRange($event)"></nz-range-picker>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyDateRangeExtra>
      <math-collapse-extra-buttons [isActive]="studyDateRangeControl.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.STUDY_DATE_RANGE)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Study day time -->
  <nz-divider></nz-divider>
  <div class="study-days study-time">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel #studyDaysControl [nzExtra]="studyDaysExtra" [nzHeader]="studyDaysInfo" [nzShowArrow]="false"
        nzActive >

        <div nz-flex nzWrap="wrap">
          <ng-container *ngFor="let day of getValuesByKey(filtersKeys.STUDY_DAYS)">
            <nz-tag nzMode="checkable"
              (nzCheckedChange)="selectStudyDay($event, day.value)"
              [nzChecked]="day.isChecked">
              {{day.label | translate}}
            </nz-tag>
          </ng-container>

          @let isChecked = isStudyDayTimesChecked();
          <button nz-button nzType="text" nzSize="default" class="add-all-days-btn"
            (click)="onAllDaysClick()">
            {{isChecked ? ('free_hours.remove_all_days' | translate) : ('free_hours.add_all_days' | translate)}}
          </button>
        </div>

        <nz-slider nzRange [(ngModel)]="studyTimeRange" [nzTipFormatter]="formatStudyTime"
          (ngModelChange)="onStudyTimeRangeChange($event)" [nzMax]="TIME_RANGE_MAX" [nzMin]="TIME_RANGE_MIN">
        </nz-slider>

        <div nz-flex [nzJustify]="'center'" [nzAlign]="'center'">
          <nz-space [nzAlign]="'center'">
            <span *nzSpaceItem>{{'free_hours.from' | translate}}</span>
            <nz-input-number *nzSpaceItem [nzFormatter]="formatStudyTime" [(ngModel)]="studyTimeMin"
              (ngModelChange)="onStudyTimeMinChange($event)" [nzMin]="TIME_RANGE_MIN" [nzMax]="studyTimeMax"
              [nzStep]="1">
            </nz-input-number>
          
            <span *nzSpaceItem>{{'free_hours.to' | translate}}</span>
            <nz-input-number *nzSpaceItem [nzFormatter]="formatStudyTime" [(ngModel)]="studyTimeMax"
              (ngModelChange)="onStudyTimeMaxChange($event)" [nzMin]="studyTimeMin" [nzMax]="TIME_RANGE_MAX"
              [nzStep]="1">
            </nz-input-number>

            
            <button *nzSpaceItem nz-button nzType="default" nzSize="default" class="apply-filter-btn" [disabled]="!isChecked"
              [nz-tooltip]="!isChecked ? ('free_hours.select_at_least_one_week_day' | translate) : null"
              (click)="onConfirmStudyDayTime()">{{'main.btn.add' | translate}}
              </button>
          </nz-space>
        </div>

      </nz-collapse-panel>
    </nz-collapse>

    <ng-template #studyDaysExtra>
      <math-collapse-extra-buttons [isActive]="studyDaysControl.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.STUDY_DAY_TIME)">
      </math-collapse-extra-buttons>
    </ng-template>

    <ng-template #studyDaysInfo>
      <nz-space>
        <span *nzSpaceItem>{{'free_hours.study_time' | translate}}</span>
        <span *nzSpaceItem nz-icon nzType="info-circle" nzTheme="outline" [nz-tooltip]="'free_hours.study_time_info' | translate"></span>
      </nz-space>
    </ng-template>
  </div>

  <!-- Lesson duration -->
  <nz-divider></nz-divider>
  <div class="study-days">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #lessonDurationControl
        nzActive
        [nzExtra]="lessonDurationExtra"
        [nzHeader]="'free_hours.lesson_duration' | translate"
        [nzShowArrow]="false"
      >
        <ng-container *ngFor="let duration of getValuesByKey(filtersKeys.LESSON_DURATION)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.LESSON_DURATION, duration.value, duration.label)" [nzChecked]="duration.isChecked">
            {{duration.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #lessonDurationExtra>
      <math-collapse-extra-buttons [isActive]="lessonDurationControl.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.LESSON_DURATION)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Grade -->
  <nz-divider></nz-divider>
  <div class="grade">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #grade
        nzActive
        [nzExtra]="gradeExtra"
        [nzHeader]="'free_hours.grade' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let grade of getValuesByKey(filtersKeys.STUDENT_GRADE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.STUDENT_GRADE, grade.value, grade.label)" [nzChecked]="grade.isChecked">
            {{ grade.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #gradeExtra>
      <math-collapse-extra-buttons [isActive]="grade.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.STUDENT_GRADE)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <ng-container *ngIf="authService.isUserUkrainian() || authService.isUserPoland()">
    <!-- Verification -->
    <nz-divider></nz-divider>
    <div class="verification">
      <nz-collapse nzGhost nzExpandIconPosition="end">
        <nz-collapse-panel
          #verification
          nzActive
          [nzExtra]="verificationExtra"
          [nzHeader]="'free_hours.verified_teachers' | translate"
          [nzShowArrow]="false">
          <ng-container *ngFor="let verify of getValuesByKey(filtersKeys.VERIFICATION)">
            <label
              nz-checkbox
              [nzChecked]="verify.isChecked"
              (nzCheckedChange)="onCheckFilter($event, filtersKeys.VERIFICATION, verify.value, verify.label)">
              {{verify.label | translate}}
            </label><br>
          </ng-container>
        </nz-collapse-panel>
      </nz-collapse>
      <ng-template #verificationExtra>
        <math-collapse-extra-buttons [isActive]="verification.nzActive" 
          (onReset)="resetFilterByKey(filtersKeys.VERIFICATION)">
        </math-collapse-extra-buttons>
      </ng-template>
    </div>
  </ng-container>


  <!-- Not take new student -->
  <nz-divider></nz-divider>
  <div>
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel #notTakeNewStudents nzActive [nzExtra]="notTakeNewStudentsExtra"
        [nzHeader]="'free_hours.not_take_new_student' | translate" [nzShowArrow]="false">
        <ng-container *ngFor="let notTakeNewStudents of getValuesByKey(filtersKeys.SHOW_NOT_TAKE_NEW_STUDENTS)">
          <label 
            nz-checkbox 
            [nzChecked]="notTakeNewStudents.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.SHOW_NOT_TAKE_NEW_STUDENTS, notTakeNewStudents.value, notTakeNewStudents.label)"
          >
            {{notTakeNewStudents.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #notTakeNewStudentsExtra>
      <math-collapse-extra-buttons 
        [isActive]="notTakeNewStudents.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.SHOW_NOT_TAKE_NEW_STUDENTS)"
      >
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Teacher age -->
  <nz-divider></nz-divider>
  <div class="teacher-age">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #teacherAge
        nzActive
        [nzExtra]="teacherAgeExtra"
        [nzHeader]="'free_hours.teacher_age' | translate"
        [nzShowArrow]="false">
        <nz-slider
          nzRange
          [(ngModel)]="teacherAgeRange"
          (ngModelChange)="onTeacherAgeRangeChange($event)"
          nzMax="75"
          nzMin="16"></nz-slider>
          <div nz-flex [nzJustify]="'center'" [nzAlign]="'center'">
            <nz-space [nzAlign]="'center'" [nzSize]="16">
              <span *nzSpaceItem>{{'free_hours.from' | translate}}</span>
              <nz-input-number
                *nzSpaceItem
                [(ngModel)]="teacherMinAge"
                (ngModelChange)="onTeacherMinAgeChange($event)"
                [nzMin]="16"
                [nzMax]="teacherMaxAge"
                [nzStep]="1"></nz-input-number>
              <span *nzSpaceItem>{{'free_hours.to' | translate}}</span>
              <nz-input-number
                *nzSpaceItem
                [(ngModel)]="teacherMaxAge"
                (ngModelChange)="onTeacherMaxAgeChange($event)"
                [nzMin]="teacherMinAge"
                [nzMax]="75"
                [nzStep]="1"></nz-input-number>
              <button
                *nzSpaceItem
                nz-button
                nzType="default"
                nzSize="default"
                class="apply-filter-btn"
                (click)="onConfirmTeacherAge()"
              >{{'main.btn.ok' | translate}}</button>
            </nz-space>
          </div>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teacherAgeExtra>
      <math-collapse-extra-buttons [isActive]="teacherAge.nzActive" 
        (onReset)="resetTeacherAge()">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Study program -->
  <nz-divider></nz-divider>
  <div class="study-program">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #studyProgram
        nzActive
        [nzExtra]="studyProgramExtra"
        [nzHeader]="'free_hours.study_programs' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let program of getValuesByKey(filtersKeys.STUDY_PROGRAM)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.STUDY_PROGRAM, program.value, program.label)" [nzChecked]="program.isChecked">
            {{program.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyProgramExtra>
      <math-collapse-extra-buttons [isActive]="studyProgram.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.STUDY_PROGRAM)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Additional subject -->
  <nz-divider></nz-divider>
  <div class="add-subject">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #addSubject
        nzActive
        [nzExtra]="addSubjectExtra"
        [nzHeader]="'free_hours.additional_jubjects' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let addSubject of getValuesByKey(filtersKeys.ADDITIONAl_SUBJECT)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.ADDITIONAl_SUBJECT, addSubject.value, addSubject.label)" [nzChecked]="addSubject.isChecked">
            {{addSubject.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #addSubjectExtra>
      <math-collapse-extra-buttons [isActive]="addSubject.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.ADDITIONAl_SUBJECT)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Study level -->
  <nz-divider></nz-divider>
  <div class="study-level">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #studyLevel
        nzActive
        [nzExtra]="studyLevelExtra"
        [nzHeader]="'free_hours.study_level' | translate"
        [nzShowArrow]="false">
        <div *ngFor="let level of getValuesByKey(filtersKeys.STUDYING_LEVELS)" class="toggle-wrap">
          <span>{{level.label | translate}}</span>
          <nz-switch [ngModel]="level.isChecked" (ngModelChange)="onCheckFilter($event, filtersKeys.STUDYING_LEVELS, level.value, level.label)"></nz-switch>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #studyLevelExtra>
      <math-collapse-extra-buttons [isActive]="studyLevel.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.STUDYING_LEVELS)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Gender -->
  <nz-divider></nz-divider>
  <div class="gender">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #genderControl
        [nzExtra]="genderExtra"
        [nzHeader]="'free_hours.gender' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let gender of getValuesByKey(filtersKeys.GENDER)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.GENDER, gender.value, gender.label)" [nzChecked]="gender.isChecked">
            {{gender.label | translate | titlecase}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #genderExtra>
      <math-collapse-extra-buttons [isActive]="genderControl.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.GENDER)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Country -->
  <nz-divider></nz-divider>
  <div class="country">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #country
        [nzExtra]="countryExtra"
        [nzHeader]="'free_hours.country' | translate"
        [nzShowArrow]="false">
        <nz-select
          style="width: 100%"
          [nzCustomTemplate]="selectedCountry"
          nzAllowClear
          nzShowSearch
          nzSize="large"
          [nzShowArrow]="true"
          (ngModelChange)="onSetCountries($event)"
          nzPlaceHolder="Оберіть країну перебування"
          nzMode="multiple"
          [nzMaxTagCount]="3"
          [(ngModel)]="selectedCountries">
          <nz-option *ngFor="let country of countries" nzCustomContent [nzLabel]="country.label" [nzValue]="country.value">
            <span class="fi fi-{{country.id}}"></span>
            <span class="country-label">{{country.label}}</span>
          </nz-option>
        </nz-select>
        <ng-template #selectedCountry let-selected>
          <span class="fi fi-{{selected.nzValue}}"></span>
          <span class="country-label">{{selected.nzLabel}}</span>
        </ng-template>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #countryExtra>
      <math-collapse-extra-buttons [isActive]="country.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.COUNTRY)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Status -->
  <nz-divider></nz-divider>
  <div class="status">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #status
        [nzExtra]="statusExtra"
        [nzHeader]="'free_hours.teacher_type' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let status of getValuesByKey(filtersKeys.TEACHER_STATUS)">
          <label
            nz-checkbox
            [nzChecked]="status.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHER_STATUS, status.value, status.label)">
            {{status.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #statusExtra>
      <math-collapse-extra-buttons [isActive]="status.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.TEACHER_STATUS)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Payment level -->
  <nz-divider></nz-divider>
  <div class="payment-level">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #paymentLevel
        [nzExtra]="paymentLevelExtra"
        [nzHeader]="'free_hours.payment_level' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let level of getValuesByKey(filtersKeys.PAYMENT_LEVEL)">
          <nz-tag
            nz-tooltip
            [nzTooltipTitle]="getPaymentLevelTip(level.label)"
            nzTooltipPlacement="topLeft"
            nzMode="checkable"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.PAYMENT_LEVEL, level.value, level.label)" [nzChecked]="level.isChecked"
          >
            {{level.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #paymentLevelExtra>
      <math-collapse-extra-buttons [isActive]="paymentLevel.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.PAYMENT_LEVEL)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Qualification -->
  <nz-divider></nz-divider>
  <div class="qualification">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #qualification
        [nzExtra]="qualificationExtra"
        [nzHeader]="'free_hours.teacher_qualification' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let qualification of getValuesByKey(filtersKeys.QUALIFICATION)">
          <nz-tag
            nzMode="checkable"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.QUALIFICATION, qualification.value, qualification.label)" [nzChecked]="qualification.isChecked"
          >
            {{qualification.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #qualificationExtra>
      <math-collapse-extra-buttons [isActive]="qualification.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.QUALIFICATION)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Experience -->
  <nz-divider></nz-divider>
  <div class="experience">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #experience
        [nzExtra]="experienceExtra"
        [nzHeader]="'free_hours.study_experience' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let exp of getValuesByKey(filtersKeys.TEACHING_EXPERIENCE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_EXPERIENCE, exp.value, exp.label)" [nzChecked]="exp.isChecked">
            {{exp.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #experienceExtra>
      <math-collapse-extra-buttons [isActive]="experience.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.TEACHING_EXPERIENCE)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Tutor experience -->
  <nz-divider></nz-divider>
  <div class="tutor-experience">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #tutorExperience
        [nzExtra]="tutorExperienceExtra"
        [nzHeader]="'free_hours.tutor_experience' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let exp of getValuesByKey(filtersKeys.TUTORING_EXPERIENCE)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.TUTORING_EXPERIENCE, exp.value, exp.label)" [nzChecked]="exp.isChecked">
            {{exp.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #tutorExperienceExtra>
      <math-collapse-extra-buttons [isActive]="tutorExperience.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.TUTORING_EXPERIENCE)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Exams preparation -->
  <nz-divider></nz-divider>
  <div class="exams-preparation">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #examsPreparation
        [nzExtra]="examsPreparationExtra"
        [nzHeader]="'free_hours.preparing_to_exams' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let exam of getValuesByKey(filtersKeys.EXAMS)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.EXAMS, exam.value, exam.label)" [nzChecked]="exam.isChecked">
            {{exam.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #examsPreparationExtra>
      <math-collapse-extra-buttons [isActive]="examsPreparation.nzActive"
        (onReset)="resetFilterByKey(filtersKeys.EXAMS)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Special needs -->
  <nz-divider></nz-divider>
  <div class="special-needs">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #specialNeeds
        [nzExtra]="specialNeedsExtra"
        [nzHeader]="'free_hours.special_needs_work' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let need of getValuesByKey(filtersKeys.SPECIAL_NEEDS)">
          <nz-tag nzMode="checkable" (nzCheckedChange)="onCheckFilter($event, filtersKeys.SPECIAL_NEEDS, need.value, need.label)" [nzChecked]="need.isChecked">
            {{need.label | translate}}
          </nz-tag>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #specialNeedsExtra>
      <math-collapse-extra-buttons [isActive]="specialNeeds.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.SPECIAL_NEEDS)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Teaching style -->
  <nz-divider></nz-divider>
  <div class="teaching-style">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #teachingStyle
        [nzExtra]="teachingStyleExtra"
        [nzHeader]="'free_hours.study_style' | translate"
        [nzShowArrow]="false">
        <ng-container *ngFor="let style of getValuesByKey(filtersKeys.TEACHING_STYLE)">
          <label
            nz-checkbox
            [nzChecked]="style.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_STYLE, style.value, style.label)">
            {{style.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teachingStyleExtra>
      <math-collapse-extra-buttons [isActive]="teachingStyle.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.TEACHING_STYLE)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>

  <!-- Teaching lang -->
  <nz-divider></nz-divider>
  <div class="teaching-lang">
    <nz-collapse nzGhost nzExpandIconPosition="end">
      <nz-collapse-panel
        #teachingLang
        [nzExtra]="teachingLangExtra"
        [nzHeader]="'free_hours.study_language' | translate"
        [nzShowArrow]="false"
        (nzActiveChange)="scrollDown($event)">
        <ng-container *ngFor="let lang of getValuesByKey(filtersKeys.TEACHING_LANG)">
          <label
            nz-checkbox
            [nzChecked]="lang.isChecked"
            (nzCheckedChange)="onCheckFilter($event, filtersKeys.TEACHING_LANG, lang.value, lang.label)">
            <img class="country" *ngIf="lang.img" [src]="lang.img" [alt]="lang.value">
            {{lang.label | translate}}
          </label><br>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #teachingLangExtra>
      <math-collapse-extra-buttons [isActive]="teachingLang.nzActive" 
        (onReset)="resetFilterByKey(filtersKeys.TEACHING_LANG)">
      </math-collapse-extra-buttons>
    </ng-template>
  </div>
</div>
