import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import moment from "moment/moment";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {LanguageEnum, phoneNumbersFormats} from "../../constants";
import { TranslateService } from '@ngx-translate/core';

interface CustomInputParams {
  customWidth?: string;
  customHeight?: string;
}


export interface DropdownData {
  id: string;
  label: string;
  iconUrl?: string;
  selected?: boolean;
}
export interface CheckboxData {
  id: string;
  label: string;
  selected?: boolean;
  iconUrl?: string;
}
export interface RadioData {
  id: string;
  label: string;
  selected?: boolean;
}
export interface MultiselectData {
  id: string;
  label: string;
  selected: boolean;
  icon?: string;
}

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppInputComponent),
      multi: true,
    },
  ],
})
export class AppInputComponent implements OnInit {
  public tmpData: Date;

  @Input() public placeholder: string;
  @Input() public inputId: string;
  @Input() public icon: string;
  @Input() public underlineAnimation: boolean;
  @Input() public inputType: 'text' | 'number' | 'date' | 'dropdown'| 'countries-dropdown' | 'mobile' | 'multiselect-square' | 'multiselect-auto-size' | 'checkbox' | 'radio' | 'textArea' | 'display-field';
  @Input() public label: string;
  @Input() public title: string;
  @Input() public isDisabled: boolean;
  @Input() public noWrapOpt: boolean;
  @Input() public closeOnSelect: boolean;
  @Input() public error: boolean | undefined;
  @Input() public hideArrows: boolean | undefined;
  @Input() public errorText: string | undefined;
  @Input() public min: number | string = 0;
  @Input() public max: number | string = 100;
  @Input() public step: number | string = 1;
  @Input() public set value(initValue: string | undefined) {
    this.inputValue = initValue as string;
    if (this.inputType === "dropdown" || this.inputType === "countries-dropdown"){
      this.dropdownSelectedId = initValue as string;
    }
    if (this.inputType === "date"){
      if(initValue){
        this.tmpData = moment(initValue).toDate();
      }
    }
  };
  @Input() public customInputParams: CustomInputParams;
  @Input() public dropdownParams: DropdownData[];
  @Input() public dropdownSelectedValue: DropdownData;
  @Input() public hideLabel: boolean;
  @Input() public language: LanguageEnum | string = this.translateService.currentLang.toUpperCase();

  @Input() public additionalParams: 'phone-number' | 'date';

  @Input() public multiselectData: MultiselectData[];
  @Output() public multiselectDataOutput: EventEmitter<MultiselectData[]> = new EventEmitter();

  @Input() public checkboxData: CheckboxData[];
  @Input() public RadioData: RadioData[];

  @Output() public inputChange: EventEmitter<string> = new EventEmitter();
  @Output() public focused: EventEmitter<boolean> = new EventEmitter();
  @Output() public dropdownSelectValue: EventEmitter<string> = new EventEmitter();
  @Output() public checkboxSelectValue: EventEmitter<CheckboxData[]> = new EventEmitter();
  @Output() public RadioSelectValue: EventEmitter<RadioData[]> = new EventEmitter();

  public dropdownSelectedId: string;
  public inputValue: string;
  public dropdownState: boolean = false;
  public expandDropdown: boolean = false;
  public filteredDropdownData: DropdownData[];
  private unlinkedDropdownData: DropdownData[];
  public searchText: string;
  public date = new Date();

  constructor(
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if((this.inputType === 'dropdown' || this.inputType === 'countries-dropdown') && this.dropdownSelectedId){
      if(this.dropdownParams.some(item => item.id === this.dropdownSelectedId)){
        this.dropdownSelectedValue = this.dropdownParams.find(item => item.id === this.dropdownSelectedId) as DropdownData;
      } else {
        this.dropdownSelectedValue = {...this.dropdownSelectedValue, label: this.label ? this.label : ''};
      }
    } else {
      this.dropdownSelectedValue = {...this.dropdownSelectedValue, label: 'no_info', id: null};
    }
    if (this.inputType === 'countries-dropdown') {
      this.filteredDropdownData = JSON.parse(JSON.stringify(this.dropdownParams));
      this.unlinkedDropdownData = JSON.parse(JSON.stringify(this.dropdownParams));
    }

  }

  public handleInputChange(): void {
    if (this.additionalParams === 'phone-number'){
      if(this.inputValue.trim()[0] === '+' && this.inputValue.trim()[1]==='('){
        if((this.inputValue.trim().length === 3 && phoneNumbersFormats.oneDigitsFormat.some(item=> item === this.inputValue.trim()[2]))||
          (this.inputValue.trim().length === 4 && phoneNumbersFormats.twoDigitsFormats.some(item=> item === this.inputValue.trim()[2]+this.inputValue.trim()[3]))||
          (this.inputValue.trim().length === 5 && phoneNumbersFormats.threeDigitsFormat.some(item=> item === this.inputValue.trim()[2]+this.inputValue.trim()[3]+this.inputValue.trim()[4]))){
          this.inputValue += ')'
        }
      }
      this.autocompletePhoneNumber()
    }
    if (this.additionalParams === 'date'){
      if(this.inputValue.trim().length === 2 || this.inputValue.trim().length === 5){
        this.inputValue+='.';
      }
    }

    this.inputChange.emit(this.inputValue)
    this.onChange(this.inputValue)
  }

  private autocompletePhoneNumber(): void {
    this.inputValue = this.inputValue.trim();
    if(
      (this.inputValue.trim().length === 12 &&
        phoneNumbersFormats.threeDigitsFormat.some(item=> item === this.inputValue.trim()[0]+this.inputValue.trim()[1]+this.inputValue.trim()[2]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[0]+this.inputValue.trim()[1]+this.inputValue.trim()[2])
      return;
    }
    else if(
      (this.inputValue.trim().length === 13 &&
        phoneNumbersFormats.threeDigitsFormat.some(item=> item === this.inputValue.trim()[1]+this.inputValue.trim()[2]+this.inputValue.trim()[3]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[1]+this.inputValue.trim()[2]+this.inputValue.trim()[3])
      return;
    }
    else if(
      ((this.inputValue.trim().length === 18 && this.inputValue[4] === ' ') || (this.inputValue.trim().length === 15 && this.inputValue[4] !== ' ')) &&
      phoneNumbersFormats.threeDigitsFormat.some(item=> item === this.inputValue.trim()[2]+this.inputValue.trim()[3]+this.inputValue.trim()[4])
    ){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[2]+this.inputValue.trim()[3]+this.inputValue.trim()[4])
      return;
    }
    if(
      (this.inputValue.trim().length === 11 &&
        phoneNumbersFormats.twoDigitsFormats.some(item=> item === this.inputValue.trim()[0]+this.inputValue.trim()[1]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[0]+this.inputValue.trim()[1])
      return;
    }
    else if(
      (this.inputValue.trim().length === 12 &&
        phoneNumbersFormats.twoDigitsFormats.some(item=> item === this.inputValue.trim()[1]+this.inputValue.trim()[2]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[1]+this.inputValue.trim()[2])
      return;
    }
    else if(
      ((this.inputValue.trim().length === 16 && this.inputValue[4] === ' ') || (this.inputValue.trim().length === 14 && this.inputValue[4] !== ' ')) &&
      phoneNumbersFormats.twoDigitsFormats.some(item=> item === this.inputValue.trim()[2]+this.inputValue.trim()[3])
    ){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[2]+this.inputValue.trim()[3])
      return;
    }

    if(
      (this.inputValue.trim().length === 10 &&
        phoneNumbersFormats.oneDigitsFormat.some(item=> item === this.inputValue.trim()[0]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[0])
      return;
    }
    else if(
      (this.inputValue.trim().length === 11 &&
        phoneNumbersFormats.oneDigitsFormat.some(item=> item === this.inputValue.trim()[1]))){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[1])
      return;
    }
    else if(
      ((this.inputValue.trim().length === 15 && this.inputValue[4] === ' ') || (this.inputValue.trim().length === 13 && this.inputValue[4] !== ' ')) &&
      phoneNumbersFormats.oneDigitsFormat.some(item=> item === this.inputValue.trim()[2])
    ){
      this.inputValue = this.formatPhoneNumber(this.inputValue, this.inputValue.trim()[2])
      return;
    }
  }

  public handleInputFocus(): void {
    this.inputChange.emit(this.inputValue)
  }

  public toggleDropdownState(state: boolean): void {
    this.dropdownState = state;
    if (!state){
      this.expandDropdown = state;
    }
  }
  public handleDropdownSelectValue(value: DropdownData): void {
    this.dropdownSelectedValue = value;
    this.dropdownSelectValue.emit(value.id)
    if(this.closeOnSelect) {
      this.toggleDropdownState(false);
    }
  }

  public formatPhoneNumber(phoneNumber: string, countryCode: string = '1'): string {
    let cleaned = phoneNumber.replace(/\D/g, '');
    let tmp: string = '';
    cleaned = cleaned.substr(countryCode.length, cleaned.length-1);
    let groups = cleaned.match(/(\d{1,3})/g);
    if(groups[groups.length-1].length === 1){
      tmp = groups[groups.length-1];
      groups.pop();
    }
    return `+(${countryCode}) ${groups.join(' ')}` + tmp;
  }

  public multiselectItemSelect(id: string): void {
    let unlinkedData: MultiselectData[] = JSON.parse(JSON.stringify(this.multiselectData));
    unlinkedData.forEach((item)=>{
      if (item.id === id) {
        item.selected = !item.selected;
      }
    })
    this.multiselectDataOutput.emit(unlinkedData);
  }

  public filterData() {
    if (this.searchText && this.searchText.length > 0) {
      this.filteredDropdownData = (JSON.parse(JSON.stringify(this.unlinkedDropdownData)) as DropdownData[]).filter(param=>param.label.toLowerCase().includes(this.searchText.toLowerCase()))
    } else {
      this.filteredDropdownData = this.unlinkedDropdownData;
    }
  }

  public onFocus(): void {
    this.onTouched();
  }


  private onChange = (data: string) => {};
  private onTouched = () => {};
  public touched = false;
  public disabled = false;

  public writeValue(v: string) {
    this.value = v;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
