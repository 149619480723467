<ng-template mathHeaderTitle>
  {{'free_hours.search_available_hours' | translate}}
</ng-template>

<nz-layout class="page">
  <!--  FILTERS-->
  <nz-drawer
    class="filters-drawer"
    [nzClosable]="false"
    (nzOnClose)="filtersOpened=false"
    [nzVisible]="filtersOpened"
    [nzContent]="filtersComponent"
    nzPlacement="left"
    [nzTitle]="'free_hours.filters' | translate"
  ></nz-drawer>

  <ng-template mathLeftSider>
    <nz-sider
      mathContentSiderStyle
      nzCollapsible
      nzBreakpoint="lg"
      [nzTrigger]="null"
      [nzCollapsedWidth]="0"
      [nzWidth]="380"
    class="sidebar"
  >
    <nz-row class="sidebar-filters-header" nzJustify="space-between">
      <nz-col><h2>{{ 'free_hours.filters' | translate }}</h2></nz-col>
      <nz-col>
        <button nz-button nzType="text" class="filters-btn" (click)="resetAllFilters()">
          <span nz-icon nzType="reload"></span>
          {{ 'free_hours.reset_all_filters' | translate }}
        </button>
      </nz-col>
    </nz-row>
    <ng-template [ngTemplateOutlet]="filtersComponent"></ng-template>
    </nz-sider>
  </ng-template>
  <!--  END FILTERS-->

  <nz-layout class="content">
    <div class="header">
      <!--        todo: combine to one component-->
      <nz-row>
        <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="0" class="active-filters-md">
          <mat-free-hours-filters-state
            (onToggleFiltersModal)="modalFiltersOpened = !modalFiltersOpened"
            (onToggleFiltersDrawer)="filtersOpened = !filtersOpened"
            [activeFilters]="activeFilters"
            [teachersFound]="total"
            (onDeactivateFilter)="resetFilterWithEvent($event)"
          ></mat-free-hours-filters-state>
        </nz-col>
      </nz-row>

      <nz-row>
        <nz-col [nzXs]="0" [nzSm]="0" [nzMd]="0" [nzLg]="24">
          <mat-free-hours-filters-state
            (onToggleFiltersModal)="modalFiltersOpened = !modalFiltersOpened"
            (onToggleFiltersDrawer)="filtersOpened = !filtersOpened"
            [activeFilters]="activeFilters"
            [teachersFound]="total"
            (onDeactivateFilter)="resetFilterWithEvent($event)"
          ></mat-free-hours-filters-state>
        </nz-col>
      </nz-row>
    </div>

    <mathema-some-loading-error *ngIf="errorHappened"></mathema-some-loading-error>

    <div class="no-content" *ngIf="tableData.length === 0 && !errorHappened">
      <ng-template [ngTemplateOutlet]="noResults"></ng-template>
      <h3 class="reason">{{ 'free_hours.no_results' | translate }}</h3>
      <span class="details">{{ 'free_hours.no_results_details' | translate }}</span>
    </div>

    <nz-table
      *ngIf="!errorHappened && tableData.length > 0"
      #teachersTable
      nzTableLayout="fixed"
      nzShowSizeChanger
      [nzLoadingIndicator]="loader"
      [nzFrontPagination]="false"
      [nzNoResult]="noResults"
      [nzPageSize]="pageSize"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzTotal]="total"
      [nzPageSizeOptions]="[5, 10, 15, 20, 30, 40, 50]"
      [nzData]="tableData"
      (nzPageIndexChange)="onPageIndexChange($event)"
      (nzPageSizeChange)="onPageSizeChange($event)"
      class="teachers-table"
    >
      <thead>
      <tr>
        <th class="tag-cell"></th>
        <th>{{ 'free_hours.name' | translate }}</th>
        <th>{{ 'free_hours.surname' | translate }}</th>
        <th class="small-cell">{{ 'free_hours.age' | translate }}</th>
        <th nzEllipsis>{{ 'free_hours.teacher_type' | translate }}</th>
        <th class="no-show-mobile" nzEllipsis>{{ 'free_hours.payment_level' | translate }}</th>
        <th
          style="padding-left: 0"
          class="no-show-mobile sortable"
          nzEllipsis
          [nzSortDirections]="workloadSortingDirections"
          (nzSortOrderChange)="onColumnOrderChange($event, filtersKeys.WORKLOAD)"
          nzShowSort
        >
          {{ 'free_hours.load' | translate }}
          <div class="priority {{numberToStringPriority(sortState[filtersKeys.WORKLOAD].priority)}}"
                (click)="changeSortPriority($event, filtersKeys.WORKLOAD)"></div>
        </th>
        <th
          class="no-show-mobile sortable"
          nzEllipsis
          [nzSortDirections]="conversionSortingDirections"
          (nzSortOrderChange)="onColumnOrderChange($event, filtersKeys.CONVERSION)"
          nzShowSort
        >
          {{ 'free_hours.conversion' | translate }}
          <div class="priority {{numberToStringPriority(sortState[filtersKeys.CONVERSION].priority)}}"
                (click)="changeSortPriority($event, filtersKeys.CONVERSION)"></div>
        </th>
        <th class="no-show-mobile" nzEllipsis>{{ 'free_hours.qualification' | translate }}</th>
        <th class="no-show-mobile" nzEllipsis>{{ 'free_hours.tutor_experience' | translate }}</th>
        <th class="no-show-mobile" nzEllipsis>{{ 'free_hours.preparing_to_exams' | translate }}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let teacher of teachersTable.data">
        <tr (click)="toggleTeacherCalendar(teacher.id)" [class.teacher-expanded]="teacher.id === expandedTeacherId">
          <td class="tag-cell">
            <div nz-flex nzVertical [nzGap]="8">
              <!-- Verified icon -->
              <span 
                nz-icon 
                nzType="check-circle" 
                nzTheme="twotone" 
                nzTwotoneColor="#52c41a"
                *ngIf="(authService.isUserUkrainian() || authService.isUserPoland()) && teacher.isVerified"
                nz-tooltip
                [nzTooltipTitle]="'free_hours.verified' | translate"
              >
              </span>
              <!-- Not take new student icon -->
              <span 
                nz-icon 
                nzType="lock" 
                nzTheme="twotone" 
                nzTwotoneColor="#faad14"
                *ngIf="teacher.notTakeNewStudentsAt"
                nz-tooltip
                [nzTooltipTitle]="'free_hours.not_take_new_student' | translate"
              ></span>
            </div>
          </td>
          <td nzEllipsis nzBreakWord nz-tooltip [nzTooltipTitle]="teacher.firstName">
              <a class="td-link"
                  (click)="$event.stopPropagation(); openAccountInfoPage(teacher.id)">{{ teacher.firstName }}</a>
          </td>
          <td nzEllipsis nzBreakWord nz-tooltip [nzTooltipTitle]="teacher.lastName"><a class="td-link"
                                                                                        (click)="openAccountInfoPage(teacher.id)">{{ teacher.lastName }}</a>
          </td>
          <td class="">{{ parseTeacherAge(teacher.age) }}</td>
          <td nz-tooltip [nzTooltipTitle]="teacher.status | translate">
            <span [class]="teacher.status">{{ teacher.status | translate }}</span>
          </td>
          <td class="no-show-mobile" nz-tooltip [nzTooltipTitle]="getPaymentLevelTip(teacher.level)"><span
            [class]="teacher.level">{{ teacher.level }}</span></td>
          <td class="no-show-mobile">{{ (teacher.teacherWorkLoad | number:'1.0-2') + '%' }}</td>
          <td class="no-show-mobile">
            <span *ngIf="teacher.conversion !== null && teacher.conversion !== -1">{{ (teacher.conversion | number:'1.0-2') + '%' }}</span>
            <span *ngIf="teacher.conversion === null || teacher.conversion === -1">-</span>
          </td>
          <td class="no-show-mobile" nzEllipsis nz-tooltip [nzTooltipTitle]="teacher.qualification | translate">
            {{ teacher.qualification | translate }}
          </td>
          <td class="no-show-mobile">{{ teacher.tutoringExperience | translate }}</td>
          <td class="no-show-mobile">
            <ng-container *ngFor="let exam of teacher.examPreparation; let last = last">
              <span class="exam">{{ exam | translate }}{{ !last ? ', ' : '' }}</span>
            </ng-container>
          </td>
        </tr>
        <tr [nzExpand]="teacher.id === expandedTeacherId" class="week-switcher-row">
          <mathema-week-switcher
            *ngIf="teacher.id === expandedTeacherId"
            (onGoToLessons)="openTeacherLessonsPage(teacher.id)"
            [showLessonsLink]="true"
          ></mathema-week-switcher>
        </tr>
        <tr [nzExpand]="teacher.id === expandedTeacherId" class="calendar-row">
          <mathema-week-schedule
            *ngIf="teacher.id === expandedTeacherId"
            [teacherId]="teacher.id"
          ></mathema-week-schedule>
        </tr>
      </ng-container>
      </tbody>
    </nz-table>
  </nz-layout>
</nz-layout>

<nz-modal
  [nzContent]="filtersComponent"
  [(nzVisible)]="modalFiltersOpened"
  [nzTitle]="'free_hours.filters' | translate"
  nzClassName="filters-mobile"
  (nzOnCancel)="modalFiltersOpened=false"
  (nzOnOk)="modalFiltersOpened=false"
>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="modalFiltersOpened=false"
            class="btn apply-btn">{{ 'free_hours.btn_apply' | translate }}
    </button>
    <button nz-button nzType="default" (click)="resetAllFilters(); modalFiltersOpened=false" nzDanger
            class="btn reset-btn">{{ 'free_hours.reset_all_filters' | translate }}
    </button>
  </div>
</nz-modal>

<ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span>
</ng-template>
<ng-template #loader>
  <nz-spin style="display: flex; height: 100%; align-items: center; justify-content: center;" nzSimple
           [nzIndicator]="indicatorTemplate"></nz-spin>
</ng-template>

<ng-template #noResults>
  <div class="image">
    <img src="./assets/noResults.png" alt="">
  </div>
</ng-template>

<ng-template #filtersComponent>
  <mat-free-hours-filters
    [activeFilters]="activeFilters"
    [resetFilterEvent]="resetFilterSubject.asObservable()"
    [resetAllFiltersEvent]="resetAllFiltersSubject.asObservable()"
    (onResetFilter)="resetFilter($event)"
    (onChangeFilterValue)="changeFilterValue($event)"
    (onSetFilterValue)="setFilterValue($event)"
  ></mat-free-hours-filters>
</ng-template>

<!-- Create first lesson drawer -->
<nz-drawer
  *ngIf="!isFirstLessonDrawerCollapsed"
  [nzClosable]="false"
  (nzOnClose)="onCloseFirstLessonForm()"
  [nzVisible]="!isFirstLessonDrawerCollapsed"
  [nzContent]="firstLessonComponent"
  nzPlacement="right"
  [nzWidth]="365"
  [nzTitle]="null"
  nzWrapClassName="create-first-lesson-drawer"
></nz-drawer>

<!-- Create first lesson modal -->
<nz-modal
  [nzClosable]="false"
  [nzContent]="firstLessonComponent"
  [nzVisible]="!isFirstLessonModalClosed" (nzVisibleChange)="isFirstLessonModalClosed = $event"
  [nzTitle]="null"
  nzClassName="create-first-lesson-mobile"
  (nzOnCancel)="onCloseFirstLessonForm()"
  (nzOnOk)="onCloseFirstLessonForm()"
  [nzFooter]="null"
></nz-modal>

<!-- Create first lesson templates -->
<ng-template #firstLessonComponent>
  <mathema-schedule-calendar-first-lesson
    (onCancel)="onCloseFirstLessonForm()"></mathema-schedule-calendar-first-lesson>
</ng-template>
