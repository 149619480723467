<div class="month">
  <ng-container *ngIf="calendarView$">
    <ng-container *ngIf="calendarView$ | async as days; else loadingOrError">
      <div class="week-days" *ngIf="!mobileMode">
        <div class="week-day" *ngFor="let index of weekDayIndexes">{{formatWeekDayByIdx(index) | titlecase}}</div>
      </div>
      <div class="days">
        <ng-container *ngIf="!mobileMode">
          <div class="day empty" *ngFor="let emptyDay of getEmptyArray(days['offset'])">
            <div class="day-number placeholder">
              <div class="value">0</div>
            </div>
          </div>
        </ng-container>
        <div
          class="day"
          [class.mobile]="mobileMode"
          *ngFor="let day of days | keyvalue:sortObjectNumericKeys; first as first"
          [class.hidden]="day.key === 'offset'"
          [class.empty]="isDayEmpty(day.value)"
          [class.selected]="day.value['date'] === selectedDayDate"
          (click)="toggleSelectedDay(day.value['date'])"
        >
          <div
            class="vacation"
            [class.visible]="day.value['isVacation'] === true"
            nz-tooltip
            [nzTooltipTitle]="'calendar.vacations.vacation-day' | translate"
            [nzTooltipTrigger]="day.value['isVacation'] === true ? 'hover' : null"
          ></div>
          <div
            *ngIf="day.key !== 'offset'"
            class="day-number"
            [class.today]="day.value['isToday'] === true"
            [class.mobile]="mobileMode"
          >
            <ng-container *ngIf="mobileMode; else dayNumber">
              {{formatMobileMonthDay(day.value.date) | titlecase}}
            </ng-container>
            <ng-template #dayNumber>
              <div class="value">{{day.key}}</div>
            </ng-template>
          </div>
          <div class="items">
            <ng-container *ngIf="!dealMode && !freeSlotsMode">
              <div
                [dndDraggable]="item"
                [dndDisableDragIf]="!item.isLesson || item.isConducted || (authService.isTeacher() && isMoveDisabledForTeacher(item)) || (authService.isClient() && isMoveDisabledForClient(item))"
                dndEffectAllowed="move"
                *ngFor="let item of day.value['items']"
                class="drag-wrapper"
              >
                <mathema-schedule-calendar-slot [item]="item" [viewType]="'month'" [isVacation]="day.value['isVacation']"></mathema-schedule-calendar-slot>
              </div>
            </ng-container>
            <ng-container *ngIf="dealMode">
              <div *ngFor="let item of dealService.dealCalendarDays.value[day.value['date']]?.items">
                <ng-container *ngIf="!dealService.isShowOnlyFreeSlots.value">
                  <mathema-schedule-calendar-slot [dealMode]="dealMode" [item]="item" [viewType]="'month'" [isVacation]="day.value['isVacation']"></mathema-schedule-calendar-slot>
                </ng-container>
                <ng-container *ngIf="dealService.isShowOnlyFreeSlots.value && !item.isLesson">
                  <mathema-schedule-calendar-slot [dealMode]="dealMode" [item]="item" [viewType]="'month'" [isVacation]="day.value['isVacation']"></mathema-schedule-calendar-slot>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="freeSlotsMode">
              <div
                *ngIf="!freeSlotsService.activeDateForNewSlot.value && !isDateYesterdayOrBefore(day.value['date'])"
                class="add-free-slot-trigger"
                (click)="$event.stopPropagation(); onOpenAddNewFreeSlot(day.value['date'])">
                <div class="icon">
                  <mathema-slot-icon [type]="'add-slot'" color="#6FE574"></mathema-slot-icon>
                </div>
                <div class="icon-hover">
                  <mathema-slot-icon [type]="'add-slot'" color="#fff"></mathema-slot-icon>
                </div>
                <div class="text">{{'calendar.month.add-free-hours' | translate}}</div>
              </div>
              <div *ngFor="let item of freeSlotsService.freeSlotsCalendarDays.value[day.value['date']]?.items">
                <mathema-schedule-calendar-slot [freeSlotsMode]="freeSlotsMode" [item]="item" [viewType]="'month'" [isVacation]="day.value['isVacation']"></mathema-schedule-calendar-slot>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingOrError>
  <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
