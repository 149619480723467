import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'math-lesson-rate',
  templateUrl: './math-lesson-rate.component.html',
  styleUrls: ['./math-lesson-rate.component.scss'],
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class MathLessonRateComponent {
  @Input() value: number;
  @Input() disabled: boolean;
  @Input() size = 16;
  @Output() valueChange = new EventEmitter<number>();
}
