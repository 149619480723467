import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/components/auth/auth.guard';
import { AppRoutesDefinitions } from "./app.routes";
import { ChangeUserInfoComponent } from "./modules/components/change-user-info/component/change-user-info.component";
import { ViewUserInfoComponent } from "./modules/components/view-user-info/component/view-user-info.component";
import { FreeHoursComponent } from './modules/components/free-hours/free-hours.component';
import { SigninComponent } from './modules/components/auth/signin/signin.component';
import { SignupComponent } from './modules/components/auth/signup/signup.component';
import { PasswordRestoreComponent } from './modules/components/auth/password-restore/password-restore.component';
import { SignupSuccessComponent } from './modules/components/auth/signup-success/signup-success.component';
import { ScheduleStartComponent } from './modules/components/schedule/start/schedule-start.component';
import { ScheduleCalendarComponent } from './modules/components/schedule/calendar/schedule-calendar.component';
import { UserRole } from './modules/components/auth/auth.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRoutesDefinitions.FREE_HOURS,
  },
  {
    path: AppRoutesDefinitions.LOGIN,
    component: SigninComponent,
  },
  {
    path: AppRoutesDefinitions.VIEW_USER_INFO,
    component: ViewUserInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER] }
  },
  {
    path: AppRoutesDefinitions.PROFILE_CREATION,
    component: ChangeUserInfoComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER] }
  },
  {
    path: AppRoutesDefinitions.FREE_HOURS,
    component: FreeHoursComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER] }
  },
  {
    path: AppRoutesDefinitions.SIGNUP,
    component: SignupComponent
  },
  {
    path: AppRoutesDefinitions.SIGNUP + '/:id',
    component: SignupComponent
  },
  {
    path: AppRoutesDefinitions.SIGNUP_SUCCESS,
    component: SignupSuccessComponent,
  },
  {
    path: AppRoutesDefinitions.PASSWORD_RESTORE,
    component: PasswordRestoreComponent
  },
  {
    path: AppRoutesDefinitions.CALENDAR_START,
    component: ScheduleStartComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER] }
  },
  {
    path: AppRoutesDefinitions.CALENDAR,
    component: ScheduleCalendarComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER, UserRole.CLIENT] },
  },
  {
    path: AppRoutesDefinitions.CALENDAR + '/:contact',
    component: ScheduleCalendarComponent,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER] }
  },
  {
    path: AppRoutesDefinitions.DEAL,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER] },
    loadChildren: () => import('./modules/components/deal/deal.module').then(m => m.DealModule),
  },
  {
    path: AppRoutesDefinitions.FREE_SLOTS,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.TEACHER] },
    loadChildren: () => import('./modules/components/free-slots/free-slots.module').then(m => m.FreeSlotsModule),
  },
  {
    path: AppRoutesDefinitions.USERS,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER] },
    loadChildren: () => import('./modules/components/users/users.module').then(m => m.UsersModule),
  },
  {
    path: AppRoutesDefinitions.STUDENTS,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER,  UserRole.TEACHER]},
    loadChildren: () => import('./modules/components/students/students.module').then(m => m.StudentsModule),
  },
  {
    path: AppRoutesDefinitions.STUDENT_JOURNAL,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN,  UserRole.MANAGER, UserRole.TEACHER, UserRole.CLIENT]},
    loadChildren: () => import('./modules/components/student-journal/student-journal.module').then(m => m.StudentJournalModule),
  },
  {
    path: AppRoutesDefinitions.CLIENT_PROFILE,
    canActivate: [AuthGuard],
    data: { roles: [UserRole.ADMIN, UserRole.MANAGER, UserRole.CLIENT]},
    loadChildren: () => import('./modules/components/client-profile/client-profile.module').then(m => m.ClientProfileModule),
  },
  {
    path: '**',
    redirectTo: AppRoutesDefinitions.FREE_HOURS,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
