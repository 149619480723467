import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {
  AchievementModel,
  GetAllAchievementsArrByLang, GetRewardsModel, LevelModel
} from "../../models/indicators";
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDialog } from '@angular/material/dialog';
import { TeacherStatementModalComponent } from '../teacher-statement-modal/teacher-statement-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { I18nLanguage } from '@shared/language/language.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent {
  @Input() public isTeacher: boolean = false;
  @Input() public lessonsPassed: number = 0;
  @Input() public set obtData(data: GetRewardsModel) {
    if(data){
      this._obtData = data;
      this.achievementsArr.forEach((item, index)=>{
        if (data.rewards.some(elem=>elem.name === item.name)){
          this.achievementsArr[index].state = true;
        }
      })
    }
  };
  @Input() public userLevels: LevelModel[] = [];
  public _obtData?: GetRewardsModel;
  public achievementsArr: AchievementModel[] = JSON.parse(JSON.stringify(GetAllAchievementsArrByLang(this.translateService.currentLang)));
  public currentPosition: number = 0;
  public page: number = 0;
  @Output() public changeButtonClick: EventEmitter<void> = new EventEmitter<void>();

  public readonly i18nLanguage: typeof I18nLanguage = I18nLanguage;

  constructor(
    public readonly translateService: TranslateService,
    public readonly authService: AuthService,
    private readonly statementDialog: MatDialog
  ) {}

  public clickChangeInfoBtn(): void {
    this.changeButtonClick.emit();
  }

  public getAchievementsWidth(img:  HTMLDivElement, index: number): void {
    this.achievementsArr[index].widthPx = img.offsetWidth;
  }

  public previousBtnClick(): void {
    const tmp: AchievementModel[] = JSON.parse(JSON.stringify(this.achievementsArr)).filter(item=> item.widthPx !== 0);
    if (this.page === 0){
      return;
    }
    this.page--
    this.currentPosition = this.currentPosition + tmp[this.page].widthPx;
    if(this.page===0 && this.currentPosition !== 0){
      this.currentPosition = 0;
    }
  }
  public nextBtnClick(): void {
    if(this.currentPosition === 0){
      this.currentPosition = -10;
    }
    const tmp: AchievementModel[] = JSON.parse(JSON.stringify(this.achievementsArr)).filter(item=> item.widthPx !== 0);
    if (this.page + 5  >= this.getNumberOfPages()){
      return;
    }
    this.page++
    this.currentPosition = this.currentPosition - tmp[this.page].widthPx;
  }

  public getNumberOfPages(): number {
    return JSON.parse(JSON.stringify(this.achievementsArr)).filter(item=> item.widthPx !== 0).length;
  }
  public getNextLevelData(): LevelModel | undefined {
    if(!this.userLevels || !this._obtData) {
      return undefined
    }
    let tmp: number;
    if(this.userLevels.some(item=> item.id === this._obtData.level.id)){
      tmp = this.userLevels.findIndex(item=>item.id === this._obtData.level.id)
    }
    if (tmp === this.userLevels.length-1){
      return this.userLevels[tmp];
    } else {
      return this.userLevels[tmp + 1];
    }
  }

  public getPercentageToNextLevel(previousLevelPoints: number, curPoints: number, nextLevelPoints: number): number | undefined {
    if ((!previousLevelPoints && previousLevelPoints!==0) || (!curPoints && curPoints!==0) || (!nextLevelPoints && nextLevelPoints!==0)){
      return undefined;
    }
    return ((curPoints - previousLevelPoints)/(nextLevelPoints - previousLevelPoints))*100
  }

  public findMaxLevelValue(): number {
    return Math.max(...this.userLevels.map(item=>item.pointsFrom)) || 0;
  }

  public openStatementWindow(): void {
    this.statementDialog.open(TeacherStatementModalComponent);
  }
}
