import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, finalize, switchMap, take, takeUntil, throttleTime } from 'rxjs/operators';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from '../../auth/auth.service';
import ScheduleCalendarService, { ScheduleCalendarType } from './schedule-calendar.service';
import { ScheduleYearCalendarComponent } from './schedule-year-calendar/schedule-year-calendar.component';
import { ScheduleMonthCalendarComponent } from './schedule-month-calendar/schedule-month-calendar.component';
import { ScheduleWeekCalendarComponent } from './schedule-week-calendar/schedule-week-calendar.component';
import { ScheduleCalendarHostDirective } from './schedule-calendar-host.directive';
import { ScheduleDayCalendarComponent } from './schedule-day-calendar/schedule-day-calendar.component';
import {
  MathemaModalConfirmComponent,
  MathemaModalConfirmParams
} from '@shared/components/modal-confirm/modal-confirm.component';
import ScheduleApiService from '../schedule-api.service';
import { Router } from '@angular/router';
import { AppRoutesDefinitions } from '@app/app.routes';
import LanguageService from '@shared/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import LessonDto, { LessonType } from '@shared/services/lessons/dto/lesson.dto';
import { SkipLessonModalComponent } from './modals/skip-lesson-modal/skip-lesson-modal.component';
import { ReportModalComponent } from '@modules/components/schedule/calendar/modals/report-modal/report-modal.component';
import { AutoUnsubscribeComponent } from '@shared/components/auto-unsubscribe/auto-unsubscribe.component';
import { ReportType } from '../dto/report.dto';
import { TeacherStatus } from '../dto/teacher.dto';
import { MainLayoutService } from '@app/layouts/main';


@Component({
  selector: 'mathema-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarComponent extends AutoUnsubscribeComponent implements OnInit, OnDestroy {

  public mobileMode: boolean;
  public tabletMode: boolean;

  public isFiltersSidebarCollapsed: boolean = false;
  public isFiltersDrawerClosed: boolean = true;
  public isFiltersModalClosed: boolean = true;

  public isClientFiltersSidebarCollapsed: boolean = false;
  public isClientFiltersDrawerClosed: boolean = true;
  public isClientFiltersModalClosed: boolean = true;

  public isLessonDetailsDrawerClosed: boolean = true;
  public isLessonDetailsModalClosed: boolean = true;

  public activeLessonToConduct: LessonDto | null = null;
  public isConductInsteadSkip: boolean = false;
  public isConductLessonModalClosed: boolean = true;

  public isAddMenuOpened: boolean = false;

  public isFirstLessonDrawerCollapsed: boolean = true;
  public isFirstLessonModalClosed: boolean = true;

  public isVacationsModalClosed: boolean = true;

  public isMoveLessonSidebarCollapsed: boolean = true;
  public isMoveLessonDrawerCollapsed: boolean = true;
  public isMoveLessonModalClosed: boolean = true;

  public isUncoductedLessonsPresent: boolean = false;

  private subscriptions: Subscription[] = [];

  @ViewChild(ScheduleCalendarHostDirective, { static: true }) calendarHost: ScheduleCalendarHostDirective;

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly modalService: NzModalService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private readonly mainLayoutService: MainLayoutService,
  ) {
    super();
    this.breakpointObserver.observe(['(576px <= width <= 991px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.tabletMode = result.matches;
      });
    this.breakpointObserver.observe(['(max-width: 575px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
      this.mobileMode = result.matches;
    });
  }

  public ngOnInit(): void {
    this.mainLayoutService.setLoading(true);

    if (this.authService.isTeacher()) {
      combineLatest([
        this.scheduleCalendarService.calendarDate,
        this.scheduleCalendarService.calendarFilters,
      ])
        .pipe(
          throttleTime(100),
          takeUntil(this.destroy$),
          switchMap(() => this.scheduleApiService.checkNotConductedLessons())
          )
        .subscribe((value) => {
          this.isUncoductedLessonsPresent = value;
        });

      const teacherId = this.authService.user.teacherId;

      const filters = this.scheduleCalendarService.calendarFilters.value;
      this.scheduleCalendarService.calendarFilters.next({
        ...filters,
        teacherId,
      });

      const subscription = this.scheduleApiService.getTeacherById(teacherId).subscribe((teacher) => {
        this.scheduleCalendarService.activeTeacher.next(teacher);
        this.subscribeToCalendarType();
      });

      this.subscriptions.push(subscription);
    } else if (!this.authService.isTeacher()) {
      this.subscribeToCalendarType();
    }

    this.subscribeToActiveSlot();
  }

  private subscribeToCalendarType(): void {
    this.subscriptions.push(
      this.scheduleCalendarService.calendarType.subscribe(calendarType => this.renderCalendar(calendarType)),
    );
  }

  public onCloseLessonDetails(keepActiveSlot: boolean = false): void {
    if (!this.mobileMode) {
      this.isLessonDetailsDrawerClosed = true;
    } else {
      this.isLessonDetailsModalClosed = true;
    }

    if (keepActiveSlot) {
      return;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenFirstLessonForm(): void {
    this.onCloseMoveLesson(true);

    if (this.mobileMode) {
      this.isFirstLessonModalClosed = false;
    } else {
      this.isFirstLessonDrawerCollapsed = false;
    }
  }

  public onOpenVacations(): void {
    this.onCloseMoveLesson(true);
    this.isVacationsModalClosed = false;
  }

  public onCloseFirstLessonForm(): void {
    if (this.mobileMode) {
      this.isFirstLessonModalClosed = true;
    } else {
      this.isFirstLessonDrawerCollapsed = true;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onCloseVacations(): void {
    this.isVacationsModalClosed = true;
    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenMoveLesson(): void {
    if (this.mobileMode) {
      this.isMoveLessonModalClosed = false;
    } else if (this.tabletMode) {
      this.isMoveLessonDrawerCollapsed = false;
    } else {
      this.isMoveLessonSidebarCollapsed = false;
    }
  }

  public onCloseMoveLesson(keepActiveSlot: boolean = false): void {
    if (this.mobileMode) {
      this.isMoveLessonModalClosed = true;
    } else if (this.tabletMode) {
      this.isMoveLessonDrawerCollapsed = true;
    } else {
      this.isMoveLessonSidebarCollapsed = true;
    }

    if (keepActiveSlot) {
      return;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public onOpenSkipLesson(lesson: LessonDto): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzTitle: null,
      nzFooter: null,
      nzCentered: true,
      nzWrapClassName: 'skip-lesson-modal',
      nzContent: SkipLessonModalComponent,
      nzData: {
        showNotification: this.isSendNotificationNeeded(),
      },
    });

    modalRef.componentInstance.onSuccess.subscribe(() => {
      this.onCloseLessonDetails();
      this.scheduleCalendarService.refreshCalendarDate();
    });

    modalRef.componentInstance.onOpenConduct.subscribe(() => {
      this.activeLessonToConduct = lesson;
      this.forceConductLesson$(true)
        .pipe(
          take(1),
          finalize(() => this.onLessonConducted())
        ).subscribe();
    });
  }

  public openConductLesson(lesson: LessonDto): void {
    this.activeLessonToConduct = lesson;

    if (lesson.type === LessonType.REGULAR) {
      if (!this.authService.isTeacher()) {
        this.onForceConductLesson();
      } else {
        this.isConductLessonModalClosed = false;
      }
    } else {
      if (!this.authService.isTeacher()) {
        this.onForceConductFirstLesson();
      } else {
        this.openReportModal();
      }
    }
  }

  private forceConductLesson$(isConductInsteadSkip = false): Observable<void> {
    const formData = new FormData();
    formData.append('body', JSON.stringify({
      isConductInsteadSkip,
    }));

    return this.scheduleApiService.conductRegularLesson(this.scheduleCalendarService.activeSlot.value.slotItemId, formData);
  }

  private openReportModal(): void {
    const modalRef = this.modalService.create({
      nzClosable: true,
      nzFooter: null,
      nzCentered: true,
      nzTitle: this.translateService.instant('calendar.report.title-diagnostic', {
        studentName: this.activeLessonToConduct?.student?.lastName + ' ' + this.activeLessonToConduct?.student?.firstName,
      }),
      nzClassName: this.mobileMode ? 'calendar-modal-mobile' : 'calendar-modal',
      nzBodyStyle: { padding: '0' },
      nzContent: ReportModalComponent,
      nzData: {
        firstLessonId: this.activeLessonToConduct?.id,
        studentId: this.activeLessonToConduct?.student.id,
        dealId: this.activeLessonToConduct?.deal.id,
      },
    });

    modalRef.componentInstance.onSuccess
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        modalRef.close();
        this.onLessonConducted();
      });

    modalRef.componentInstance.onCancel
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        modalRef.close();
        this.onCloseConductLesson();
      });
  }

  public onCloseConductLesson(): void {
    this.activeLessonToConduct = null;
    this.isConductInsteadSkip = false;
    this.isConductLessonModalClosed = true;
  }

  public onLessonConducted(): void {
    this.activeLessonToConduct = null;
    this.isConductInsteadSkip = false;
    this.isConductLessonModalClosed = true;
    this.onCloseLessonDetails();
    this.scheduleCalendarService.refreshCalendarDate();
  }

  public toggleFiltersDrawer(value: boolean): void {
    if (this.authService.isClientOrStudent()) {
      this.isClientFiltersDrawerClosed = value;
    } else {
      this.isFiltersDrawerClosed = value;
    }
  }

  public toggleFiltersSidebar(value: boolean): void {
    if (this.authService.isClientOrStudent()) {
      this.isClientFiltersSidebarCollapsed = value;
    } else {
      this.isFiltersSidebarCollapsed = value;
    }
  }

  public toggleFiltersModal(value: boolean): void {
    if (this.authService.isClientOrStudent()) {
      this.isClientFiltersModalClosed = value;
    } else {
      this.isFiltersModalClosed = value;
    }
  }

  private onForceConductLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.conduct-lesson.title'),
      message: this.translateService.instant('calendar.conduct-lesson.message-conduction'),
      acceptText: this.translateService.instant('main.btn.yes'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      modalRef.componentInstance.isLoading = true;

      this.forceConductLesson$()
        .pipe(
          finalize(() => {
            modalRef.close();
            this.onCloseLessonDetails();
          }),
          take(1)
        )
        .subscribe(() => {
          this.scheduleCalendarService.refreshCalendarDate();
        });
    });
  }

  private onForceConductFirstLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.conduct-lesson.title'),
      message: this.translateService.instant('calendar.conduct-lesson.message-conduction'),
      acceptText: this.translateService.instant('main.btn.yes'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

      modalRef.componentInstance.isLoading = true;

      const formData = new FormData();
      const body = {
        teacherId: this.activeLessonToConduct.teacher.id,
        lessonId: this.activeLessonToConduct.id,
        studentId: this.activeLessonToConduct.student.id,
        type: ReportType.DIAGNOSTIC,
      };

      formData.append('body', JSON.stringify(body));

      return this.scheduleApiService.conductFirstLesson(lessonId, formData)
        .pipe(
          finalize(() => {
            modalRef.close();
            this.onCloseLessonDetails();
          }),
          take(1)
        )
        .subscribe(() => {
          this.scheduleCalendarService.refreshCalendarDate();
        });
    })
  }

  public onOpenCancelConductLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.conduct-lesson.header_unconduction'),
      message: this.translateService.instant('calendar.conduct-lesson.message_unconduction'),
      acceptText: this.translateService.instant('main.btn.yes'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

      modalRef.componentInstance.isLoading = true;

      this.scheduleApiService.removeConductionFromLesson(lessonId).pipe(
        finalize(() => {
          modalRef.close();
          this.onCloseLessonDetails();
        }),
        take(1)
      ).subscribe(() => {
        this.scheduleCalendarService.refreshCalendarDate();
      });
    });
  }

  public onOpenCancelLesson(): void {
    this.openModalConfirm({
      header: this.translateService.instant('calendar.cancel-lesson.header'),
      message: this.translateService.instant('calendar.cancel-lesson.message'),
      acceptText: this.translateService.instant('calendar.lesson-details.cancel_lesson'),
      declineText: this.translateService.instant('main.btn.decline'),
    }, (modalRef) => {
      const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

      modalRef.componentInstance.isLoading = true;

      this.scheduleApiService.cancelLesson(lessonId).pipe(
        delay(2000),
        finalize(() => {
          modalRef.close();
          this.onCloseLessonDetails();
        })
      ).subscribe(() => {
        this.scheduleCalendarService.refreshCalendarDate();
      });
    });
  }

  private openModalConfirm(params: MathemaModalConfirmParams, acceptCallback: (modalRef: NzModalRef) => void): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzData: { params },
    });

    this.subscriptions.push(
      modalRef.componentInstance.onDecline.subscribe(() => {
        modalRef.close();
      }),
    );
    this.subscriptions.push(
      modalRef.componentInstance.onAccept.subscribe(acceptCallback.bind(this, modalRef)),
    );
  }

  public isSendNotificationNeeded(): boolean {
    return this.authService.isAdmin() || this.authService.isManager();
  }

  public getTeacherBlockedTooltipForStuff(notTakeNewStudentsAt: Date, isTeacherBlocked: boolean, blockedAt: Date): string {
    if (this.isTeacherResigned()) {
      return this.translateService.instant('calendar.slot.tooltips.teacher_resigned');
    }

    if (notTakeNewStudentsAt && !isTeacherBlocked) {
      return this.translateService.instant('calendar.slot.tooltips.teacher_not_take_students');
    }

    const unblockFrom = new Date(blockedAt);
    unblockFrom.setDate(unblockFrom.getDate() + 14);

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return this.translateService.instant('calendar.slot.tooltips.teacher_blocked_until', { unblockDate: formatter.format(unblockFrom) });
  }

  public getTeacherBlockedTooltipForTeacher(blockedAt: Date): string {
    const unblockFrom = new Date(blockedAt);
    unblockFrom.setDate(unblockFrom.getDate() + 14);

    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    return this.translateService.instant('calendar.slot.tooltips.teacher_blocked_self', { unblockDate: formatter.format(unblockFrom) });
  }

  private renderCalendar(calendarType: ScheduleCalendarType): void {
    const viewContainerRef = this.calendarHost.viewContainerRef;
    viewContainerRef.clear();

    let component;

    if (calendarType === ScheduleCalendarType.YEAR) {
      component = ScheduleYearCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.MONTH) {
      component = ScheduleMonthCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.WEEK) {
      component = ScheduleWeekCalendarComponent;
    } else if (calendarType === ScheduleCalendarType.DAY) {
      component = ScheduleDayCalendarComponent;
    }

    localStorage.setItem('activeCalendarType', calendarType as unknown as string);

    // pass data into component ref if need
    const componentRef = viewContainerRef.createComponent(component);
  }

  private get firstLessonActive(): boolean {
    return !this.isFirstLessonDrawerCollapsed || !this.isFirstLessonModalClosed;
  }

  private get moveLessonActive(): boolean {
    return !this.isMoveLessonDrawerCollapsed || !this.isMoveLessonModalClosed;
  }

  private subscribeToActiveSlot(): void {
    this.subscriptions.push(
      this.scheduleCalendarService.activeSlot.subscribe(activeSlot => {
        if (this.firstLessonActive) {
          this.onCloseFirstLessonForm();
        }

        if (this.moveLessonActive) {
          this.onCloseMoveLesson();
        }

        if (!activeSlot) {
          return;
        }

        const { type, freeSlotAction } = activeSlot;

        if (type === 'lesson') {
          this.mobileMode
            ? this.isLessonDetailsModalClosed = false
            : this.isLessonDetailsDrawerClosed = false;
        }

        if (type === 'freeSlot') {
          if (freeSlotAction === 'firstLesson') {
            this.onOpenFirstLessonForm();
          }
        }
      }),
    );
  }

  public onTodayClick(): void {
    const value = this.scheduleCalendarService.calendarType.value;
    this.scheduleCalendarService.calendarType.next(value);
    this.scheduleCalendarService.calendarDate.next(new Date());
  }

  public onNewDealClick(): void {
    this.router.navigate([AppRoutesDefinitions.DEAL]);
  }

  public onFreeSlotsClick(): void {
    this.router.navigate([AppRoutesDefinitions.FREE_SLOTS]);
  }

  public isTeacherResigned(): boolean {
    const teacher = this.scheduleCalendarService.activeTeacher.value;

    if (!teacher) {
      return false;
    }

    return [TeacherStatus.RESIGNS, TeacherStatus.RESIGNED].includes(teacher.status);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public openTeacherProfile(teacherId: string): void {
    this.router.navigate([AppRoutesDefinitions.VIEW_USER_INFO], {
      queryParams: {
        teacherId,
      },
    });
  }
}
