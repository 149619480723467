import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import LessonDto, { LessonAction, LessonHistoryContext, LessonType } from '@shared/services/lessons/dto/lesson.dto';
import ScheduleApiService from '../../schedule-api.service';
import ScheduleCalendarService from '../schedule-calendar.service';
import { catchError } from 'rxjs/operators';
import LanguageService from '../../../../../shared/language/language.service';
import { dateToYyyyMmDd, daysBetweenDates, isPastTime, timeSlotDiapasonToTimeString } from '@shared/utils';
import { AuthService } from '../../../auth/auth.service';
import { AppRoutesDefinitions } from '@app/app.routes';
import { Router } from '@angular/router';
import DealDto, { DealType, SellRepeatDealStage, StudyRequestDealStage } from '../../dto/deal.dto';
import InvoiceDto, { InvoicePaymentStatus, InvoiceProduct } from '../../dto/invoice.dto';
import { DateTime } from 'luxon';
import HistoryDto from '../../dto/history.dto';
import { UserRole } from '@app/shared';

@Component({
  selector: 'mathema-schedule-calendar-lesson-details',
  templateUrl: './schedule-calendar-lesson-details.component.html',
  styleUrls: ['./schedule-calendar-lesson-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarLessonDetailsComponent implements OnInit, OnDestroy {

  public userRole: typeof UserRole = UserRole;

  public lessonData$: Observable<LessonDto>;
  public loadingError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public dealType: typeof DealType = DealType;
  public studyRequestStages: typeof StudyRequestDealStage = StudyRequestDealStage;
  public sellRepeatStages: typeof SellRepeatDealStage = SellRepeatDealStage;

  public isStudentScheduleOpened = false;

  private subscriptions: Subscription[] = [];

  @Input() mobileMode: boolean;

  @Output() onEditFirstLesson: EventEmitter<void> = new EventEmitter<void>();
  @Output() onMoveLesson: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSkipLesson = new EventEmitter<LessonDto>();
  @Output() onConductLesson = new EventEmitter<LessonDto>();
  @Output() onCancelConductLesson = new EventEmitter<void>();
  @Output() onCancelLesson: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly languageService: LanguageService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    public readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  public ngOnInit(): void {
    this.subscriptions.push(this.scheduleCalendarService.activeSlot.subscribe(activeSlot => {
      if (!activeSlot) {
        return;
      }

      this.loadingError$.next(false);

      const { type } = activeSlot;
      if (type === 'lesson') {
        const [, lessonId] = activeSlot.id.split('|');
        this.lessonData$ = this.scheduleApiService.getLessonInfoById(lessonId).pipe(
          catchError(() => {
            this.loadingError$.next(true);
            return EMPTY;
          }),
        );
      } else {
        this.lessonData$ = EMPTY;
      }
    }));
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });

    return formatter.format(date);
  }

  public formatHistoryDate(dateString: string): string {
    const date = new Date(dateString);
    const formatter = Intl.DateTimeFormat(this.languageService.locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });

    return formatter.format(date);
  }

  public timeSlotToTime(timeSlots: number[]): string {
    return timeSlotDiapasonToTimeString(timeSlots);
  }

  public onEditDealClick(dealId: string): void {
    this.scheduleCalendarService.activeSlot.next(null);
    this.router.navigate([AppRoutesDefinitions.DEAL, dealId]);
  }

  public isNotFutureAndNotMoreThanTwoDaysAgo(lesson: LessonDto): boolean {
    const now = new Date();
    const nowYyMmDd = dateToYyyyMmDd(now);
    const { date, timeSlot } = lesson;

    if (nowYyMmDd === date) {
      const lessonTime = timeSlotDiapasonToTimeString(timeSlot);
      const [lessonStartTime] = lessonTime.split(' - ');
      const lessonStartTimeJsDate = new Date(`${date}T${lessonStartTime}`);
      return +lessonStartTimeJsDate < +now;
    }

    const daysBetweenTodayAndDate = daysBetweenDates(nowYyMmDd, date);
    return daysBetweenTodayAndDate >= 0 && daysBetweenTodayAndDate <= 2;
  }

  public getAllPaidLessonsFromDeal(deal: DealDto): number {
    return deal.lessons.filter((lesson) => lesson.type === 'regular' && lesson.status === 'booked').length;
  }

  public getPaidNotConductedLessonsFromDeal(deal: DealDto): number {
    return deal.lessons.filter(
      (lesson) => lesson.type === 'regular' && lesson.status === 'booked' && !lesson.isConducted,
    ).length;
  }

  public getAllReservedLessonsFromDeal(deal: DealDto): number {
    return deal.lessons.filter((lesson) => lesson.status === 'reserved').length;
  }

  public getPaidLessonsFromInvoices(invoices: InvoiceDto[]): number {
    if (!invoices?.length) {
      return 0;
    }

    const subscriptionRelatedInvoices = invoices
      .filter((invoice) => ![
        InvoiceProduct.FIRST_LESSON,
        InvoiceProduct.FIRST_LESSON_POLAND,
        InvoiceProduct.FREE_LESSON,
      ].includes(invoice.productId));

    if (!subscriptionRelatedInvoices?.length) {
      return 0;
    }

    const paidInvoices = subscriptionRelatedInvoices.filter(
      (invoice) => [InvoicePaymentStatus.PAID, InvoicePaymentStatus.OVERPAID].includes(invoice.paymentStatusId),
    );

    if (!paidInvoices?.length) {
      return 0;
    }

    return paidInvoices.reduce((acc, invoice) => acc + invoice.numberOfLessons, 0);
  }

  public formatParentComment(comment: string): string {
    if (!comment) {
      return '';
    }

    return comment
      .replace(new RegExp('\n\n', 'g'), '\n')
      .replace(new RegExp('\n', 'g'), '<br />');
  }

  public openAccountInfoPage(teacherId: string): void {
    const url = this.router.createUrlTree([AppRoutesDefinitions.VIEW_USER_INFO], {
      queryParams: {
        teacherId,
      }
    });
    window.open(url.toString(), '_blank');
  }

  public isLessonMoveAllowed(item: LessonDto): boolean {
    if (this.authService.isTeacher()) {
      const date = DateTime.fromFormat(item.date, 'yyyy-MM-dd').plus({ days: 2 }).toSQLDate();
      return item.type === LessonType.REGULAR && !isPastTime(date, timeSlotDiapasonToTimeString(item.timeSlot));
    } else if (this.authService.isClient()) {
      const [lessonStart] = timeSlotDiapasonToTimeString(item.timeSlot).split(' - ');
      const lessonTime = DateTime.fromSQL(`${item.date} ${lessonStart}`);
      const now = DateTime.now();

      return item.type === LessonType.REGULAR && now.plus({ hour: 3 }) <= lessonTime;
    }

    return true;
  }

  public get isTeacher(): boolean {
    return this.authService.isTeacher();
  }

  public openStudentJournal(studentId: string): void {
    const url = this.router.createUrlTree([AppRoutesDefinitions.STUDENT_JOURNAL], {
      queryParams: {
        id: studentId,
      }
    });
    window.open(url.toString(), '_blank');
  }

  public filterAllowedHistoryRecords(history: HistoryDto<Partial<LessonDto>, LessonHistoryContext>): boolean {
    return history.context?.action && history.context?.action !== LessonAction.EDIT_CONDUCTED_LESSON;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}
