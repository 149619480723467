<input style="display: none" type="file" (change)="handlePhotoInputChange($event)" [accept]="photoAcceptanceFiles"
  [multiple]="false" #photo>

<div class="wrapper">
  <app-button class="close-btn" [buttonType]="'invincibleButton'" [customParams]="{customFontSize: '28px'}" icon="close"
    (btnClick)="closeDialog()"></app-button>
  <div class="header">
    {{'profile_settings' | translate}}
  </div>
  <div class="content">
    <mat-tab-group class="tab-group">
      <mat-tab [label]="'profile_photo' | translate">
        <div class="tab-photo-content">
          <div class="additional-text">
            <img src="../../../../../../assets/add-photo-tooltip.png" alt="adding">
            <span>{{'image_alert' | translate}}</span>
          </div>
          <div class="avatar">
            <img *ngIf="data.avatarLink else avatarLetter" [src]="data.avatarLink" alt="user avatar">
            <ng-template #avatarLetter>
              {{data.avatarLetter}}
            </ng-template>
          </div>
          <div class="buttons">
            <app-button [customParams]="{customWidth: '155px'}" (btnClick)="uploadNewPhoto()"
              [buttonType]="'loading-file-btn'" [text]="'add_photo' | translate"></app-button>
            <app-button [customParams]="{customWidth: '155px'}" (btnClick)="removePhoto()"
              [disabled]="!data.avatarLink || data.avatarLink === userPhotoLinks.noPhotoGirlLink || data.avatarLink === userPhotoLinks.noPhotoBoyLink"
              [buttonType]="'remove-file-btn'" [text]="'remove_photo' | translate"></app-button>
          </div>
        </div>
        <div class="footer">
          <app-button (btnClick)="closeDialog()" [buttonType]="'commonColourfulButton'"
            [text]="'done' | translate"></app-button>
        </div>
      </mat-tab>

      <mat-tab [label]="'email_and_password' | translate">
        <nz-layout class="tab-secure-content">
          <nz-row>
            <nz-col [nzXs]="24" [nzSm]="11" [nzMd]="11" [nzLg]="11" class="cell">
              <div class="email-label">{{'email' | translate}}</div>
              <div class="email-value">{{getUserEmail()}}</div>
            </nz-col>
          </nz-row>
          <nz-row class="cell changeButton-row">
            <nz-col>
              <app-button [text]="'changePassword' | translate" (btnClick)="onChangePassword()"
                [buttonType]="'commonColourfulButton'"></app-button>
            </nz-col>
          </nz-row>
        </nz-layout>
        <div class="footer">
          <app-button (btnClick)="closeDialog()" [buttonType]="'commonWhiteButton'" [text]="'cancel' | translate"></app-button>
        </div>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
