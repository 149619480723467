import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { StatementApiService } from '../../api-services/statement-api.service';
import { take } from 'rxjs/operators';
import { StatementModel } from '../../models/statement';
import * as moment from 'moment';
import { AuthService } from 'src/app/modules/components/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LessonType } from '../../../schedule/dto/lesson.dto';

@Component({
  selector: 'app-teacher-statement-modal',
  templateUrl: './teacher-statement-modal.component.html',
  styleUrls: ['./teacher-statement-modal.component.scss']
})
export class TeacherStatementModalComponent implements OnInit{

  loading = false;

  public dataSource: MatTableDataSource<IStatementRecord>;
  public displayedColumns: string[] = ['type', 'rate', 'amount', 'sum'];

  public teacherId: string;
  public statementPeriod = null;
  public total = 0;
  public region = null;

  constructor(
    public dialogRef: MatDialogRef<TeacherStatementModalComponent>,
    private route: ActivatedRoute,
    public statementApi: StatementApiService,
    private readonly translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(data => {
      if(data.teacherId)
        this.teacherId = data.teacherId;
      else if(this.authService.user.teacherId)
        this.teacherId = this.authService.user.teacherId;
    });
    this.reset();
  }

  periodChosen(result: Date[]) {
    if(result.length > 1)
      this.loadData(moment(result[0]).format('YYYY-MM-DD'), moment(result[1]).format('YYYY-MM-DD'));
  }

  reset(): void {
    this.dataSource = null;
    this.total = 0;
  }

  loadData(dateFrom: string, dateTo: string): void {
    this.loading = true;
    this.statementApi.getTeacherStatement$GET(this.teacherId, dateFrom, dateTo).pipe(take(1)).subscribe(statement => {
      this.loading = false;
      this.dataSource = new MatTableDataSource(this.createDataArray(statement));
      this.total = statement.total;
      this.region = statement.region;
    }, error => {
      this.reset();
      this.loading = false;
    }
    );
  }

  createDataArray(statement: StatementModel): Array<IStatementRecord> {
    const result = [];
    statement.records.forEach(record => {
      const r: IStatementRecord = {
        type: record.type,
        studentName: record.studentName,
        lessonsCount: record.lessonsCount,
        rate: record.rate,
        sum: record.sum
      };
      result.push(r);
    });
    return result;
  }

  getLessonTypeLabel(type: string): string {
    return (type == LessonType.REGULAR) ? this.translateService.instant('lessons-package') : this.translateService.instant('first-lesson');
  }

  isFirstLesson(type: string): boolean {
    return type == LessonType.FIRST;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

export interface IStatementRecord {
  type: string;
  studentName?: string;
  lessonsCount: number;
  rate: number;
  sum: number;
}
