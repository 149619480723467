<nz-rate
  style="--size:{{ size + 'px' }}"
  class="math-lesson-rate"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit($event)"
  [nzCharacter]="star"
  [nzDisabled]="disabled"
  [class.font-size]="'36px'"
></nz-rate>
<ng-template #star>
  <span nz-icon nzType="star" nzTheme="twotone"></span>
</ng-template>
