import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { slotTypeFilterInputs, teacherSlotTypeFilterInputsDefaults } from './schedule-calendar-filters.inputs';
import ScheduleApiService, { ContactListElement, LessonTypeFilter } from '../../schedule-api.service';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, pluck, tap } from 'rxjs/operators';
import ScheduleCalendarService from '../schedule-calendar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutesDefinitions } from 'src/app/app.routes';
import StudentsApiService from '@shared/services/students/students-api.service';
import StudentsListQueryDto from '@shared/services/students/dto/students-list-query.dto';
import { TeacherActiveStatuses, TeacherStatus } from '../../dto/teacher.dto';
import { Location } from '@angular/common';
type CheckboxElement = {
  value: string;
  label: string;
  checked?: boolean;
};

@Component({
  selector: 'mathema-schedule-calendar-filters',
  templateUrl: './schedule-calendar-filters.component.html',
  styleUrls: ['./schedule-calendar-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleCalendarFiltersComponent implements OnInit, OnDestroy {

  public slotTypeFilterInputs = slotTypeFilterInputs;
  public teacherSlotTypeFilterInputs = [...teacherSlotTypeFilterInputsDefaults];

  public isStudentsListLoading: boolean = false;

  public teachersList: Observable<ContactListElement[]>;
  public isTeachersListLoading: boolean = false;

  public filtersForm: UntypedFormGroup;

  private subscriptions: Subscription[] = [];

  public readonly teacherNameSearch: Subject<Event> = new Subject<Event>();
  public readonly studentNameSearch: Subject<Event> = new Subject<Event>();

  public teacherNameInput: string = '';
  public studentNameInput: string = '';

  constructor(
    public readonly authService: AuthService,
    public readonly scheduleApiService: ScheduleApiService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly studentsApiService: StudentsApiService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
  ) {
  }

  public ngOnInit(): void {
    this.initFiltersForm();
    this.setTeacherIdFromRoute();
    this.initStartConnection();
    if (this.authService.isTeacher()) {
      this.loadStudents('', this.getTeacherId());
    }
  }

  public isTeacherSelected(): boolean {
    return this.authService.isTeacher() || (this.filtersForm.controls['teacherId'].value != null && this.filtersForm.controls['teacherId'].value.length);
  }

  public isAnyStudentSelected(): boolean {
    return (
      Array.isArray(this.filtersForm.controls['studentsIds'].value) &&
      this.filtersForm.controls['studentsIds'].value.filter((s: CheckboxElement) => s.checked === true).length > 0
    );
  }

  public loadTeachers(search: string = '', studentIds: string[] = []): void {
    this.isTeachersListLoading = true;
    const status = [
      ...TeacherActiveStatuses,
      TeacherStatus.RESIGNS,
    ];
    this.teachersList = this.scheduleApiService.getTeachersList({ search, studentIds, status, limit: 50 }).pipe(
      finalize(() => this.isTeachersListLoading = false),
    );
  }

  public loadStudents(name: string = '', teacherId: string = ''): void {
    this.isStudentsListLoading = true;
    const params: StudentsListQueryDto = { search: name, limit: 50 };

    if (teacherId) {
      params.teacherId = teacherId;
    }

    this.studentsApiService.getList(params).pipe(
      map(result => result.map(student => ({
        label: `${student.lastName} ${student.firstName}`,
        value: student.id
      }))),
      finalize(() => this.isStudentsListLoading = false),
    ).subscribe(students => {
      this.filtersForm.controls['studentsIds'].setValue(students, { emitEvent: false });
    });
  }

  private initFiltersForm(): void {
    this.filtersForm = new UntypedFormGroup({
      studentsIds: new UntypedFormControl(),
    });
    this.listenStudentNameChanges();

    const filters = this.scheduleCalendarService.calendarFilters.value;
    if (this.authService.isAdmin() || this.authService.isManager()) {
      this.filtersForm.addControl('slotType', new UntypedFormControl(filters?.slotType || slotTypeFilterInputs[0].value));
      this.filtersForm.addControl('teacherId', new UntypedFormControl());
      this.listenTeacherNameChanges();
      this.listenTeacherIdChanges();
    } else if (this.authService.isTeacher()) {
      this.filtersForm.addControl('lessonTypes', new UntypedFormControl(filters?.lessonTypes));
    }

    this.listenFormValueChanges();
  }

  private listenFormValueChanges(): void {
    const subscription = this.filtersForm.valueChanges.pipe(debounceTime(300)).subscribe(formValue => {
      const studentsIds = this.getCheckedStudentsIds(formValue.studentsIds);
      const teacherId = this.authService.isTeacher() ? this.authService.user.teacherId : formValue.teacherId;
      const slotType = formValue.slotType;
      const lessonTypes = formValue.lessonTypes;

      const filters = {
        studentsIds,
        teacherId,
        slotType,
        lessonTypes,
      }

      for (const key in filters) {
        if (Array.isArray(filters[key]) && !filters[key].length) {
          delete filters[key];
        } else if (!filters[key]) {
          delete filters[key];
        }
      }

      this.scheduleCalendarService.calendarFilters.next(filters);
    });

    this.subscriptions.push(subscription);

    if (this.authService.isAdmin() || this.authService.isManager()) {
      this.subscriptions.push(
        this.filtersForm.controls['teacherId'].valueChanges.subscribe(teacherId => {
          if (teacherId && !this.isAnyStudentSelected()) this.loadStudents('', teacherId);
        }));

      this.subscriptions.push(
        this.filtersForm.controls['studentsIds'].valueChanges.subscribe(studentsIds => {
          if (!this.isTeacherSelected() && this.getCheckedStudentsIds(studentsIds).length) this.loadTeachers('', this.getCheckedStudentsIds(studentsIds));
        }));
    }
  }

  private initStartConnection(): void {
    if (this.scheduleCalendarService.isContactsCached()) {
      this.scheduleCalendarService.calendarFilters.subscribe(filters => {

        if (filters.teacherId && (this.getTeacherId() !== filters.teacherId || !this.isTeacherSelected())) {
          this.teachersList = this.scheduleApiService.getTeacherById(filters.teacherId).pipe(
            tap(contact => {
              this.scheduleCalendarService.activeTeacher.next(contact);
            }),
            map(contact => Array.of(contact))
          );
          this.filtersForm.controls['teacherId'].setValue(filters.teacherId, { emitEvent: false });
          if (!this.isAnyStudentSelected() && (!filters.studentsIds || !filters.studentsIds.length)) this.loadStudents('', filters.teacherId);
        }

        if (filters.studentsIds && filters.studentsIds.length && !this.isAnyStudentSelected()) {
          this.studentsApiService.getList({ ids: filters.studentsIds }).pipe(
            map(students => {
              const list: CheckboxElement[] = [];
              students.forEach(s => {
                list.push({
                  label: `${s.lastName} ${s.firstName}`,
                  value: s.id,
                  checked: true
                });
              });
              return list;
            })
          ).subscribe(students => {
            this.filtersForm.controls['studentsIds'].setValue(students, { emitEvent: false });
            if (!this.isTeacherSelected()) this.loadTeachers('', filters.studentsIds);
          });
        }

        // if (filters.slotType && (this.authService.isAdmin() || this.authService.isManager())) {
        //   this.filtersForm.controls['slotType'].setValue(filters.slotType, { emitEvent: false });
        // }

        if (filters.lessonTypes && this.authService.isTeacher()) {
          filters.lessonTypes.forEach(type => {
            this.teacherSlotTypeFilterInputs.find((slot) => slot.value === type).checked = true;
          });
        }
      });
    } else {
      if (this.authService.isAdmin() || this.authService.isManager()) {
        this.router.navigate([AppRoutesDefinitions.CALENDAR_START]);
      }
    }

  }

  public resetTeachersFilter(): void {
    this.filtersForm.controls['teacherId'].reset();
    this.teacherNameInput = '';
    this.teachersList = of([]);

    this.filtersForm.controls['slotType'].setValue(slotTypeFilterInputs[0].value);
    if (this.isAnyStudentSelected()) {
      const studentsIds = this.getCheckedStudentsIds(this.filtersForm.controls['studentsIds'].value);
      this.loadTeachers('', studentsIds);
    }
  }

  public resetStudentsFilter(): void {
    this.filtersForm.controls['studentsIds'].reset();
    this.studentNameInput = '';
    if (this.isTeacherSelected())
      this.loadStudents('', this.getTeacherId());
  }

  public isClearAvailable(): boolean {
    if (this.authService.isTeacher()) return this.isAnyLessonTypeSelected();
    if (this.isTeacherSelected() || this.isAnyStudentSelected()) return true;
    return this.isStudentsListPresent();
  }

  private isAnyLessonTypeSelected(): boolean {
    return this.teacherSlotTypeFilterInputs.filter(type => type.checked).length > 0;

  }

  public clearFilters(): void {
    if (this.authService.isTeacher()) {
      this.teacherSlotTypeFilterInputs.forEach(type => type.checked = false);
      this.filtersForm.controls['lessonTypes'].patchValue([]);
      this.filtersForm.controls['studentsIds'].reset();
      this.studentNameInput = '';
      this.loadStudents('', this.authService.user.teacherId);
      return;
    }
    this.filtersForm.controls['teacherId'].reset();
    this.teacherNameInput = '';
    this.teachersList = of([]);
    this.filtersForm.controls['studentsIds'].reset();
    this.studentNameInput = '';
    this.filtersForm.controls['slotType'].patchValue(slotTypeFilterInputs[0].value);
  }

  public isStudentsListPresent(): boolean {
    return Array.isArray(this.filtersForm.controls['studentsIds'].value) && this.filtersForm.controls['studentsIds'].value.length > 0;
  }

  public selectLessonTypes($event: LessonTypeFilter[]): void {
    this.filtersForm.controls['lessonTypes'].setValue($event);
  }

  private getTeacherId(): string {
    return this.authService.isTeacher() ? this.authService.user.teacherId : this.filtersForm.controls['teacherId'].value;
  }

  private getCheckedStudentsIds(students: CheckboxElement[]): string[] {
    if (students)
      return students.filter((s: CheckboxElement) => s.checked === true)
        .reduce((ids: Array<string>, s: CheckboxElement) => { ids.push(s.value); return ids }, new Array<string>());
    else return [];
  }

  private listenTeacherNameChanges(): void {
    const subscription = this.teacherNameSearch.pipe(
      pluck('target', 'value'),
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe((value: string) => {
      this.loadTeachers(value);
    });

    this.subscriptions.push(subscription);
  }

  private listenTeacherIdChanges(): void {
    this.subscriptions.push(
      this.filtersForm.controls['teacherId'].valueChanges.subscribe((value: string) => {
        if (!value) {
          const filters = this.scheduleCalendarService.calendarFilters.value;
          delete filters.teacherId;
          this.scheduleCalendarService.calendarFilters.next(filters);

          this.scheduleCalendarService.activeTeacher.next(null);
        } else {
          const subscription = this.scheduleApiService.getTeacherById(value).subscribe((teacher) => {
            this.scheduleCalendarService.activeTeacher.next(teacher);
          });

          this.subscriptions.push(subscription);
        }
      }),
    );
  }

  public listenStudentNameChanges(): void {
    const subscription = this.studentNameSearch.pipe(
      pluck('target', 'value'),
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe((value: string) => {
      if (this.isTeacherSelected())
        this.loadStudents(value, this.getTeacherId())
      else this.loadStudents(value);
    });

    this.subscriptions.push(subscription);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private setTeacherIdFromRoute(): void {
    const teacherId = this.activatedRoute.snapshot.params?.contact;
    if (teacherId) {
      this.scheduleCalendarService.calendarFilters.next({ teacherId: teacherId });
      this.location.replaceState(AppRoutesDefinitions.CALENDAR);
    }
  }
}
