<div class="first-lesson-loader" *ngIf="isProcessing">
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon
            nzType="loading"></span></ng-template>
</div>

<nz-row class="first-lesson-component-header">
    <nz-col nzSpan="10" class="title">{{'calendar.first-lesson.first_lesson' | translate}}</nz-col>
    <nz-col nzSpan="6">
        <button nz-button class="action-btn bordered" (click)="cancel()">{{'cancel' | translate}}</button>
    </nz-col>
    <nz-col nzSpan="6" nzOffset="2">
        <button class="action-btn bordered" *ngIf="isEditMode; else addBtn" (click)="save()"
            [class]="isFormValid() ? 'done-btn' : 'disabled-btn' ">{{'save' | translate}}</button>
        <ng-template #addBtn>
            <button class="action-btn bordered" (click)="createWithConfirm()"
                [disabled]="isFormValid() ? '': 'disabled'"
                [class]="isFormValid() ? 'done-btn' : 'disabled-btn' ">{{'add' | translate}}</button>
        </ng-template>
    </nz-col>
</nz-row>
<div class="first-lesson-component-content">
    <section>
        <div class="label">{{'teacher' | translate}}:</div>
        <nz-input-group [nzSuffix]="teacherInputSuffix">
            <input nz-input type="text" placeholder="{{'calendar.filters.teacher_search' | translate}}" [(ngModel)]="lesson.teacher" [nzAutocomplete]="auto"
                (input)="onTeacherNameInput($event)">
            <ng-template #teacherInputSuffix>
                <span nz-icon nzType="close-circle" nzTheme="outline" (click)="clearTeacher()"
                    style="cursor: pointer"></span>
            </ng-template>
            <nz-autocomplete #auto>
                <nz-auto-option *ngFor="let teacher of teachers" class="name-link" [nzValue]="teacher?.id"
                    (click)="selectTeacher(teacher)">
                    {{getTeacherName(teacher)}}
                </nz-auto-option>
            </nz-autocomplete>
        </nz-input-group>
    </section>
    <section>
        <div class="label">{{'main.labels.event_date' | translate}}:</div>
        <nz-date-picker [(ngModel)]="lesson.date" (ngModelChange)="onDateSelect()"
            [nzDisabledDate]="isDateYesterdayOrBefore()" nzPlacement="bottomLeft"></nz-date-picker>
    </section>
    <section>
        <div class="label">{{'calendar.first-lesson.duration' | translate}}:</div>
        <nz-radio-group [(ngModel)]="lesson.duration">
            <label nz-radio [nzValue]=30>{{'calendar.first-lesson.30_min' | translate}}</label>
            <label nz-radio [nzValue]=60>{{'calendar.first-lesson.60_min' | translate}}</label>
        </nz-radio-group>
    </section>
    <section>
        <ng-container *ngIf="lesson.date">
            <div class="control-block" *ngIf="!manualChooseTime; else manualTime">
                <div class="label">{{'calendar.first-lesson.event_time' | translate}}:</div>
                <mathema-info-block _type="warn" *ngIf="!isTimeSlotsAvailable() && isTeacherSelected()"
                    text="{{'calendar.first-lesson.no_available_slots' | translate}}"></mathema-info-block>
                <div class="slots" *ngIf="isTimeSlotsAvailable() && isTeacherSelected()">
                    <nz-tag [nzChecked]="selectedTime === lessonSlot"
                        *ngIf="isEditMode && isLessonDaySelected() && !isTeacherChanged()" nzMode="checkable"
                        (nzCheckedChange)="onTimeChecked($event, lessonSlot)">{{lessonSlot}}</nz-tag>
                    <ng-container *ngFor="let time of timeDiapasonToFreeSlotsTimes(slot.slotItemTime, lesson.duration)">
                        <nz-tag [nzChecked]="selectedTime === time" nzMode="checkable"
                            (nzCheckedChange)="onTimeChecked($event, time)" *ngIf="isTimeSlotValid(time)">
                            {{time}}
                        </nz-tag>
                    </ng-container>
                    <nz-tag *ngIf="manuallySelectedTime" [nzChecked]="selectedTime === manuallySelectedTime"
                        nzMode="checkable" (nzCheckedChange)="onManualTimeToggle($event)">
                        {{'calendar.first-lesson.manually_selected' | translate}}: {{manuallySelectedTime}}
                    </nz-tag>
                </div>
                <button nz-button class="time-choose-mode-btn" (click)="manualChooseTime = true"
                    *ngIf="isSchedulePresent()">{{'calendar.first-lesson.select_manually' | translate}}</button>
            </div>
        </ng-container>
        <ng-template #manualTime>
            <div class="control-block">
                <div class="label">{{'calendar.first-lesson.event_time' | translate}}:</div>
                <nz-slider [nzMarks]="manualTimeMarks" nzRange [(ngModel)]="manualTimeRange"
                    [nzTipFormatter]="formatManualTime" [nzMin]="manualTimeMin"
                    (ngModelChange)="onManualTimeRangeChange($event)" [nzMax]="manualTimeMax"></nz-slider>
                <nz-input-number-group nzCompact>
                    <span>{{'from' | translate}}</span>
                    <nz-input-number style="width: 30%" [nzFormatter]="formatManualTime"
                        [(ngModel)]="manualTimeInputMin" (ngModelChange)="onManualTimeMinChange($event)"
                        [nzMin]="manualTimeMin" [nzMax]="manualTimeMax" [nzStep]="1"></nz-input-number>
                    <span>{{'to' | translate}}</span>
                    <nz-input-number style="width: 30%" [nzFormatter]="formatManualTime"
                        [(ngModel)]="manualTimeInputMax" (ngModelChange)="onManualTimeMaxChange($event)"
                        [nzMin]="manualTimeMin" [nzMax]="manualTimeMax" [nzStep]="1"></nz-input-number>
                    <button [disabled]="!isManualTimeValid()" nz-button nzType="default" nzSize="default"
                        (click)="onConfirmManualTime()" class="apply-manual-time-btn">{{'ok' | translate}}</button>
                </nz-input-number-group>
                <mathema-info-block _type="warn"
                    text="{{'calendar.first-lesson.select_time_according_to_duration' | translate}}"></mathema-info-block>
                <button nz-button class="time-choose-mode-btn" (click)="manualChooseTime = false">{{'calendar.first-lesson.select_from_available' | translate}}</button>
            </div>
        </ng-template>
    </section>
    <section>
        <div class="label">{{'calendar.first-lesson.deal' | translate}}:</div>
        <div nz-row [nzGutter]="16">
            <div nz-col [nzSpan]="20">
                <input nz-input type="text" placeholder="{{'calendar.first-lesson.enter_deal' | translate}}" [(ngModel)]="lesson.deal"
                    (keypress)="search($event)" (ngModelChange)="resetDealValidity()" [disabled]="isEditMode">
            </div>
            <div nz-col [nzSpan]="2">
                <button nz-button (click)="getDeal()" class="search-btn" [disabled]="isEditMode">
                    <span nz-icon [nzType]="dealLoading ? 'loading' : 'search'" [nzSpin]="dealLoading"></span>
                </button>
            </div>
        </div>
        <mathema-info-block *ngIf="!dealValid.valid" [_type]="dealValid.type" [text]="dealValid.error"
            class="deal-error">
        </mathema-info-block>
    </section>
    <div *ngIf="isDealSelected()">
        <section>
            <div class="label">{{'status' | translate}}:</div>
            <nz-radio-group [(ngModel)]="lesson.paid">
                <label nz-radio [nzValue]="true" nzDisabled="disabled">{{'calendar.first-lesson.paid' | translate}}</label>
                <label nz-radio [nzValue]="false" nzDisabled="disabled">{{'calendar.first-lesson.unpaid' | translate}}</label>
            </nz-radio-group>
        </section>
        <section>
            <nz-input-group>
                <div class="label">{{'calendar.first-lesson.contact_person' | translate}}:</div>
                <input nz-input type="text" placeholder="" [(ngModel)]="lesson.contact" disabled>
            </nz-input-group>
        </section>
        <section>
            <nz-input-group>
                <div class="label">{{'calendar.first-lesson.phone_number' | translate}}:</div>
                <input nz-input type="text" placeholder="" [(ngModel)]="lesson.phoneNumber" disabled>
            </nz-input-group>
        </section>
        <section>
            <nz-input-group>
                <div class="label">{{'email' | translate}}:</div>
                <input nz-input type="email" placeholder="example@example.com" [(ngModel)]="lesson.contactEmail"
                    disabled>
            </nz-input-group>
        </section>
        <section>
          <nz-input-group>
            <div class="label">{{'calendar.first-lesson.student_name' | translate}}:</div>
            <input nz-input type="text" placeholder="" [(ngModel)]="lesson.student" disabled>
          </nz-input-group>
        </section>
        <section>
            <div class="label">{{'study_language' | translate}}:</div>
            <nz-select [(ngModel)]="lesson.language" class="dropdown">
                <nz-option *ngFor="let lang of languages" [nzValue]="lang.id"
                    [nzLabel]="lang.label | translate"></nz-option>
            </nz-select>
        </section>
        <section>
            <div class="label">{{'comments_about_studying' | translate}}</div>
            <textarea nz-input [nzAutosize]="{minRows: 2, maxRows: 8}" [(ngModel)]="lesson.comment"></textarea>
        </section>
    </div>
</div>
