import { Subject, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ActiveFilter, FilterChangedEvent, FiltersKeys } from './free-hours-filters/free-hours-filters.component';
import FreeHoursService, { TeacherSelectionPagedQueryDto, StudyDayFilterDto, TeacherSelectionDto } from './free-hours.service';
import { LanguageEnum } from '@shared/constants';
import { AppRoutesDefinitions } from '@app/app.routes';
import ScheduleCalendarService from '../schedule/calendar/schedule-calendar.service';
import ScheduleApiService, { SlotTypeFilter } from '../schedule/schedule-api.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DateTime } from 'luxon';
import { ContactMarketRegion } from '@shared/services/dto/pifagor-api.dto';
import { MainLayoutService } from '@app/layouts/main';

type TableRow = Omit<TeacherSelectionDto, 'examPreparation'> & { examPreparation: string[] };

const filtersCacheKey = 'freeHoursFilters';

@Component({
  selector: 'mat-free-hours',
  templateUrl: './free-hours.component.html',
  styleUrls: ['./free-hours.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FreeHoursComponent implements OnInit, AfterViewInit, OnDestroy {
  public resetAllFiltersSubject: Subject<void> = new Subject<void>();
  public resetFilterSubject: Subject<FiltersKeys> = new Subject<FiltersKeys>();
  public filtersKeys = FiltersKeys;

  public filtersOpened = false;
  public modalFiltersOpened = false;

  public activeFilters: ActiveFilter[] = [];

  private getDataSub: Subscription;
  public isLoading = false;
  public errorHappened = false;
  public tableData: TableRow[] = [];
  public pageSize = 15;
  public pageIndex = 1;
  public total = 0;

  public workloadSortingDirections = ['descend', 'ascend'];
  public conversionSortingDirections = ['descend', 'ascend'];
  public sortState = {
    [FiltersKeys.WORKLOAD]: {
      order: 'asc',
      priority: 0,
    },
    [FiltersKeys.CONVERSION]: {
      order: 'asc',
      priority: 0,
    },
  };

  public expandedTeacherId: string | null = null;

  public mobileMode: boolean;
  public isFirstLessonDrawerCollapsed: boolean = true;
  public isFirstLessonModalClosed: boolean = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly router: Router,
    public readonly authService: AuthService,
    private readonly freeHoursService: FreeHoursService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly scheduleApiService: ScheduleApiService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly mainLayoutService: MainLayoutService,
  ) {
    this.setupFiltersFromCache();
    this.subscriptions.push(this.breakpointObserver.observe(['(max-width: 575px)']).subscribe(result => {
      this.mobileMode = result.matches;
    }));
    this.subscriptions.push(this.scheduleCalendarService.activeSlot.subscribe((slot) => {
      if(this.expandedTeacherId && slot) {
        this.onOpenFirstLessonForm();
      }
    }));
  }

  public ngOnInit(): void {
    this.mainLayoutService.setLoading(true);
  }

  public changeSortPriority(event: Event, sortKey: FiltersKeys): void {
    event.stopPropagation();

    if (this.sortState[sortKey]?.priority) {
      this.sortState[sortKey].priority -= 1;
    } else {
      this.sortState[sortKey].priority = 3
    }

    this.loadResult(this.pageSize, this.pageIndex);
  }

  public numberToStringPriority(priority: number): string {
    switch (priority) {
      case 1:return 'one';
      case 2: return 'two';
      case 3: return 'three';
      default: return '';
    }
  }

  public ngAfterViewInit(): void {
    this.loadResult(this.pageSize, this.pageIndex);
  }

  private setupFiltersFromCache(): void {
    const filters = localStorage.getItem(filtersCacheKey);

    if (filters) {
      this.activeFilters = JSON.parse(filters);
    }
  }

  private loadResult(pageSize: number, pageIndex: number): void {
    if (this.getDataSub) {
      this.getDataSub.unsubscribe();
    }

    this.expandedTeacherId = null;
    this.errorHappened = false;
    this.isLoading = true;

    this.getDataSub = this.freeHoursService.getTeachersList(this.buildRequestBody(pageSize, pageIndex))
      .pipe(
        map(result => {
          return {
            ...result,
            page: result.page.map(this.mapTeacherToTableRow),
          }
      }),
        finalize(() => this.mainLayoutService.setLoading(false)),
      )
      .subscribe(result => {
        const { page, totalRows } = result;

        if (page.length) {
          this.tableData = page;
        } else {
          this.tableData = [];
        }

        this.total = totalRows;
        this.isLoading = false;
      }, () => {
        this.errorHappened = true;
        this.isLoading = false;
        this.tableData = [];
      });
  }

  public onColumnOrderChange(order: string, key: FiltersKeys) {
    switch (order) {
      case 'ascend':
        this.sortState[key].order = 'asc';
        break;
      case 'descend':
        this.sortState[key].order = 'desc';
        break;
      default:
        this.sortState[key].order = null;
    }

    this.loadResult(this.pageSize, this.pageIndex);
  }

  public getPaymentLevelTip(paymentLevel: string): string {
    return this.freeHoursService.makePaymentLevelTip(paymentLevel, this.authService);
  }

  public parseTeacherAge(dateOfBirth: string | null): string {
    if (!dateOfBirth) {
      return '-';
    }

    const date = DateTime.fromFormat(dateOfBirth, 'dd.MM.yyyy');
    if (!date.isValid) {
      return '-';
    }

    return DateTime.now().diff(date, 'years').toFormat('yy');
  }

  private buildRequestBody(pageSize: number, pageNumber: number): TeacherSelectionPagedQueryDto {
    let region;
    switch (this.authService.user.language) {
      case LanguageEnum.polish:
        region = ContactMarketRegion.POLAND;
        break;
      case LanguageEnum.ukrainian:
        region = ContactMarketRegion.UKRAINE;
        break;
      case LanguageEnum.english:
      default:
        region = ContactMarketRegion.UNITED_KINGDOM;
    }

    let body: TeacherSelectionPagedQueryDto = {
      pageSize,
      pageNumber,
      filters: {
        region,
      },
      sorting: {},
    };

    if (this.activeFilters?.length) {
      body.filters = {
        region,
      };
    }

    if (this.sortState[FiltersKeys.WORKLOAD]) {
      body.sorting[FiltersKeys.WORKLOAD] = {
        order: this.sortState[FiltersKeys.WORKLOAD].order?.toUpperCase() as 'ASC' | 'DESC',
      };

      body.sorting[FiltersKeys.WORKLOAD].priority = this.sortState[FiltersKeys.WORKLOAD].priority;
    }

    if (this.sortState[FiltersKeys.CONVERSION]) {
      body.sorting[FiltersKeys.CONVERSION] = {
        order: this.sortState[FiltersKeys.CONVERSION].order?.toUpperCase() as 'ASC' | 'DESC',
      };

      body.sorting[FiltersKeys.CONVERSION].priority = this.sortState[FiltersKeys.CONVERSION].priority;
    }

    for (const filter of this.activeFilters) {
      switch (filter.key) {
        case FiltersKeys.STUDY_DATE_RANGE: {
          const [dateFrom, dateTo] = filter.values[0].split(',');
          body.filters.dateFrom = dateFrom;
          body.filters.dateTo = dateTo;
          break;
        }
        case FiltersKeys.STUDY_DAY_TIME: {
          const parsedValue = JSON.parse(filter.values[0]);
          body.filters.studyDays = body.filters.studyDays 
            ? [...body.filters.studyDays as StudyDayFilterDto[], parsedValue]
            : [parsedValue];
          break;
        }
        case FiltersKeys.TEACHER_AGE: {
          const [minAge, maxAge] = filter.values[0].split(',');
          body.filters.minAge = +minAge;
          body.filters.maxAge = +maxAge;
          break;
        }
        case FiltersKeys.WORKLOAD: {
          const [min, max] = filter.values[0].split(',');
          body.filters.minWorkload = min;
          body.filters.maxWorkload = max;
          break;
        }
        case FiltersKeys.COUNTRY: {
          body.filters.country = filter.values[0].split(',');
          break;
        }
        default:
          body.filters[filter.key] = filter.values;
      }
    }

    if (body.sorting && Object.keys(body.sorting).length === 0) {
      delete body.sorting;
    }

    return body;
  }

  public mapTeacherToTableRow(teacher: TeacherSelectionDto): TableRow {
    return { ...teacher, examPreparation: teacher.examPreparation?.split(',') };
  }

  public onPageIndexChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.loadResult(this.pageSize, pageIndex);
  }

  public onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadResult(pageSize, this.pageIndex);
  }

  public resetFilterWithEvent(event: { key: FiltersKeys; secondaryKey?: string; reload: boolean }): void {
    const { key, secondaryKey, reload } = event;
    this.activeFilters = this.activeFilters.filter(el => el.key !== key || el?.secondaryKey !== secondaryKey);
    this.resetFilterSubject.next(key);

    if (reload) {
      this.resetFiltersCache();
      this.loadFromFirstPage();
    }
  }

  public resetFilter(key: FiltersKeys): void {
    this.activeFilters = this.activeFilters.filter(el => el.key !== key);
    this.resetFiltersCache();
    this.loadFromFirstPage();
  }

  public resetAllFilters(): void {
    this.activeFilters = [];
    this.resetAllFiltersSubject.next();
    this.resetFiltersCache();
    this.loadFromFirstPage();
  }

  public setFilterValue(event: FilterChangedEvent): void {
    const { key, value, label, skipLoad } = event;

    switch (key) {
      case FiltersKeys.STUDY_DAY_TIME: {
        this.addStudyDayTimeFilter(value, label);
        break;
      }
      default: {
        const index = this.activeFilters.findIndex(el => el.key === key);

        if (index !== -1) {
          this.activeFilters = this.activeFilters.filter(el => el.key !== key);
        }

        this.activeFilters.push({ key, values: [value], labels: [label] });
      }
    }

    this.resetFiltersCache();

    if (!skipLoad) {
      this.loadFromFirstPage();
    }
  }

  public changeFilterValue(event: FilterChangedEvent): void {
    const { isActive, key, value, label }  = event;

    if (isActive) {
      this.addFilter(key, value, label);
    } else {
      this.removeFilter(key, value, label);
    }

    this.resetFiltersCache();
    this.loadFromFirstPage();
  }

  private loadFromFirstPage(): void {
    this.pageIndex = 1;
    this.loadResult(this.pageSize, this.pageIndex);
  }

  private addFilter(key: FiltersKeys, value: string, label: string): void {
    const index = this.activeFilters.findIndex(el => el.key === key);

    if (index === -1) {
      this.activeFilters.push({ key, values: [value], labels: [label] });
    } else {
      this.activeFilters[index].values = [...this.activeFilters[index].values, value];
      this.activeFilters[index].labels = [...this.activeFilters[index].labels, label];
    }
  }

  private removeFilter(key: string, value: string, label: string): void {
    const index = this.activeFilters.findIndex(el => el.key === key);
    this.activeFilters[index].values = this.activeFilters[index].values.filter(el => el !== value);
    this.activeFilters[index].labels = this.activeFilters[index].labels.filter(el => el !== label);

    if (this.activeFilters[index].values.length === 0) {
      this.activeFilters = this.activeFilters.filter(el => el.key !== key);
    }
  }

  private resetFiltersCache(): void {
    localStorage.setItem(filtersCacheKey, JSON.stringify(this.activeFilters));
  }

  public openTeacherLessonsPage(teacherId: string): void {
    this.scheduleCalendarService.calendarFilters.next({
      teacherId,
      slotType: SlotTypeFilter.ALL,
    });

    this.router.navigate([AppRoutesDefinitions.CALENDAR], { skipLocationChange: false });
  }

  public toggleTeacherCalendar(teacherId: string): void {
    if (this.expandedTeacherId === teacherId) {
      this.expandedTeacherId = null;
      this.scheduleCalendarService.activeTeacher.next(null);
    } else {
      this.expandedTeacherId = teacherId;
      this.subscriptions.push(this.scheduleApiService.getTeacherById(teacherId).subscribe((teacher) => {
        this.scheduleCalendarService.activeTeacher.next(teacher);
      }));
    }
  }

  public openAccountInfoPage(teacherId: string): void {
    const url = this.router.createUrlTree([AppRoutesDefinitions.VIEW_USER_INFO], {
      queryParams: {
        teacherId,
      }
    });
    window.open(url.toString(), '_blank');
  }

  public onOpenFirstLessonForm(): void {
    if (this.scheduleCalendarService.activeTeacher.value?.notTakeNewStudentsAt) {
      return;
    }

    if (this.scheduleCalendarService.activeTeacher.value?.blockedAvailability) {
      return;
    }

    if (this.mobileMode) {
      this.isFirstLessonModalClosed = false;
    } else {
      this.isFirstLessonDrawerCollapsed = false;
    }
  }

  public onCloseFirstLessonForm(): void {
    if (this.mobileMode) {
      this.isFirstLessonModalClosed = true;
    } else {
      this.isFirstLessonDrawerCollapsed = true;
    }

    this.scheduleCalendarService.activeSlot.next(null);
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private addStudyDayTimeFilter(value: string, label: string): void {
    const secondaryKeyIndex = this.activeFilters.findIndex(el => el.secondaryKey === value);

    if (secondaryKeyIndex === -1) {
      this.activeFilters.push({ key: FiltersKeys.STUDY_DAY_TIME, secondaryKey: value, values: [value], labels: [label] });
    }
  }
}
