<nz-layout class="base">
  <nz-layout class="base-container">
    <nz-row nzJustify="space-between">
      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <div class="logo">
          <img src="assets/mathema-logo.png"
               alt="Logo">
        </div>
      </nz-col>

      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <app-language-switch>
        </app-language-switch>
      </nz-col>
    </nz-row>

    <nz-row>
      <nz-col [nzXs]="24"
              [nzSm]="20"
              [nzMd]="20"
              [nzLg]="20">
        <div class="header mobile-align-center">
          <p class="head-line">
            {{ 'authorization' | translate }}
          </p>

          <p class="annotation">
            {{ 'login_welcome' | translate }}
          </p>
        </div>
      </nz-col>
    </nz-row>

    <nz-row nzJustify="space-evenly">
      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 14, order: 2}"
              [nzMd]="{span: 14, order: 2}"
              [nzLg]="{span: 14, order: 2}"
              class="align-box">
        <img class="illustration"
             src="assets/login/signin.svg"
             alt="">
      </nz-col>

      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 9, order: 1}"
              [nzMd]="{span: 9, order: 1}"
              [nzLg]="{span: 9, order: 1}">
        <div class="form-block">
          <div class="input-group">
            <span class="input-label">
              {{ 'auth.sign-in.email-or-username' | translate }}
            </span>

            <nz-input-group [nzPrefix]="usernamePrefix">
              <input nz-input
                     class="input"
                     type="text"
                     name="username"
                     [class.input-error]="touched.username && error.username"
                     [(ngModel)]="username"
                     (focus)="focus($event)"
                     (blur)="blur($event)"
                     (keydown)="keyDown($event)">
            </nz-input-group>
          </div>

          <ng-template #usernamePrefix>
            <span nz-icon>
              <img src="assets/login/username-prefix.svg"
                   alt="">
            </span>
          </ng-template>

          <div class="input-group">
            <span class="input-label">
              {{ 'password' | translate }}
            </span>

            <nz-input-group [nzPrefix]="passwordPrefix"
                            [nzSuffix]="passwordSuffix">
              <input nz-input
                     class="input"
                     [type]="getPasswordType()"
                     name="password"
                     [class.input-error]="touched.password && error.password"
                     [(ngModel)]="password"
                     (focus)="focus($event)"
                     (blur)="blur($event)"
                     (keydown)="keyDown($event)">
            </nz-input-group>
          </div>

          <ng-template #passwordPrefix>
            <span nz-icon>
              <img src="assets/login/password-prefix.svg"
                   alt="">
            </span>
          </ng-template>

          <ng-template #passwordSuffix>
            <span nz-icon
                  (click)="togglePasswordView()"
                  class="clickable">
              <img src="assets/login/password-suffix.svg"
                   alt="">
            </span>
          </ng-template>

          <div class="button">
            <p *ngIf="!!error.wrong"
               class="error">
              {{ error.wrong }}
            </p>

            <button [disabled]="checkButtonDisabled()"
                    nz-button
                    (click)="login()">
              {{ 'log_in' | translate }}
            </button>
          </div>
        </div>

        <div class="links">
          <div class="restore-password">
            <a class="restore-password-link"
               routerLink="/password-restore">
            {{ 'forgot_password' | translate }}
            </a>
          </div>

          <div class="registration">
            {{ 'no_account' | translate }}
            <a class="registration-link"
               routerLink="/signup">
              {{ 'signup' | translate }}
            </a>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </nz-layout>
</nz-layout>
