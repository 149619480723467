import LessonDto from '@shared/services/lessons/dto/lesson.dto';

export enum StudentClass {
  EMPTY = '',
  PL_0_KINDERGARTEN = 'f879b0f4-4a69-47c7-848e-cfb04dbbfaa8',
  PL_LYCEUM_1 = '73a32502-4b84-4173-a68f-4153b3cf7aa7',
  PL_LYCEUM_1_DEFAULT = '9b8218f3-debe-4158-a3f6-7f9f9deb1284',
  PL_VOCATIONAL_SCHOOL_1 = '31477867-1ec4-417c-a7fd-0283001fc5da',
  PL_PRIMARY_SCHOOL_1 = 'e63e1a97-d49a-425e-b5d9-12bfbf3f2d34',
  PL_TECHNICAL_SCHOOL_1 = '4048d24f-0c62-4f28-86c0-a0b9c742a908',
  PL_LYCEUM_2 = '1bf6169e-56d8-4a82-aad1-7037248fbef1',
  PL_VOCATIONAL_SCHOOL_2 = 'c3d0a3f3-e225-4bc4-a97c-559f6fa08c9e',
  PL_PRIMARY_SCHOOL_2 = '47d0ebea-78f8-4587-a9e0-dc2c6eb832e0',
  PL_TECHNICAL_SCHOOL_2 = '871bc75d-86e7-43d3-bb02-83fcaa427d6a',
  PL_LYCEUM_3 = '83802558-aae6-4ce6-bd5b-7f2650f14b77',
  PL_VOCATIONAL_SCHOOL_3 = 'ae2464a6-ad74-4da5-b59f-d00f2d1678ce',
  PL_PRIMARY_SCHOOL_3 = '658cda1e-c60f-4c27-be93-daa1b023d0d0',
  PL_TECHNICAL_SCHOOL_3 = '2dd44925-5ab6-427b-a92d-be5f011d78ce',
  PL_LYCEUM_4 = 'b5b4603b-0f69-41f2-9a92-e32c4b32e996',
  PL_PRIMARY_SCHOOL_4 = '460c83a0-a6a2-4d16-aff5-87c411e82e41',
  PL_TECHNICAL_SCHOOL_4 = '9e12683b-8304-4c68-a33f-7a0ef9bd1a67',
  PL_PRIMARY_SCHOOL_5 = 'a6ea5cfa-d175-48e4-a9a2-80de5b3fc44d',
  PL_TECHNICAL_SCHOOL_5 = 'f55ef3a4-de13-4339-aa8e-4cbfd990601c',
  PL_PRIMARY_SCHOOL_6 = '06fbad3f-11eb-4df5-929b-835e1a6d5eb3',
  PL_PRIMARY_SCHOOL_7 = '215c7ff2-c8be-4b32-af7b-61a3b9f2c010',
  PL_PRIMARY_SCHOOL_8 = 'a7e98034-85a5-4383-b46b-b6df0b19a267',
  NUM_1 = '719c7495-d146-4b0c-ba2b-f6e3cdb135fa',
  NUM_2 = '0ec509b7-1634-4856-b874-2ad3ffcc307f',
  NUM_3 = '23d3eec6-e1d6-4ae3-a3fe-3c827c315a38',
  NUM_4 = '0f80fa56-49c4-4533-b6c7-79085dad3f31',
  NUM_5 = '61a7ffea-6e03-4e6c-b3c0-9e6f0ac05936',
  NUM_6 = '48378f40-3e16-44c4-8c09-b44b5c0c9da0',
  NUM_7 = 'ddb22d8c-733a-4eb5-aa7e-e6aafd397ccb',
  NUM_8 = '73d131d1-d706-4317-b6ab-7715ec2e5217',
  NUM_9 = '766c7e14-288e-4ae9-856a-aaa6d6ae645b',
  NUM_10 = '2071e045-51be-435a-aab6-e496e65b6715',
  NUM_11 = '38675cd1-a69f-4bcf-845f-7e112ec9bf31',
  FINISHED_SCHOOL = '40908db9-38c2-46a3-9171-7ad75268a0ac',
  COLLEGE_OR_TECH_SCHOOL = 'c5edb959-9ab3-4ba9-9d7f-e2cc8b8e6755',
  UNIVERSITY = 'a1c943b2-0707-4384-a2b3-a4d0dc48dbb9',
  // todo: legacy below, temporary for sync purposes, remove soon'
  NUM_10_11 = '00206929-95df-4a21-a171-f5576e92de77',
  NUM_12_13 = '35ae198b-778b-4179-b1c4-b5e5e037937a',
  NUM_3_6 = 'e059f185-dbb7-4435-a889-7c429bc0e178',
  NUM_7_9 = 'c8f395de-91a6-48e9-b85e-5e0d1c686894',
  LYCEUM = '29500d5d-b873-4386-8f03-8fe908610cf3',
  TECHNICAL_SCHOOL = 'a50f4c33-cf66-4483-8c67-d94f65848618',
  VOCATIONAL_SCHOOL = 'e722cd91-0789-4db8-90ee-723c5df19083',
  ZNO = '508b152c-7b12-41f7-8fe9-14f7d87d7bfd',
  SCHOOL_PROGRAM_1_4 = '43d0c554-2b88-4aee-b1c7-d2ce24c71c04',
  SCHOOL_PROGRAM_5_9 = 'ca87129a-0ad7-4db2-b052-0b31248b67b7',
}

export const studentClassesUa = [
  StudentClass.NUM_1, StudentClass.NUM_2, StudentClass.NUM_3, StudentClass.NUM_4, StudentClass.NUM_5, StudentClass.NUM_6,
  StudentClass.NUM_7, StudentClass.NUM_8, StudentClass.NUM_9, StudentClass.NUM_10, StudentClass.NUM_11,
];

export const studentClassesPl = [
  StudentClass.PL_0_KINDERGARTEN, StudentClass.PL_LYCEUM_1,
  StudentClass.PL_VOCATIONAL_SCHOOL_1, StudentClass.PL_PRIMARY_SCHOOL_1, StudentClass.PL_TECHNICAL_SCHOOL_1,
  StudentClass.PL_LYCEUM_2, StudentClass.PL_VOCATIONAL_SCHOOL_2, StudentClass.PL_PRIMARY_SCHOOL_2,
  StudentClass.PL_TECHNICAL_SCHOOL_2, StudentClass.PL_LYCEUM_3, StudentClass.PL_VOCATIONAL_SCHOOL_3,
  StudentClass.PL_PRIMARY_SCHOOL_3, StudentClass.PL_TECHNICAL_SCHOOL_3, StudentClass.PL_LYCEUM_4,
  StudentClass.PL_PRIMARY_SCHOOL_4, StudentClass.PL_TECHNICAL_SCHOOL_4, StudentClass.PL_PRIMARY_SCHOOL_5,
  StudentClass.PL_TECHNICAL_SCHOOL_5, StudentClass.PL_PRIMARY_SCHOOL_6, StudentClass.PL_PRIMARY_SCHOOL_7,
  StudentClass.PL_PRIMARY_SCHOOL_8,
];

interface StudentPreferredScheduleItemSlot {
  timeSlots: number[];
}

export interface StudentPreferredScheduleItem {
  weekday: number;
  schedule: StudentPreferredScheduleItemSlot[];
}

export default interface StudentDto {
  id: string;
  firstName: string;
  lastName: string;
  grade: string;
  class: StudentClass | null;
  lessons: LessonDto[];
  preferredSchedule: StudentPreferredScheduleItem[] | null;
  workingClasses: StudentClass[] | null;
}
