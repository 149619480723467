import { I18nLanguage } from '@shared/language/language.service';
import { studentClassesPl, studentClassesUa } from '@shared/services/students/dto/student.dto';

export const studyDays = [{
  value: '1',
  label: 'free_hours.labels.week_days.monday',
  isChecked: false,
}, {
  value: '2',
  label: 'free_hours.labels.week_days.tuesday',
  isChecked: false,
}, {
  value: '3',
  label: 'free_hours.labels.week_days.wednesday',
  isChecked: false,
}, {
  value: '4',
  label: 'free_hours.labels.week_days.thursday',
  isChecked: false,
}, {
  value: '5',
  label: 'free_hours.labels.week_days.friday',
  isChecked: false,
}, {
  value: '6',
  label: 'free_hours.labels.week_days.saturday',
  isChecked: false,
}, {
  value: '0',
  label: 'free_hours.labels.week_days.sunday',
  isChecked: false,
}];

const studentGradesUa = studentClassesUa.map(el => ({
  value: el,
  label: 'main.entity.student.class.' + el,
  isChecked: false,
}));

const studentGradesPl = studentClassesPl.map(el => ({
  value: el,
  label: 'main.entity.student.class.' + el,
  isChecked: false,
}));

export const studentGrades = {
  [I18nLanguage.UA]: studentGradesUa,
  [I18nLanguage.PL]: studentGradesPl,
  [I18nLanguage.EN]: studentGradesUa,
};

const studyProgramUa = [{
  value: 'intelectUkraine',
  label: 'free_hours.labels.study_programs.intelectUkraine',
  isChecked: false,
}, {
  value: 'naKrylaxUspixy',
  label: 'free_hours.labels.study_programs.naKrylaxUspixy',
  isChecked: false,
}, {
  value: 'rostock',
  label: 'free_hours.labels.study_programs.rostock',
  isChecked: false,
}, {
  value: 'NUS',
  label: 'free_hours.labels.study_programs.NUS',
  isChecked: false,
}, {
  value: 'other',
  label: 'free_hours.labels.study_programs.other',
  isChecked: false,
}];

const studyProgramPl = [...studyProgramUa].filter(el => el.value !== 'NUS');
const studyProgramEn = [...studyProgramUa].concat([{
  value: 'a_level_further',
  label: 'free_hours.labels.study_programs.a_level_further',
  isChecked: false,
}, {
  value: 'adv_math',
  label: 'free_hours.labels.study_programs.adv_math',
  isChecked: false,
}]);

export const studyProgram = {
  [I18nLanguage.UA]: studyProgramUa,
  [I18nLanguage.PL]: studyProgramPl,
  [I18nLanguage.EN]: studyProgramEn,
}


export const teachingExperience = [{
  value: 'to_three_years',
  label: 'free_hours.labels.teaching_experience.to_three_years',
  isChecked: false,
}, {
  value: 'from_three_to_five',
  label: 'free_hours.labels.teaching_experience.from_three_to_five',
  isChecked: false,
}, {
  value: 'from_five_to_ten',
  label: 'free_hours.labels.teaching_experience.from_five_to_ten',
  isChecked: false,
}, {
  value: 'from_ten_to_fifteen',
  label: 'free_hours.labels.teaching_experience.from_ten_to_fifteen',
  isChecked: false,
}, {
  value: 'above_fifteen',
  label: 'free_hours.labels.teaching_experience.above_fifteen',
  isChecked: false,
}];

export const tutoringExperience = [{
  value: 'to_three_years',
  label: 'free_hours.labels.teaching_experience.to_three_years',
  isChecked: false,
}, {
  value: 'from_three_to_five',
  label: 'free_hours.labels.teaching_experience.from_three_to_five',
  isChecked: false,
}, {
  value: 'from_five_to_ten',
  label: 'free_hours.labels.teaching_experience.from_five_to_ten',
  isChecked: false,
}, {
  value: 'from_ten_to_fifteen',
  label: 'free_hours.labels.teaching_experience.from_ten_to_fifteen',
  isChecked: false,
}, {
  value: 'above_fifteen',
  label: 'free_hours.labels.teaching_experience.above_fifteen',
  isChecked: false,
}];

const examsUa = [{
  value: 'dpa',
  label: 'free_hours.labels.exams.dpa',
  isChecked: false,
}, {
  value: 'zno',
  label: 'free_hours.labels.exams.zno',
  isChecked: false,
}, {
  value: 'nmt',
  label: 'free_hours.labels.exams.nmt',
  isChecked: false,
}];

const examsPl = examsUa.concat({
  value: 'polExam',
  label: 'free_hours.labels.exams.polExam',
  isChecked: false,
}, {
  value: 'polInternational',
  label: 'free_hours.labels.exams.polInternational',
  isChecked: false,
});

const examsEn = examsUa.concat({
  value: 'polExam',
  label: 'free_hours.labels.exams.polExam',
  isChecked: false,
});

export const exams = {
  [I18nLanguage.UA]: examsUa,
  [I18nLanguage.PL]: examsPl,
  [I18nLanguage.EN]: examsEn,
}

export const specialNeeds = [{
  value: 'autism',
  label: 'free_hours.labels.special_needs.autism',
  isChecked: false,
}, {
  value: 'miscalculation',
  label: 'free_hours.labels.special_needs.miscalculation',
  isChecked: false,
}, {
  value: 'cerebralPalsy',
  label: 'free_hours.labels.special_needs.cerebralPalsy',
  isChecked: false,
}, {
  value: 'mentalStress',
  label: 'free_hours.labels.special_needs.mentalStress',
  isChecked: false,
}, {
  value: 'mentalDisabilities',
  label: 'free_hours.labels.special_needs.mentalDisabilities',
  isChecked: false,
}];

export const studyLevels = [{
  value: 'depth_study',
  label: 'free_hours.labels.study_levels.depth_study',
  isChecked: false,
}];

export const teachingStyles = [{
  value: 'authoritarian',
  label: 'free_hours.labels.teaching_styles.authoritarian',
  isChecked: false,
}, {
  value: 'democratic',
  label: 'free_hours.labels.teaching_styles.democratic',
  isChecked: false,
}, {
  value: 'liberal',
  label: 'free_hours.labels.teaching_styles.liberal',
  isChecked: false,
}];

export const lessonDurations = [{
  value: '30',
  label: 'free_hours.labels.lesson_durations.half_hour',
  isChecked: false,
}, {
  value: '60',
  label: 'free_hours.labels.lesson_durations.one_hour',
  isChecked: false,
}];

export const teachingLanguages = [{
  value: I18nLanguage.UA.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.uk',
  isChecked: false,
  img: '/assets/flags/ua-100px.png',
}, {
  value: I18nLanguage.EN.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.en',
  isChecked: false,
  img: '/assets/flags/gb-100px.png',
}, {
  value: I18nLanguage.DE.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.de',
  isChecked: false,
  img: '/assets/flags/de-100px.png',
}, {
  value: I18nLanguage.PL.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.pl',
  isChecked: false,
  img: '/assets/flags/pl-100px.png',
}, {
  value: I18nLanguage.FR.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.gf',
  isChecked: false,
  img: '/assets/flags/fr-100px.png',
}, {
  value: I18nLanguage.CZ.toUpperCase(),
  label: 'free_hours.labels.teaching_languages.cz',
  isChecked: false,
  img: '/assets/flags/cz-100px.png',
}, {
  value: I18nLanguage.OTHER,
  label: 'free_hours.labels.teaching_languages.other',
  isChecked: false,
}];

export const teacherStatuses = [{
  value: 'regular',
  label: 'free_hours.labels.teacher_statuses.regular',
  isChecked: false,
}, {
  value: 'trainee',
  label: 'free_hours.labels.teacher_statuses.trainee',
  isChecked: false,
}];

export const verifications = [{
  value: 'show_verified',
  label: 'free_hours.labels.verifications.show_verified',
  isChecked: false,
}];

export const notTakeNewStudents = [{
  value: 'show_not_take_new_students',
  label: 'free_hours.labels.show_not_take_new_student',
  isChecked: false,
}];

export const additionalSubjects = [{
  value: 'Phisics',
  label: 'free_hours.labels.additional_subjects.phisics',
  isChecked: false,
}, {
  value: 'ComputerSciense',
  label: 'free_hours.labels.additional_subjects.informatics',
  isChecked: false,
}];

export const paymentLevels = [{
  value: 'Basic',
  label: 'free_hours.labels.payment_levels.Basic',
  isChecked: false,
}, {
  value: 'Silver',
  label: 'free_hours.labels.payment_levels.Silver',
  isChecked: false,
}, {
  value: 'Gold',
  label: 'free_hours.labels.payment_levels.Gold',
  isChecked: false,
}, {
  value: 'Premium',
  label: 'free_hours.labels.payment_levels.Premium',
  isChecked: false,
}];

export const qualificationsUa = [{
  value: 'chartered_math_teacher',
  label: 'free_hours.labels.qualifications.chartered_math_teacher',
  isChecked: false,
}, {
  value: 'further_math_teacher',
  label: 'free_hours.labels.qualifications.further_math_teacher',
  isChecked: false,
}, {
  value: 'npqml',
  label: 'free_hours.labels.qualifications.npqml',
  isChecked: false,
}, {
  value: 'npqsl',
  label: 'free_hours.labels.qualifications.npqsl',
  isChecked: false,
}, {
  value: 'first_category',
  label: 'free_hours.labels.qualifications.first_category',
  isChecked: false,
}, {
  value: 'second_category',
  label: 'free_hours.labels.qualifications.second_category',
  isChecked: false,
}, {
  value: 'higher_category',
  label: 'free_hours.labels.qualifications.higher_category',
  isChecked: false,
}, {
  value: 'no_category',
  label: 'free_hours.labels.qualifications.no_category',
  isChecked: false,
}, {
  value: 'other',
  label: 'free_hours.labels.qualifications.other',
  isChecked: false,
}];

const qualificationsPl = [...qualificationsUa].splice(0, 4);
const qualificationsEn = [...qualificationsUa].splice(0, 4);

export const qualifications = {
  [I18nLanguage.UA]: qualificationsUa,
  [I18nLanguage.PL]: qualificationsPl,
  [I18nLanguage.EN]: qualificationsEn,
}

export const genders = [{
  value: 'Male',
  label: 'free_hours.labels.genders.male',
  isChecked: false,
}, {
  value: 'Female',
  label: 'free_hours.labels.genders.female',
  isChecked: false,
}];
