<div class="schedule-calendar-client-filters">
  <div class="wrapper">
    <div class="filters" *ngIf="filtersData; else loadingOrError">
      <section class="filter">
        <div class="filter-title">{{'calendar.filters.student' | translate}}</div>
        <ng-container *ngIf="filtersData.students?.length; else noStudents">
          <nz-radio-group [ngModel]="studentId" (ngModelChange)="onStudentSelected($event)">
            <label
              *ngFor="let student of filtersData.students"
              nz-radio
              [nzValue]="student.id"
            >
              {{ student?.lastName }} {{ student?.firstName }}
            </label>
          </nz-radio-group>
        </ng-container>
        <ng-template #noStudents>
          <div class="tip no-result">{{'calendar.filters.no_results' | translate}}</div>
        </ng-template>
      </section>
      <section class="filter">
        <div class="filter-title">{{'calendar.filters.teacher' | translate}}</div>
        <ng-container *ngIf="filtersData.teachers?.length; else noTeachers">
          <nz-radio-group [ngModel]="teacherId" (ngModelChange)="onTeacherSelected($event)">
            <label
              *ngFor="let teacher of filtersData.teachers | filter : filterEnabledTeachers(studentId)"
              nz-radio
              [nzValue]="teacher.id"
            >
              {{ teacher?.lastName }} {{ teacher?.firstName }}
            </label>
          </nz-radio-group>
        </ng-container>
        <ng-template #noTeachers>
          <div class="tip no-result">{{'calendar.filters.no_results' | translate}}</div>
        </ng-template>
      </section>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  <ng-template #loading>
    <nz-spin class="calendar-client-filters-spin" [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
