import { Component, OnInit } from '@angular/core';
import en from '@angular/common/locales/en';
import pl from '@angular/common/locales/pl';
import uk from '@angular/common/locales/uk';
import { registerLocaleData } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
import HelpCrunchWidgetService from '@shared/widgets/help-crunch-widget.service';
registerLocaleData(en);
registerLocaleData(pl);
registerLocaleData(uk);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translateService: TranslateService,
    private readonly helpCrunchWidgetService: HelpCrunchWidgetService,
  ) {
    const userLanguage = localStorage.getItem('userLanguage');
    if (userLanguage) {
      this.translateService.use(userLanguage.toLowerCase());
    }
  }

  public ngOnInit(): void {
    this.helpCrunchWidgetService.init();
  }
}
