import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../components/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Inject storage
   */
  public constructor(private router: Router, private authService: AuthService, private dialog: MatDialog,) { }

  /**
   * Handle not auth response
   */
  public handleNotAuthorizedStatus(response: HttpErrorResponse) {
    this.authService.removeToken();
    this.dialog.closeAll();
    this.router.navigate(['/login']);
  }

  /**
   * Check 401 status
   */
  public checkIsNotAuthorizedReponse(response: HttpErrorResponse) {
    if (response instanceof HttpErrorResponse) {
      // handled request with 401 status and if it was called not in auth, and it's NOT a login method (it also returns 401 on error)
      if (
        'status' in response &&
        response.status == 401 &&
        !this.router.url.includes('/auth')
      ) {
        return true;
      }
    }

    return false;
  }

  private _isTokenNeeded(url: string): boolean {
    let res = true;
    const allowedUrls = [
      '/teachers/signup/',
      '/user/log-in',
      '/user/register',
      '/user/password-restore',
      '/auth/login',
      '/auth/teacher-signup/',
      '/auth/client-signup/',
      '/auth/register',
      '/auth/restore'
    ];
    allowedUrls.forEach(u => {
      if (url.includes(u))
        res = false;
    })
    return res;
  }

  /**
   * Set needed headers
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const oldServerToken = this.authService.getOldServerToken();
    const pifagorApiToken = this.authService.getPifagorAPIToken;

    const token = request.url.startsWith(environment.PifagorApiAddress) ? pifagorApiToken : oldServerToken;

    const updatedRequest = request.clone({
      setHeaders: {
        Authorization: this._isTokenNeeded(request.url) ? token : environment.apiKey,
      },
      withCredentials: false,
    });

    return next.handle(updatedRequest).pipe(
      catchError((response) => {
        if (
          response instanceof HttpErrorResponse &&
          this.checkIsNotAuthorizedReponse(response)
        ) {
          this.handleNotAuthorizedStatus(response);
        }

        response.message = response?.error?.message || response?.error?.error?.message || response?.statusText;

        return throwError(response);
      })
    );
  }
}
