import {Regions} from "@shared/constants";

export enum PagesNames {
  PERSONAL_INFO = 'personal_info',
  WORK_SPECIFICATION = 'work_specification',
  EDUCATION = 'education',
  EXPERIENCE = 'experience',
  ZOOM = 'lesson_link',
  ADDITIONAL = 'additional',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'other'
}

export enum PageActions {
  EDIT_INDICATORS = 'edit_indicators',
  EDIT = 'edit',
  CREATE_NEW = 'create_new_user',
}

export interface PagesInterface {
  label: PagesNames;
  icon: string;
}

export interface ICreateExamSchema {
  name: string;
  expirience: number | string;
}

export interface ICreateTeacherSchema {
  type?: string;
  firstName?: string;
  lastName?: string;
  secondName?: string;
  phone?: string;
  email?: string;
  age?: number;
  lang?: string;
  tutoringExperience?: string;
  dateOfBirth?: string;
  countPay?: number;
  experience?: string;
  education?: string;
  verifiedAt?: Date;
  linkToZoom?: string;
  zoomCode?: string;
  zoomIdentificator?: string;
  diploma?: boolean;
  blockedAvailability?: boolean;
  event?: string;
  grade?: number;
  messengerId?: string;
  workingClass?: string[];
  count?: number;
  capacity?: number;
  avatarLink?: string;
  gender?: string;
  educationProgram?: EEducationProgram[];
  educationProgramOther?: string;
  educationStyle?: EEducationStyle;
  AdditionalSubject?: EAdditionalSubject[];
  isDepthStudy?: boolean;
  childSpecialNeeds?: EPeopleSpecialNeed[];
  teacherInstruments?: ETeacherInstrument[];
  instrumentsOther?: string;
  additionalEducation?: string;
  diplomaLink?: string;
  qualification?: string;
  qualificationOther?: string;
  currentWorkPlace?: string;
  previousWorkPlace?: string[];
  description?: string;
  country?: string;
  workingRegion?: Regions;
  examPreparation?: ICreateExamSchema[];
  status?: ETeacherStatus;
  additionalSubject?: string[];
  diplomaFileName?: string;
  langOther?: string;
}

export enum ETeacherStatus {
  trainee = 'trainee',
  regular = 'regular',
  resigns = 'resigns',
  resigned = 'resigned',
}

export interface IGetTeacherData extends ICreateTeacherSchema {
  teacherWorkLoad: number;
  id: string;
}

export interface IGetTeacherSchema extends IGetTeacherData{
  activeStudents: number;
  lessons: number;
  conversionValue: number;
}

export enum EEducationStyle {
  AUTHORITARIAN = 'authoritarian',
  DEMOCRATIC = 'democratic',
  LIBERAL = 'liberal',
}

export enum EPeopleSpecialNeed {
  AUTISM = 'autism',
  MISCALCULATION = 'miscalculation',
  CEREBRAL_PALSY = 'cerebralPalsy',
  MENTAL_STRESS = 'mentalStress',
  MENTAL_DISABILITIES = 'mentalDisabilities',
}

export enum ETeacherInstrument {
  HEADSET = 'headset',
  GRAPHIC_TABLE = 'graphicTable',
  CAMERA = 'camera',
  MOUSE_AND_INTERACTIVE_BOARD = 'mouseAndInteractiveBoard',
  MAGNITOPHONE = 'magnitophone',
}

export enum EAdditionalSubject {
  PHISICS = 'Phisics',
  COMPUTES_SCIENCE = 'ComputerSciense',
}

export enum EEducationProgram {
  INTELIGENCE_OF_UKRAINE = 'intelectUkraine',
  WINGS_OF_SUCCESS = 'naKrylaxUspixy',
  ROSTOK = 'rostock',
  NUSH = 'NUS',
  A_LEVEL = 'a_level_further',
  ADV_MATH = 'adv_math',
  other = 'other',
}
