import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  exports: [
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTableModule,
  ],
})
export default class MatModule {}
