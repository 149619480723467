import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {ICreateTeacherSchema, IGetTeacherSchema} from "../models/change-user-info";
import { environment } from '@env/environment';

const API = environment.PifagorApiAddress;

@Injectable({
  providedIn: 'root'
})
export class ChangeUserInfoApiService {

  constructor(
    private readonly http: HttpClient
  ) { }

  public getTeacherDataById$GET(teacherId: string): Observable<{data: IGetTeacherSchema}> {
    return this.http.get<{data: IGetTeacherSchema}>(`/teachers/${teacherId}`)
  }

  public updateTeacherInfo$PATCH(data: ICreateTeacherSchema, teacherId: string): Observable<void>{
    return this.http.patch<void>(`/teachers/${teacherId}`, {...data})
  }

  public removeFileByUrl$DELETE(fileUrl: string): Observable<void> {
    return this.http.delete<void>(`/file`, {body: {url: fileUrl}});
  }

  public removePhotoByUrl$DELETE(fileUrl: string, teacherId: string): Observable<void> {
    return this.http.delete<void>(`/teachers/${teacherId}/avatar`, {body: {url: fileUrl}});
  }

  public resignTeacher$POST(teacherId: string): Observable<void> {
    return this.http.post<void>(`${API}/teachers/${teacherId}/resign`, {});
  }
}
