import { I18nLanguage } from '@shared/language/language.service';

export interface AchievementModel {
  isVisibleWhenInactive: boolean;
  state: boolean;
  photoUrl: string;
  name: string;
  isCustomReward: boolean;
  widthPx?: number;
}
interface Level {
  id: string;
  image?: string;
  name: string;
  pointsFrom: number;
  rate: number;
  ratePl: number;
}
export interface GetRewardsModel {
  feedbacks: {
    negative: number;
    positive: number;
  };
  income: number;
  level: Level;
  minLevel: Level | null;
  points: number;
  profit: number;
  regulationViolations: number;
  rewards: {
    name: string;
    points: number;
    isCustomReward: boolean;
  }[];
  availableHours: number;
  conversionValue: number;
}

export interface LevelModel {
  id: string;
  image: string | null;
  name: string;
  pointsFrom: number;
  rate: number;
  ratePl: number;
}
export interface CustomRewardModel {
  defaultAmount: number;
  header: string;
  id: number;
  image: string;
  name: string;
  state?: boolean;
  connectionId?: number;
}
export interface AddCustomRewardModel {
  data: {
    id: number;
    points: number;
    reward: {
      id: number;
    };
    teacher: {
      id: string;
    };
  }
  status: string;
}
export interface GetCustomRewardModel {
  data: {
    id: number;
    points: number;
    reward: {
      defaultAmount: number;
      header: number;
      id: number;
      image: string;
      name: string;
    };
  }[]
}

export const Achievements: AchievementModel[] = [
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-all-tools',
    name: 'personalInstrument',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-deep-learning',
    name: 'isDepthStudy',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-hours',
    name: 'freeHours24',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-lessons(1000)',
    name: 'lessons1000',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-lessons(2000)',
    name: 'lessons2000',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-lessons(3000)',
    name: 'lessons3000',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-many-sales',
    name: 'conversionOfSales',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-special-needs',
    name: 'specialNeeds',
    isCustomReward: true,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-study-experience(-3)',
    name: 'experienceLessThan3',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-study-experience(3-5)',
    name: 'experience3to5',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-study-experience(5-10)',
    name: 'experience5to10',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-study-experience(10-15)',
    name: 'experience10to15',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: false,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-study-experience(15+)',
    name: 'experienceMoreThan15',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-teacher-diploma',
    name: 'teachersDiploma',
    isCustomReward: false,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-youtube',
    name: 'youtube',
    isCustomReward: true,
  },
  {
    isVisibleWhenInactive: true,
    state: false,
    photoUrl: '/assets/achievements/${lang}/${lang}-zno',
    name: 'examPreparation',
    isCustomReward: false,
  },
]

export function GetAllAchievementsArrByLang(lang: string): AchievementModel[] {
  const langRegex = /\$\{lang\}/g;
  switch (lang) {
    case I18nLanguage.UA:
      return Achievements.map(item=> {
        return {
          ...item,
          photoUrl: item.photoUrl.replace(langRegex, 'ua')}
      });
    case I18nLanguage.PL:
      return Achievements.map(item=> {
        return {
          ...item,
          photoUrl: item.photoUrl.replace(langRegex, 'pl')}
      });
    default:
      return Achievements.map(item=> {
        return {
          ...item,
          photoUrl: item.photoUrl.replace(langRegex, 'en')}
      })
  }
}

export const IndicatorsInitialSettings: {
  positiveResponsesMaxValue: number;
  negativeResponsesMaxValue: number;
  regulationViolationMaxValue: number;
  lessonsPassedMaxValue: number;
} = {
  positiveResponsesMaxValue: 500,
  negativeResponsesMaxValue: 500,
  regulationViolationMaxValue: 500,
  lessonsPassedMaxValue: 5000,
}
