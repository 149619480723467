import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'math-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

  callback: Subject<string>;

  username: string;
  password: string;
  passwordVisibility: boolean;

  touched = {
    username: false,
    password: false,
  };

  error = {
    username: false,
    password: false,
    wrong: '',
  };

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.callback = new Subject<string>();
    this.passwordVisibility = false;

    this.callback.subscribe((message) => {
      this.error.wrong = message;
    });

    if (!this.authService.isLoggedIn()) return;

    this.router.navigate(['/users']);
  }

  focus(event: Event) {
    this.touched = {
      ...this.touched,
      // @ts-ignore
      [event.target.name]: true,
    };
  }

  blur(event: Event) {
    this.error = {
      ...this.error,
      // @ts-ignore
      [event.target.name]: !event.target.value,
    };
  }

  login() {
    this.authService.login(this.username, this.password/*, this.callback*/).catch(err => {
      this.error = {
        ...this.error,
        username: true,
        password: true,
      };
    });
  }

  keyDown(event: KeyboardEvent) {
    if (event.code !== "Enter" || this.checkButtonDisabled()) return;

    this.login();
  }

  checkButtonDisabled() {
    return !this.username || !this.password;
  }

  togglePasswordView(): void {
    this.passwordVisibility = !this.passwordVisibility;
  }

  getPasswordType(): string {
    if(this.passwordVisibility) return 'text'
    else return 'password';
  }

}
