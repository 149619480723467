import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport
} from '@angular/common/http';
import { DndModule } from 'ngx-drag-drop';
import { NzModule } from '@modules/nz-module';
import MatModule from '@modules/mat-module';

import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CoreModule } from '@core/core.module';
import { DefineDirective } from './shared';
import { AuthComponent } from '@modules/components/auth/auth.component';

import { ChangeUserInfoComponent } from '@modules/components/change-user-info/component/change-user-info.component';
import { AppButtonComponent } from '@shared/components/app-button/app-button.component';
import { AppInputComponent } from '@shared/components/app-input/app-input.component';
import { ViewUserInfoComponent } from '@modules/components/view-user-info/component/view-user-info.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HammerModule } from '@angular/platform-browser';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { IndicatorsComponent } from '@modules/components/view-user-info/component/indicators/indicators.component';
import { CountUpDirective } from '@shared/directives/count-up.directive';
import {
  IndicatorsCalcComponent
} from '@modules/components/view-user-info/component/indicators-calc/indicators-calc.component';
import {
  TeacherStatementModalComponent
} from '@modules/components/view-user-info/component/teacher-statement-modal/teacher-statement-modal.component';
import { FreeHoursComponent } from '@modules/components/free-hours/free-hours.component';
import {
  FreeHoursFiltersComponent
} from '@modules/components/free-hours/free-hours-filters/free-hours-filters.component';
import {
  FreeHoursFiltersStateComponent
} from '@modules/components/free-hours/free-hours-filters-state/free-hours-filters-state.component';
import { WeekSwitcherComponent } from '@modules/components/week-switcher/week-switcher.component';
import { WeekScheduleComponent } from '@modules/components/week-schedule/week-schedule.component';
import { SigninComponent } from '@modules/components/auth/signin/signin.component';
import { SignupComponent } from '@modules/components/auth/signup/signup.component';
import { PasswordRestoreComponent } from '@modules/components/auth/password-restore/password-restore.component';
import { SignupSuccessComponent } from '@modules/components/auth/signup-success/signup-success.component';
import { LanguageSwitchComponent } from '@modules/components/auth/language-switch/language-switch.component';
import {
  TeacherSettingsModalComponent
} from '@modules/components/view-user-info/component/teacher-settings-modal/teacher-settings-modal.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageLoader } from '@shared/language/language.service';
import { ScheduleStartComponent } from '@modules/components/schedule/start/schedule-start.component';
import { ScheduleCalendarComponent } from '@modules/components/schedule/calendar/schedule-calendar.component';
import {
  ScheduleCalendarFiltersComponent
} from '@modules/components/schedule/calendar/schedule-calendar-filters/schedule-calendar-filters.component';
import {
  ScheduleYearCalendarComponent
} from '@modules/components/schedule/calendar/schedule-year-calendar/schedule-year-calendar.component';
import {
  ScheduleMonthCalendarComponent
} from '@modules/components/schedule/calendar/schedule-month-calendar/schedule-month-calendar.component';
import {
  ScheduleWeekCalendarComponent
} from '@modules/components/schedule/calendar/schedule-week-calendar/schedule-week-calendar.component';
import { ScheduleCalendarHostDirective } from '@modules/components/schedule/calendar/schedule-calendar-host.directive';
import {
  ScheduleCalendarSlotComponent
} from '@modules/components/schedule/calendar/schedule-calendar-slot/schedule-calendar-slot.component';
import {
  ScheduleDayCalendarComponent
} from '@modules/components/schedule/calendar/schedule-day-calendar/schedule-day-calendar.component';
import {
  ScheduleCalendarFirstLessonComponent
} from '@modules/components/schedule/calendar/schedule-calendar-first-lesson/schedule-calendar-first-lesson.component';
import {
  MoveLessonDndModalComponent
} from '@modules/components/schedule/calendar/modals/move-lesson-dnd-modal/move-lesson-dnd-modal.component';
import {
  ScheduleCalendarLessonDetailsComponent
} from '@modules/components/schedule/calendar/schedule-calendar-lesson-details/schedule-calendar-lesson-details.component';
import {
  ScheduleCalendarMoveLessonComponent
} from '@modules/components/schedule/calendar/schedule-calendar-move-lesson/schedule-calendar-move-lesson.component';
import { MathemaModalConfirmComponent } from '@shared/components/modal-confirm/modal-confirm.component';
import { SlotIconComponent } from '@shared/components/slot-icon/slot-icon.component';
import ScheduleModule from './modules/components/schedule/schedule.module';
import SharedModule from './shared/shared.module';
import { en_US, NZ_I18N, pl_PL, uk_UA } from 'ng-zorro-antd/i18n';
import {
  ScheduleCalendarVacationsComponent
} from '@modules/components/schedule/calendar/schedule-calendar-vacations/schedule-calendar-vacations.component';
import { MatDialogModule } from '@angular/material/dialog';
import {
  HeaderNotificationsWidgetComponent
} from '@shared/components/header-notifications-widget/header-notifications-widget.component';
import { CollapseExtraButtonsComponent } from './modules/components/free-hours/components/collapse-extra-buttons/collapse-extra-buttons.component';
import { MathemaInfoBlockComponent } from "./shared/components/mathema-info-block/mathema-info-block.component";
import {
  LeftSiderDirective,
  RightSiderDirective,
  ContentSiderStyleDirective,
  HeaderBackButtonDirective,
  HeaderExtraDirective,
  HeaderSubtitleDirective,
  HeaderTitleDirective,
  HeaderTitleActionDirective,
} from '@app/layouts/main';
import { TeacherSignupFormComponent } from './modules/components/auth/signup/teacher-signup-form/teacher-signup-form.component';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';

@NgModule({
  declarations: [
    AppComponent,
    DefineDirective,
    ScheduleCalendarHostDirective,
    AuthComponent,
    ChangeUserInfoComponent,
    AppButtonComponent,
    AppInputComponent,
    ViewUserInfoComponent,
    NotificationComponent,
    IndicatorsComponent,
    CountUpDirective,
    IndicatorsCalcComponent,
    TeacherStatementModalComponent,
    FreeHoursComponent,
    FreeHoursFiltersComponent,
    FreeHoursFiltersStateComponent,
    WeekSwitcherComponent,
    WeekScheduleComponent,
    SigninComponent,
    SignupComponent,
    PasswordRestoreComponent,
    SignupSuccessComponent,
    LanguageSwitchComponent,
    TeacherSettingsModalComponent,
    ScheduleStartComponent,
    ScheduleCalendarComponent,
    ScheduleCalendarFiltersComponent,
    ScheduleYearCalendarComponent,
    ScheduleMonthCalendarComponent,
    ScheduleWeekCalendarComponent,
    ScheduleCalendarSlotComponent,
    SlotIconComponent,
    ScheduleDayCalendarComponent,
    ScheduleCalendarFirstLessonComponent,
    MoveLessonDndModalComponent,
    ScheduleCalendarLessonDetailsComponent,
    ScheduleCalendarMoveLessonComponent,
    ScheduleCalendarVacationsComponent,
    MathemaModalConfirmComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
            provide: TranslateLoader,
            useClass: LanguageLoader,
        },
    }),
    DndModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    NzModule,
    MatModule,
    MatDialogModule,
    ScheduleModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ScrollingModule,
    DragDropModule,
    CoreModule,
    HammerModule,
    ClipboardModule,
    HeaderNotificationsWidgetComponent,
    CollapseExtraButtonsComponent,
    MathemaInfoBlockComponent,
    HeaderTitleDirective,
    HeaderSubtitleDirective,
    HeaderExtraDirective,
    HeaderBackButtonDirective,
    LeftSiderDirective,
    RightSiderDirective,
    ContentSiderStyleDirective,
    HeaderTitleActionDirective,
    TeacherSignupFormComponent,
    NzAvatarModule,
    NzSegmentedModule,
],
  providers: [
    {
      provide: NZ_I18N,
      useFactory: () => {
        const userLanguage = localStorage.getItem('userLanguage');
        switch (userLanguage) {
          case 'en':
            return en_US;
          case 'pl':
            return pl_PL;
          case 'ua':
            return uk_UA;
          default:
            return en_US;
        }
      },
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class AppModule {}
