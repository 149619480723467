import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {NotificationService} from "@shared/services/notification.service";
import { TranslateService } from '@ngx-translate/core';
import { I18nLanguage } from '@shared/language/language.service';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptor implements HttpInterceptor {
  /**
   * Inject storage
   */
  public constructor(
    private readonly translateService: TranslateService,
    public readonly notificationService: NotificationService
  ) {
    this.urlBase = environment.APIEndpoint;
  }

  /**
   * Url base for all requests made via http to api
   */
  private urlBase: string;

  /**
   * Set needed headers for api
   */
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.includes('.svg') &&
      !request.url.includes('icons') &&
      !request.url.includes('assets')
    ) {
      const url = request.url.startsWith('http')
        ? request.url
        : this.urlBase + request.url;

      request = request.clone({
        url,
      });
    }

    return next.handle(request).pipe(
      map((data)=>{
          return data;
      }),
      catchError((err) => {
        if (request.url.startsWith(environment.PifagorApiAddress)) {
          if (err.status === 400) {
            const errorMessage = err.error[this.translateService.currentLang] ?? err.error[I18nLanguage.EN] ?? err?.error?.message;
            this.notificationService.showErrorMessage(errorMessage);
          } else if (err.status === 500) {
            const timestamp = err.error?.timestamp;
            const key = timestamp ? 'main.api.internal-with-timestamp' : 'main.api.internal';
            this.translateService.get(key, { timestamp }).subscribe(message => {
              this.notificationService.showErrorMessage(message);
            });
          } else if (err.status === 403) {
            this.notificationService.showErrorMessage(this.translateService.instant('main.api.forbidden'));
          } else {
            this.notificationService.showErrorMessage(this.translateService.instant('Unknown Error'));
          }
        } else {
          if(err.statusText === 'Unknown Error'){
            this.notificationService.showErrorMessage(this.translateService.instant('Unknown Error'));
            return throwError(err)
          }
          if (environment.errorCodesExceptions.includes(err.error.code)){
            return throwError(err)
          }

          if (err.error.code && !err.error?.data) {
            this.translateService.get(`${err.error.code}`).subscribe(message => {
              this.notificationService.showErrorMessage(message);
            });
          } else if (!err.error.code && err.error?.message) {
            this.notificationService.showErrorMessage(err.error.message);
          }
        }

        return throwError(err)
      }),
    );
  }
}
