import { Observable, Subscription } from 'rxjs';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  studentGrades,
  studyDays,
  studyProgram,
  teachingExperience,
  tutoringExperience,
  exams,
  specialNeeds,
  studyLevels,
  teachingStyles,
  teachingLanguages,
  lessonDurations,
  teacherStatuses,
  additionalSubjects,
  paymentLevels,
  qualifications,
  genders,
  verifications,
  notTakeNewStudents,
} from './free-hours-constants';
import { translatedCountriesList } from '../../change-user-info/models/countries';
import FreeHoursService, { StudyDayFilterDto } from '../free-hours.service';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { halfHours, LanguageEnum } from '@shared/constants';
import LanguageService from '@app/shared/language/language.service';
import { getShortWeekdayName } from '@app/shared/utils';

export enum FiltersKeys {
  STUDY_DAY_TIME = 'studyDayTime',
  STUDY_DAYS = 'studyDay',
  STUDY_TIME = 'studyTime',
  TEACHER_AGE = 'teacherAge',
  STUDENT_GRADE = 'studentGrade',
  STUDY_PROGRAM = 'studyProgram',
  TEACHING_EXPERIENCE = 'teachingExperience',
  TUTORING_EXPERIENCE = 'tutoringExperience',
  EXAMS = 'exams',
  SPECIAL_NEEDS = 'specialNeeds',
  STUDYING_LEVELS = 'studyingLevels',
  TEACHING_STYLE = 'teachingStyle',
  TEACHING_LANG = 'teachingLanguage',
  STUDY_DATE_RANGE = 'studyDateRange',
  LESSON_DURATION = 'lessonDuration',
  WORKLOAD = 'workload',
  CONVERSION = 'conversion',
  TEACHER_STATUS = 'status',
  COUNTRY = 'country',
  ADDITIONAl_SUBJECT = 'additionalSubject',
  PAYMENT_LEVEL = 'level',
  QUALIFICATION = 'qualification',
  GENDER = 'gender',
  VERIFICATION = 'verification',
  SHOW_NOT_TAKE_NEW_STUDENTS = 'showNotTakeNewStudents',
}

export interface FilterChangedEvent {
  isActive: boolean;
  key: FiltersKeys;
  value: string;
  label: string;
  skipLoad?: boolean;
}

export interface ActiveFilter {
  key: FiltersKeys;
  secondaryKey?: string;
  values: string[];
  labels: string[];
}

interface FilterValue {
  value: string;
  label: string;
  isChecked: boolean;
  img?: string;
}

@Component({
  selector: 'mat-free-hours-filters',
  templateUrl: './free-hours-filters.component.html',
  styleUrls: ['./free-hours-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FreeHoursFiltersComponent implements OnInit, OnDestroy {
  private resetAllFiltersSub: Subscription;
  @Input() resetAllFiltersEvent: Observable<void>;

  private resetFilterSub: Subscription;
  @Input() resetFilterEvent: Observable<FiltersKeys>;

  @Input() activeFilters: ActiveFilter[];

  @Output() onChangeFilterValue = new EventEmitter<FilterChangedEvent>();
  @Output() onSetFilterValue = new EventEmitter<FilterChangedEvent>();
  @Output() onResetFilter = new EventEmitter<FiltersKeys>();

  public filtersKeys = FiltersKeys;
  public [FiltersKeys.STUDY_DAYS]: FilterValue[] = studyDays;
  public [FiltersKeys.STUDENT_GRADE]: FilterValue[];
  public [FiltersKeys.STUDY_PROGRAM]: FilterValue[];
  public [FiltersKeys.TEACHING_EXPERIENCE]: FilterValue[] = teachingExperience;
  public [FiltersKeys.TUTORING_EXPERIENCE]: FilterValue[] = tutoringExperience;
  public [FiltersKeys.EXAMS]: FilterValue[];
  public [FiltersKeys.SPECIAL_NEEDS]: FilterValue[] = specialNeeds;
  public [FiltersKeys.STUDYING_LEVELS]: FilterValue[] = studyLevels;
  public [FiltersKeys.TEACHING_STYLE]: FilterValue[] = teachingStyles;
  public [FiltersKeys.TEACHING_LANG]: FilterValue[] = teachingLanguages;
  public [FiltersKeys.LESSON_DURATION]: FilterValue[] = lessonDurations;
  public [FiltersKeys.TEACHER_STATUS]: FilterValue[] = teacherStatuses;
  public [FiltersKeys.ADDITIONAl_SUBJECT]: FilterValue[] = additionalSubjects;
  public [FiltersKeys.PAYMENT_LEVEL]: FilterValue[] = paymentLevels;
  public [FiltersKeys.QUALIFICATION]: FilterValue[];
  public [FiltersKeys.GENDER]: FilterValue[] = genders;
  public [FiltersKeys.VERIFICATION]: FilterValue[] = verifications;
  public [FiltersKeys.SHOW_NOT_TAKE_NEW_STUDENTS]: FilterValue[] = notTakeNewStudents;
  public studyDateRange = null;

  public countries: { id: string; label: string; value: string }[] = [];
  public selectedCountries: string[] = [];

  /* Study time values */
  public studyTimeRange: number[];
  public studyTimeMin: number;
  public studyTimeMax: number;

  /* Teacher age values */
  public teacherAgeRange: number[];
  public teacherMinAge: number;
  public teacherMaxAge: number;

  public readonly TIME_RANGE_MIN = 0;
  public readonly TIME_RANGE_MAX = 48;

  constructor(
    public readonly translateService: TranslateService,
    public readonly authService: AuthService,
    private readonly freeHoursService: FreeHoursService,
    private readonly languageService: LanguageService,
  ) {
    this.resetStudyDayTimes();
    this.resetTeacherAge();
    this.resetCountry();

    const uiLang = translateService.currentLang;

    this[FiltersKeys.STUDENT_GRADE] = studentGrades[uiLang];
    this[FiltersKeys.EXAMS] = exams[uiLang];
    this[FiltersKeys.STUDY_PROGRAM] = studyProgram[uiLang];
    this[FiltersKeys.QUALIFICATION] = qualifications[uiLang];
  }

  public ngOnInit(): void {
    this.resetAllFiltersSub = this.resetAllFiltersEvent.subscribe(this.resetAllFilters.bind(this));
    this.resetFilterSub = this.resetFilterEvent.subscribe(key => this.resetFilterByKey(key, false));
    this.initStateFromActiveFilters(this.activeFilters);

    setTimeout(this.initStudyDateRange.bind(this), 1000);

    this.freeHoursService.getTeachersUsedCountries().subscribe(result => {
      let countries = translatedCountriesList(this.translateService.currentLang.toUpperCase() as LanguageEnum);

      if (result?.length) {
        countries = countries.filter(el => result.includes(el.value.toLowerCase()));
      }

      for (const country of countries) {
        const value = country.value.toLowerCase();
        this.countries.push({ id: value, label: country.text, value });
      }
    });
  }

  public getPaymentLevelTip(paymentLevel: string): string {
    return this.freeHoursService.makePaymentLevelTip(paymentLevel, this.authService);
  }

  private initStateFromActiveFilters(activeFilters: ActiveFilter[]): void {
    if (!activeFilters.length) {
      return;
    }

    for (const filter of activeFilters) {
      const key: FiltersKeys = filter.key;

      if (filter.key === FiltersKeys.TEACHER_AGE) {
        const [min, max] = filter.values[0].split(',').map(el => +el);
        this.resetTeacherAge(false, min, max);
      } else if (filter.key === FiltersKeys.COUNTRY) {
        this.resetCountry(false, filter.values[0].split(','));
      } else if (filter.key === FiltersKeys.STUDY_DATE_RANGE) {
        const [start, end] = filter.values[0].split(',')
        this.resetStudyDateRange(false, new Date(start), new Date(end));
      } else {
        this[key] = this[key]?.map(el => ({ ...el, isChecked: filter.values.includes(el.value) }));
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.resetAllFiltersSub) {
      this.resetFilterSub.unsubscribe();
    }

    if (this.resetFilterSub) {
      this.resetFilterSub.unsubscribe();
    }
  }

  public resetFilterByKey(key: FiltersKeys, emitEvent = true): void {
    if (key === FiltersKeys.TEACHER_AGE) {
      this.resetTeacherAge(emitEvent);
      return;
    } else if (key === FiltersKeys.COUNTRY) {
      this.resetCountry(emitEvent);
      return;
    } else if (key === FiltersKeys.STUDY_DATE_RANGE) {
      this.resetStudyDateRange(emitEvent);
      return;
    } else if (key === FiltersKeys.STUDY_DAY_TIME) {
      this.resetStudyDayTimes();
      return;
    }

    this[key] = this[key]?.map(el => ({ ...el, isChecked: false }));

    if (emitEvent) {
      this.onResetFilter.emit(key);
    }
  }

  private resetStudyDayTimes(): void {
    this.studyTimeRange = [this.TIME_RANGE_MIN, this.TIME_RANGE_MAX];
    this.studyTimeMin = this.TIME_RANGE_MIN;
    this.studyTimeMax = this.TIME_RANGE_MAX;
    this[FiltersKeys.STUDY_DAYS] = studyDays.map(el => ({ ...el, isChecked: false }));
  }

  public selectStudyDay(isActive: boolean, day: string): void {
    this[FiltersKeys.STUDY_DAYS].map(el => {
      if (el.value === day) {
        el.isChecked = isActive;
      }
    });
  }

  public isStudyDayTimesChecked(): boolean {
    return this[FiltersKeys.STUDY_DAYS].some(el => el.isChecked);
  }

  private resetAllFilters(): void {
    for (const key of Object.values(FiltersKeys)) {
      this.resetFilterByKey(key, false);
    }
  }

  public getValuesByKey(key: FiltersKeys): FilterValue[] {
    return this[key];
  }

  public onCheckFilter(isActive: boolean, key: FiltersKeys, value: string, label: string): void {
    this.onChangeFilterValue.emit({ isActive, key, value, label });
  }

  /* Study date range handlers */
  private initStudyDateRange(): void {
    const start = new Date();
    const end = new Date();
    const currentDay = end.getDay();
    const daysToAdd = (7 - (currentDay === 7 ? 0 : currentDay)) + 7;
    end.setDate(end.getDate() + daysToAdd);

    this.changeStudyDateRange([start, end], true);
  }

  public changeStudyDateRange(event: Date[], skipLoad = false): void {
    const [start] = event[0].toISOString().split('T');
    const [end] = event[1].toISOString().split('T');

    this.onSetFilterValue.emit({
      isActive: true,
      key: FiltersKeys.STUDY_DATE_RANGE,
      value: `${start},${end}`,
      label: `${this.translateService.instant('free_hours.from')} ${start} ${this.translateService.instant('free_hours.to')} ${end}`,
      skipLoad,
    });
  }

  private resetStudyDateRange(emitEvent = true, start?: Date, end?: Date): void {
    if (start && end) {
      this[FiltersKeys.STUDY_DATE_RANGE] = [start, end];
    } else {
      this[FiltersKeys.STUDY_DATE_RANGE] = null;
    }

    if (!emitEvent) {
      return;
    }

    this.onResetFilter.emit(FiltersKeys.STUDY_DATE_RANGE);
  }

  /* Country handlers */
  public resetCountry(emitEvent = true, countries = []): void {
    this.selectedCountries = countries;

    if (!emitEvent) {
      return;
    }

    this.onResetFilter.emit(FiltersKeys.COUNTRY);
  }

  public onSetCountries(event: string[]): void {
    if (!event.length) {
      this.resetCountry();
      return;
    }

    const labels = this.countries.filter(el => event.includes(el.id.toLowerCase())).map(el => el.label);
    this.onSetFilterValue.emit({
      isActive: true,
      key: FiltersKeys.COUNTRY,
      value: event.join(','),
      label: `${this.translateService.instant('free_hours.country')}: ${labels.join(', ')}`,
    });
  }

  /* Study time handlers */
  public onStudyTimeRangeChange(value: number[] | number): void {
    if (Array.isArray(value)) {
      const [min, max] = value;
      this.studyTimeMin = min;
      this.studyTimeMax = max;
    }
  }

  public onStudyTimeMinChange(value: number): void {
    this.studyTimeRange = [value, this.studyTimeRange[1]];
  }

  public onStudyTimeMaxChange(value: number): void {
    this.studyTimeRange = [this.studyTimeRange[0], value];
  }

  public formatStudyTime(value: number): string {
    return halfHours[value];
  }

  public onConfirmStudyDayTime(): void {
    const filterValue: StudyDayFilterDto = {
      weekDays: this[FiltersKeys.STUDY_DAYS].filter(el => el.isChecked).map(el => +el.value),
      timeStart: halfHours[this.studyTimeMin],
      timeEnd: halfHours[this.studyTimeMax],
    };

    const daysLabel = this[FiltersKeys.STUDY_DAYS]
      .filter(el => el.isChecked)
      .map(el => getShortWeekdayName(+el.value, this.languageService.locale))
      .join(', ');

    const label = `${daysLabel} ${this.translateService.instant('free_hours.from')} ${halfHours[this.studyTimeMin]} `
      + `${this.translateService.instant('free_hours.to')} ${halfHours[this.studyTimeMax]}`;

    this.onSetFilterValue.emit({
      isActive: true,
      key: FiltersKeys.STUDY_DAY_TIME,
      value: JSON.stringify(filterValue),
      label,
    });

    this.resetStudyDayTimes();
  }

  /* Teacher age handlers */
  public onTeacherAgeRangeChange(value: number[] | number): void {
    if (Array.isArray(value)) {
      const [min, max] = value;
      this.teacherMinAge = min;
      this.teacherMaxAge = max;
    }
  }

  public resetTeacherAge(emitEvent = true, min = 16, max = 75): void {
    this.teacherAgeRange = [min, max];
    this.teacherMinAge = min;
    this.teacherMaxAge = max;

    if (!emitEvent) {
      return;
    }

    this.onResetFilter.emit(FiltersKeys.TEACHER_AGE);
  }

  public onTeacherMinAgeChange(value: number): void {
    this.teacherAgeRange = [value, this.teacherAgeRange[1]];
  }

  public onTeacherMaxAgeChange(value: number): void {
    this.teacherAgeRange = [this.teacherAgeRange[0], value];
  }

  public onConfirmTeacherAge(): void {
    this.onSetFilterValue.emit({
      isActive: true,
      key: FiltersKeys.TEACHER_AGE,
      value: `${this.teacherMinAge},${this.teacherMaxAge}`,
      label: `${this.translateService.instant('free_hours.from')} ${this.teacherMinAge} ${this.translateService.instant('free_hours.to')} ${this.teacherMaxAge} ${this.translateService.instant('free_hours.years')}`,
    });
  }

  /* Other */
  public scrollDown(isExpanded): void {
    if (isExpanded) {
      setTimeout(() => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      }, 150);
    }
  }

  public onAllDaysClick(): void {
    if (this.isStudyDayTimesChecked()) {
      this[FiltersKeys.STUDY_DAYS] = studyDays.map(el => ({ ...el, isChecked: false }));
    } else {
      this[FiltersKeys.STUDY_DAYS] = studyDays.map(el => ({ ...el, isChecked: true }));
    }
  }
}
