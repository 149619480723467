import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FiltersKeys } from '../free-hours-filters/free-hours-filters.component';
import { TranslateService } from '@ngx-translate/core';

interface ActiveFilter {
  key: FiltersKeys;
  values: string[];
  labels: string[];
}

interface DeactivateEvent {
  key: FiltersKeys;
  reload: boolean;
}

@Component({
  selector: 'mat-free-hours-filters-state',
  templateUrl: './free-hours-filters-state.component.html',
  styleUrls: ['./free-hours-filters-state.component.scss']
})
export class FreeHoursFiltersStateComponent implements OnInit {
  @Input() activeFilters: ActiveFilter[] = [];
  @Input() teachersFound: number;

  @Output() onDeactivateFilter: EventEmitter<DeactivateEvent> = new EventEmitter<DeactivateEvent>();
  @Output() onToggleFiltersDrawer: EventEmitter<void> = new EventEmitter<void>();
  @Output() onToggleFiltersModal: EventEmitter<void> = new EventEmitter<void>();

  constructor(public readonly translateService: TranslateService) { }

  ngOnInit(): void {
  }

  public formatLabels(key: string, labels: string[]): string {
    switch (key) {
      case FiltersKeys.STUDY_TIME:
        return labels.join(' ');
      case FiltersKeys.TEACHING_EXPERIENCE:
        return this.translateService.instant('study_experience') + ': ' + labels.map(label => this.translateService.instant(label)).join(', ');
      case FiltersKeys.TUTORING_EXPERIENCE:
        return this.translateService.instant('tutor_experience') + ': ' + labels.map(label => this.translateService.instant(label)).join(', ');
      case FiltersKeys.STUDENT_GRADE:
        return this.translateService.instant('grade') + ': ' + labels.map(label => this.translateService.instant(label)).join(', ');
      case FiltersKeys.STUDY_PROGRAM:
        return labels.map(label => this.translateService.instant(label)).join(', ');
      case FiltersKeys.GENDER:
        return this.translateService.instant('gender') + ': ' + labels.map(label => this.translateService.instant(label)).join(', ');
      default:
        return labels.map(label => this.translateService.instant(label)).join(', ');
    }
  }

  public deactivateAllFilters(): void {
    const length = this.activeFilters.length;

    for (let i = 0; i < length; i++) {
      this.deactivateFilter(this.activeFilters[i].key, i === length - 1);
    }
  }

  public deactivateFilter(key: FiltersKeys, reload = true): void {
    this.onDeactivateFilter.emit({ key, reload });
  }

}
