<nz-row class="active-filters" nzAlign="middle" [nzGutter]="[8, 8]">
  <nz-col class="show-button-md">
    <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="onToggleFiltersDrawer.emit()" class="show-btn">
      <span nz-icon nzType="filter" nzTheme="fill"></span>
      {{'apply_filters' | translate}}
    </button>
  </nz-col>
  <nz-col class="show-button-sm">
    <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="onToggleFiltersModal.emit()" class="show-btn">
      <span nz-icon nzType="filter" nzTheme="fill"></span>
      {{'apply_filters' | translate}}
    </button>
  </nz-col>
  <nz-col><span class="total-result">
    {{'found' | translate}} {{'teachers' | translate}}: {{teachersFound}}</span>
  </nz-col>
  <nz-col class="active-filter" *ngIf="activeFilters.length !== 0">
    <nz-tag class="active-filter-tag close-all" (click)="deactivateAllFilters()">
      <span class="value">{{'reset_all_filters' | translate}}</span>
    </nz-tag>
  </nz-col>
  <nz-col class="active-filter" *ngFor="let filter of activeFilters">
    <nz-tag class="active-filter-tag" (click)="$event.stopPropagation(); deactivateFilter(filter.key)">
      <span class="value">{{formatLabels(filter.key, filter.labels)}}</span>
      <span nz-icon nzType="close" class="close-icon"></span>
    </nz-tag>
  </nz-col>
</nz-row>
