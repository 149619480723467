<nz-layout class="base">
  <nz-layout class="base-container">
    <nz-row nzJustify="space-between">
      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <div class="logo">
          <img src="assets/mathema-logo.png" alt="Logo">
        </div>
      </nz-col>
      <nz-col [nzXs]="24"
              [nzSm]="6"
              [nzMd]="6"
              [nzLg]="6">
        <app-language-switch></app-language-switch>
      </nz-col>
    </nz-row>
    <nz-row>
      <nz-col [nzXs]="24"
              [nzSm]="20"
              [nzMd]="20"
              [nzLg]="20">
        <div class="header mobile-align-center">
          <p class="head-line">
            {{ 'auth.sign-up.header' | translate }}
          </p>

          <p class="annotation">
            {{ 'auth.sign-up.description' | translate }}
          </p>
        </div>
      </nz-col>
    </nz-row>
    <nz-row nzJustify="space-evenly">
      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 14, order: 2}"
              [nzMd]="{span: 14, order: 2}"
              [nzLg]="{span: 14, order: 2}"
              class="align-box">
        <img class="illustration"
             alt=""
             src="assets/login/signup.svg">
      </nz-col>

      <nz-col [nzXs]="{span: 24}"
              [nzSm]="{span: 9, order: 1}"
              [nzMd]="{span: 9, order: 1}"
              [nzLg]="{span: 9, order: 1}">
        <div class="form-block">
          @if (isTeacherSignupView()) {
          <math-teacher-signup-form />
          } @else {
          <ng-container *ngTemplateOutlet="signupForm"></ng-container>

          <div class="button">
            <button nz-button (click)="register()">
              {{ 'auth.sign-up.confirm' | translate }}
            </button>
          </div>
          <div class="login">
            <span>{{ 'auth.sign-up.already-signed-up' | translate }}</span>
            <a class="login-link" routerLink="/login">
              {{ 'auth.sign-up.to-sign-in' | translate }}
            </a>
          </div>
          }
        </div>
      </nz-col>
    </nz-row>
  </nz-layout>
</nz-layout>

<ng-template #signupForm>
  <form nz-form [formGroup]="form" nzLayout="vertical">
    <nz-form-item>
      <nz-form-label nzRequired nzFor="firstName">
        {{ 'auth.sign-up.first-name' | translate }}
      </nz-form-label>
  
      <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
        <input nz-input type="text" formControlName="firstName" />
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzRequired nzFor="lastName">
        {{ 'auth.sign-up.last-name' | translate }}
      </nz-form-label>
  
      <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
        <input nz-input type="text" formControlName="lastName">
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzRequired nzFor="email">
        {{ 'auth.sign-up.email' | translate }}
      </nz-form-label>
  
      <nz-form-control [nzErrorTip]="emailErrorTpl" [nzValidatingTip]="'main.form.validation.pending' | translate">
        <nz-input-group [nzPrefix]="emailPrefix">
          <input nz-input type="email" formControlName="email">
        </nz-input-group>
        <ng-template #emailErrorTpl let-control>
          @if (control?.errors?.['required']) {
          {{'main.form.errors.required-tip' | translate}}<br />
          }
          @if (control?.errors?.['emailTaken']) {
          {{'main.form.errors.email-taken' | translate}}<br />
          }
          @if (control?.errors?.['email']) {
          {{'main.form.errors.email-not-valid' | translate}}<br />
          }
        </ng-template>
  
        <ng-template #emailPrefix>
          <span nz-icon>
            <img src="assets/login/username-prefix.svg" alt="at" />
          </span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzFor="username">
        {{ 'auth.sign-up.username' | translate }}
      </nz-form-label>
  
      <nz-form-control [nzValidatingTip]="'main.form.validation.pending' | translate" [nzErrorTip]="usernameErrorTpl">
        <input nz-input type="text" formControlName="username" />
        <ng-template #usernameErrorTpl let-control>
          @if (control?.errors?.['pattern']) {
          {{'main.form.errors.alphanumeric' | translate }}<br />
          }
          @if (control?.errors?.['usernameTaken']) {
          {{'main.form.errors.username-taken' | translate}}<br />
          }
          @if (control?.errors?.['minlength']) {
          {{'main.form.errors.min-length' | translate: { value: 6 } }}<br />
          }
          @if (control?.errors?.['maxlength']) {
          {{'main.form.errors.max-length' | translate: { value: 128 } }}<br />
          }
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzRequired nzFor="password">
        {{ 'auth.sign-up.password' | translate }}
      </nz-form-label>
  
      <nz-form-control [nzErrorTip]="passwordErrorTpl">
        <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordSuffix">
          <input nz-input [type]="passwordView ? 'text' : 'password'" formControlName="password">
        </nz-input-group>
        <ng-template #passwordErrorTpl let-control>
          @if (control?.errors?.['required']) {
          {{'main.form.errors.required-tip' | translate}}<br />
          }
          @if (control?.errors?.['minlength']) {
          {{'main.form.errors.min-length' | translate: { value: 8 } }}<br />
          }
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  
    <nz-form-item>
      <nz-form-label nzRequired nzFor="passwordConfirm">
        {{ 'auth.sign-up.password-confirm' | translate }}
      </nz-form-label>
      <nz-form-control [nzErrorTip]="passwordConfirmErrorTpl">
        <nz-input-group [nzPrefix]="passwordPrefix" [nzSuffix]="passwordSuffix">
          <input nz-input [type]="passwordView ? 'text' : 'password'" formControlName="passwordConfirm">
        </nz-input-group>
        <ng-template #passwordConfirmErrorTpl let-control>
          @if (control?.errors?.['required']) {
          {{'main.form.errors.required-tip' | translate}}<br />
          }
          @if (control?.errors?.['minlength']) {
          {{'main.form.errors.min-length' | translate: { value: 8 } }}<br />
          }
          @if (control?.errors?.['passwordsMismatch']) {
          {{'main.form.errors.password-mismatch' | translate: { value: 8 } }}<br />
          }
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  
    <ng-template #passwordPrefix>
      <span nz-icon>
        <img src="assets/login/password-prefix.svg" alt="">
      </span>
    </ng-template>
  
    <ng-template #passwordSuffix>
      <span nz-icon (click)="passwordView = !passwordView" class="pointer">
        <img src="assets/login/password-suffix.svg" alt="">
      </span>
    </ng-template>
  </form>
</ng-template>
